import React, { useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "./Enter.css"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { useAppDispatch } from "../../../app/store"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../app/hooks"
import { MODALNAME, USERTYPE } from "../../../constants"
import {
  closeModal,
  setModalName,
} from "../../../features/Auth/ModalHandler/modalSlice"
import Verified from "./Verified"
import {
  useEmailVerifyMutation,
  useProviderEmailverifyMutation,
  useResendOtpMutation,
  useResendOtpProviderMutation,
} from "../../../features/Auth/authApi"
import { toast } from "react-toastify"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import { setError } from "../../../features/seekerDashboard/seekerDashboardSlice"

const OTP = () => {
  const [otpError, setOtpError] = useState(null)
  const [valerror, setValError] = useState<string>()
  const [resentMessage, setResentMessage] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState(0)
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const { currentUserRole, user } = useAppSelector(selectCurrentUser)

  const auth = useAppSelector((state: any) => state.auth)

  // seekr email verify
  const [verifyEmail, { data, isError, isLoading, isSuccess, error }] =
    useEmailVerifyMutation()
  const [
    providerEmailVerify,
    {
      error: providererror,
      isLoading: peoviderloading,
      isSuccess: providerverifySuccess,
    },
  ] = useProviderEmailverifyMutation()
  const [
    resendOtp,
    {
      isLoading: loading,
      error: resendError,
      isSuccess: resendsuccess,
      data: resendOtpData,
    },
  ] = useResendOtpMutation()

  const [
    resendOtpProvider,
    {
      isLoading: resendOTPproviderloading,
      error: resendOTPproviderError,
      isSuccess: resendOTPproviderSuccess,
      data: resendProviderOtpData,
    },
  ] = useResendOtpProviderMutation()
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState(["", "", "", "", "", ""])

  const inputRefs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ]

  const handleInputChange = (index: number, event: any) => {
    setResentMessage(true as any)
    const value = event.target.value
    setOtpError(null)
    if (isNaN(Number(value))) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, event: any) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current?.focus()
    }
  }

  const handleEmailVerify = () => {
    if (user.role === "Seeker" || currentUserRole === USERTYPE.SEEKER) {
      if (otp.join("") === "") {
        setValError("Plese enter the OTP shared")
      } else {
        verifyEmail({ otp: otp.join(""), email: user?.email })
        setValError("")
      }
    } else {
      if (otp.join("") === "") {
        setValError("Plese enter the OTP shared")
      } else {
        const payload = {
          email: user?.email,
          otp: otp.join(""),
        }
        providerEmailVerify(payload)
        setValError("")
      }
    }
  }
  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""])
    setOtpError(null)
    setValError("")
    if (currentUserRole === USERTYPE.SEEKER || user?.role === "Seeker") {
      const res = resendOtp(user?.email)
      if ((res as any)?.token) {
        setValError("")
        setOtpError(null)
      }
    } else {
      const res = resendOtpProvider(user?.email)
      if ((res as any)?.token) {
        setValError("")
        setOtpError(null)
      }
    }
  }

  useEffect(() => {
    if (isSuccess || providerverifySuccess) {
      setOtp(["", "", "", "", "", ""])
    }
    if (resendsuccess || resendOTPproviderSuccess) {
      setOtpError(null)
      setResentMessage(false)
    }
    if (error || providererror) {
      setOtpError(
        (error as any)?.data?.message || (providererror as any)?.data?.message,
      )
    }
  }, [
    isSuccess,
    providerverifySuccess,
    resendsuccess,
    resendOTPproviderSuccess,
    error,
    providererror,
  ])

  useEffect(() => {
    if (
      modalName === MODALNAME.OTP_VERIFICATION ||
      resendsuccess ||
      resendOTPproviderSuccess
    ) {
      setCurrentTime(60)
      setOtpError(null)
      const timerId = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerId)
            return 0
          } else {
            return prevTime - 1
          }
        })
      }, 1000)

      return () => clearInterval(timerId)
    } else {
      setCurrentTime(0)
    }
  }, [modalName, resendsuccess, resendOTPproviderSuccess])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`
  }
const handleModalClose=()=>{
  dispatch(setModalName(MODALNAME.CLOSED))
  setValError('')
}
  return (
    <>
      <Modal
        show={modalName === MODALNAME.OTP_VERIFICATION}
        // show={true}
        onHide={() => {
          dispatch(setModalName(MODALNAME.CLOSED))
        }}
        onExited={() => {
          setOtp(["", "", "", "", "", ""]), setOtpError(null)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton  style={{ border: "none" }} onClick={handleModalClose}>
          <Modal.Title className="verification-heading"> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col xl={12} lg={12}>
                  <div className="d-flex justify-content-between flex-column h-100 text-center">
                    <div>
                      <p className="verification-heading"> Verification</p>
                      <p className="get-started-text mb-1">
                        You are Successfully registered please verify your Email
                        to Continue !
                      </p>
                      <p className="get-started-text mb-2">
                        Enter a 6 digit OTP sent to your Email
                      </p>
                      {(resendsuccess || resendOTPproviderSuccess) && (
                        <p className="resend-otp mb-1">
                          {!resentMessage &&
                            "Otp has been sent successfully to your email"}
                        </p>
                      )}
                    </div>
                    <form action="margin-auto">
                      <div className="input-field">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            onKeyDown={(event) => handleKeyDown(index, event)}
                            ref={inputRefs[index] as any}
                          />
                        ))}
                      </div>

                      {otpError && <span className="error">{otpError}</span>}
                      {valerror && <span className="error">{valerror}</span>}
                    </form>
                    {
                      !(modalName===MODALNAME.CLOSED) &&     <div className="otp-heading mt-4">
                      {currentTime ===0 ? (
                        <p style={{ color: "red" }}>
                          Time's up! Click Resend for new OTP.
                        </p>
                      ) : (
                        <p>Time Remaining: {formatTime(currentTime)}</p>
                      )}
                      {(loading || resendOTPproviderloading) && (
                        <span>
                          <Spinner />
                        </span>
                      )}
                      {currentTime == 0 ? (
                        <>
                          <p className="resend-otp" onClick={handleResendOtp}>
                            Resend OTP{" "}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    }
                
                    <button
                      type="button"
                      className="send-button mt-5"
                      onClick={handleEmailVerify}
                      // disabled={currentTime === 0}
                      // otp.join("").length !== 6
                    >
                      {isLoading ? (
                        <>
                          <Spinner />
                        </>
                      ) : (
                        "VERIFY"
                      )}
                    </button>{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Verified />
    </>
  )
}

export default OTP
