import React from "react";
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"



interface RequestCardProps { 
  details?:any
}

const DashRequestCard = ({details}:RequestCardProps) => {
  const {requestMessage, requestStatus, date} = details
const handleDataFormat = (date: any) => {
        const originalDate = new Date(date)
        const formattedDate = originalDate.toLocaleDateString()
        return formattedDate
      }
    return    <div
    className="d-md-flex justify-content-between class-req-list align-items-start"
  >
    <div className="d-flex align-items-center mb-md-0 mb-4">
      <div className="user-img me-3">
        <img
          src={
            details?.adId?.providerId?.profilePic || dummy
          }
        />
      </div>
      <div>
        <div className="mb-2">
          <h3 className="class-name">
            {
              details?.adId?.providerId?.firstName
            }
          </h3>
          <p className="occ-name">
            {requestMessage}
          </p>
        </div>
      </div>
    </div>
    <div className="status-info">
      <p
        className={`status-class waiting-status mb-2 ${
          requestStatus === "Accept"
            ? "status-class-Accepted"
            : requestStatus ===
              "Delete"
            ? "status-class-deleted"
            : "status-class-pending"
        }`}
      >
        {requestStatus === "Accept"
          ? "Accept"
          : requestStatus === "Delete"
          ? "Rejected"
          : "Pending"}
      </p>

      <span className="d-flex justify-content-center font-14">
        {" "}
        {date === "soon"
          ? " soon"
          : handleDataFormat(date)}
      </span>
    </div>
  </div>

}

export default DashRequestCard