import * as React from "react"
import "./DashMainWrapper.css"
import DashboardHeader from "../../navbars/DashNav/DashNav"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import TopNavbar from "../../navbars/TopNavbar"
import { useAppSelector } from "../../../app/hooks"
import { useEffect } from "react"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import { USERTYPE } from "../../../constants"
import DashboardNav from "../../navbars/DashNav/DashNav"

interface customerProps {
  userType: USERTYPE
}

 const DashMainWrapper = ({ userType }: customerProps) => {
  const [types, setTypes] = React.useState("seeker")
  const {seekerToken, providerToken} = useAppSelector(selectCurrentUser)
  
  return (    
   seekerToken || providerToken ?  <>
      <TopNavbar navClass="bg-body-tertiary" />
      <DashboardNav userType={userType} />  
      <section className="provider-sec pb-5 bg-f9f9f9">
      <Outlet/></section>
    </> : <Navigate to="/" replace/>
  )
}

export default DashMainWrapper
