import React, { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./Enter.css"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { useAppDispatch } from "../../../app/store"
import { useForm } from "react-hook-form"
import verifiedimage from "../../../assets/images/verified-image.png"
import { useAppSelector } from "../../../app/hooks"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../constants"
import { useDispatch } from "react-redux"
import {
  selectCurrentUser,
  setEmailVerification,
} from "../../../features/Auth/authSlice"
import { useNavigate } from "react-router-dom"
import correctgif from "../../../assets/img/correctgif.gif"
const Verified = () => {
  const navigate = useNavigate()

  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setModalName(MODALNAME.CLOSED))
    dispatch(setEmailVerification(false as any))
  }
  const handleButtonClick = () => {
    if ((user?.providerToken !== null) ||( user?.seekerToken !==null)) {
      dispatch(setModalName(MODALNAME.CLOSED))
      
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }
  return (
    <>
      {" "}
      <Modal
        show={modalName === MODALNAME.VERIFY_EMAIL}
        onHide={() => {
          dispatch(setModalName(MODALNAME.CLOSED))
        }}
        centered
      >
        <Modal.Body>
          <Container>
            <div className="mb-5">
              <Row className="justify-content-center align-items-center">
                <Col xl={12} lg={12}>
                  <div className="verify-content text-center py-5">
                    <p className="verification-heading">
                      VERIFIED SUCCESSFULLY
                    </p>
                    <img src={correctgif} alt="correctgif" height={"250px"}/>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Turpis parturient
                      vel viverra duis duis at enim. Ut tristique risus at ma.
                    </p>
                  </div>
                  {user?.emailVeificationStep ? (
                    <button
                      type="button"
                      className="verified-button mt-5 w-100"
                      onClick={handleClose}
                    >
                      click here to Close and continue
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="verified-button mt-5 w-100"
                      onClick={handleButtonClick}
                    >
                      {((user?.role ==="Provider" &&  user?.providerToken === null) || (user?.role ==="Seeker" &&  user?.seekerToken === null)  )
                        ? "click here to Proceed"
                        : "click here to close continue"}
                    </button>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Verified
