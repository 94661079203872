import React from "react"
import "./funnelStepper.css"
import { Form } from "react-router-dom"
import { Container } from "react-bootstrap"

type Props = {
  stepCount: number
  activeStep: number
}

const FunnelStepper = ({ stepCount, activeStep }: Props) => {
  const handleStepperTitle = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        return "Ad Details"
      case 2:
        return "Your Details"
      case 3:
        return "Class Info"
      case 4:
        return "Upload Photo"
      default:
        return "Subject Info"
    }
  }

  const steps = []
  for (let step = 0; step <= stepCount; step++) {
    const isDone = step <= activeStep
    steps.push(
      <li
        key={step}
        className={`${activeStep === step ? "active" : isDone ? "active" : ""}`}
        data-id="1"
      >
        <span className="arrow"></span>
        <p>{handleStepperTitle(step)}</p>
      </li>,
    )
  }

  return (
    <>
      <Container fluid="lg">
        <Form id="msform">
          <ul id="progressbar">{steps}</ul>
          {/* <div
            style={{
              backgroundColor: "#53DAC121",
              padding: "1rem",
              height: "4rem",
            }}
          >
            <h3 className="make-heading">
              Fields marked with <span className=" text-black">*</span> are
              mandatory!
            </h3>
          </div> */}
        </Form>
      </Container>
    </>
  )
}

export default FunnelStepper
