import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../Auth/authSlice"
import { Navigate } from "react-router-dom"
import DashboardWrapper from "../../components/dasboard/Providerdashboard/dasboardWrapper/DashboardWrapper"
import { resetProviderStepper } from "../ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useDispatch } from "react-redux"
import DashMainWrapper from "../../components/wrappers/DashMainWrapper/DashMainWrapper"
import { USERTYPE } from "../../constants"
import { setNewUser } from "./FeatProviderDashboardSlice"

interface authProps {}

const FeatProviderDashboard = ({}: authProps) => {
  const dispatch = useDispatch()
  const auth = useAppSelector((state) => state.auth)
  useEffect(() => {
    dispatch(resetProviderStepper())
  }, [])
 useEffect(()=>{
 dispatch( setNewUser(auth?.user?.skills?.length))
  
 },[auth?.user?.skills?.length])
 
  return (
    <>{auth?.user?.skills?.length === 0 ?   <Navigate to={"/journey-source"} replace />  :   !auth?.providerToken ? <Navigate to={"/"} replace /> :  <DashMainWrapper userType={USERTYPE.PROVIDER} />
  }</>
  )
}

export default FeatProviderDashboard
