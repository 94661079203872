import React, { useState } from "react"
import "./PlanCard.css"
import premimumStar from "../../assets/images/premium-icon.svg"
import { useNavigate } from "react-router-dom"

interface PlanCardsProps {
  planType: string
  price: string
  period: string
  perks: string[]
  color?: string
  id: any
}
function PlanCards({ planType, price, period, perks, id }: PlanCardsProps) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const navigate = useNavigate()
  return (
    <div
      className="planCardMain"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() =>
        navigate(`/packagePayment/${id}`, {
          state: { planType, price, period, perks },
        })
      }
    >
      <div className="d-flex justify-content-between mb-5">
        <div>
          <p className="perks font-14">{planType}</p>
          <h3 className="perks mb-0">
            <span>₹</span>
            {price}
          </h3>
          <p className="perks mb-0">{period}</p>
        </div>
        <img
          className="mt-2 icon"
          src={premimumStar}
          style={{ width: "52px", height: "52px" }}
          alt="premimumStar"
        />
      </div>
      <div className="perks">
        {perks.map((text, index) => (
          <>
            <div className="d-flex w-100 gap-2 mb-4 pb-2" key={index}>
              <span className="check-icon-list">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M23 12L20.56 9.22004L20.9 5.54004L17.29 4.72004L15.4 1.54004L12 3.00004L8.6 1.54004L6.71 4.72004L3.1 5.53004L3.44 9.21004L1 12L3.44 14.78L3.1 18.47L6.71 19.29L8.6 22.47L12 21L15.4 22.46L17.29 19.28L20.9 18.46L20.56 14.78L23 12ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58004L18 9.00004L10 17Z"
                    fill="#7653DA"
                  />
                </svg>
              </span>
              <p className="perks perks-list">{text}</p>
            </div>
          </>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <button className="selectOfferBtn">Select</button>
      </div>
    </div>
  )
}

export default PlanCards
