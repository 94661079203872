import React, { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Spinner,
} from "react-bootstrap"
import { AiOutlinePlus } from "react-icons/ai"
import { BsPencil, BsTwitter } from "react-icons/bs"
import { BsCameraVideo } from "react-icons/bs"
import "./MyAds.css"

import EditTeacher from "../../../modals/EditAds/EditTeacher/EditTeacher"
import EditCourse from "../../../modals/EditAds/EditCaurse/EditCourse"
import EditTitle from "../../../modals/EditAds/EditTitle/EditTitle"
import EditSession from "../../../modals/EditAds/EditSession/EditSession"
import EditClassFormat from "../../../modals/EditAds/EditClassFormat/EditClassFormat"
import EditLanguge from "../../../modals/EditAds/EditLanguage/EditLanguage"
import {
  useGetFAQMutation,
  useProviderAdvertisementQuery,
  useReplyFAQMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import {
  setProviderData,
  setProviderIndex,
} from "../../../../features/featProviderDashboard/FeatProviderDashboardSlice"
import { useAppDispatch } from "../../../../app/store"
import { ADVT_MODALS } from "../../../../constants"
import EditAbout from "../../../modals/EditAds/EditAbout/EditAbout"
import EditPrice from "../../../modals/EditAds/EditPrice/EditPrice"
import { useLocation, useNavigate } from "react-router-dom"
import { setLoading } from "../../../../features/Auth/authSlice"
import { toast } from "react-toastify"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import Recommendations from "../Recommendations/Recommendations"
import PriceCard from "./PriceCard"
const MyAds = () => {
  const [selectedOption, setSelectedOption] = useState("realTime")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [id, setId] = useState<number>(0)
  const { state } = useLocation()
  const [active, setActive] = useState<number>(0)
  const [show, setShow] = useState(false)
  const [answer, setAnswer] = useState([])
  const [currentSendIndex, setCurrentSendIndex] = useState<number>(-1)
  const [currentModal, setCurrentModal] = useState<string>("")
  const handleClose = () => setCurrentModal(ADVT_MODALS.CLOSED)
  const handleShow = () => setShow(true)

  const { data, isError, isLoading, isSuccess, status } =
    useProviderAdvertisementQuery()
  const [
    GetFAQ,
    { data: FAQData, isSuccess: FAQSuccess, isLoading: FAQLoading },
  ] = useGetFAQMutation()
  const [
    ReplyFAQ,
    {
      data: ReplyFAQData,
      isLoading: ReplyFAQLoading,
      isSuccess: ReplyFAQSuccess,
    },
  ] = useReplyFAQMutation()
  const handleIndex = (id: any) => {
    setId(id)
    setActive(id)
    dispatch(setProviderIndex(id))
    dispatch(setProviderData(data))
  }
  const openModal = (modalType: any) => {
    setCurrentModal(modalType)
    setShow(true)
  }

  const handleAnswerChange: (index: number, value: string) => void = (
    index,
    value,
  ) => {
    if (value === "") {
      toast.error("Please enter your Answer")
    } else {
      const newAnswer: string[] = [...answer]
      newAnswer[index] = value
      setAnswer(newAnswer as any)
    }
  }

  const handleSendClick = (index: number) => {
    if (answer[index] === undefined || (answer as any)[index].trim() === "") {
      toast.dismiss()
      toast.error("Please enter your Answer")
    } else {
      setCurrentSendIndex(index)
      ReplyFAQ({
        faqId: FAQData?.data[index]?._id,
        answer: answer[index],
      })
    }
  }

  const handleAddCreation = () => {
    if (data?.data?.allAdvertisements.length === 3) {
      toast.dismiss()
      toast.error("You cannot create more than 3 Ads")
    } else {
      navigate("/journey-source")
    }
  }

  useEffect(() => {
    if (data?.data?.allAdvertisements[id]?._id) {
      GetFAQ({
        adId: data?.data?.allAdvertisements[id]?._id,
      })
    }
    if (ReplyFAQData && answer.length>0) {
      toast.success(ReplyFAQData?.message)
      setAnswer([])
    }
    if (state?.index === 0 || state?.index) {
      setId(state?.index)
      setActive(state?.index)
      dispatch(setProviderIndex(state?.index))
    }
    if (data?.data?.allAdvertisements.length === 0) {
      navigate("/journey-source")
    }
  }, [id, data?.data?.allAdvertisements[id]?._id, ReplyFAQData, state?.index])
  useEffect(() => {
    dispatch(setLoading(isLoading as any))
  }, [isLoading])
  useEffect(() => {
    handleIndex(0)
  }, [data?.data?.allAdvertisements.length])  

  return (
    <>
      <div>{isLoading && <SpinnerSecondary />}</div>
      {
        data?.data?.allAdvertisements.length > 0 && (
          <section className="my-account-sec">
            <Container fluid>
              <div className="inner-account pt-4">
                <Row className="justify-content-center flex-lg-row flex-column-reverse">
                  <Col xxl={9} lg={8}>
                    <div className="ads-section">
                      <Row>
                        <Col md={12}>
                          <Card className="ad-card mb-4">
                            <Card.Header className="p-0">
                              <h3 className="ad-heading">
                                Teaching as
                                <a
                                  onClick={() =>
                                    openModal(ADVT_MODALS.TEACHING)
                                  }
                                >
                                  <BsPencil className="edit-icon" />
                                </a>
                              </h3>
                            </Card.Header>
                            <Card.Body className="p-0 d-flex">
                              <div className="listing-pill">
                                {data?.data?.allAdvertisements[
                                  id
                                ]?.services.map(
                                  (service: any, index: number) => {
                                    return (
                                      <span
                                        className="pill-list mb-2"
                                        key={index}
                                      >
                                        {service}
                                      </span>
                                    )
                                  },
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={12}>
                          <Card className="ad-card mb-4">
                            <Card.Header className="p-0">
                              <h3 className="ad-heading">
                                Course Taught
                                {/* <a
                                  onClick={() => openModal(ADVT_MODALS.COURSE)}
                                >
                                  <BsPencil className="edit-icon" />
                                </a> */}
                              </h3>
                            </Card.Header>
                            <Card.Body className="p-0">
                              <div className="coursestaught">
                                {data?.data?.allAdvertisements[
                                  id
                                ]?.providerId?.skills.map(
                                  (skill: any, index: number) => {
                                    return (
                                      <div
                                        className="pill-list taught"
                                        key={index}
                                      >
                                        {skill}
                                      </div>
                                    )
                                  },
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Card className="ad-card mb-4">
                        <Card.Header className="p-0">
                          <h3 className="ad-heading">
                            Add Title
                            <a onClick={() => openModal(ADVT_MODALS.TITLE)}>
                              <BsPencil className="edit-icon" />
                            </a>
                          </h3>
                        </Card.Header>
                        <Card.Body className="p-0">
                          <p className="ad-text mb-0">
                            {data?.data?.allAdvertisements[id]?.title}
                          </p>
                        </Card.Body>
                      </Card>
                      <Card className="ad-card mb-4">
                        <Card.Header className="p-0">
                          <h3 className="ad-heading">
                            About You
                            <a onClick={() => openModal(ADVT_MODALS.ABOUT_YOU)}>
                              <BsPencil className="edit-icon" />
                            </a>
                          </h3>
                        </Card.Header>
                        <Card.Body className="p-0">
                          <p className="ad-text">
                            {data?.data?.allAdvertisements[id]?.aboutYou}
                          </p>
                        </Card.Body>
                      </Card>
                      <Card className="ad-card mb-4">
                        <h3 className="ad-heading">
                          About Session{" "}
                          <a
                            onClick={() => openModal(ADVT_MODALS.ABOUT_SESSION)}
                          >
                            <BsPencil className="edit-icon" />
                          </a>
                        </h3>
                        <Card.Body className="p-0">
                          <ul className="session-list">
                            <li>
                              <b>Session Description</b> -{" "}
                              {data?.data?.allAdvertisements[id]?.aboutClass}
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                      {/* <Card className="ad-card mb-4">
                        <h3 className="ad-heading">
                          Mode of Class{" "}
                          <a
                            onClick={() => openModal(ADVT_MODALS.CLASS_FORMAT)}
                          >
                            <BsPencil />
                          </a>
                        </h3>

                        <Card.Body className="p-0">
                          <div className="secondary-listing-pill">
                            <span className="second-pill-list">
                              <BsCameraVideo className="me-2" />
                              {data?.data?.allAdvertisements[id]?.modeOfClass}
                            </span>
                          </div>
                        </Card.Body>
                      </Card> */}
                      <Card className="ad-card   my-4">
                        <Card.Header className="p-0 d-flex ">
                          <h3 className="ad-heading">Pricing </h3>
                          <a
                            className="ms-2"
                            onClick={() => {
                              openModal(ADVT_MODALS.PRICING)
                            }}
                          >
                            <BsPencil />
                          </a>
                        </Card.Header>

                        <Card.Body className="p-0">
                          <Form className="">
                            <Row>
                              <Col
                                className={`real-virtual-btn mx-2 ${
                                  selectedOption === "realTime"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div
                                  onClick={() => setSelectedOption("realTime")}
                                >
                                  <Form.Check
                                    type="radio"
                                    id="realTime"
                                    label="Real Time"
                                  
                                    checked={selectedOption === "realTime"}
                                    onChange={() =>
                                      setSelectedOption("realTime")
                                    }
                                    style={{paddingLeft:"0px"}}
                                  />
                                </div>
                              </Col>
                              <Col
                                className={`real-virtual-btn ${
                                  selectedOption === "virtual" ? "selected" : ""
                                }`}
                              >
                                <div
                                  onClick={() => setSelectedOption("virtual")}
                                >
                                  <Form.Check
                                    type="radio"
                                    id="virtual"
                                    label="Virtual"
                                    checked={selectedOption === "virtual"}
                                    onChange={() =>
                                      setSelectedOption("virtual")
                                    }
                                    style={{paddingLeft:"0px"}}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>

                          {selectedOption === "realTime" && (
                            <div className="secondary-listing-pill d-sm-flex gap-5">
                              <div className="price-feature-box border-0 my-1">
                                <div className="feature-title">GROUP</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.group
                                        ?.hourlyFee
                                        ? "₹ " +
                                          data?.data?.allAdvertisements[id]
                                            ?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                        : " Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="groupSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2 "
                                    checked={
                                      data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.group
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="price-feature-box border-0  my-1">
                                <div className="feature-title">PRIVATE</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.private
                                        ?.hourlyFee
                                        ? "₹" +
                                          data?.data?.allAdvertisements[id]
                                            ?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                        : "Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="privateSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                      data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.private
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedOption === "virtual" && (
                            <div className="secondary-listing-pill d-sm-flex gap-5">
                              <div className="price-feature-box border-0 my-1">
                                <div className="feature-title">GROUP</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.group
                                        ?.hourlyFee
                                        ? "₹ " +
                                          data?.data?.allAdvertisements[id]
                                            ?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                        : " Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="groupSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                      data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.group
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="price-feature-box border-0  my-1">
                                <div className="feature-title">PRIVATE</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.private
                                        ?.hourlyFee
                                        ? "₹" +
                                          data?.data?.allAdvertisements[id]
                                            ?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                        : "Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="privateSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                      data?.data?.allAdvertisements[id]
                                        ?.prices?.[selectedOption]?.private
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                      {/* <Card className="mb-3">   
                     <h5 className="ms-4 mt-4"> Fees Structure   </h5>     
                      <div className="d-flex gap-4">
                        <PriceCard data={data} id={id} heading={"virtual"} />
                        <PriceCard data={data} id={id} heading={"realTime"} /> </div></Card> */}
                      <Card className="ad-card mb-4">
                        <h3 className="ad-heading">
                          Language
                          <a onClick={() => openModal(ADVT_MODALS.LANGUAGE)}>
                            <BsPencil />
                          </a>
                        </h3>
                        <Card.Body className="p-0">
                          <div className="secondary-listing-pill">
                            {data?.data?.allAdvertisements[id]?.language.map(
                              (lang: any, index: number) => {
                                return (
                                  <span
                                    className="second-pill-list mt-1"
                                    key={index}
                                  >
                                    {lang}{" "}
                                  </span>
                                )
                              },
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                      {/* <Card className="pricing-card">
                        <Card.Body className="p-0">
                          <Row>
                            <Col xxl={12}>
                              <Card className="ad-card mb-4">
                                <Row>
                                  <Col md={12}>
                                    <Card className="price-card">
                                      <Card.Header className="p-0">
                                        <h3 className="ad-heading">
                                          Pricing{" "}
                                          <a
                                            onClick={() => {
                                              openModal(ADVT_MODALS.PRICING)
                                            }}
                                          >
                                            <BsPencil />
                                          </a>
                                        </h3>
                                      </Card.Header>
                                      <ul className="price-list">
                                        <li>
                                          <div>
                                            <span className="price-list-heading">
                                              Hourly Fees
                                            </span>
                                            <span>
                                              ₹{" "}
                                              {
                                                data?.data?.allAdvertisements[
                                                  id
                                                ]?.hourlyRate
                                              }
                                              /hr
                                            </span>
                                          </div>
                                        </li>
                                        <li>
                                          {data?.data?.allAdvertisements[id]
                                            ?.packages?.packageCharges
                                            ?.classes && (
                                              <div>
                                                <span className="price-list-heading">
                                                  Packages
                                                </span>
                                                <p className="packing-list">
                                                  <span>
                                                    {
                                                      data?.data
                                                        ?.allAdvertisements[id]
                                                        .packages?.packageCharges
                                                        ?.classes
                                                    }
                                                    hr-₹
                                                    {
                                                      data?.data
                                                        ?.allAdvertisements[id]
                                                        ?.packages.packageCharges
                                                        ?.total
                                                    }
                                                    /hr
                                                  </span>
                                                </p>
                                              </div>
                                            )}
                                        </li>
                                        <li>
                                          {data?.data?.allAdvertisements[id]
                                            ?.packages?.webcam && (
                                              <div>
                                                <span className="price-list-heading">
                                                  Webcam
                                                </span>
                                                <span>
                                                  ₹
                                                  {
                                                    data?.data?.allAdvertisements[
                                                      id
                                                    ]?.packages?.webcam
                                                  }
                                                  /hr
                                                </span>
                                              </div>
                                            )}
                                        </li>
                                        <li className="first-class-list">
                                          {data?.data?.allAdvertisements[id]
                                            ?.packages?.firstClassFree && (
                                              <span className="first-class">
                                                1st class Free
                                              </span>
                                            )}
                                        </li>
                                      </ul>
                                    </Card>
                                  </Col>
                                  {data?.data?.allAdvertisements[id]?.packages
                                    ?.additionalDetails && (
                                      <Col md={12}>
                                        <div>
                                          <Card.Header className="p-0">
                                            <h3 className="ad-heading">
                                              Specify Additional Details
                                            </h3>
                                          </Card.Header>
                                          <p className="specify-text">
                                            {
                                              data?.data?.allAdvertisements[id]
                                                ?.packages?.additionalDetails
                                            }
                                          </p>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card> */}
                      {data?.data?.allAdvertisements[id]?.youtubeLink && (
                        <Card className="ad-card bg-transparent p-0 shadow-none border-0">
                          <h3 className="ad-heading">Video</h3>
                          <Card.Body className="p-0 border-none">
                            <iframe
                              width="100%"
                              height="315"
                              src={
                                data?.data?.allAdvertisements[id]?.youtubeLink
                              }
                            
                              title="YouTube video player"
                              className="video-ad border-none"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </Card.Body>
                        </Card>
                      )}
                      {FAQData?.data?.length > 0 && (
                        <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                          <h3 className="ad-heading">
                            {" "}
                            Frequently Asked Question
                          </h3>
                          {FAQData?.data?.map(
                            (question: any, index: number) => {
                              return (
                                <div className="faq-section mt-2" key={index}>
                                  <Accordion
                                    className="faq-accordion"
                                    defaultActiveKey="1"
                                  >
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header className="faq-head p-2">
                                        <h6>{question?.question}</h6>
                                      </Accordion.Header>
                                      {!question?.answer ? (
                                        <Accordion.Body className="faq-body">
                                          <Form
                                            onSubmit={(e) => e.preventDefault()}
                                          >
                                            <Form.Group controlId="questionInput">
                                              <Form.Label>
                                                Your Answer:
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Type your question here..."
                                                value={answer[index]}
                                                onChange={(e) =>
                                                  handleAnswerChange(
                                                    index,
                                                    e.target.value,
                                                  )
                                                }
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault()
                                                    handleSendClick(index)
                                                  }
                                                }}
                                              />
                                            </Form.Group>

                                            <Button
                                              className="mt-2"
                                              variant="primary"
                                              onClick={(e) =>
                                                handleSendClick(index)
                                              }
                                            >
                                              {ReplyFAQLoading &&
                                              currentSendIndex === index ? (
                                                <Spinner />
                                              ) : (
                                                "Send"
                                              )}
                                            </Button>
                                          </Form>
                                        </Accordion.Body>
                                      ) : (
                                        <>Your Answer : {question?.answer}</>
                                      )}
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              )
                            },
                          )}
                        </div>
                      )}
                      <Recommendations />
                    </div>
                  </Col>
                  <Col xxl={3} lg={4}>
                    <a
                      className="create-ad-btn w-100"
                      onClick={handleAddCreation}
                    >
                      Create an Ad <AiOutlinePlus />
                    </a>
                    <p className="color-131313 mb-2">
                      You have total
                      <span> {data?.data?.allAdvertisements.length}</span> Ads
                    </p>
                    <Row className="mb-4 bg">
                      {data?.data?.allAdvertisements.length > 0 &&
                        data?.data?.allAdvertisements.map(
                          (providerDetails: any, index: number) => {
                            return (
                              <Col md={12} key={index}>
                                <Button
                                  className={`btn ad-cate-btn ${
                                    active === index && "active"
                                  } `}
                                  onClick={() => handleIndex(index)}
                                >
                                  {providerDetails.vedicSubject}

                                  {providerDetails.status === "Active" ? (
                                    <span className="status online">
                                      {" "}
                                      Online{" "}
                                    </span>
                                  ) : (
                                    <span className="status offline">
                                      {" "}
                                      Offline{" "}
                                    </span>
                                  )}
                                </Button>
                              </Col>
                            )
                          },
                        )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        )
        // : (
        //   " ! OOPS No Advertisments Published"
        // )
      }
      <EditTeacher
        AdData={data?.data?.allAdvertisements[id]}
        show={currentModal === ADVT_MODALS.TEACHING}
        handleClose={handleClose}
      />
      {
        <EditCourse
          AdData={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.COURSE}
          handleClose={handleClose}
        />
      }
      {
        <EditAbout
          about={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.ABOUT_YOU}
          handleClose={handleClose}
        />
      }
      {
        <EditClassFormat
          AdData={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.CLASS_FORMAT}
          handleClose={handleClose}
        />
      }
      {
        <EditSession
          session={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.ABOUT_SESSION}
          handleClose={handleClose}
        />
      }
      {
        <EditPrice
          show={currentModal === ADVT_MODALS.PRICING}
          handleClose={handleClose}
          Data={data?.data?.allAdvertisements[id]}
        />
      }
      {
        <EditTitle
          title={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.TITLE}
          handleClose={handleClose}
        />
      }
      {
        <EditLanguge
          AdData={data?.data?.allAdvertisements[id]}
          show={currentModal === ADVT_MODALS.LANGUAGE}
          handleClose={handleClose}
        />
      }
    </>
  )
}

export default MyAds
