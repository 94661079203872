import { useState, useEffect } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "./PaymentSucess.css"
import successIcon from "../../../assets/img/circle-checked.svg"
import PaymentCard from "../PaymentCard/PaymentCard"
import leftarrow from "../../../assets/img/left-arrow.svg"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  useGetProviderDetailsMutation,
  usePostCreateRquestMutation,
} from "../../../features/Auth/authApi"
import { useAppSelector } from "../../../app/hooks"
import { useCreateSeekerRequestMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import exploreImg from "../../../assets/images/person-img.png"

const PaymentSuccess = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const requestSeeker = useAppSelector((state) => state.seekerDashboardSlice)
  const [
    request,
    { data, isSuccess, error: reqError, isLoading: createRequestLoading },
  ] = useCreateSeekerRequestMutation()
  const [profile, { isLoading, isError, error, data: profileData }] =
    useGetProviderDetailsMutation()
  useEffect(() => {
    if (id) {
      profile(id)
    }
  }, [id])

  useEffect(() => {
    if (data?.message) {
      navigate(`/request-success/${id}`)
    }
  }, [data])

  return (
    <>
      <div className="back-btn-wrapper pt-4 px-5 pb-5">
        <a href="#" className="back-link">
          <img src={leftarrow} />
        </a>
      </div>
      <section className="explore-section maincontainer pb-5 ">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {" "}
            <div className="success_ribbon">
              <h3 className="success_head">
                Yaay! <img src={successIcon} />
              </h3>
              <p className="success_text">
                You are one step away from creating a request
              </p>
            </div>
            <Container className="mt-5">
              <div className="inner-explore">
                <Row className="justify-content-center align-items-center">
                  <Col md={3}>
                    <PaymentCard data={profileData?.data} />
                  </Col>
                  <Col md={8}>
                    <h3 className="success-head text-start mb-4">
                      Continue connecting with tutor
                    </h3>
                    <p className="font-16 font-light mb-5">
                      Lorem ipsum dolor sit amet consectetur. Sollicitudin et
                      enim massa suspendisse ultrices odio donec at massa. Ac
                      dui cursus lacus integer sagittis. Neque tortor at ut
                      gravida.
                    </p>
                    <div className="">
                      <a
                        className="explore-link"
                        onClick={() => request(requestSeeker?.requestData)}
                      >
                        {createRequestLoading ? <Spinner /> : "Send Request"}
                      </a>
                    </div>
                    <p className="errormsg">
                      {reqError && (reqError as any).data?.message}
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </>
        )}
      </section>
    </>
  )
}

export default PaymentSuccess
