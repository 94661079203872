import { useEffect, useState } from "react"
import { MutatingDots } from "react-loader-spinner"
import { Modal, Button, Tabs, Tab, Container } from "react-bootstrap"
import { useAppSelector } from "../../../../app/hooks"
import "../authComponent.css"
import { MODALNAME, USERTYPE } from "../../../../constants"
import { useAppDispatch } from "../../../../app/store"
import { closeModal } from "../../../../features/Auth/ModalHandler/modalSlice"
import LoginSeeker from "./LoginSeeker"
import {
  selectCurrentUser,
  setCurrentUser,
} from "../../../../features/Auth/authSlice"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
interface loginProps {}

const Login = ({}: loginProps) => {
  const [loading, setLoading] = useState(false)
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const dispatch = useAppDispatch()
  const { currentUserRole: loginType } = useAppSelector(selectCurrentUser)
  
  const handlePopstate = () => {
    dispatch(closeModal())
  }
  useEffect(() => {
    setLoading(true),
      setTimeout(() => {
        setLoading(false)
      }, 3000)
  }, [loginType])

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate)

    return () => {
      window.removeEventListener("popstate", handlePopstate)
    }
  }, [])

  const handleClose = () => {
    dispatch(closeModal())
    dispatch(setCurrentUser(USERTYPE.SEEKER))
  }
  return (
    <>
      <Modal
        animation
        centered
        show={modalName === MODALNAME.LOGIN}
        className="login-modal"
        aria-labelledby="exampleModalToggleLabel"
        onHide={() => {
          closeModal()
        }}
        // onExiting={() => dispatch(setCurrentUser(USERTYPE.SEEKER))}
      >
        <Modal.Dialog className="w-100 my-0 mx-0">
          <div className="pb-5 popup-content">
            {loading ? (
              <Container
                style={{
                  height: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "18%",
                }}
              >
                <SpinnerSecondary wrapperStyle={{}} />
              </Container>
            ) : (
              <>
                {" "}
                <Modal.Header className="popup-header border-0">
                  <Modal.Title
                    className="popup-heading"
                    id="exampleModalToggleLabel"
                  ></Modal.Title>
                  <Button
                    variant="secondary"
                    className="btn-close"
                    onClick={handleClose}
                  ></Button>
                </Modal.Header>
                <Modal.Body className="popup-body login-tab-wrapper border-0">
                  <h3 className="popup-title mb-md-4 mb-3">
                    {loginType === USERTYPE.SEEKER ? (
                      <>Welcome Seeker</>
                    ) : (
                      <>Welcome Tutor</>
                    )}
                  </h3>
                  <LoginSeeker userType={loginType} />
                </Modal.Body>
              </>
            )}
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default Login
