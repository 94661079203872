import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import {
  Navigate,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom"
import TopNavbar from "../../navbars/TopNavbar"
import { Dispatch } from "@reduxjs/toolkit"
import FunnelStepper from "../../funnels/funnelStepper/ProviderStepper"
import { providerFunnelRoutes } from "../../../Routes"
import { useAppSelector } from "../../../app/hooks"
import { useAppDispatch } from "../../../app/store"
import arrowLeftArrow from "../../../assets/img/arrow-left-white.svg"
import arrowRightArrow from "../../../assets/img/arrow-right-white.svg"
import greenLeftArrow from "../../../assets/img/arrow-left-green.svg"
import greenRightArrow from "../../../assets/img/arrow-right-green.svg"
import ProviderStepper from "../../funnels/funnelStepper/ProviderStepper"
import {
  decrementAsync,
  incrementAsync,
  resetProviderFunel,
  setActiveStep,
  setFunnelStepNumber,
} from "../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useAddDeatilsMutation, useUploadAdProfileDetailsMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import { setAdvertisementId } from "../../../features/ServiceFunnel/ProviderImgVideoSlice/ProviderImgVideoSlice"
import { toast } from "react-toastify"

type Props = {}

const ProviderFunnelWrapper = (props: Props) => {
  const navigate = useNavigate()
  const statelocation = useLocation()
  const dispatch: Dispatch<any> = useAppDispatch()
  dispatch(setFunnelStepNumber(providerFunnelRoutes.length - 1))
  const { status, stepCount, activeStep, funnelStepperCount, isStepValid } =
    useAppSelector((state) => state.providerFunnel)
  const funnelData = useAppSelector((state) => state.providerFunnel)
  const imgData = useAppSelector((state) => state.imgVideoSlice)
  const handleStepperCounts = (activePath: string) => {
    const providerpaths = providerFunnelRoutes.map(
      (item: { path: string }) => item.path,
    )
    const index = providerpaths.indexOf(activePath)
    return index !== -1 ? index : 0 // Return -1 if the item is not found
  }
  const stepperNavigator = () => {
    const routeObj: any = providerFunnelRoutes[stepCount]
    const path = routeObj?.path ? routeObj?.path : "/journey-source"
    navigate(path)
  }

  const [addDetailsSubmit, { isSuccess, isLoading, isError, error, data }] =
    useAddDeatilsMutation()

  const [profileData, { data: profileUploadData, isLoading: loading }] =
    useUploadAdProfileDetailsMutation()

  useEffect(() => {
    stepperNavigator()
  }, [stepCount])

  useEffect(() => {
    dispatch(setActiveStep(handleStepperCounts(statelocation.pathname)))
  }, [statelocation])

  const validateRequiredFields = () => {
    const { skillSet, services, vedicSubject, language, modeOfClass } =
      funnelData.providerFunnelData
    if (activeStep === 0) {
      return skillSet.length === 0 || services === "" || vedicSubject === ""
    } else if (activeStep === 2) {
      return language.length === 0
    } else if (activeStep === 3) {
      return modeOfClass === ""
    }
  }
  const {
    aboutClass,
    aboutYou,
    hourlyRate,
    language,
    location,
    modeOfClass,
    phoneNo,
    profileImage,
    profileVideo,
    services,
    skillSet,
    title,
    vedicSubject,
    youtubeLink,
    prices
  } = funnelData?.providerFunnelData
  const addAdvertisment = () => {
    // dispatch(
    addDetailsSubmit({
      aboutClass,
      aboutYou,
      hourlyRate,
      language,
      location,
      // modeOfClass,
      phoneNo,
      services,
      skillSet,
      title,
      vedicSubject,
      youtubeLink,
      prices
    })
    // )
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAdvertisementId(data?.data?._id))
      dispatch(resetProviderFunel())
      navigate("/source-dashboard/my-advt")
     
      dispatch(resetProviderFunel())
      toast.success("Ad Created Successfully")
    }
    if (error) {
      toast.dismiss()
      toast.error((error as any)?.data?.message)
    }
  }, [isSuccess, error])

  return (
    <>
      {(loading || isLoading) && (
        <div>
          <Spinner />
        </div>
      )}
      <Container fluid className="">
        <Row className="mx-0">
          <Col className="px-0">
            <TopNavbar />
          </Col>
        </Row>

        <>
          {" "}
          <div className="min-h-100 d-flex flex-column">
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-center align-items-center section-padding">
                  <ProviderStepper
                    stepCount={funnelStepperCount}
                    activeStep={activeStep}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mx-0 flex-1 h-100 align-items-center">
              <Col className="px-0">
                {status === "loading" ? (
                  <div className="text-center ">
                    <Spinner variant="grow" />
                  </div>
                ) : (
                  <Outlet />
                )}
              </Col>
            </Row>
          </div>
          <div className="funnel-navigation">
            <Row>
              <Col>
                <div className="d-flex justify-content-center align-items-center mt-lg-4 mb-lg-5">
                  {/* {validateRequiredFields() && (
                    <div className="alert alert-warning mx-3" role="alert">
                      Please enter at least one field from each category.
                    </div>
                  )} */}
                  <div className="btn-contain w-auto">
                    {stepCount !== 0 && (
                      <Button
                        onClick={() => dispatch(decrementAsync(activeStep))}
                        disabled={stepCount <= 0}
                        className="schedule-btn flex-none ms-lg-5 me-lg-5 me-2"
                      >
                        <img src={arrowLeftArrow} className="white-arrow" />
                        <img src={greenLeftArrow} className="green-arrow" />
                      </Button>
                    )}
                  </div>
                  {/* <div className="mx-5">
                    {
                      activeStep === 4 ? '': <a href="#" className="skip-text"  onClick={() => dispatch(incrementAsync(activeStep))}>
                      Skip
                    </a>
                    }
                  
                  </div> */}
                  <div className="btn-contain w-auto">
                    {statelocation.pathname ===
                    "/journey-source/source-picture" ? (
                      <Button
                        type="submit"
                        className="schedule-btn2"
                        onClick={addAdvertisment}
                        disabled={isStepValid}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        onClick={() => dispatch(incrementAsync(activeStep))}
                        // disabled={
                        //   stepCount >= providerFunnelRoutes.length ||
                        //   validateRequiredFields()
                        // }
                        disabled={
                          stepCount >= providerFunnelRoutes.length ||
                          isStepValid
                        }
                        className="schedule-btn flex-none"
                      >
                        <img
                          src={arrowRightArrow}
                          className="white-arrow"
                          alt=""
                        />
                        <img src={greenRightArrow} className="green-arrow" />
                      </Button>
                    )}
                    {/* {error && (
                      <span className="error">
                        {(error as any)?.data?.message}
                      </span>
                    )} */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </Container>
    </>
  )
}

export default ProviderFunnelWrapper
