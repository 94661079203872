import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { BsPencil } from "react-icons/bs"
import { BiUser } from "react-icons/bi"
import { FiThumbsUp } from "react-icons/fi"
import { BsPlusLg } from "react-icons/bs"
import { AiOutlineCamera } from "react-icons/ai"
import { AiOutlineCheck } from "react-icons/ai"
import { FiBookOpen } from "react-icons/fi"
import {
  useGetProviderStatusQuery,
  useUpdateProviderStatusQuery,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import { useNavigate } from "react-router"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"

const MyStatus = () => {
  const { data, isLoading, refetch } = useGetProviderStatusQuery()
  const { isLoading: updateStatusLoading, isSuccess } =
    useUpdateProviderStatusQuery()
  useEffect(() => {
    refetch()
  }, [isSuccess])

  return isLoading || updateStatusLoading ? (
    <SpinnerSecondary />
  ) : (
    <section className="my-account-sec py-5">
      <Container fluid>
        <div className="inner-account pt-0">
          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="complete-profile">
                <h3 className="complete-head">Complete your profile(4/5)</h3>
                <p className="complete-para mb-5">to gain 2X Visibility</p>
                <div className="complete-profile-steps">
                  {data?.data?.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`profile-step ${item?.value && "active"}`}
                      >
                        <div>
                          <span> {item?.name}</span>
                        </div>
                        {item?.value ? (
                          <a>
                            <AiOutlineCheck />
                          </a>
                        ) : (
                          <a>
                            {" "}
                            <BsPlusLg />
                          </a>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default MyStatus
