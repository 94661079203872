import React from "react"
import reviewBlankImg from "../../../../assets/images/reviewBlankImg.png"
import "./ReviewBlank.css"
function ReviewBlank() {
  return (
    <div className="reviewMain">
      <img className="reviewBlankImg" src={reviewBlankImg} alt="" />
      <p className="mt-4 ms-3 text-black-50">No reviews yet</p>
    </div>
  )
}
export default ReviewBlank
