import React from "react"
import { Modal } from "react-bootstrap"
import successEmail from "../../../assets/img/mail 1.svg"
import "./ReplyModal.css"
import { useNavigate } from "react-router-dom"

interface CardProps {
  show: boolean
  data: {
    seekerId: {
      firstName: string
    }
  }
  handleClose: () => void
}
const ReplyModal = ({ show, handleClose, data }: CardProps) => {
  return (
    <>
      <Modal
        className="login-modal"
        show={show}
        centered
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0"></Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <div className="change-password-card success-message py-5">
                <div className="text-center">
                  <img src={successEmail} className="mb-4 lock-img" />
                  <p className="font-20">
                    Your reply is sent to {data?.seekerId.firstName}
                  </p>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default ReplyModal
