import { createSlice } from "@reduxjs/toolkit"

const adId = ""
const imageVideoSlice = createSlice({
  name: "imageVideoSlice",
  initialState: { adId },
  reducers: {
    setAdvertisementId: (state, action) => {
      state.adId = action.payload
    },
  },
})

export const { setAdvertisementId } = imageVideoSlice.actions

export default imageVideoSlice.reducer
