import { Container, Row, Col, Spinner } from "react-bootstrap"
import "./RequestSuccessfull.css"
import successIcon from "../../../assets/img/success-icon.svg"
import PaymentCard from "../PaymentCard/PaymentCard"
import leftarrow from "../../../assets/img/left-arrow.svg"
import circleChecked from "../../../assets/img/circle-checked.svg"
import { useGetProviderDetailsMutation } from "../../../features/Auth/authApi"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import ScheduleCard from "../scheduleCard/ScheduleCard"
import { useAppSelector } from "../../../app/hooks"
import {
  useCreateSeekerRequestMutation,
  useSearchLandingPageMutation,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import ProviderCards from "../../cards/providerCards"

const RequestSuccessfull = () => {
  const [viewAll, setviewAll] = useState<boolean>(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const seekerRequestSData = useAppSelector(
    (state: any) => state?.seekerDashboardSlice?.requestData,
  )
  const advertisementData = useAppSelector((state:any)=>state.auth.data)
  
  const [profile, { isError, error, data, isLoading }] =
    useGetProviderDetailsMutation()
  // const [search, { data: allProviderData, isSuccess }] =
  //   useSearchLandingPageMutation()
  const [request, { data: reqData, error: reqError }] =
    useCreateSeekerRequestMutation()
  useEffect(() => {
    if (id != "dashboardPremium") {
      profile(id)
      // search(null)
    }
  }, [id])  
  return (
    <>
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}
      {id === "dashboardPremium" ? (
        <section className="explore-section pb-5">
          <Container fluid className="mt-5 px-md-5 px-3">
            <div className="inner-explore">
              <div className="request-screen mb-5">
                <Row className="justify-content-center align-items-center">
                  <Col md={5}>
                    <h3 className="success-text text-center">
                      Successfully Upgraded to Premium
                      <img src={circleChecked} />
                    </h3>
                    <div className="text-center">
                      <button
                        className="schedule-btn2"
                        onClick={() => navigate("/seeker-dashboard")}
                      >
                        Go to Dashboard
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>
      ) : (reqError as any)?.data?.message ? (
        <section className="explore-section pb-5">
          <Container fluid className="mt-5 px-md-5 px-3">
            <div className="inner-explore">
              <div className="request-screen mb-5">
                <Row className="justify-content-center align-items-center">
                  <Col md={5}>
                    <div className="px-md-5">
                      <h3 className="error-text text-center">
                        {(reqError as any)?.data?.message}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>
      ) : (
        // </section>
        <>
          {isLoading && (
            <div className="loading-screen">
              <Spinner />
            </div>
          )}
          <section className="explore-section section-padding d-block pb-5">
            <div className="back-btn-wrapper pt-4 px-5 pb-xl-5 pb-3">
              <a href="#" className="back-link">
                <img src={leftarrow} />
              </a>
            </div>
            <Container>
              <div className="success_ribbon mb-5">
                <div className="request-candidate">
                  <PaymentCard data={data?.data} />
                </div>
                <h3 className="success_head">
                  Your request is Successfully sent <img src={successIcon} />
                </h3>
                <p className="success_text mb-4">
                  Lorem ipsum dolor sit amet consectetur. Nulla arcu massa ipsum
                  pellentesque
                </p>
                <div className="d-md-flex justify-content-center gap-4">
                  <a
                    className="dasboardbtn mb-md-0 mb-3"
                    onClick={() => navigate("/seeker-dashboard")}
                  >
                    Go to Dashboard
                  </a>
                  {/* <a
                    className="dasboardbtn link-text"
                    onClick={() => navigate("/results")}
                  >
                    Explore More
                  </a> */}
                </div>
              </div>
              <div className="inner-explore">
                <Row className="justify-content-center align-items-center">
                  <Col md={4}></Col>
                </Row>
              </div>

              <section className="evaluated-section mb-5">
                <div className="inner-evaluate">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <h2 className="evaluated-heading">You may interested in</h2>
                    {/* {!viewAll ? (
                      <Link
                        to={""}
                        className="common-btn w-auto"
                        onClick={() => setviewAll(!viewAll)}
                      >
                        View All
                      </Link>
                    ) : (
                      <Link
                        to={""}
                        className="common-btn w-auto"
                        onClick={() => setviewAll(!viewAll)}
                      >
                        View Less
                      </Link>
                    )} */}
                  </div>
                  <Row>
                    <Row xxl={3} lg={4}>
                      <ProviderCards
                            data={viewAll
                              ? advertisementData
                              : advertisementData.slice(0, 4)}
                            key={""}
                            searchError={""}
                            sortBy={false}
                            page={1} sortOrder={false}                      />
                    </Row>
                  </Row>
                </div>
              </section>
            </Container>
          </section>
        </>
      )}
    </>
  )
}

export default RequestSuccessfull
