import React, { ReactNode, useEffect, useState } from "react"
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap"
import passwordicon from "../../../assets/img/password-icon.svg"
import keyicon from "../../../assets/img/key-icon.svg"
import closeIcon from "../../../assets/img/close-icon.svg"
import "../AuthComponents/authComponent.css"
import "./ChangePassword.css"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useChangePasswordMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import keylock from "../../../assets/img/keylock.png"
import { useChangeSeekerPasswordMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import eyeOpen from "../../../assets/images/figma-eye-open.png"
import eyeClose from "../../../assets/images/figma-eye-close.png"
import { passwordRegex } from "../../../utils/regexPattern/Pattern"
import { toast } from "react-toastify"
import { changePasswordSchema } from "../../../utils/yupSchema/Schema"

interface CardProps {
  show: boolean
  handleClose: () => void
  userType: any
}
const ChangePassword = ({ show, handleClose, userType }: CardProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [passwordChangeSuccess, setpasswordChangeSuccess] =
    useState<boolean>(false)
  const [passwordChange, { data, error, isSuccess, isLoading  }] =
    userType === "SEEKER"
      ? useChangeSeekerPasswordMutation()
      : useChangePasswordMutation()

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: changePasswordSchema,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      password: values.password,
      newPassword: values.newpassword,
    }
    passwordChange(payload)
  }

  const togglePasswordVisibility = (key: any) => {
    if (key == "old") {
      setShowPassword(!showPassword)
    } else {
      setShowNewPassword(!showNewPassword)
    }
  }
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  useEffect(() => {
    if (isSuccess) {
      setpasswordChangeSuccess(isSuccess)
      
        handleClose()
        setpasswordChangeSuccess(true)
        reset()
        setpasswordChangeSuccess(true)
        reset()
      
    }
    if (error) {
      toast.dismiss()
      toast.error((error as any)?.data?.message)
    }
  }, [isSuccess, error])

  const handleCloseModal = () => {
    handleClose()
    reset()
  }
  const handleCloseSuccessModal = () =>{
    handleClose()
    setpasswordChangeSuccess(!passwordChangeSuccess)

  }
  
  return (
    <>
      <Modal show={show} centered size="lg">
      
      <Row className="justify-content-center">
        <Col lg={12}>
          <div className="edit-card edit-card-responsive w-auto">
            <div className="position-relative">
              <h3 className="mb-4 text-center teaching-head h-primary ">
                Change Password
              </h3>
              <Button
                variant="transparent"
                className="shadow-none p-0 close-icon-popup"
                onClick={handleCloseModal}
              >
                <img src={closeIcon} />
              </Button>
            </div>
            <div className="teaching-as mb-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="popup-field-wrapper mb-4">
                  <img src={passwordicon} alt="Password Icon" />

                  <Form.Control
                    {...register("password")}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="old password"
                    className="form-control shadow-none border-0 rounded-0 popup-field bg-transparent"
                  />

                  <img
                    className="eye-icon"
                    onClick={() => togglePasswordVisibility("old")}
                    src={showPassword ? eyeOpen : eyeClose}
                    alt="Toggle Password Visibility"
                  />
                </div>
                <span className="text-danger">
                  {errors?.password?.message as ReactNode}
                </span>
                <div className="popup-field-wrapper mb-4">
                <img src={passwordicon} alt="Password Icon" />
                  <Form.Control
                    {...register("newpassword")}
                    name="newpassword"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="new password"
                    className="form-control shadow-none border-0 rounded-0 popup-field bg-transparent"
                  />
                  <img
                    className="eye-icon"
                    onClick={() => togglePasswordVisibility("new")}
                    src={showNewPassword ? eyeOpen : eyeClose}
                    alt="Toggle Password Visibility"
                  />
                </div>

                <span className="text-danger">
                  {errors?.newpassword?.message as ReactNode}
                </span>

                <div className="d-sm-flex justify-content-between w-100 mt-5">
                  <button
                    className="btn bottom-btn save-edit-btn w-100"
                    type="submit"
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      </Modal>
          <Modal show={passwordChangeSuccess}>
          <div className="edit-card-success w-auto">
             <div className="position-relative">
               <Button
                 variant="transparent"
                 className="shadow-none p-0 close-icon-popup"
                 onClick={handleCloseSuccessModal}
               >
                 <img src={closeIcon} />
               </Button>
             </div>
             <div className="d-sm-flex justify-content-center  w-100  success-message mb-4 ">
               <img src={keylock} />
               <p> Your Password is Successfully Changed</p>
             </div>
           </div>
           </Modal>
              
    </>
  )
}

export default ChangePassword


// ) : (
      