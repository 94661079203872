import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import SpinnerSecondary from "../components/spinner/SpinnerSecondary";
import { useAppSelector } from "../app/hooks";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { useAppDispatch } from "../app/store";
import { logout, selectCurrentUser } from "../features/Auth/authSlice";
import { useTokenStatusQuery } from "../features/Auth/authApi";

interface PrivateProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const pollIntervalValue = 3600 * 1000;
  const { data, isSuccess } = useTokenStatusQuery( {
    pollingInterval: pollIntervalValue,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (isSuccess && data?.isTokenExpired) {
      dispatch(logout());
      navigate("/");
    }
  }, [isSuccess, data]);

  // Additional code for token decoding and expiration check if needed
  return <>{children}</>;
};

export default PrivateRoute;