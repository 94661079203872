import { Form } from "react-bootstrap"
import FormLabel from "./FormLabel"
import "./FormInput.css"
import _ from "lodash"
import { useState } from "react"
import eyeOpen from "../../assets/images/figma-eye-open.png"
import eyeClose from "../../assets/images/figma-eye-close.png"

interface FormInputProps {
  name: string
  title: string
  register?: any
  errors?: any
  placeholder?: string
  type?: string
  className?: string
  rows?: number
  extra?: JSX.Element
}

function FormInput({
  errors,
  title,
  name,
  register,
  placeholder,
  type = "text",
  className,
  rows = 3,
  extra,
  ...rest
}: FormInputProps | any) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Form.Group className={className}>
      <FormLabel title={title} />

      {type === "textarea" ? (
        <Form.Control
          isInvalid={Boolean(_.get(errors, name))}
          placeholder={placeholder}
          as={type}
          rows={rows}
          {...rest}
          {...register(name)}
        />
      ) : type === "password" ? (
        <div className="custom-password-input position-relative">
          <Form.Control
            isInvalid={Boolean(_.get(errors, name))}
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            {...rest}
            {...register(name)}
          />
          <img
            className="eye-icon ms-5"
            onClick={togglePasswordVisibility}
            src={showPassword ? eyeOpen : eyeClose}
            alt="Toggle Password Visibility"
          />
          {errors && _.get(errors, name) ? (
            <Form.Control.Feedback type="invalid">
              {_.get(errors, name).message}
            </Form.Control.Feedback>
          ) : null}
        </div>
      ) : (
        <Form.Control
          isInvalid={Boolean(_.get(errors, name))}
          placeholder={placeholder}
          type={type}
          {...rest}
          {...register(name)}
        />
      )}

      {errors && _.get(errors, name) ? (
        <Form.Control.Feedback type="invalid">
          {_.get(errors, name).message}
        </Form.Control.Feedback>
      ) : null}
      {extra}
    </Form.Group>
  )
}

export default FormInput
