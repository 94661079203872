import { useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import UploadProviderVedio from "../uploadProviderVedio/UploadProviderVedio"
import demoimage from "../../../../assets/img/card-image.png"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import playicon from "../../../../assets/img/play-icon.svg"
import {
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useAppDispatch } from "../../../../app/store"
import { useUploadAdProfileDetailsMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import VideoModal from "../../../modals/VideoModal/VideoModal"
import { useAppSelector } from "../../../../app/hooks"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import Cropper from "react-easy-crop"
import { Image } from "image-js"
import getCroppedImg from "./GetCroppedImg"
import FormRange from "react-bootstrap/esm/FormRange"
const UploadProviderPhoto = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const adId = useAppSelector((state: any) => state.imgVideoSlice)
  const [videoBtn, setVideoBtn] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [videoSrc, setVideoSrc] = useState<any>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [image, setImage] = useState<any>(null)
  const [video, setVideo] = useState<any>(null)
  const [infoactive, setInfoActive] = useState(false)
  const [finalImg, setFinalImg] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedImg, setCroppedImg] = useState(false)

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setFinalImg(croppedAreaPixels)
  }
  const handleCrop = async () => {
    if (imgSrc && finalImg) {
      const croppedImgUrl = await getCroppedImg(imgSrc, finalImg)
      const blob = await fetch(croppedImgUrl).then((res) => res.blob());
      const formData = new FormData();
      formData.append("profileImage", blob, Math.random().toString());
      setImgSrc(croppedImgUrl)
      formData.append("profileImage", imgSrc)
      setImage(formData)
      setCroppedImg(true)
      setShowModal(true)
      setVideoBtn(true)
    }
  }

  const [
    profileData,
    { data: profileUploadData, isLoading: loading, isSuccess, isError },
  ] = useUploadAdProfileDetailsMutation()

  const handleCloseModal = () => setShowModal(false)
  const handleSkipVideoModal = () => {
    setVideoBtn(false)
    setShowModal(false)
  }
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }
  const handleShowModal = () => {
    if (!isError) {
      setTimeout(() => {
        setShowModal(true)
        setVideoBtn(true)
      }, 500)
    }
  }

  const handleImageChange = (event: any) => {
    setCroppedImg(false)
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append("profileImage", file)
    setImage(formData)
    dispatch(setIsStepValid(false))
    // handleShowModal()
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setImgSrc((reader as any).result)
      }
   
    }
  }
  const handleImageUploadClick = () => {
    ; (document as any).getElementById("image-upload").click()
  }
  const handleVideoChange = async (event: any) => {
    const videoFile = event.target.files[0]
    const formData = new FormData()
    formData.append("profileVideo", videoFile)
    setVideo(formData)
    if (videoFile) {
      const videoUrl = URL.createObjectURL(videoFile)
      setVideoSrc(videoUrl)
    }
  }
  useEffect(() => {
    if (adId?.adId) {
      profileData({ id: adId?.adId, adData: image })
    }
    if (adId?.adId && video) {
      profileData({ id: adId?.adId, adData: video })
    }
    if (isSuccess) {
      navigate("/source-dashboard/my-advt")
    }
  }, [adId.adId, isSuccess])

  useEffect(() => {
    dispatch(setIsStepValid(true))
  }, [])

  return (
    <>
      {loading && (
        <div>
          <Spinner />
        </div>
      )}
      <section className="py-lg-5 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className=" d-flex flex-row-lg-reverse justify-content-lg-start justify-content-center">
                <div className="d-flex mb-5 flex-column ms-3 mt-5 gap-4"></div>
                {!videoBtn ? (
                  <div>
                    <p className="get-started-text fw-normal">
                      Upload Image
                      <span className=" text-danger">*</span>
                    </p>
                    <div className="image-box">
                      {!imgSrc && (
                        <div
                          className="d-flex justify-content-center align-items-center h-100"
                          onClick={handleImageUploadClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="87"
                            height="87"
                            viewBox="0 0 87 87"
                            fill="none"
                          >
                            <path
                              d="M43.5 15C27.76 15 15 27.76 15 43.5C15 59.2409 27.76 72 43.5 72C59.2409 72 72 59.2409 72 43.5C72 27.76 59.2409 15 43.5 15ZM43.5 68.4936C29.7496 68.4936 18.5625 57.2504 18.5625 43.4999C18.5625 29.7495 29.7496 18.5624 43.5 18.5624C57.2504 18.5624 68.4375 29.7496 68.4375 43.4999C68.4375 57.2502 57.2504 68.4936 43.5 68.4936ZM55.9688 41.7188H45.2812V31.0312C45.2812 30.048 44.4832 29.25 43.5 29.25C42.5168 29.25 41.7188 30.048 41.7188 31.0312V41.7188H31.0312C30.048 41.7188 29.25 42.5168 29.25 43.5C29.25 44.4832 30.048 45.2812 31.0312 45.2812H41.7188V55.9688C41.7188 56.952 42.5168 57.75 43.5 57.75C44.4832 57.75 45.2812 56.952 45.2812 55.9688V45.2812H55.9688C56.952 45.2812 57.75 44.4832 57.75 43.5C57.75 42.5168 56.952 41.7188 55.9688 41.7188Z"
                              fill="#EDEDED"
                            />
                          </svg>
                        </div>
                      )}
                      {imgSrc && croppedImg && (
                        <img src={imgSrc as any} alt="Uploaded" />
                      )}
                      <Modal
                        show={!croppedImg && imgSrc}
                        style={{ top: "10%" }}
                      >
                        {!croppedImg && imgSrc && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "white",
                              padding: "20px",
                              borderRadius: "8px",
                              width: "500px",
                              height: "500px",
                            }}
                          >
                            <div
                              className="w-100"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="d-flex justify-content-between">
                                <p className="text-black fw-bold">Crop Image</p>
                                {/* <div
                                  className="bg-white p-1"
                                  onClick={handleCloseModal}
                                >
                                  X
                                </div> */}
                              </div>
                              <hr />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "400px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Cropper
                                image={imgSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                               
                              />
                            </div>
                            <input
                              type="range"
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e) => {
                                setZoom(e.target.value as any)
                              }}
                              className="mt-3"
                            />
                            <button
                              style={{
                                height: "60px",
                                width: "50%",
                                color: "white",
                                background: "#53dac1",
                                border: "none",
                                marginTop: "20px",
                                borderRadius: "25px",
                              }}
                              onClick={handleCrop}
                            >
                              Crop
                            </button>
                          </div>
                        )}
                      </Modal>
                    </div>
                    <div>
                      <input
                        type="file"
                        className="d-none"
                        id="image-upload"
                        onChange={handleImageChange}
                        accept=".jpg, .jpeg, .png"
                      />
                      <label
                        htmlFor="image-upload"
                        className="schedule-btn2 upload-btn mb-3"
                      >
                        Upload Image
                      </label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="get-started-text fw-normal">
                      Add your profile Video
                    </p>
                    <div
                      className="video-box"
                      style={{ width: "300px", height: "300px" }}
                    >
                      {videoSrc && (
                        <video controls width="300" height="370">
                          <source src={videoSrc} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    <div className={` ${videoSrc ? "d-none" : ""}`}>
                      <input
                        type="file"
                        className="d-none"
                        id="video-upload"
                        accept="video/mp4,video/mkv, video/x-m4v,video/*"
                        onChange={handleVideoChange}
                      />
                      <label
                        htmlFor="video-upload"
                        className="schedule-btn2 upload-btn mb-3"
                      >
                        Upload Video
                      </label>
                    </div>
                    <div>
                      <input
                        type="file"
                        className="d-none"
                        id="video-upload"
                        accept="video/mp4,video/mkv, video/x-m4v,video/*"
                        onChange={handleVideoChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                      Make it better!
                    </h3>
                  </div>
                  <p className="font-lg-20 mb-0">
                    Lorem ipsum dolor sit amet consectetur. Eget quis dignissim
                    ac vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                    sagittis nulla ac luctus mus imperdiet. Tellus dui tempus
                    blandit cras. Et vel adipiscing ut at faucibus praesent
                    neque id orci.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {
        <VideoModal
          show={showModal}
          handleSkipVideoModal={handleSkipVideoModal}
          handleClose={handleCloseModal}
          handleVideoChange={handleVideoChange}
        />
      }
    </>
  )
}

export default UploadProviderPhoto
