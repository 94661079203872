import React, { useEffect, useState } from "react"
import "./GeneralInformation.css"
import { useForm, Controller } from "react-hook-form"
import { Button, Form, Spinner } from "react-bootstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useProviderPhoneVerificationMutation,
  useUpdateAccountMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import {
  useSeekerPhoneVerificationMutation,
  useUpdateSeekerProfileMutation,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"

import { startAfter } from "firebase/firestore"
import { toast } from "react-toastify"
import { generalInfoSchema } from "../../../../utils/yupSchema/Schema"
import { useAppSelector } from "../../../../app/hooks"
import OTP from "./verificationModal/VerificationModal"
import VerificationModal from "./verificationModal/VerificationModal"
import { useAppDispatch } from "../../../../app/store"
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../../constants"
import {
  useResendOtpMutation,
  useResendOtpProviderMutation,
} from "../../../../features/Auth/authApi"

const GeneralInformation = ({ Data, userType }: any) => {
const [newPhoneNumber,setNewPhoneNumber] = useState<boolean>(false)
const [newEmail,setNewEmail] = useState<boolean>(false)
  const handleDataFormat = (date: any) => {
    const originalDate = new Date(date)
    const month = ("0" + (originalDate.getMonth() + 1)).slice(-2)
    const day = ("0" + originalDate.getDate()).slice(-2)
    const year = originalDate.getFullYear()
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const [generaForm, { data, error, isLoading }] =
    userType === "SEEKER"
      ? useUpdateSeekerProfileMutation()
      : useUpdateAccountMutation()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = useForm<any>({
    resolver: generalInfoSchema,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      email: values.email,
      firstName: values.fname,
      lastName: values.lname,
      mobileNo: values.phone,
      gender: values.gender,
      landlineNo: values.landline,
      skypeId: values.skype,
      TelegramId: values.TelegramId,
      dob: values.dob,
    }
    const res = await generaForm(payload)    
    if ((res as any)?.data) {
      setNewPhoneNumber(false)
      toast.dismiss()
      toast.success("Updated Successfully")
    }
    else{if ((res as any)?.error) {
      toast.dismiss()
      toast.error((res as any)?.error?.data?.message)
    }
    }
  }
  useEffect(() => {
    setValue("fname", Data?.data?.firstName)
    setValue("lname", Data?.data?.lastName)
    setValue("gender", Data?.data?.gender)
    setValue("dob", Data?.data?.dob ? handleDataFormat(Data?.data?.dob) : "")
    setValue("email", Data?.data?.email)
    setValue("phone", Data?.data?.mobileNo)
    setValue("landline", Data?.data?.landlineNo)
    setValue("skype", Data?.data?.skypeId)
    setValue("TelegramId", Data?.data?.TelegramId)
  }, [Data])
  
  const auth = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  // phonr verification
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification

  const [
    verifyEmail,
    {
      data: verifySeekerEmaildata,
      error: verifySeekerEmailError,
      isSuccess: verifySeekerEmailSuccess,
      isLoading: verifySeekerEmailLoading,
    },
  ] = useResendOtpMutation()
  const [
    verifyProviderEmail,
    {
      data: verifyProviderEmaildata,
      error: verifyProviderEmailError,
      isSuccess: verifyProviderEmailSuccess,
      isLoading: verifyProviderEmailLoading,
    },
  ] = useResendOtpProviderMutation()

  const handleEmailVerify = async () => {
    try {
      if (auth?.currentUserRole === USERTYPE.PROVIDER) {
        if (newEmail) {
          toast.dismiss()
          toast.error("Please confirm your updated Email first")
        }
        else{
          if (!verifyProviderEmailLoading) {
            const res = await verifyProviderEmail(auth?.user?.email)
            if ((res as any)?.data?.success) {
              dispatch(
                setModalName(
                  MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
                ),
              )
            } else {
              toast.error("Something went Wrong, please try again later")
            }
          }
        }
       
      } else {
        if (newEmail) {
          toast.dismiss()
          toast.error("Please confirm your updated Email first")
        }else{
          if (!verifySeekerEmailLoading) {
            const res = await verifyEmail(auth?.user?.email)
            if ((res as any)?.data?.success) {
              dispatch(
                setModalName(
                  MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
                ),
              )
            } else {
              toast.error("Something went Wrong, please try again later")
            }
          }
        }
       
      }
    } catch (error) {
      console.error("Error while handling email verification:", error)
      toast.error("An unexpected error occurred. Please try again later.")
    }
  }

  const handlePhoneVerify = async () => {

    if (auth?.currentUserRole === USERTYPE.PROVIDER) {
      if (newPhoneNumber) {
        toast.dismiss()
        toast.error("Please confirm your updated phone number first")
      }
      else{
        if (!phoneVerificationLoading) {
          const res = await phoneVerify()
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
            )
          } else {
            toast.dismiss()
            toast.error("Something went Wrong, please try again later")
          }
        }
      }
     
    } else {
      if (newPhoneNumber) {
        toast.dismiss()
        toast.error("Please confirm your updated phone number first")
      }
      else{
        if (!seekerphoneVerificationLoading) {
          const res = await seekerphoneVerify()
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
            )
          } else {
            toast.dismiss()
            toast.error("Something went Wrong, please try again later")
          }
        }
      }
     
    }
  }

   const handlePhoneChange=()=>{    
    setNewPhoneNumber(true)
   }
  return (
    <div>
      <div className="block-card account-block-card mb-4">
        <h2 className="card-heading">General Information</h2>
        <Form className="form-general" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-4">
            <Form.Control
              {...register("fname")}
              name="fname"
              type="text"
              placeholder="First Name"
              className="account-field"
            />
            {errors && (
              <span className="error">{(errors as any)?.fname?.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-4 ">
            <Form.Control
              {...register("lname")}
              // value={getValues("lname")}
              name="lname"
              type="text"
              placeholder="Last Name"
              // onChange={(e) => setValue("fname", e.target.value)}
              defaultValue={Data?.data?.lastName}
              className="account-field"
            />
            {errors && (
              <span className="error">{(errors as any)?.lname?.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Select
              {...register("gender")}
              name="gender"
              className="account-field"
              placeholder="Gender"
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              {...register("dob")}
              name="dob"
              type="date"
              placeholder="DD/MM/YYYY"
              max={new Date().toISOString().split("T")[0]}
              className="account-field"
            />
            {errors && (
              <span className="error">{(errors as any)?.dob?.message}</span>
            )}
          </Form.Group>
          <Form.Group
            className="phoneFormGroup mb-4"
            style={{ border: "1px solid #ccc", borderRadius: "7px" }}
          >
            <Form.Control
              {...register("email")}
              name="email"
              type="email"
              // defaultValue={Data?.data?.email}
              disabled={true}
              placeholder="Email Address"
              className="account-field"
              style={{ width: "71%", border: "none" }}
            />
            {auth?.user?.isEmailVerified ? (
              <span className="verifyspan">Verified</span>
            ) : (
            
              <span className="notverifyspan" onClick={handleEmailVerify}>
                Please Verify
              </span>
            )}

            {errors && (
              <span className="error">{(errors as any)?.email?.message}</span>
            )}
          </Form.Group>
          <Form.Group
            className="phoneFormGroup mb-1"
            style={{ border: "1px solid #ccc", borderRadius: "7px" }}
          >
            <Form.Control
              {...register("phone")}
              name="phone"
              type="number"
              onChange={handlePhoneChange}
              defaultValue={Data?.data?.mobileNo}
              onKeyDown={(e) => {
                if (
                  !(
                    (e.key >= "0" && e.key <= "9") ||
                    e.key === "Backspace" ||
                    e.key === "Delete"
                  )
                ) {
                  e.preventDefault()
                }
              }}
              placeholder="Phone Number"
              className="account-field"
              style={{ width: "71%", border: "none" }}
            />
           
            {auth?.user?.isMobileNoVerified ? (
              <div className="verifyspan">Verified</div>
            ) : (
              !newPhoneNumber && <div className="notverifyspan" onClick={handlePhoneVerify}>
                Please Verify
              </div>
            )}
            
          </Form.Group>
          {errors && (
              <span className="error">{(errors as any)?.phone?.message}</span>
            )}
          
          <Form.Group className="mb-4 mt-3">
            <Form.Control
              {...register("landline")}
              name="landline"
              type="number"
              defaultValue={Data?.data?.landlineNo}
              placeholder="Landline Number"
              className="account-field"
              onKeyDown={(e) => {
                if (
                  !(
                    (e.key >= "0" && e.key <= "9") ||
                    e.key === "Backspace" ||
                    e.key === "Delete"
                  )
                ) {
                  e.preventDefault()
                }
              }}
            />
            {errors && (
              <span className="error">
                {(errors as any)?.landline?.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              type="text"
              {...register("skype")}
              name="skype"
              placeholder="Skype Id"
              // defaultValue={Data?.data?.skypeId}
              className="account-field"
            />
            {errors && (
              <span className="error">{(errors as any)?.skype?.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              type="text"
              {...register("TelegramId")}
              name="TelegramId"
              placeholder="Telegram Id"
              // defaultValue={Data?.data?.TelegramId}
              className="account-field"
            />
            {errors && (
              <span className="error">
                {(errors as any)?.TelegramId?.message}
              </span>
            )}
          </Form.Group>
          <Button variant="primary" className="submit-card-btn" type="submit">
            {isLoading ? <Spinner /> : "Confirm"}
          </Button>
        </Form>
      </div>
      <VerificationModal />
    </div>
  )
}

export default GeneralInformation
