import React, { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Table,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import reviewimage from "../../../../assets/img/card-image.png"
import averageimg from "../../../../assets/img/average.png"
import averageimg1 from "../../../../assets/img/average-1.png"
import goodimg from "../../../../assets/img/good.png"
import amazingimg from "../../../../assets/img/amazing.png"
import outstandingimg from "../../../../assets/img/outstanding.png"
import "../../Providerdashboard/receivedReview/ReceivedReview.css"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useCreateSeekerReviewMutation } from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import { useLocation } from "react-router-dom"
import ReviewSuccessModal from "./ReiviewSuccessModal"
import { customerDashboardWriteReviewSchema } from "../../../../utils/yupSchema/Schema"
import { USERTYPE } from "../../../../constants"

const ReceivedReview = ({ data, id }: any) => {
  const { state } = useLocation()
  const [reviewmsg, setReviewmsg] = useState<string>()
  const [rating, setRating] = useState<number>()
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }

  const [seekerReview, { data: reviewdata, error: revError, isSuccess }] =
    useCreateSeekerReviewMutation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<any>({
    resolver: customerDashboardWriteReviewSchema,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      message: values.review,
      providerId:
        data?.data[id]?.providerId ||
        state?.data?.data[state?.index]?.providerId._id,
      rating: rating,
    }
    await seekerReview(payload)
  }
  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
        setReviewmsg("")
        reset()
      }, 3000)
    }
  }, [isSuccess])
  return (
    <>
      <DashboardHeader userType={USERTYPE.SEEKER} />
      <section className="my-account-sec bg-f9f9f9 py-3">
        <Container fluid>
          <div className="inner-account">
            <Row className="justify-content-center">
              <Col xl={8} lg={9}>
                <div className="evolution-heading">
                  <Card className="border-0 review-card">
                    <Card.Body className="p-0">
                      <div className="review py-4">
                        <div className="d-lg-flex justify-content-between align-items-center mb-5">
                          <div className="review-user d-flex align-items-center">
                            <img src={reviewimage} className="review-img" />
                            <div>
                              <h3 className="class-name d-block">
                                {
                                  state.data.data[state.index].providerId
                                    .firstName
                                }
                              </h3>
                              <p className="occ-name">
                                {
                                  state.data.data[state.index].providerId
                                    .skills[0]
                                }
                              </p>
                            </div>
                          </div>
                          <p className="review-text">
                            {data?.data[id]?.replyMessage ||
                              state?.data?.data[state?.index]?.replyMessage}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center rate-listing">
                          <div onClick={() => setRating(1)}>
                            <input
                              type="radio"
                              className="d-none review-radio"
                              name="review-input"
                              id="average_radio"
                            />
                            <label
                              htmlFor="average_radio"
                              className="review-label"
                            >
                              <img src={averageimg} />
                              <p className="review-rate"> Below Average</p>
                            </label>
                          </div>
                          <div onClick={() => setRating(2)}>
                            <input
                              type="radio"
                              className="d-none review-radio"
                              name="review-input"
                              id="average1_radio"
                            />
                            <label
                              htmlFor="average1_radio"
                              className="review-label"
                            >
                              <img src={averageimg1} />
                              <p className="review-rate">Average</p>
                            </label>
                          </div>
                          <div onClick={() => setRating(3)}>
                            <input
                              type="radio"
                              className="review-radio d-none"
                              name="review-input"
                              id="good_radio"
                            />
                            <label
                              htmlFor="good_radio"
                              className="review-label"
                            >
                              <img src={goodimg} />
                              <p className="review-rate">Good</p>
                            </label>
                          </div>
                          <div onClick={() => setRating(4)}>
                            <input
                              type="radio"
                              className="d-none review-radio"
                              name="review-input"
                              id="amazing_radio"
                            />
                            <label
                              htmlFor="amazing_radio"
                              className="review-label"
                            >
                              <img src={amazingimg} />
                              <p className="review-rate">Amazing</p>
                            </label>
                          </div>
                          <div onClick={() => setRating(5)}>
                            <input
                              type="radio"
                              className="d-none review-radio"
                              name="review-input"
                              id="outstanding_radio"
                            />
                            <label
                              htmlFor="outstanding_radio"
                              className="review-label"
                            >
                              <img src={outstandingimg} />
                              <p className="review-rate">Outstanding</p>
                            </label>
                          </div>
                        </div>
                        <div className="reply-review-post write-review-box mb-4">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <textarea
                              className="form-control review-field review-textarea"
                              {...register("review")}
                              name="review"
                              placeholder="Enter the review"
                              value={reviewmsg}
                              onChange={(e) => setReviewmsg(e.target.value)}
                            />
                            <div className="errormsg">
                              {(revError as any)?.data?.message ||
                                (errors?.review && errors.review.message)}
                            </div>

                            <div className="review-btnlist text-end">
                              <button
                                type="submit"
                                className="reply-btn send-review-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#replysendmodal"
                              >
                                Send
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {
        <ReviewSuccessModal
          show={show}
          handleClose={handleClose}
          data={data?.data[id] || state?.data?.data[state?.index]}
        />
      }
    </>
  )
}

export default ReceivedReview
