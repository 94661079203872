import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardComponent from '../../dasboard/Providerdashboard/Dashboardcomponent/DashboardComponent';
import { USERTYPE } from '../../../constants';
import DashboardDesign from '../../dasboard/seekerDasboard/CustomerDashOverview/CustomerDashOverview';
interface layoutProps {
    ColumnLeft?:React.ReactNode
    ColumnRight?:React.ReactNode
}
const DashLayoutWrapper = ({ColumnLeft, ColumnRight}:layoutProps) => {
    return (
        <>
            <Row className="justify-content-center mx-0">
           { ColumnLeft ? <Col xxl={3} lg={4}>
{ColumnLeft}                </Col> : <></>
           }     
           <Col xxl={ ColumnLeft ? 9 : 12} lg={ ColumnLeft ? 8 :12}>
{ColumnRight}
                    </Col>
            </Row>

        </>
    );
};

export default DashLayoutWrapper;
