import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Table,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import "./UploadIdentification.css"
import { USERTYPE } from "../../../../constants"
const UploadIdentification = () => {
  return (
    <>
      <DashboardHeader userType={USERTYPE.PROVIDER} />
      <section className="my-account-sec py-5">
        <Container fluid>
          <div className="inner-account">
            <Row>
              <Col xl={7} lg={9}>
                <div className="d-flex align-items-start">
                  <div>
                    <a href="#" className="back-link">
                      <i className="fas fa-chevron-left back-btn"></i>
                    </a>
                  </div>
                  <div className="complete-profile w-100">
                    <h3 className="complete-para text-start mb-5">
                      Identification{" "}
                    </h3>
                    <div className="identi-doc-upload">
                      <div className="identi-doc-list">
                        <div className="doc-name-box">
                          <p className="doc-name">Adhaar Card</p>
                        </div>
                        <div>
                          <input
                            type="file"
                            id="identi-field1"
                            className="identi-file-field"
                          />
                          <label htmlFor="identi-field1" className="upload-btn">
                            Upload
                          </label>
                        </div>
                      </div>
                      <div className="identi-doc-list">
                        <div className="doc-name-box">
                          <p className="doc-name"> Card</p>
                        </div>
                        <div>
                          <input
                            type="file"
                            id="identi-field2"
                            className="identi-file-field"
                          />
                          <label htmlFor="identi-field2" className="upload-btn">
                            Upload
                          </label>
                        </div>
                      </div>
                      <div className="identi-doc-list">
                        <div className="doc-name-box">
                          <p className="doc-name">PAN Card</p>
                        </div>
                        <div>
                          <input
                            type="file"
                            id="identi-field3"
                            className="identi-file-field"
                          />
                          <label htmlFor="identi-field3" className="upload-btn">
                            Upload
                          </label>
                        </div>
                      </div>
                      <div className="save-button">
                        <Button className="save-btn">Save</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default UploadIdentification
