import React from "react"
import { Dropdown, Button, Row, Col } from "react-bootstrap"
import ProfileAccountImg from "../../assets/images/profile-a.png"

import styles from "./ChatUi.module.css"
import AvatarRow from "./AvatarRow"

const ProfileAvatars = () => {
  return (
    <>
      <Row className="align-items-end">
        <Col xs={6}>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              Online{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Offline</Dropdown.Item>
              <Dropdown.Item href="#/action-2"></Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={6} className="text-end">
          <Button
            variant="light"
            className={` ${styles["rounded-xl"]} ${styles["unread-btn"]}`}
          >
            2 Unread Messages{" "}
          </Button>{" "}
        </Col>
        <Col xs={12} className="my-2">
          <AvatarRow />
        </Col>
      </Row>
    </>
  )
}

export default ProfileAvatars
