import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface Search {
  searchType: string
  searchText: string
  selectedSearchText: string
  isSelectedLocation: boolean
  selectedLocation: string
  locationText: string
}
const initialState: Search = {
  searchType: "",
  searchText: "",
  selectedSearchText: "",
  isSelectedLocation: false,
  selectedLocation: "",
  locationText: "",
}

const landingSearchSlice = createSlice({
  name: "landingSearchSlice",
  initialState,
  reducers: {
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload
    },
    setSearchText: (state, action: PayloadAction<any>) => {
      state.searchText = action.payload
      state.selectedSearchText = ""
    },
    setSelectedSearchText: (state, action: PayloadAction<string>) => {
      state.selectedSearchText = action.payload
    },
    setLocationText: (state, action: PayloadAction<string>) => {
      state.locationText = action.payload
    },

    setSelectedLocation: (state, action: PayloadAction<string>) => {
      state.selectedLocation = action.payload
    },
    setIsSelectedLocation: (state, action: PayloadAction<boolean>) => {
      state.isSelectedLocation = action.payload
    },
  },
})

export default landingSearchSlice.reducer
export const {
  setSearchText,
  setSearchType,
  setSelectedSearchText,
  setIsSelectedLocation,
  setSelectedLocation,
} = landingSearchSlice.actions

export const selectedLocation = (state: RootState) => state.landingSearch
