import * as React from "react"
import { MutatingDots } from "react-loader-spinner"
interface spinnerProps {
  wrapperStyle?: {}
  wrapperClass?: string
}

const SpinnerSecondary = ({ wrapperStyle, wrapperClass }: spinnerProps) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center landing-spinner">
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color="#53dac1"
          secondaryColor="#ffb900"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={wrapperStyle}
          wrapperClass={wrapperClass}
        />{" "}
      </div>
    </>
  )
}

export default SpinnerSecondary
