import React from "react"
import ChatBar from "./ChatBar"
import ChatList from "./ChatList"
import ChatArea from "./ChatArea"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./ChatUi.module.css"

const ChatWrapper = () => {
  return (
    <>
      <div className={`${styles["chat-wrapper"]}`}>
        <Container >
          <ChatBar />
          <Row>
            <Col lg={5} xl={4}>
              <ChatList />
            </Col>
            <Col lg={7} xl={8}>
              <ChatArea />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ChatWrapper
