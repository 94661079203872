import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./PaymentSub.css"
import razorpay from "../../../assets/img/razorpay.svg"
import checkicon from "../../../assets/img/check-icon.svg"
import leftarrow from "../../../assets/img/left-arrow.svg"
import lockImg from "../../../assets/img/lock.png"
import checkYellow from "../../../assets/img/check-yellow.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useGetProviderDetailsMutation } from "../../../features/Auth/authApi"
import {
  useCreateSeekerRequestMutation,
  usePaymentCheckoutMutation,
  usePaymentKeyQuery,
  usePaymentVerifyMutation,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { handlePayment } from "../paymentGatewayfunction"
import sendReqImg from "../../../assets/images/sendReqImg.png"
import { useAppSelector } from "../../../app/hooks"
import { setSeekerRequest } from "../../../features/featSeekerDashboard/seekerDashboardSlice"
import { useDispatch } from "react-redux"

const PaymentSub = () => {
  const [showRequest, setShowRequest] = useState<boolean>(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { state } = useLocation()
  const [sendRequest, setSendRequest] = useState(false)
  const [profile, { isError, error }] = useGetProviderDetailsMutation()
  const [payment, { data }] = usePaymentCheckoutMutation()
  const { data: key } = usePaymentKeyQuery()
  const [paymentVerify, { data: paymentSucccessData, isSuccess }] =
    usePaymentVerifyMutation()

  const toggleSendTRequest = () => {
    setSendRequest(!sendRequest)
  }

  useEffect(() => {
    if (id != "dashboardPremium") {
      profile(id)
    }
  }, [id])
  useEffect(() => {
    payment({ amount: 500 })
  }, [])

  // new flow implementation
  const requestSeeker = useAppSelector((state) => state.seekerDashboardSlice)
  const [
    request,
    { data: requestData, isSuccess: requestSuccess, error: reqError },
  ] = useCreateSeekerRequestMutation()

  useEffect(() => {
    if (isSuccess) {
      try {
        if (!state?.dashboardPremium) {
          request(requestSeeker?.requestData)
        } else {
          navigate("/seeker-dashboard/my-requests")
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (requestSuccess) {
      navigate("/seeker-dashboard/my-requests")
      dispatch(setSeekerRequest(requestData?.data as any))
    }
  }, [isSuccess, requestSuccess])

  return (
    <>
      <section className="explore-section pb-5 pt-90">
        <Container>
          <div className="inner-explore bg">
            <Row className="align-items-center">
              <Col xl={4} lg={5} className="mb-lg-0 mb-4">
                <div className="unlock-access">
                  <div className="paymentlock d-flex justify-content-start align-items-center">
                    <div className="textContainer">
                      <span>
                        {state?.dashboardPremium
                          ? " Monthly Membership"
                          : "Student Pass"}
                      </span>
                      <h2 className="unlock-head"> ₹ 500</h2>
                      <span> Per Month</span>
                    </div>
                  </div>
                  <ul className="unlock-list">
                    <li>
                      <img src={checkYellow} />
                      Access to contact with unlimited tutors
                    </li>
                    <li>
                      <img src={checkYellow} />
                      Get additional discount and offers.
                    </li>
                    <li>
                      <img src={checkYellow} />
                      Get additional discount and offers.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xl={8} lg={7}>
                {!sendRequest ? (
                  <div className="schedule-section ps-4">
                    <h2
                      className="  fw-light"
                      style={{ color: "#434343", textAlign: "center" }}
                    >
                      {state?.dashboardPremium != "dashboardPremium"
                        ? "Student"
                        : "Membership"}{" "}
                      Pass Vaild for <b className="fw-bold">30 Days</b>
                    </h2>

                    <div>
                      <div className="d-flex flex-column gap-3">
                        <a
                          onClick={(e) => {
                            handlePayment(
                              e,
                              key,
                              id,
                              data,
                              paymentVerify,
                              requestSuccess,
                              state?.dashboardPremium,
                            )
                          }}
                          className="studentPassBtn  mt-4"
                        >
                          {state?.dashboardPremium
                            ? "Buy Membership"
                            : "Get Student Pass"}
                        </a>
                        <p className="text-center">
                          <a
                            className={
                              sendRequest
                                ? "cursor-pointer tritary-btn disabled"
                                : "cursor-pointer tritary-btn"
                            }
                            onClick={toggleSendTRequest}
                          >
                            I don't need right now
                          </a>
                        </p>
                      </div>
                      {
                        <span className="error">
                          {(reqError as any)?.data?.message}{" "}
                        </span>
                      }
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      sendRequest
                        ? "send-request-wrapper`active"
                        : "send-request-wrapper"
                    }
                  >
                    <div className="dottedLine mt-2"></div>
                    <Row className="py-5 align-items-center">
                      <Col xxl={4} md={6}>
                        <div className="sendImg">
                          <img src={sendReqImg} alt="sendReqImg" />
                        </div>
                      </Col>
                      <Col xxl={8} md={6}>
                        <div className="request-detail">
                          <h5 className=" fw-semibold">Send request anyway</h5>
                          <p className="font-14">
                            Lorem ipsum dolor sit amet consectetur. Nulla arcu
                            massa ipsum pellentesque habitant et non ultricies.
                            Vestibulum ipsum pellentesque dui purus magna. Nibh
                            ipsum ullamcorper convallis.
                          </p>
                          <button
                            className="sendrequestBtn"
                            onClick={() => navigate(`/payment-success/${id}`)}
                          >
                            Send Request
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {/* lower section */}
              </Col>
            </Row>

            {!state?.dashboardPremium && <></>}
          </div>
        </Container>
      </section>
    </>
  )
}

export default PaymentSub
