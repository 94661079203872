import { useEffect, useState } from "react"
import { Modal, Button, Form, Spinner } from "react-bootstrap"
import mailicon from "../../../../assets/img/mail-icon.svg"
import backicon from "../../../../assets/img/back-icon.svg"
import { toast } from "react-toastify"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import "../authComponent.css"
import FormInput from "../../../form/FormInput"
import { useAppSelector } from "../../../../app/hooks"
import { useAppDispatch } from "../../../../app/store"
import { MODALNAME } from "../../../../constants"
import {
  closeModal,
  setModalName,
} from "../../../../features/Auth/ModalHandler/modalSlice"
import { useForgetPasswordMutation } from "../../../../features/Auth/authApi"
import { useProviderforgetPasswordMutation } from "../../../../features/Auth/authApi"
import { forgotPasswordSchema } from "../../../../utils/yupSchema/Schema"
import { setForgotPasswordEmail } from "../../../../features/Auth/authSlice"

interface CardProps {}
interface ForgetUser {
  email: string
}
const Forgot = ({}: CardProps) => {
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const [userModalName, setUserModalName] = useState<string>()
  const dispatch = useAppDispatch()

  const [forgetEmail, { isError, error, data, isLoading }] =
    useForgetPasswordMutation()
  const [
    forgetProviderEmail,
    {
      isError: providerisError,
      error: providerError,
      data: providerData,
      isLoading: forgetProviderloading,
    },
  ] = useProviderforgetPasswordMutation()

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ForgetUser>({
    resolver: forgotPasswordSchema,
  })

  const onSubmit = async (values: any) => {
    const payload = {
      email: values.email,
    }
    if (modalName === MODALNAME.FORGET_PASSWORD_TUTOR) {
      setUserModalName("Provider")
      const res = await forgetProviderEmail(payload)
      if ((res as any)?.data?.success) {
        dispatch(setForgotPasswordEmail(values.email))
      }
    } else if (modalName === MODALNAME.FORGET_PASSWORD_SEEKER) {
      setUserModalName("Seeker")

      const res = await forgetEmail(payload)
      if ((res as any)?.data?.success) {
        dispatch(setForgotPasswordEmail(values.email))
      }
    }
  }

  const handleCloseModal = () => {
    reset()
    dispatch(closeModal())
  }
  const handleBackIcon = () => {
    reset()
    dispatch(dispatch(setModalName(MODALNAME.LOGIN)))
  }

  useEffect(() => {
    if (
      data?.message !== "" &&
      data?.message !== undefined &&
      userModalName == "Seeker"
    ) {
      toast.success("Request Successfully")
      reset()

      dispatch(setModalName(MODALNAME.RESET_PASSWORD_OTP_SEEKER))
    } else if (
      providerData?.message !== "" &&
      providerData?.message !== undefined &&
      userModalName == "Provider"
    ) {
      toast.success("Request Successfully")
      reset()
      dispatch(setModalName(MODALNAME.RESET_PASSWORD_OTP_PROVIDER))
    } else if (
      (providerError as any)?.data?.message !== "" &&
      (providerError as any)?.data?.message !== undefined &&
      userModalName == "Provider"
    ) {
      toast.error((providerError as any)?.data?.message)
    } else if (
      userModalName == "Seeker" &&
      (error as any)?.data?.message !== "" &&
      (error as any)?.data?.message !== undefined
    ) {
      toast.dismiss()
      toast.error((error as any)?.data?.message)
    }
  }, [data, providerData, error, providerError, userModalName])

  return (
    <>
      <Modal
        animation
        centered
        show={
          modalName === MODALNAME.FORGET_PASSWORD_TUTOR ||
          modalName === MODALNAME.FORGET_PASSWORD_SEEKER
        }
        className="login-modal"
        size="lg"
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0 ms-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0">
              <Modal.Title
                className="popup-heading d-flex"
                id="exampleModalToggleLabel"
              >
                <a
                  href="#"
                  className="back-link px-0 d-inline-flex justify-content-center"
                  onClick={handleBackIcon}
                >
                  <img src={backicon} />
                </a>
              </Modal.Title>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
                className="btn-close"
              ></Button>
            </Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <h3 className="popup-title">
                Forgot Password{" "}
                {modalName == "FORGET_SEEKER" ? "as Seeker" : "as Provider"}
              </h3>
              <p className="mb-4 font-15" style={{ textAlign: "center" }}>
                Enter the email address associated with your account
              </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="popup-field-wrapper mb-4">
                  <img src={mailicon} alt="Mail Icon" />

                  <FormInput
                    name="email"
                    placeholder="Enter E-mail Address"
                    register={register}
                    // errors={errors}
                    className="popup-field w-100"
                  />
                  {/* <Form.Control
                    type="email"
                    placeholder="Enter E-mail Address"
                    className="form-control shadow-none border-0 rounded-0 popup-field"
                  /> */}
                </div>
                <span className="error">{errors?.email?.message}</span>

                {/* {(providerError as any)?.data?.message ||
                  ((error as any)?.data?.message && (
                    <span style={{ color: "red" }}>
                      {(providerError as any)?.data?.message ||
                        (error as any)?.data?.message}
                    </span>
                  ))} */}
                <Button
                  type="submit"
                  // onClick={() =>
                  //   dispatch(setModalName(MODALNAME.RESET_PASSWORD))
                  // }
                  className="common-btn auth-btn mb-2"
                  disabled={isLoading || forgetProviderloading}
                >
                  {isLoading || forgetProviderloading ? <Spinner /> : "Send"}
                </Button>
              </Form>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default Forgot
