import React, { useState } from "react"
import "./ReviewCard.css"
import reviewimage from "../../../../assets/img/card-image.png"
import ReplyReview from "../../Providerdashboard/receivedReview/ReplyReview"

interface reviewCardProps {
  name: string
  course: string
  para: any
  btnStr: string
  userType: any
  id: any
  handleClose: (id: any) => void
}

const ReviewCard = (props: reviewCardProps) => {
  return (
    <>
      <div>
        <div className="review-list">
          <div className="review-user d-flex align-items-center me-lg-3">
            <img src={reviewimage} className="review-img" />
            <div>
              <h3 className="class-name d-block">{props.name}</h3>
              {/* <p className="occ-name">{props.course}</p> */}
            </div>
          </div>
          <div className="review-post">
            <p className="review-text">{props.para}</p>
          </div>
          <div className="review-btnlist ms-lg-3">
            <button
              className="reply-btn"
              onClick={() => props.handleClose(props.id)}
            >
              {props.btnStr}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewCard
