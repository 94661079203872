import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./SeekerStepOne.css"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../../navbars/TopNavbar"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { useAppDispatch } from "../../../../app/store"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppSelector } from "../../../../app/hooks"
import { useForm } from "react-hook-form"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
const SeekerStepOne = () => {
  const dispatch = useAppDispatch()
  // const [selectedOption, setSelectedOption] = useState("")
  // const handleSelectChange = (e: any) => {
  //   setSelectedOption(e.target.value)
  //   dispatch(updateFunnel({ service: e.target.value }))
  // }

  const [selectedValue, setSelectedValue] = useState<any>([])

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()

  const subOptions = [
    // { value: "Teacher", label: "Teacher" },
    { value: "Source", label: "Source" },
    // { value: "Vedic Guru", label: "Vedic Guru" },
    // { value: "Mentor", label: "Mentor" },
    // { value: "Guide", label: "Guide" },
  ]
  const seekerData = useAppSelector((state) => state.seekerFunnel)
  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
  ) => {
    const payloadPreference = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    const isStepValid = selectedValues.length > 0
    dispatch(
      updateFunnel({ ...seekerData, service: payloadPreference, isStepValid }),
    )
  }

  return (
    <>
      <section>
        <Container>
          <div className="inner-fav mb-5">
            <Row className="justify-content-center">
              <Col xl={6} lg={8}>
                <div className="d-flex justify-content-between flex-column h-100 text-center">
                  <div>
                    <h3 className="get-started-heading">
                      Let’s Get started on your Vedic Journey
                    </h3>
                    <p className="get-started-text mb-5">
                      Tell us your preferences and we’ll match you with the
                      perfect vedic teacher
                    </p>
                    <label className="get-started-text mb-2">
                      What are you looking for?
                    </label>
                  </div>
                  <div>
                    <FormMultiSelect
                      title=""
                      control={control}
                      options={subOptions}
                      onChange={(option) => handleSelectChange(option)}
                      name="select"
                      value={selectedValue}
                    />
                    <br />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepOne
