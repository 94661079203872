import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./ClassInfo.css"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import {
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { Prev } from "react-bootstrap/esm/PageItem"
import presentation1 from "../../../../assets/images/presentation 1.svg"
import arrowUp from "../../../../assets/img/arrow-up.png"
import arrowDown from "../../../../assets/img/arrow-down.png"
import infoIcon from "../../../../assets/img/infoIcon.png"
import group from "../../../../assets/images/Group.svg"
import solo from "../../../../assets/images/singleSvg.svg"
import video from "../../../../assets/images/video.svg"

interface PricesInterface {
  realTime: {
    group: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
    private: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
  }
  virtual: {
    group: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
    private: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
  }
}

const ClassInfo = () => {
  const dispatch: any = useAppDispatch()
  const [modeOfClass, setModeOfClass] = useState({
    realTime: false,
    virtual: false,
  })

  const [prices, setPrices] = useState<PricesInterface>({
    realTime: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
    virtual: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
  })
  const [pricesCheckBox, setPricesCheckBox] = useState({
    realTime: {
      groupCheckBox: false,
      privateCheckBox: false,
    },
    virtual: {
      groupCheckBox: false,
      privateCheckBox: false,
    },
  })
  const [pricesError, SetpricesError] = useState({
    realTime: {
      group: false,
      private: false,
    },
    virtual: {
      group: false,
      private: false,
    },
  })

  const [ClassFields, setClassFields] = useState({
    modeOfClass: "",
    HourlyRate: "",
  })
  const providerFunnelDatas = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )
  const [isHourlyRateError, setIsHourlyRateError] = useState<boolean>(false)
  const [infoactive, setInfoActive] = useState(false)
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }

  useEffect(() => {
    const priceErrors =
      pricesError?.realTime?.group ||
      pricesError?.realTime?.private ||
      pricesError?.virtual?.group ||
      pricesError?.virtual?.private
    dispatch(setIsStepValid(priceErrors))
  }, [
    pricesError?.realTime?.group,
    pricesError?.realTime?.private,
    pricesError?.virtual?.group,
    pricesError?.virtual?.private,
  ])

  useEffect(() => {
    if (providerFunnelDatas?.modeOfClass && providerFunnelDatas?.hourlyRate) {
      setClassFields({
        modeOfClass: providerFunnelDatas.modeOfClass,
        HourlyRate: providerFunnelDatas.hourlyRate,
      })
    }
  }, [providerFunnelDatas])

  const handleSetModeofClass = (value: any) => {
    setModeOfClass((prev: any) => ({ ...prev, [value]: !prev[value] }))
  }
  const funnelData = useAppSelector((state) => state.providerFunnel)
  const data = funnelData?.providerFunnelData
  
  //price datastructure handling
  const handlePriceChange = (
    mode: any,
    classType: any,
    field: any,
    value: any,
  ) => {
    if (field === "hourlyFee") {
      if (value === "") {
        dispatch(setIsStepValid(true));
      } else if (value <= 0 ) {
        handlePricesErrorChange(mode, classType, true);
        dispatch(setIsStepValid(false));
      } else {
        handlePricesErrorChange(mode, classType, false);
        dispatch(setIsStepValid(false));
      }
    }

    setPrices((prevPrices: any) => ({
      ...prevPrices,
      [mode]: {
        ...prevPrices[mode],
        [classType]: {
          ...prevPrices[mode]?.[classType],
          [field]: value,
        },
      },
    }))
    // console.log(prices);

    dispatch(updateFunnel({ prices }))
  }
  //error handling
  const handlePricesErrorChange = (
    mode: "realTime" | "virtual",
    type: "group" | "private",
    value: boolean,
  ) => {
    SetpricesError((prevPricesError) => ({
      ...prevPricesError,
      [mode]: {
        ...prevPricesError[mode],
        [type]: value,
      },
    }))
  }
  //checkbox handling
  const handlePricesCheckBoxChange = (
    mode: "realTime" | "virtual",
    type: "groupCheckBox" | "privateCheckBox",
    value: boolean,
  ) => {
    if (!value) {
      setPrices((prevPrices: any) => ({
        ...prevPrices,
        [mode]: {
          ...prevPrices[mode],
          [type === "groupCheckBox" ? "group" : "private"]: null,
        },
      }))
    }

    setPricesCheckBox((prevPricesCheckBox) => ({
      ...prevPricesCheckBox,
      [mode]: {
        ...prevPricesCheckBox[mode],
        [type]: value,
      },
    }))
  }
  useEffect(() => {
    const isAnyCheckBoxChecked =
      pricesCheckBox?.realTime?.groupCheckBox ||
      pricesCheckBox?.realTime?.privateCheckBox ||
      pricesCheckBox?.virtual?.groupCheckBox ||
      pricesCheckBox?.virtual?.privateCheckBox;
  
    if (!isAnyCheckBoxChecked) {
      dispatch(setIsStepValid(true))
    } else {
      if (
        (prices?.realTime?.group?.hourlyFee !== null &&
          prices?.realTime?.group?.hourlyFee > 0) ||
        (prices?.realTime?.private?.hourlyFee !== null &&
          prices?.realTime?.private?.hourlyFee > 0) ||
        (prices?.virtual?.group?.hourlyFee !== null &&
          prices?.virtual?.group?.hourlyFee > 0) ||
        (prices?.virtual?.private?.hourlyFee !== null &&
          prices?.virtual?.private?.hourlyFee > 0)
      ) {
        dispatch(setIsStepValid(false));
      } 
      else {
        dispatch(setIsStepValid(true));
      }
    }
  }, [prices, pricesCheckBox]);

useEffect(()=>{
 
  if (pricesError?.realTime?.group || pricesError?.realTime?.private|| pricesError?.virtual?.group||pricesError?.virtual?.private) {
    dispatch(setIsStepValid(true));
      } 
    
  
  else {
    dispatch(setIsStepValid(false));
  }
},[pricesError])


  useEffect(() => {
    setPrices(data?.prices as any)
    dispatch(setIsStepValid(true))
  }, [])
  return (
    <>
      <section className="py-lg-5 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="mb-5">
                  <form>
                    <label className="green-text mb-2 font-14 d-flex gap-2 justify-content-start align-items-center">
                      <p className=" text-black fw-bolder fs-4">Select </p>
                      <p className=" fs-5 fw-bolder mt-1">Mode of class</p>
                    </label>
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`class-mode-box ${
                          modeOfClass.realTime ? "active" : ""
                        }`}
                        onClick={() => handleSetModeofClass("realTime")}
                      >
                        <div className="d-flex ms-3 align-items-center gap-2">
                          <img src={presentation1} alt="presentation" />
                          <p className=" fw-semibold text-black my-auto">
                            Real Time
                          </p>
                        </div>
                        <img
                          src={`${modeOfClass.realTime ? arrowDown : arrowUp}`}
                          alt="arrowUp"
                        />
                      </div>
                      <div
                        className={`lower-content d-flex flex-column gap-2 ${
                          modeOfClass.realTime ? "show" : ""
                        }`}
                      >
                        <p className="d-flex gap-1">
                          Class <span className="green-text">Type</span>{" "}
                          <span className=" text-black-50">
                            (You can select both options)
                          </span>{" "}
                          <img src={infoIcon} alt="infoIcon" />
                        </p>
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3  mb-3 ">
                            <input
                              type="checkbox"
                              id="groupClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.realTime?.groupCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "realTime",
                                  "groupCheckBox",
                                  !pricesCheckBox?.realTime?.groupCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">Group Class</p>
                              <img src={group} alt="group" />
                            </div>
                          </div>
                          {/* <div className="d-flex">
                            <p className=" text-black my-auto">
                              First class free
                            </p>
                            <Form.Check
                              type="switch"
                              id="adonline"
                              label=""
                              className="notify-toggle mb-0 mt-0"
                              checked={prices?.realTime?.group?.firstClassFree}
                              disabled={
                                !pricesCheckBox?.realTime?.groupCheckBox
                              }
                              onClick={() =>
                                handlePriceChange(
                                  "realTime",
                                  "group",
                                  "firstClassFree",
                                  !prices.realTime?.group?.firstClassFree,
                                )
                              }
                            />
                          </div> */}
                        </div>
                        {pricesCheckBox?.realTime?.groupCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold ">
                              Hourly Rate
                              <p
                                className=" text-black-50"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div
                              className="mb-4"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="realTime_Group_HourlyRate"
                                placeholder="2000"
                                className="provider-field w-100 outline-0 font-14 py-2 shadow-none rounded-4"
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                disabled={
                                  !pricesCheckBox?.realTime?.groupCheckBox
                                }
                                value={
                                  prices?.realTime?.group?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "realTime",
                                    "group",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                ₹/hr
                              </span>
                              {pricesError?.realTime?.group && (
                                <div className="text-danger">
                                  "Rates cannot be zero or negative"
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {/* private class */}
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3 mb-3 ">
                            <input
                              type="checkbox"
                              id="privateClass"
                              className="class-checkbox"
                              checked={
                                pricesCheckBox?.realTime?.privateCheckBox
                              }
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "realTime",
                                  "privateCheckBox",
                                  !pricesCheckBox?.realTime?.privateCheckBox,
                                )
                              }
                            />
                            <div className="d-flex">
                              <p className=" text-black my-auto">
                                Private Class
                              </p>
                              <img src={solo} alt="group" />
                            </div>
                          </div>
                          {/* <div className="d-flex">
                            <p className=" text-black my-auto">
                              First class free
                            </p>
                            <Form.Check
                              type="switch"
                              id="adonline"
                              label=""
                              className="notify-toggle mb-0 mt-0"
                              disabled={
                                !pricesCheckBox?.realTime?.privateCheckBox
                              }
                              checked={
                                prices?.realTime?.private?.firstClassFree
                              }
                              onClick={() =>
                                handlePriceChange(
                                  "realTime",
                                  "private",
                                  "firstClassFree",
                                  !prices.realTime?.private?.firstClassFree,
                                )
                              }
                            />
                          </div> */}
                        </div>
                        {pricesCheckBox?.realTime?.privateCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold ">
                              Hourly Rate
                              <p
                                className=" text-black-50"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div
                              className="mb-4"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="2000"
                                className="provider-field w-100 outline-0 font-14 py-2 shadow-none rounded-4"
                                style={{ paddingRight: "3rem" }}
                                disabled={
                                  !pricesCheckBox?.realTime?.privateCheckBox
                                }
                                min={1}
                                value={
                                  prices?.realTime?.private?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "realTime",
                                    "private",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                ₹/hr
                              </span>
                              {pricesError?.realTime?.private && (
                                <div className="text-danger">
                                  "Rates cannot be zero or negative"
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`class-mode-box ${
                          modeOfClass.virtual ? "active" : ""
                        }`}
                        onClick={() => handleSetModeofClass("virtual")}
                      >
                        <div className="d-flex ms-3 align-items-center gap-2">
                          <img src={video} alt="presentation" />
                          <p className=" fw-semibold text-black my-auto">
                            Virtual
                          </p>
                        </div>
                        <img
                          src={`${modeOfClass.virtual ? arrowDown : arrowUp}`}
                          alt="arrowUp"
                        />
                      </div>
                      <div
                        className={`lower-content d-flex flex-column gap-2 ${
                          modeOfClass.virtual ? "show" : ""
                        }`}
                      >
                        <p className="d-flex gap-1">
                          Class <span className="green-text">Type</span>{" "}
                          <span className=" text-black-50">
                            (You can select both options)
                          </span>{" "}
                          <img src={infoIcon} alt="infoIcon" />
                        </p>
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3 mb-3 ">
                            <input
                              type="checkbox"
                              id="virtual_groupClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.virtual?.groupCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "virtual",
                                  "groupCheckBox",
                                  !pricesCheckBox?.virtual?.groupCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">Group Class</p>
                              <img src={group} alt="group" />
                            </div>
                          </div>
                          <div className="d-flex">
                            {/* <p className=" text-black my-auto">
                              First class free
                            </p> */}
                            {/* <Form.Check
                              type="switch"
                              id="adonline"
                              label=""
                              className="notify-toggle mb-0 mt-0"
                              disabled={!pricesCheckBox?.virtual?.groupCheckBox}
                              checked={prices?.virtual?.group?.firstClassFree}
                              onClick={() =>
                                handlePriceChange(
                                  "virtual",
                                  "group",
                                  "firstClassFree",
                                  !prices?.virtual?.group?.firstClassFree,
                                )
                              }
                            /> */}
                          </div>
                        </div>
                        {pricesCheckBox?.virtual?.groupCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold ">
                              Hourly Rate
                              <p
                                className=" text-black-50"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div
                              className="mb-4"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="2000"
                                disabled={
                                  !pricesCheckBox?.virtual?.groupCheckBox
                                }
                                className="provider-field w-100 outline-0 font-14 py-2 shadow-none rounded-4"
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                value={prices?.virtual?.group?.hourlyFee as any}
                                onChange={(e) => {
                                  handlePriceChange(
                                    "virtual",
                                    "group",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                ₹/hr
                              </span>
                              {pricesError?.virtual?.group && (
                                <div className="text-danger">
                                  "Rates cannot be zero or negative"
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {/* private class */}
                        <div className="group-checkbox-container  mb-3">
                          <div className="d-flex align-items-center gap-3 ">
                            <input
                              type="checkbox"
                              id="privateClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.virtual?.privateCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "virtual",
                                  "privateCheckBox",
                                  !pricesCheckBox?.virtual?.privateCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">
                                Private Class
                              </p>
                              <img src={solo} alt="group" />
                            </div>
                          </div>
                          <div className="d-flex">
                            {/* <p className=" text-black my-auto">
                              First class free
                            </p> */}
                            {/* <Form.Check
                              type="switch"
                              id="adonline"
                              label=""
                              disabled={
                                !pricesCheckBox?.virtual?.privateCheckBox
                              }
                              className="notify-toggle mb-0 mt-0"
                              checked={prices?.virtual?.private?.firstClassFree}
                              onClick={() =>
                                handlePriceChange(
                                  "virtual",
                                  "private",
                                  "firstClassFree",
                                  !prices?.virtual?.private?.firstClassFree,
                                )
                              }
                            /> */}
                          </div>
                        </div>
                        {pricesCheckBox?.virtual?.privateCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold ">
                              Hourly Rate
                              <p
                                className=" text-black-50"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div
                              className="mb-4"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="2000"
                                className="provider-field w-100 outline-0 font-14 py-2 shadow-none rounded-4"
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                disabled={
                                  !pricesCheckBox?.virtual?.privateCheckBox
                                }
                                value={
                                  prices?.virtual?.private?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "virtual",
                                    "private",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                ₹/hr
                              </span>
                              {pricesError?.virtual?.private && (
                                <div className="text-danger">
                                  "Rates cannot be zero or negative"
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="mb-lg-4 mb-3">
                      <input
                        type="radio"
                        name="modeOfClass"
                        id="private-place"
                        value="Virtual"
                        className=" d-none class-radio"
                        checked={
                          providerFunnelDatas.modeOfClass === "Virtual"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          dispatch(
                            updateFunnel({ modeOfClass: e.target.value }),
                          )
                          // dispatch(setIsStepValid(false))
                          setClassFields((prev) => ({
                            ...prev,
                            modeOfClass: e.target.value,
                          }))
                        }}
                      />
                      <label
                        className="session-labels font-14 py-3 rounded-4"
                        htmlFor="private-place"
                      >
                        Virtual
                      </label>
                    </div>
                    <div className={`mb-lg-4 mb-3 `}>
                      <input
                        type="radio"
                        name="modeOfClass"
                        value="Real Time"
                        className="d-none class-radio"
                        id="group-place"
                        checked={
                          providerFunnelDatas.modeOfClass === "Real Time"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          dispatch(
                            updateFunnel({ modeOfClass: e.target.value }),
                          )
                          // dispatch(setIsStepValid(false))
                          setClassFields((prev) => ({
                            ...prev,
                            modeOfClass: e.target.value,
                          }))
                        }}
                      />
                      <label
                        className="session-labels font-14 py-3 rounded-4"
                        htmlFor="group-place"
                      >
                        Real Time
                      </label>
                    </div> */}
                    {/* <div className={`mb-lg-4 mb-3`}>
                      <input
                        type="radio"
                        name="modeOfClass"
                        value="Any"
                        id="any-place"
                        className="d-none class-radio"
                        checked={
                          providerFunnelDatas.modeOfClass === "Any"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          dispatch(
                            updateFunnel({ modeOfClass: e.target.value }),
                          )
                          // dispatch(setIsStepValid(false))
                          setClassFields((prev) => ({
                            ...prev,
                            modeOfClass: e.target.value,
                          }))
                        }}
                      />
                      <label
                        className="session-labels font-14 py-3 rounded-4"
                        htmlFor="any-place"
                      >
                        Any
                      </label>
                    </div> */}
                  </form>
                  {/* {isStepValid && (
                    <div className="mt-3 text-danger">
                      Mode of class is required*
                    </div>
                  )} */}
                </div>
                {/* <div>
                  <p className="get-started-text fw-normal">
                    Hourly Rate <span className=" text-danger">*</span>
                  </p>
                  <div className="mb-4" style={{ position: "relative" }}>
                    <input
                      type="number"
                      name="hourlyRate"
                      placeholder="2000"
                      className="provider-field w-100 outline-0 font-14 py-2 shadow-none rounded-4"
                      style={{ paddingRight: "3rem" }}
                      min={1}
                      value={ClassFields.HourlyRate}
                      onChange={(e) => {
                        const value = e.target.value
                        if (parseInt(value, 10) > 0 || value.length === 0) {
                          dispatch(updateFunnel({ hourlyRate: value }))
                          setClassFields((prev) => ({
                            ...prev,
                            HourlyRate: e.target.value,
                          }))
                          setIsHourlyRateError(false)
                        } else {
                          setIsHourlyRateError(true)
                        }
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "2rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        height: "24px",
                      }}
                      className="font-14"
                    >
                      ₹/hr
                    </span>
                    {isHourlyRateError && (
                      <div className="text-danger">
                        "Rates cannot be zero or negative"
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                      Make it better!
                    </h3>
                  </div>
                  <p className="font-lg-20 mb-0">
                    Lorem ipsum dolor sit amet consectetur. Eget quis dignissim
                    ac vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                    sagittis nulla ac luctus mus imperdiet. Tellus dui tempus
                    blandit cras. Et vel adipiscing ut at faucibus praesent
                    neque id orci.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ClassInfo
