import * as React from "react"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/Auth/authSlice"
import { Navigate, Outlet } from "react-router-dom"
import { toast } from "react-toastify"

interface handlerProps {
  children: React.ReactNode
}

const LandingHandler = ({ children }: handlerProps) => {
  const { seekerToken, providerToken, isLoginEmailVerified } =
    useAppSelector(selectCurrentUser)

  return (
    <>
      {seekerToken ? (
        <Navigate to={"/seeker-dashboard"} replace />
      ) : providerToken ? (
        <Navigate to={"/source-dashboard"} replace />
      ) : (
        children
      )}
    </>
  )
}

export default LandingHandler
