import React, { useState } from "react"
import { Card, InputGroup, Row, Col } from "react-bootstrap"
import Dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { useAppSelector } from "../../../../app/hooks"
import {
  useAcceptDeleteRequestMutation,
  useProviderAdvertisementQuery,
  useProviderRequestsQuery,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import "./DashBoardOverView.css"
import DashboardBlank from "../../Blanks/DashboardBlank/DashboardBlank"
import { LuPenLine } from "react-icons/lu"
import { Link, useNavigate } from "react-router-dom"
import { GoChevronRight } from "react-icons/go"
import { GoChevronUp } from "react-icons/go"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import blankImage from "../../../../assets/images/blankDashboardImage.png"
const DashboardOverview = () => {
  const [open, setOpen] = React.useState(false)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [showAllReq, setShowAllReq] = useState<boolean>(false)
  const { data, isLoading } = useProviderRequestsQuery()
  const {data:AdData} = useProviderAdvertisementQuery()  
  const navigate = useNavigate()
  const ProviderData = useAppSelector((state: any) => state.providerSlice)
  const [requestHandler, { error }] = useAcceptDeleteRequestMutation()
  const onSubmit = async (action: any, requestId: any) => {
    const payload = {
      seekerId: requestId,
      action: action,
    }
    requestHandler(payload)
  }
  return (
    <>
      {isLoading && (
        <div className="spin-loading">
          <SpinnerSecondary />
        </div>
      )}
      <section className="dashboard-status mt-4">
        <div className="status-stepper ">
          <div id="employer-post-new-job">
            <div className="inner-step-form mb-5 d-lg-block d-none">
              <h5>Upgrade My Status</h5>
              <Row>
                <Col xs={12}  id="container">
                  <div className="res-steps-container">
                    <div
                      className="res-steps res-step-one active"
                      data-class=".res-form-one"
                    >
                      <div className="res-step-bar"></div>
                      <div className="res-progress-bar"></div>
                      <div id="res-progress">
                        <li className="res-progress-title" data-name="New">
                          <span className="arrow"></span>
                        </li>
                      </div>
                    </div>
                    <div
                      className="res-steps res-step-two"
                      data-class=".res-form-two"
                    >
                      <div className="res-step-bar"></div>
                      <div className="res-progress-bar"></div>
                      <div id="res-progress">
                        <li
                          className="res-progress-title"
                          data-name="Experienced Teacher"
                        >
                          <span className="arrow"></span>
                        </li>
                      </div>
                    </div>
                    <div
                      className="res-steps res-step-three"
                      data-class=".res-form-three"
                    >
                      <div className="res-step-bar"></div>
                      <div className="res-progress-bar"></div>
                      <div id="res-progress">
                        <li
                          className="res-progress-title"
                          data-name="Super Teacher"
                        >
                          <span className="arrow"></span>
                        </li>
                      </div>
                    </div>
                    <div
                      className="res-steps res-step-four"
                      data-class=".res-form-four"
                    >
                      <div className="res-step-bar"></div>
                      <div className="res-progress-bar"></div>
                      <div id="res-progress">
                        <li
                          className="res-progress-title"
                          data-name="Ambassador"
                        >
                          <span className="arrow"></span>
                        </li>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Col md={12}>
              <Card className="mb-5 class-card">
                <Card.Body className="p-2">
                  <Card.Title className="mb-0 class-title">
                    Class Request
                  </Card.Title>
                  {data?.data?.length > 0 ? (
                    <div className="class-listing">
                      {data?.data
                        ?.slice(0, showAllReq ? data?.data?.length : 2)
                        .map((request: any, index: number) => {
                          return (
                            <div className="d-md-flex justify-content-between class-req-list align-items-center">
                              <div className="d-flex align-items-center mb-md-0 mb-4">
                                <div className="user-img me-3">
                                  <img
                                    src={request?.seekerId?.profilePic || Dummy}
                                    alt="User"
                                  />
                                </div>
                                <div>
                                  <div className="mb-2">
                                    <h3 className="class-name">
                                      {request.seekerId &&
                                        request.seekerId.firstName}
                                    </h3>
                                  </div>
                                  <p className="request_message">
                                    {request.requestMessage}
                                  </p>
                                  <p className="online-status">
                                    {request.date}
                                  </p>
                                </div>
                              </div>
                              <div className="class-btn-list">
                                <span
                                  className={`${
                                    request.requestStatus === "Accept"
                                      ? "accept-btn dashboard-accept-btn"
                                      : request.requestStatus === "Delete"
                                      ? "delete-btn dashboard-delete-btn"
                                      : "req-pending-btn"
                                  } `}
                                >
                                  {request.requestStatus === "Accept"
                                    ? "Accepted"
                                    : request.requestStatus === "Delete"
                                    ? "Rejected"
                                    : "Pending"}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  ) : (
                    <>
                   <div className="d-flex justify-content-center align-items-center mt-2"> No Request Yet</div>
                    <div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-direction-column">
                      {/* <DashboardBlank text="No Request yet" /> */}
                      <div>
                       
                     <img src={blankImage} alt="No Ads Published"/>
                    </div>
                    </div>
                    </>
                  )}
                  {!showAllReq ? (
                    <div
                      className="text-center mt-2"
                      onClick={() => setShowAllReq(true)}
                    >
                      <a href="#" className="all-req-link">
                        {data?.data.length > 2 && data?.data?.length != 0 ? (
                          <>
                            {" "}
                            Show all <GoChevronRight />
                          </>
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                  ) : (
                    <div
                      className="text-center mt-2"
                      onClick={() => setShowAllReq(false)}
                    >
                      <a href="#" className="all-req-link">
                        Show less
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </div>
                  )}
                </Card.Body>
              </Card>
              <Card className="my-ad-card">
                <Card.Body className="p-2">
                  <Card.Title className="ad-title mb-3">My Ads</Card.Title>
                  {/* Repeat this block for each ad */}
                  {AdData?.data?.allAdvertisements.length > 0 ? (
                    AdData?.data?.allAdvertisements
                      .slice(
                        0,
                        showAll
                          ? AdData?.data?.allAdvertisements.length
                          : 2,
                      )
                      .map((details: any, index: number) => {
                        return (
                          <div className="d-md-flex align-items-center ad-listing py-0 mb-md-0 mb-3  ms-2" key={index}>
                            <div className="d-flex align-items-start ad-info-wrapper">
                              <img
                                src={details?.profileImage || dummy}
                                alt="Ad"
                                className="ad-img"
                              />
                              <div className="ad-info">
                                <p>{details.aboutClass}</p>
                                <span className="ad-pill">
                                  {details.vedicSubject}
                                </span>
                                <span className="ad-pill">
                                  {details.language}
                                </span>
                              </div>
                            </div>
                            <div className="ad-control py-md-0 pb-2 pt-2 ">
                              <div className="d-md-block d-flex align-items-center justify-content-center">
                                <div className="mb-md-3">
                                  <InputGroup className="justify-content-center">
                                    <div
                                      className={`${
                                        details?.status === "Active"
                                          ? `activetoggle`
                                          : ""
                                      }  myAdsToggle`}
                                    ></div>
                                  </InputGroup>
                                </div>
                                <Link
                                  to={""}
                                  className="update-btn mb-2"
                                  onClick={() =>
                                    navigate(`/source-dashboard/my-advt`, {
                                      state: { index },
                                    })
                                  }
                                >
                                  Update <LuPenLine className="ms-1 " />
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <>
                    <div className="d-flex justify-content-center align-items-center mt-2"> No Ads Published Yet</div>
                    <div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-direction-column">
                      {/* <DashboardBlank text="No Request yet" /> */}
                      <div>
                       
                     <img src={blankImage} alt="No Ads Published"/>
                    </div>
                    </div>
                    </>
                  )}
                  {/* End of ad */}
                </Card.Body>
                {!showAll ? (
                  <div
                    className="text-center mt-4"
                    onClick={() => setShowAll(true)}
                  >
                    <a href="#" className="all-req-link">
                      {ProviderData?.user?.data?.allAdvertisements.length >
                      2 ? (
                        <>
                          {" "}
                          Show all <GoChevronRight />
                        </>
                      ) : (
                        ""
                      )}
                    </a>
                  </div>
                ) : (
                  <div
                    className="text-center mt-4"
                    onClick={() => setShowAll(false)}
                  >
                    <a href="#" className="all-req-link">
                      Show less
                      <GoChevronUp />
                    </a>
                  </div>
                )}
              </Card>
            </Col>
          </div>
        </div>
      </section>
    </>
  )
}

export default DashboardOverview
