import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import Select from "react-select"
import { useAppDispatch } from "../../../../app/store"
import { useGetVedicLanguageQuery } from "../../../../features/Auth/authApi"
import { useAppSelector } from "../../../../app/hooks"
import {
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { toast } from "react-toastify"
import { useGetLocationSuggestionMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { reactHooksModule } from "@reduxjs/toolkit/dist/query/react"
import SpinnerMain from "../../../spinner/SpinnerMain"
const options = [
  { value: "Hindi", label: "Hindi" },
  { value: "English", label: "English" },
  { value: "Punjabi", label: "Punjabi" },
]

interface ProviderInfoForm {
  phone: string
  location: string
}
const ProviderInfo = () => {
  // const [selectedOption, setSelectedOption] = useState(null)
  // const [editMode, setEditMode] = useState<boolean>(false);
  // const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null)
  const [infoactive, setInfoActive] = useState(false)
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }
  const auth = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState<ProviderInfoForm>({
    phone: "",
    location: "",
  })
const [selectedLocation ,setSelectedLocation] = useState<boolean>(false)
  const { data } = useGetVedicLanguageQuery()

  const providerFunnelData = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )
  const handlePhoneNumberChange = (value: string) => {
    toast.dismiss()
    toast.error("Please go to My Account to edit !")
    dispatch(updateFunnel({ phoneNo: value }))
    setFormData((prev) => ({
      ...prev,
      phone: value.slice(0, 16),
    }))

    // const isValidPhoneNumber = value.length >= 6
    // setPhoneNumberError(
    //   isValidPhoneNumber ? null : "Phone number must be at least 6 digits",
    // )
  }

  useEffect(() => {
    dispatch(setIsStepValid(true))
  }, [])
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>()
  const providerFunnelDatas = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )
  type StateType = { value: string; label: string }[]
  const [selectedValue, setSelectedValue] = useState<any>([])
  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
    setSelectedValue: React.Dispatch<React.SetStateAction<StateType>>,
    updateField: string,
  ) => {
    const payload = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    dispatch(updateFunnel({ ...providerFunnelData, [updateField]: payload }))
  }

  const { isStepValid } = useAppSelector((state) => state.providerFunnel)

  useEffect(() => {
    const isValid =
      formData.location === "" ||
      selectedValue.length === 0
    dispatch(setIsStepValid(isValid))
  }, [formData.phone, formData.location, selectedValue])

  useEffect(() => {
    const languageOption =
      Array.isArray(providerFunnelDatas?.language) &&
      providerFunnelDatas?.language?.map((item: any) => ({
        label: item,
        value: item,
      }))

    setSelectedValue(languageOption)
    setFormData({
      phone: auth?.user?.mobileNo.toString(),
      location: providerFunnelDatas?.location,
    })
  }, [providerFunnelDatas])

  const locationIcon = () => {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25 7.95979 5.25 9.75C5.25 12.519 7.4655 16.128 12 20.451C16.5345 16.128 18.75 12.519 18.75 9.75ZM12 22.5C6.4995 17.5005 3.75 13.2495 3.75 9.75C3.75 7.56196 4.61919 5.46354 6.16637 3.91637C7.71354 2.36919 9.81196 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.25 13.2495 17.5005 17.5005 12 22.5Z"
            fill="#1C1C1C"
          />
          <path
            d="M12 12C12.5967 12 13.169 11.7629 13.591 11.341C14.0129 10.919 14.25 10.3467 14.25 9.75C14.25 9.15326 14.0129 8.58097 13.591 8.15901C13.169 7.73705 12.5967 7.5 12 7.5C11.4033 7.5 10.831 7.73705 10.409 8.15901C9.98705 8.58097 9.75 9.15326 9.75 9.75C9.75 10.3467 9.98705 10.919 10.409 11.341C10.831 11.7629 11.4033 12 12 12ZM12 13.5C11.0054 13.5 10.0516 13.1049 9.34835 12.4017C8.64509 11.6984 8.25 10.7446 8.25 9.75C8.25 8.75544 8.64509 7.80161 9.34835 7.09835C10.0516 6.39509 11.0054 6 12 6C12.9946 6 13.9484 6.39509 14.6517 7.09835C15.3549 7.80161 15.75 8.75544 15.75 9.75C15.75 10.7446 15.3549 11.6984 14.6517 12.4017C13.9484 13.1049 12.9946 13.5 12 13.5Z"
            fill="#1C1C1C"
          />
        </svg>
      </>
    )
  }
  const [search, { isLoading }] = useGetLocationSuggestionMutation()

  const handleLocationChange = async (e: any) => {
 setSelectedLocation(false)
    setFormData((prev) => ({
      ...prev,
      location: e.target.value,
    }))

  }
  const [searchResults, setSearchResults] = useState([]);

  const debounce = <F extends (...args: any[]) => void>(
    func: F,
    delay: number,
  ) => {
    let timeoutId: NodeJS.Timeout

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
      clearTimeout(timeoutId)

      timeoutId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  useEffect(() => {
    if (!selectedLocation) {
      if (formData.location.trim() === '') {
        setSearchResults([]);
        return;
      }
      const fetchData = async () => {
        try {
          const res = await search({
            value: formData.location
          });
  
  
          setSearchResults(res as any);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const debouncedFetchData = debounce(fetchData, 500);
      debouncedFetchData();
    }
  
  }, [formData.location]);
  
  const handleLocationClick =(place:string)=>{
    setSelectedLocation(true)
    setSearchResults([])
    dispatch(updateFunnel({ location:place }))
    setFormData((prev) => ({
      ...prev,
      location:place,
    }))  
  }
  return (
    <>
      <section className="py-lg-5 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="pb-4" >
                  <p className="get-started-text font-14 fw-normal">
                    Your Phone Number
                    <span className=" text-danger">*</span>
                  </p>
                  <Form.Group className="mb-3" controlId="formBasicEmail"  >
                    <Form.Control
                      value={auth?.user?.mobileNo}
                      onChange={(e) => handlePhoneNumberChange(e.target.value)}
                      onKeyDown={(e) => {
                        if (
                          !(
                            (e.key >= "0" && e.key <= "9") ||
                            e.key === "Backspace" ||
                            e.key === "Delete"
                          )
                        ) {
                          e.preventDefault()
                        }
                      }}
                      type="text"
                      className="provider-field shadow-none py-3 font-14 rounded-4 position-relative"
                      placeholder="Enter Your Phone Number"

                      maxLength={12}
                    />
                    {/* <span >Edit</span>
                    {editMode ? (
                      <span onClick={handleSaveClick} style={{
                        position: "absolute",
                        bottom: "140px",
                        right: "53%",
                        color: "skyblue",
                        cursor: "pointer"
                      }}>
                        Save
                      </span>
                    ) : (
                      <span style={{
                        position: "absolute",
                        bottom: "140px",
                        right: "53%",
                        color: "skyblue",
                        cursor: "pointer"
                      }} onClick={handleEditClick}>
                        Edit
                      </span>
                    )} */}
                  </Form.Group>

                  {/* {phoneNumberError && (
                    <div className="text-danger">{phoneNumberError}</div>
                  )} */}
                </div>
                <div className="pb-4">
                  <p className="get-started-text fw-normal font-14 mb-2">
                    Language <span className=" text-danger">*</span>
                  </p>
                  {/* <Select
                    defaultValue={selectedOption}
                    className="provider-select shadow-none"
                    value={options.find(
                      (option) => option.value === formData.language,
                    )}
                    onChange={(option) =>
                      dispatch(updateFunnel({ language: option?.value }))
                    }
                    options={data?.data}
                  /> */}
                  <FormMultiSelect
                    title=""
                    control={control}
                    options={data?.data}
                    onChange={(option) => {
                      handleSelectChange(option, setSelectedValue, "language")
                    }}
                    name="select"
                    className="multi-select-bx"
                    value={selectedValue as any}
                  />
                </div>
                <div>
                  <p className="get-started-text fw-normal font-14 mb-2 pt-4">
                    {locationIcon()} Location{" "}
                    <span className=" text-danger">*</span>
                  </p>
                  <div className="locatiopnsuggestioncontainer shadow-none p-1 font-14 rounded-2">
               
                    <input
                      value={formData.location }
                      onChange={handleLocationChange}
                      type="text"
                      className="formfield"
                      placeholder="location"
                      autoComplete="off"
                    />
                
                 
                  {(searchResults as any)?.data?.data?.Results.length > 0 && (
                    <ul  className="locationsuggestions">
                       {isLoading && <div className="d-flex justify-content-center align-items-center p-2" ><SpinnerMain className="d-flex justify-content-center" /> </div>}
                      {(searchResults as any)?.data?.data?.Results.map((result:any, index:number) => (
                        <li key={index} onClick={()=>{handleLocationClick(result?.Place?.Label)}}>{result?.Place?.Label}</li>
                      ))}
                    </ul>
                  )}
                  </div>
                  
                </div>
                {/* {isStepValid && (
                  <div className="mt-3 text-danger">
                    All these are required fields*
                  </div>
                )} */}
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                      Make it better!
                    </h3>
                  </div>
                  <p className="font-lg-20 mb-0">
                    Lorem ipsum dolor sit amet consectetur. Eget quis dignissim
                    ac vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                    sagittis nulla ac luctus mus imperdiet. Tellus dui tempus
                    blandit cras. Et vel adipiscing ut at faucibus praesent
                    neque id orci.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ProviderInfo
