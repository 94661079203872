import React, { useEffect, useState } from "react"
import {
  Container,
  Card,
  Modal,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import "./EditClassFormat.css"
import { classFormat } from "../data"
import { useUpdateAdvertisementMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"

interface CardProps {
  show: boolean
  handleClose: () => void
  AdData: any
}
const EditSession = ({ show, handleClose, AdData }: CardProps) => {
  const [selectedLabels, setSelectedLabels] = useState([])
  const [id, setId] = useState("")
  const [selectedIndex, setselectedIndex] = useState<number>()
  const handleLabelClick = (id: string, index: number) => {
    setId(id)
    setselectedIndex(index)
  }
  const [updateAdvt, { data: UpdatedData }] = useUpdateAdvertisementMutation()
  const handleSubmit = async (index: any) => {
    const classformat = {
      modeOfClass: classFormat.format[index].head,
    }
    const response = await updateAdvt({
      servicedata: classformat,
      id: AdData._id,
    })
    if (response) {
      handleClose()
    }
  }
  useEffect(() => {
    if (AdData) {
      const index = classFormat?.format?.findIndex(
        (item: any) => item?.head === AdData?.modeOfClass,
      )
      if (index !== -1) {
        setselectedIndex(index)
      }
    }
  }, [AdData])
  const handleCloseModal =()=>{
    if (AdData) {
      const index = classFormat?.format?.findIndex(
        (item: any) => item?.head === AdData?.modeOfClass,
      )
      if (index !== -1) {
        setselectedIndex(index)
      }
    }
    handleClose()
    
  }
  return (
    <>
      <Modal show={show}>
        <Row>
          <Col lg={6}>
            <div className="edit-card">
              <h3 className="mb-4 text-center teaching-head">Mode of Class</h3>
              <div className="teaching-as mb-4">
                <Form>
                  <div className="position-relative mb-4">
                    {classFormat.format.map((classformat, index) => (
                      <div key={classformat.id}>
                        <p
                          className="mb-3 mt-1 edit-class-label d-inline-block"
                          style={{
                            color:
                              selectedIndex === index ? "#53dac1" : "Black",
                          }}
                        >
                          {classformat.head}
                        </p>

                        <label
                          htmlFor={classformat.id}
                          className={`edit-label ${
                            selectedIndex === index ? "selected" : ""
                          }`}
                          onClick={() =>
                            handleLabelClick(classformat.id, index)
                          }
                        >
                          {classformat.source === "byhome"
                            ? AdData?.location
                            : classformat.source}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form>
              </div>
              <div className="d-sm-flex justify-content-between w-100 mt-5">
                <button
                  className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  className="btn bottom-btn save-edit-btn"
                  onClick={() => handleSubmit(selectedIndex)}
                >
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditSession
