import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../app/hooks"
import { Col, Container, Row, Spinner, Table } from "react-bootstrap"
import "./Invoices.css"
import { useGetPaymentsInvoicesQuery } from "../../../../features/featProviderDashboard/FeatProviderApi"
const MyInvoice = () => {
  const auth = useAppSelector((state: any) => state.auth)
  const { data, isLoading  } = useGetPaymentsInvoicesQuery()
 
  return (
    <div className="bg-white p-2 py-4 rounded-xl my-4">
      <Container fluid>
        <Row>
          <Col xs={12} className="primary-scrollbar">
            <Table responsive className="class-request-table ">
              <thead>
                <tr>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Order Id</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                  <th>
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.data?.length > 0 && data?.data?.map((invoices: any, index: number) => {
                    return <tr key={invoices?._id}>
                      <td className="profile-info">

                        <div className="profile-detail">
                          <p className="profile-name">{auth?.user?.firstName}</p>
                          <div className="rating-stars">


                          </div>
                        </div>
                      </td>

                      <td>{invoices?.orderId}</td>
                      <td className="fw-600">{invoices?.paymentStatus}</td>
                      <td>
                        <span> ₹ {invoices?.amount / 100} </span>

                      </td>
                    </tr>
                  })


                }

              </tbody>

              <tbody>

              </tbody>


            </Table>
          </Col>
        </Row>
      </Container>
    </div>


  )
}

export default MyInvoice
