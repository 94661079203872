import React, { useEffect, useState } from "react"
import {
  Container,
  Card,
  Modal,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import "./EditCourse.css"
import { courses } from "../data"
import { useUpdateAccountMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
interface CardProps {
  show: boolean
  handleClose: () => void
  AdData: any
}
const EditCourse = ({ show, handleClose, AdData }: CardProps) => {
  const [selectedLabels, setSelectedLabels] = useState([])

  const toggleLabel = (index: any) => {
    if (selectedLabels.includes(index as never)) {
      setSelectedLabels(selectedLabels.filter((i) => i !== index))
    } else {
      setSelectedLabels([...selectedLabels, index as never])
    }
  }
  const [updateAdvt, { data: UpdatedData, isSuccess }] =
    useUpdateAccountMutation()
  const handleSubmit = async () => {
    const services = selectedLabels.map((index) => {
      return courses?.taught[index]
    })
    const servicedata = {
      skills: services,
    }

    const response = await updateAdvt(servicedata)
    if (response) {
      handleClose()
    }
  }
  useEffect(() => {
    if (courses && courses?.taught && AdData?.services) {
      const servicesArray = AdData.skillSet.map((service: any) => service)
      const commonElements = courses?.taught.filter((teachingItem: any) => {
        return servicesArray.includes(teachingItem)
      })
      const indicesOfCommonElements = commonElements.map(
        (commonElement: any) => {
          return courses?.taught.indexOf(commonElement)
        },
      )

      setSelectedLabels([...indicesOfCommonElements] as any)
    }
  }, [courses, AdData])

  return (
    <>
      <Modal show={show}>
        <Row className="">
          <Col lg={6}>
            <div className="edit-card">
              <h3 className="mb-4 text-center teaching-head">Courses Taught</h3>
              <Form>
                <Form.Group controlId="vedic-guru">
                  <Row className="">
                    {courses?.taught?.map((courses: any, index: number) => {
                      return (
                        <Col md={4} sm={6} xs={12} className="p-3" key={index}>
                          <Form.Check className="teaching-checkbox" />
                          <Form.Label
                            className={`teaching-pill ${
                              selectedLabels.includes(index as never)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => toggleLabel(index)}
                          >
                            {courses}
                          </Form.Label>
                        </Col>
                      )
                    })}
                  </Row>
                </Form.Group>
              </Form>
              <div className="active-button">
                <div className="d-sm-flex justify-content-between px-md-4 px-2 w-100 mt-5">
                  <Button
                    className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: !selectedLabels.length ? "grey" : "",
                    }}
                    disabled={!(selectedLabels as any).length}
                    className="btn bottom-btn save-edit-btn"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditCourse
