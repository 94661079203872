import { SetStateAction, useEffect, useState } from "react"
import {
  Accordion,
  Container,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "./HelpCenter.css"
import searchicon from "../../../assets/img/search.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { useGetHelpFaqMutation } from "../../../features/Auth/authApi"

const HelpCenter = () => {
  const [getHelp, { data: helpData, error, isLoading }] = useGetHelpFaqMutation()
  const [activeTab, setActiveTab] = useState("student")
  const [data, setData] = useState({
    search: "",
    userType: ""
  })
  const handleTabChange = (key: SetStateAction<string>) => {
    setActiveTab(key)
    setData({
      search: "",
      userType: ""
    });

  }
  const handleSearchValue = (e: any) => {
    setData({
      search: e.target.value,
      userType: activeTab === "student" ? "Seeker" : "Provider"
    })
  }
  const handleSearch = () => {
    getHelp(data)
  }
  useEffect(() => {
    getHelp(data);
  }, [activeTab]);
  
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      <section className={`support-help ${activeTab}`}>
        <Container fluid className="px-0">
          <div className="inner-help">
            <div className="support-head-wrapper">
              <p className="support-head text-center mb-4">
                Hey, what can we do for you?
              </p>
              <div className="search-field-group">
                <input
                  className="form-control search-field"
                  value={data?.search}
                  type="text"
                  placeholder="Start typing your search"
                  onChange={handleSearchValue}
                />
                <button className="btn search-btn" onClick={handleSearch}>
                  <img src={searchicon} />
                </button>
              </div>
            </div>
            <div className="support-tab-wrapper">
              <Tabs
                variant="pills"
                className="justify-content-center support-tabs"
                defaultActiveKey="student"
                activeKey={activeTab}
                onSelect={handleTabChange as any}
                id="pills-tab"
              >
                <Tab eventKey="mentor" title="Mentor">
                  <div className="category-wrapper">
                    <div>
                      <h3 className="category-heading">Categories</h3>
                      <div className="category-accordion">

                        {
                          isLoading ? <Spinner /> : helpData?.data?.Provider && helpData?.data?.Provider?.map((data: any, index: number) => {
                            return <Accordion flush>
                              <Accordion.Item
                                className="category-acc-item"
                                eventKey="0"
                              >
                                <Accordion.Header className="category-acc-head">
                                  {data?.question}
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                  <div className="innerText">

                                    {data?.answer}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>


                            </Accordion>
                          })
                        }
                      </div>


                    </div>
                  </div>
                </Tab>
                <Tab eventKey="student" title="Student">
                  <div className="category-wrapper">
                    <div>
                      <h3 className="category-heading">Categories</h3>
                      <div className="category-accordion">
                        {
                          isLoading ? <Spinner /> : helpData?.data?.Seeker && helpData?.data?.Seeker?.map((data: any, index: number) => {
                            return <Accordion flush>
                              <Accordion.Item
                                className="category-acc-item"
                                eventKey="0"
                              >
                                <Accordion.Header className="category-acc-head">
                                  {data?.question}
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                  <div className="innerText">
                                    {data?.answer}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>


                            </Accordion>
                          })
                        }

                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default HelpCenter
