import React, { useEffect, useState } from "react"
import sendIcon from "../../../../assets/img/send-icon.svg"
import "./RequestScreen.css"
import { useAppSelector } from "../../../../app/hooks"
import {
  useGetseekerProfileQuery,
  useSeekerDeleteRequestMutation,
  useSeekerSearchMutation,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import {
  useGetMessagesQuery,
  usePostMessageMutation,
} from "../../../../features/chat/ChatApi"
import EmojiPicker from "emoji-picker-react"
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDocs,
} from "firebase/firestore"
import { db } from "../../../../app/firebase"
import { Timestamp } from "firebase/firestore"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { FiArrowLeft } from "react-icons/fi"
import {
  setAcceptRejectRequest,
  setNewMessageLength,
  setNewMessageSent,
  setSeekerRequest,
} from "../../../../features/featSeekerDashboard/seekerDashboardSlice"
import { useDispatch } from "react-redux"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import { useLocation, useParams } from "react-router-dom"
import { setProviderNewMsgNotification } from "../../../../features/featProviderDashboard/FeatProviderDashboardSlice"
import { Button, Form } from "react-bootstrap"
import DeleteRequestModal from "../../../modals/RequestModals/DeleteRequestModal"
import deleteduserimg from  "../../../../assets/img/deleteduserimg.png"


const RequestCustomerScreen = () => {
  const dispatch = useDispatch()
  const [msg, setMsg] = useState<string>("")
  const [finalData, setFinalData] = useState([])
  const { state } = useLocation()
  const { data: SeekerProfileData } = useGetseekerProfileQuery()
  const [messages, setMessages] = useState<any[]>([])
  const [newMsgSent, setnewMsgSent] = useState<boolean>(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const auth = useAppSelector((state: any) => state.auth)

  const data = useAppSelector((state: any) => state.seekerDashboardSlice)
  const [search, { data: searchedData, isLoading: loading }] =
    useSeekerSearchMutation()
  // chat
  const handleChange = (e: any) => {
    setMsg(e.target.value)
  }
  const [postMessage, { data: sentData, isSuccess: messageSendSuccess }] =
    usePostMessageMutation()
  const handleSendMessage = async () => {
    if (msg.trim() !== "") {
      try {
        await postMessage({
          chatId: data?.requestData?.data?._id,
          message: msg,
          userId: auth?.user._id,
          username: SeekerProfileData?.data?.firstName,
        })

        dispatch(setNewMessageSent(!data?.newMessageSent as any))
        setMsg("")
      } catch (error) {
        console.error("Error sending message:", error)
      }
    }
  }
  const handleEmojiClick = (event: any) => {
    setMsg((prevMessage) => prevMessage + event.emoji)
  }
  const openEmojiPicker = () => {
    if (data?.requestData?.class != "Pending") {
      setShowEmojiPicker(!showEmojiPicker)
    }
  }
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }
  // mpodal

  const messageDate = (createdAt: any) => {
    if (createdAt) {
      const date = createdAt.toDate()
      return {
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
      }
    } else {
      return {
        date: "N/A",
        time: "N/A",
      }
    }
  }

  useEffect(() => {
    async function searchHandler() {
      try {
        const res = await search({
          status: state?.status,
          sort: -1,
          search: "",
        })
        if (res && (res as any)?.data) {
          const filteredData = (res as any)?.data?.data?.filter(
            (item: any) => item?._id === state?.id,
          )
          dispatch(setSeekerRequest(filteredData[0]))
          setFinalData(filteredData)
        } else {
          console.error("Invalid response:", res)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    searchHandler()
  }, [state, state?.status])

  useEffect(() => {
    dispatch(setNewMessageSent(false as any))
    setMsg("")
    const messagesCollection = collection(db, "messages")
    const q = query(messagesCollection, orderBy("createdAt"))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedMessages = snapshot.docs
        .filter((doc) => doc.data().chatId === data?.requestData?.data?._id)
        .map((doc) => {
          const data = doc.data()
          const createdAtTimestamp = data.createdAt as Timestamp
          const formattedDate = messageDate(createdAtTimestamp)
          return { ...data, formattedDate }
        })
      if (updatedMessages.length > messages.length) {
        dispatch(setNewMessageSent(true as any))
      }
      setMessages(updatedMessages)
    })

    return () => unsubscribe() 
  }, [data?.requestData?.data?._id])
  

  return (
    <>
      {data?.requestData?.data ? (
        <div className="block-card mt-4 mb-4 py-4 message-container-block h-100">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-4 pxlg-5 px-4 py-3 chat-header-wrapper">
              <div className="d-flex align-items-center gap-3">
                {/* <button className="chat-back-btn d-lg-none d-flex">
                <FiArrowLeft />
              </button> */}
                <div  className={`chat-profile  ${
                    data?.requestData?.status === "Delete"
                      ? "deletedrequestprofile"
                      : ""
                  }`}>
                  <img
                     src={
                      data?.requestData?.data?.seekerId?.profilePic ||
                      (data?.requestData?.data?.requestStatus === "Delete"
                       ? deleteduserimg
                        : dummy)
                    }
                  />
                 <span
                    style={{
                      textDecoration:
                        data?.requestData?.data?.requestStatus === "Delete"
                          ? "line-through"
                          : "none",
                    }}
                  >  {data?.requestData?.data?.adId?.providerId?.firstName}</span>
                </div>
              </div>
              <DeleteRequestModal data={data} />
            </div>

            <div className="allmessage-container mt-4 px-lg-5 px-4">
              <div className="d-block">
                <div className="d-flex">
                  <div className="sent-msg">
                    <h2 className="username">
                      {data?.requestData?.data?.adId?.providerId?.firstName}
                    </h2>
                    <p>{data?.requestData?.data?.requestMessage}</p>
                  </div>
                </div>
              </div>
              {messages &&
                messages.map((receivedMessage: any, index:number) => (
                  <div className="d-flex" key={index}>
                    <div
                      key={receivedMessage?.id}
                      className={`${
                        auth.user?._id === receivedMessage.userId
                          ? "sent-msg "
                          : "recive-msg"
                      }`}
                    >
                      <h2 className="username">{receivedMessage.username}</h2>{" "}
                      <p className="messageText">{receivedMessage.message}</p>
                      {auth.user?._id === receivedMessage.userId && (
                        <span className="senttext">sent</span>
                      )}
                      {
                        <span className="senttext">
                          {receivedMessage?.formattedDate.date}{" "}
                          {receivedMessage?.formattedDate.time}
                        </span>
                      }
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="position-relative message-field-group mx-lg-5 mx-4 mb-4">
            <Form.Control
              type="text"
              value={msg}
              className="message-field"
              placeholder="Type a message"
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              disabled={
                data?.requestData?.data?.requestStatus === "Pending" ||
               data?.requestData?.data?.requestStatus === "Delete" ||
               data?.requestData?.data?.requestStatus === "Rejected"
              }
            />
            <button
              onClick={openEmojiPicker}
              className="emoji-btn btn btn border-0"
              disabled={
               data?.requestData?.data?.requestStatus === "Pending" ||
               data?.requestData?.data?.requestStatus === "Delete" ||
               data?.requestData?.data?.requestStatus === "Rejected"
              }
            >
              😊
            </button>
            <button
              className={`sent-btn btn ${msg === "" ? "grey-btn" : ""}`}
              onClick={handleSendMessage}
              // disabled={msg === "" ? true : false}
              disabled={
               data?.requestData?.data?.requestStatus === "Pending" ||
               data?.requestData?.data?.requestStatus === "Delete" ||
               data?.requestData?.data?.requestStatus === "Rejected"
              }
            >
              <img src={sendIcon} />
            </button>
            {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          No Request Available
        </div>
      )}
    </>
  )
}

export default RequestCustomerScreen
