import { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../../navbars/TopNavbar"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppDispatch } from "../../../../app/store"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../../app/hooks"
import { useGetVedicSubjectQuery } from "../../../../features/Auth/authApi"
const SeekerStepTwo = () => {
  const [selectedValue, setSelectedValue] = useState<any>("")
  const dispatch = useAppDispatch()
  const seekerData = useAppSelector((state) => state.seekerFunnel)

  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
  ) => {
    const payloadVedicSubject = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    const isStepValid = selectedValues.length > 0
    dispatch(
      updateFunnel({
        ...seekerData,
        vedicSubject: payloadVedicSubject,
        isStepValid,
      }),
    )
  }
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    // resolver: schemaResolver,
  })
  const { data } = useGetVedicSubjectQuery()
  return (
    <>
      <section>
        <Container>
          <div className="inner-fav pb-5">
            <Row className="justify-content-center">
              <Col xl={6} lg={8}>
                <div className="d-flex justify-content-between flex-column">
                  <div>
                    <form>
                      <label className="get-started-text mb-2">
                        Which Vedic Subject you are interested in?
                      </label>
                      <FormMultiSelect
                        title=""
                        options={data?.data}
                        control={control}
                        onChange={(option) => handleSelectChange(option)}
                        name="select"
                        value={selectedValue}
                      />
                    </form>
                  </div>
                  <div></div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepTwo
