import * as React from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import star from "../../assets/img/solar_star-broken.svg"
import locationIcon from "../../assets/img/location.svg"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Form } from "react-bootstrap"
import { useAppDispatch } from "../../app/store"
import { useSearchAdvtsMutation } from "../../features/featSearchAdvts/SearchAdvtsApi"
import { useSearchLandingPageMutation } from "../../features/featSeekerDashboard/seekerDashboardApi"
import {
  setIsSelectedLocation,
  setSearchText,
  setSearchType,
  setSelectedLocation,
  setSelectedSearchText,  
} from "../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppSelector } from "../../app/hooks"
import { useLocation, useNavigate } from "react-router-dom"
import { setSeekerLandingPageSearchData } from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { topNavSearchBarSchema } from "../../utils/yupSchema/Schema"

interface searchProps {}

const TopNavSearchBar = (props: searchProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchBar, setSearchBar] = React.useState<boolean>(false)
  const location = useLocation()
  const [search, { data: searchTextResponse, error, isSuccess }] =
    useSearchAdvtsMutation()
  const [
    searchAdvts,
    { data, error: searchAdvtsError, isSuccess: searchAdvtsSuccess },
  ] = useSearchLandingPageMutation()
  const {
    searchType,
    searchText,
    selectedSearchText,
    isSelectedLocation,
    selectedLocation,
  } = useAppSelector((state) => state.landingSearch)

  const handleSearchText = (text: string) => {
    dispatch(setSearchText(text))
    if (!selectedSearchText) {
      search({ searchText: text })
    }
  }

  const onSubmit = (details: any) => {
    const payload = {
      Location: selectedLocation,
      VedicSubject: selectedSearchText || searchText,
      teacherType: searchType,
      sortBy: "realtime-private",
      sortOrder: "ascending",
      pageNo: 1,
    }
    dispatch(setSeekerLandingPageSearchData(payload as any))
    searchAdvts(payload)
  }

  const { handleSubmit, setValue, register, control, reset } = useForm<any>({
    resolver: topNavSearchBarSchema,
  })

  const shouldHideSearchBar =
    location.pathname === "/explore-more" ||
    location.pathname === "/" ||
    location.pathname === "/results"

  const handleSelect = (selectedAddress: any) => {
    geocodeByAddress(selectedAddress)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: any) => console.log("Success", latLng))
      .catch((error: any) => console.error("Error", error))
  }
  const handleChange = (newAddress: any) => {
    dispatch(setSelectedLocation(newAddress))
  }

  // React.useEffect(() => {
  //   if (selectedSearchText) {
  //     ;(inputRef as any).current.focus()
  //   }
  // }, [selectedSearchText])

  React.useEffect(() => {
    if (searchText) search({ searchText: searchText })
  }, [searchText])
  React.useEffect(() => {
    if (searchAdvtsSuccess || searchAdvtsError) {
      navigate("/results", { state: { error: searchAdvtsError, data } })
      dispatch(setSearchText(""))
      dispatch(setSelectedSearchText(""))
      dispatch(setSelectedLocation(""))
    }
  }, [searchAdvtsSuccess, searchAdvtsError])

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <>
      <form
        className={` ${
          !shouldHideSearchBar ? "d-none" : "d-flex justify-content-center"
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={
            searchBar || selectedSearchText
              ? isScrolled
                ? "filter-section top-search-field  w-100 active"
                : "d-none"
              : isScrolled
              ? "filter-section top-search-field w-100 active"
              : "d-none"
          }
          onClick={() => setSearchBar(true)}
        >
          <div className="find-vedic position-relative d-flex align-items-center w-100">
            <img src={star} className="star-icon" />
            <Form.Control
              type="text"
              autoComplete="off"
              {...register("VedicSubject")}
              name="VedicSubject"
              placeholder="Find Your Vedic Expert"
              className="form-control find-field pe-2"
              value={selectedSearchText || searchText}
              onChange={(e: any) => handleSearchText(e.target.value)}
            />
          </div>

          {/* <div className={"find-location align-items-center w-100"}>
            <img src={locationIcon} className="location-icon" />
            {selectedLocation === "Online" ||
            selectedLocation === "Around me" ? (
              <input
                //   {...register("Location")}
                ref={inputRef}
                value={selectedLocation}
                onChange={() => dispatch(setSelectedLocation(""))}
              />
            ) : (
              <PlacesAutocomplete
                onChange={handleChange}
                onSelect={handleSelect}
                value={selectedLocation}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }: any) => (
                  <div>
                    <input
                      // value={selectedLocation}
                      {...register("Location")}
                      ref={inputRef}
                      {...getInputProps({
                        placeholder: "Search Places",
                        className: "form-control find-field pl-2",
                        // onClick: handleInputClick,
                      })}
                      style={{border: "none", outline: "none"}}
                      />
                  </div>
                )}
              </PlacesAutocomplete>
            )}
            {selectedSearchText && !selectedLocation ? (
              <div className={`search-top-town`}>
                <p
                  className="tab text town locate choice-locate"
                  onClick={() => {
                    dispatch(setSelectedLocation("Around me"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Around me
                </p>
                <p
                  className="tab text town choice-webcam"
                  onClick={() => {
                    dispatch(setSelectedLocation("Online"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Online
                </p>
              </div>
            ) : (
              <></>
            )}
          </div> */}
          <div className="flex-none dropdown_loc">
            <button
              className="find-btn d-flex align-items-center justify-content-center"
              //   onClick={() =>
              //     onSubmit({
              //       Location: selectedLocation,
              //       VedicSubject: selectedSearchText || searchText,
              //       teacherType: searchType,
              //     })
              //   }
            >
              <img src={findSearch} className="me-md-3" />
              <span className="d-none d-md-block ">Find</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default TopNavSearchBar
