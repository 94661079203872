import { useEffect, useState } from "react"
import {
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap"
import mailicon from "../../../assets/img/mail-icon.svg"
import passwordicon from "../../../assets/img/password-icon.svg"
import facebookimage from "../../../assets/img/facebook-icon.png"
import googleimage from "../../../assets/img/google-icon.png"
// import SignUp from "../../../../features/Auth/signup/SignUp"
import usericon from "../../../assets/img/user-icon.svg"
import "../authComponent.css"
import { useAppSelector } from "../../../../app/hooks"

import { MODALNAME, USERTYPE } from "../../../../constants"

import SignupSeeker from "./SignupSeeker"
import { useAppDispatch } from "../../../../app/store"
import { closeModal } from "../../../../features/Auth/ModalHandler/modalSlice"
import OTP from "../../../Verification/Number/Otp"
import { useNavigate } from "react-router-dom"

interface signUpProps {}

const SignUpAlt = ({}: signUpProps) => {
  const navigate = useNavigate()
  const [user, setUser] = useState("")
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState("PROVIDER")

 

  return (
    <>
      <Modal
        animation
        centered
        show={modalName === MODALNAME.SIGNUP}
        className="login-modal"
        size="lg"
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0 mx-0">
          <div className="pb-1 popup-content">
            <Modal.Header className="popup-header border-0">
              <Modal.Title
                className="popup-heading"
                id="exampleModalToggleLabel"
              ></Modal.Title>
              <Button
                variant="secondary"
                onClick={() => dispatch(closeModal())}
                size="sm"
                className="btn-close"
              ></Button>
            </Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <h3 className="popup-title mb-md-4 mb-3">Signup Seeker</h3>
              <Row>
                <Col md={12}>
                  <SignupSeeker userType={activeTab} />
                </Col>
              </Row>
              {/* Login as a {userType === USERTYPE.SEEKER_LOGIN ? <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}>Tutor</span>: <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}> Seeker</span>}   */}
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
      <OTP />
    </>
  )
}

export default SignUpAlt
