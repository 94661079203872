enum MODALNAME {
  CLOSED = "CLOSED",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  RESET_PASSWORD = "RESET",
  FORGET_PASSWORD = "FORGET",
  CONFIRM_PASSWORD = "CONFIRM",
  FORGET_PASSWORD_TUTOR = "FORGET_TUTOR",
  FORGET_PASSWORD_SEEKER = "FORGET_SEEKER",
  RESET_PASSWORD_TUTOR = "RESET_TUTOR",
  RESET_PASSWORD_SEEKER = "RESET_SEEKER",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  OTP_VERIFICATION = "OTP_VERIFICATION",
  SCHEDULE_SESSION_LOGIN = "SCHEDULE_SESSION_LOGIN",
  RESET_PASSWORD_OTP_SEEKER = "RESET_PASSWORD_OTP_SEEKER",
  RESET_PASSWORD_OTP_PROVIDER = "RESET_PASSWORD_OTP_PROVIDER",
  OTP_VERIFICATION_AFTER_REGISTERATION_PHONE = "OTP_VERIFICATION_AFTER_REGISTERATION_PHONE",
  OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL = "OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL",
}

enum USERTYPE {
  SEEKER = "SEEKER",
  PROVIDER = "PROVIDER",
  SEEKER_LOGIN = "SEEKER_LOGIN",
  TUTOR_LOGIN = "TUTOR_LOGIN",
  // SEEKER_RESET = "SEEKER_RESET",
  // TUTOR_RESET = "TUTOR_RESET",
}

enum SESSION_PREFERENCE {
  VIRTUAL = "Virtual",
  REAL_TIME = "Real Time",
  ANY = "Any",
}

// enum MODALNAME {
//   CLOSED = "CLOSED",
//   LOGIN = "LOGIN",
//   SIGNUP = "SIGNUP",
//   RESET_PASSWORD = "RESET",
//   FORGET_PASSWORD = "FORGET",
//   CONFIRM_PASSWORD = "CONFIRM",
// }

// enum MYADS {
// SOURSEMYADS = SOURSEMYADS,
// SEEKER

// }
enum ADVT_MODALS {
  TEACHING = "Teaching",
  COURSE = "Course",
  ABOUT_YOU = "Aboutyou",
  ABOUT_SESSION = "Aboutsession",
  CLASS_FORMAT = "class",
  LANGUAGE = "language",
  PRICING = "pricing",
  TITLE = "title",
  CLOSED = "CLOSED",
}

export { MODALNAME, USERTYPE, SESSION_PREFERENCE, ADVT_MODALS }
