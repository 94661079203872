import React, { useEffect, useState } from "react"
import { Container, Spinner } from "react-bootstrap"
import "./ExploreWrapper.css"
import ProviderCards from "../cards/providerCards"
import { useAppSelector } from "../../app/hooks"
import { useLocation } from "react-router"
import { useSearchLandingPageMutation } from "../../features/featSeekerDashboard/seekerDashboardApi"
import { useNavigate } from "react-router-dom"
import TopNavSearchBar from "../searchBars/topNavSearchBar"
import LandingSearch from "../searchBars/LandingSearchBar"
import SpinnerSecondary from "../spinner/SpinnerSecondary"
import BannerSearchSection from "../pages/LandingPage/bannerSearchSection/BannerSearchSection"

const ExploreWrapper: React.FC = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = useState(false)
  const [asc, SetAsc] = useState<boolean>(false)
  const [finalData, SetFinalData] = useState()
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [scrollLoading, setScrollLoading] = useState(false)

  const dataProviders = useAppSelector((state: any) => state.auth.data)
  const searchAdvtsCount = useAppSelector(
    (state: any) => state.auth.searchAdvtsCount,
  )
  const landingPageSearchedData = useAppSelector(
    (state: any) => state.seekerDashboardSlice,
  )
  const [search, { data, error, isSuccess, isLoading }] =
    useSearchLandingPageMutation()

  const handleShow = () => {
    setShowFilter(!showFilter)
  }
  const dataProviderArray: [] = dataProviders
  const [loading, setLoading] = useState(true)
  setTimeout(() => setLoading(false), 2000)

  const handleCheckboxChange = (checkboxId: string) => {
    setCheckedCheckboxes((prev) =>
      prev.includes(checkboxId)
        ? prev.filter((id) => id !== checkboxId)
        : [...prev, checkboxId],
    )
  }
  const handleClearAll = () => {
    setCheckedCheckboxes([])
  }

  const handleSort = async () => {
    SetAsc(!asc)
    const response = await search({
      Location: landingPageSearchedData?.searchedData?.Location,
      VedicSubject: landingPageSearchedData?.searchedData?.VedicSubject,
      teacherType: "",
      sortBy: "realtime-private",
      sortOrder: asc ? "ascending" : "descending",
      pageNo: page,
    })

    SetFinalData((response as any)?.data?.data as any)
  }
  const handleAddScrollData = async () => {
    setScrollLoading(true)

    try {
      const res = await search({
        Location: landingPageSearchedData?.searchedData?.Location,
        VedicSubject: landingPageSearchedData?.searchedData?.VedicSubject,
        teacherType: "",
        sortBy: "realtime-private",
        sortOrder: asc ? "descending" : "ascending",
        pageNo: page,
      })
      setTotalPages((res as any)?.data?.totalPages)

      setScrollLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error)
      setScrollLoading(false)
    }
  }

  useEffect(() => {
    let debounceTimer: any
    const handleScroll = () => {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => {
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight,
        )

        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop
        const viewportHeight = window.innerHeight
        const distanceFromBottom = documentHeight - (scrollTop + viewportHeight)
        const threshold = 50
        if (distanceFromBottom < threshold) {
          setPage(page + 1)
          handleAddScrollData()
        }
      }, 100)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [page, landingPageSearchedData])

  useEffect(() => {
    SetFinalData(dataProviders)
  }, [dataProviders, finalData])

  return (
    <>
      <section className="exploreMoreSection pt-100">
        <div className={`explore-wrapper ${showFilter ? "active" : ""}`}>
          <Container  className="cards-container" >
            <div className="searchbannersection">
              <BannerSearchSection />
            </div>
            <div className="d-lg-flex justify-content-between align-items-start mb-lg-0 mb-5">
              <div>
                {/* <h3>Become a Vedic expert<br />
                  Share your knowledge and inspire others</h3>
                <p className="pt-2">Join our global Vedic community, tailor your class schedule, and let your skills and
                  wisdom inspire others with enriching teachings worldwide.</p> */}
                <h2 className="heading-explore">
                  Find <span>best</span> guides around you
                </h2>
                {state?.error ? (
                  ""
                ) : (
                  <p className="result-text">
                    {searchAdvtsCount} Sources around you
                  </p>
                )}
              </div>
              {state?.error ? (
                ""
              ) : (
                <div className="button-list d-flex align-items-start">
                  <select
                    className="filter-btn transparent "
                    onChange={handleSort}
                  >
                    <option value="Sort By Price" disabled>
                      Sort By Price
                    </option>
                    <option value="asc">Low to High </option>
                    <option value="desc">High to Low </option>
                  </select>
                </div>
              )}
            </div>
            {state?.error?.status === 404 ? (
              <div className="similarmatches">
                No search result found Similar Matches are
              </div>
            ) : (
              ""
            )}
            <div className="cards-wrapper">
              <>
                <ProviderCards
                  data={finalData}
                  sortOrder = {asc}
                  key={""}
                  searchError={""}
                  sortBy={false}
                  page={page}
                />
              </>
            </div>
          </Container>
        </div>
        {isLoading && page <= totalPages && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </section>
    </>
  )
}

export default ExploreWrapper
