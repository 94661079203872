import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { api } from "../features/Auth/authApi"
import auth from "../features/Auth/authSlice"
import SeekerFunnelSlice from "../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import authModal from "../features/Auth/ModalHandler/modalSlice"
import ProviderDashboardSlice from "../features/featProviderDashboard/FeatProviderDashboardSlice"
import seekerDashboardSlice from "../features/featSeekerDashboard/seekerDashboardSlice"
import imageVideoSlice from "../features/ServiceFunnel/ProviderImgVideoSlice/ProviderImgVideoSlice"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import ProviderFunnelSlice from "../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { seekerDashboardApi } from "../features/featSeekerDashboard/seekerDashboardApi"
import { providerDashboardApi } from "../features/featProviderDashboard/FeatProviderApi"
// import authModalSlice from "../features/Auth/ModalHandler/";
import { chatApi } from "../features/chat/ChatApi"
import landingSearch from "../features/featSearchAdvts/SearchAdvtSlice"
import { searchAdvtsApi } from "../features/featSearchAdvts/SearchAdvtsApi"
import { setupListeners } from "@reduxjs/toolkit/query"
const persistConfig = {
  key: "root",
  whitelist: [
    "funnelData",
    "auth",
    "seekerFunnel",
    "authProvider",
    "providerFunnel",
    "seekerDashboardSlice",
    "ProviderDashboardSlice",
  ],
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [providerDashboardApi.reducerPath]: providerDashboardApi.reducer,
  [seekerDashboardApi.reducerPath]: seekerDashboardApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [searchAdvtsApi.reducerPath]: searchAdvtsApi.reducer,

  auth,
  seekerDashboardSlice,
  authModal,
  seekerFunnel: SeekerFunnelSlice,
  providerFunnel: ProviderFunnelSlice,
  providerSlice: ProviderDashboardSlice,
  imgVideoSlice: imageVideoSlice,
  landingSearch,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      api.middleware,
      providerDashboardApi.middleware,
      seekerDashboardApi.middleware,
      chatApi.middleware,
      searchAdvtsApi.middleware,
    ]),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
setupListeners(store.dispatch)
