import React, { useEffect, useState } from "react"
import {
  Container,
  Button,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  Spinner,
  Modal,
} from "react-bootstrap"

import { FaGraduationCap } from "react-icons/fa"
import { FcLock } from "react-icons/fc"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import "./MyAccount.css"
import GeneralInformation from "../../commonDashboard/generalInformation/GeneralInformation"
import { IoCloseOutline } from "react-icons/io5"
import {
  useProviderAccountDetailsQuery,
  useUpdateAccountMutation,
  useUploadProfileDocumentsMutation,
  useUploadProfilepicMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import ChangePasswordPopup from "../../../modals/ChangePassword/ChangePassword"
import { pdfjs } from "react-pdf"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import CommonDeleteCard from "../../../cards/commonCard/CommonDeleteCard"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { FaBook } from "react-icons/fa"
import MyStatus from "./MyStatus"
import MyInvoice from "./MyInvoice"
import { toast } from "react-toastify"
import { providerDashboardMyAccountSchema } from "../../../../utils/yupSchema/Schema"
import { useAppSelector } from "../../../../app/hooks"
import { USERTYPE } from "../../../../constants"
import getCroppedImg from "../../../funnels/ProviderFunnel/uploadPhoto/GetCroppedImg"
import Cropper from "react-easy-crop"
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const MyAccount = () => {
  const [show, setShow] = useState(false)
  const [postalactive, setPostalActive] = useState(false)
  const [qualificationactive, setQualificationActive] = useState(false)
  const [profilepicactive, setProfilePicActive] = useState(false)
  const [deleteaccountactive, setDeleteAccountActive] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [finalImg, setFinalImg] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const handlepostalActive = () => {
    setPostalActive(!postalactive)
  }
  const handleClose = () => setShow(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShow = () => setShow(true)
  const handleQualificationActive = () => {
    setQualificationActive(!qualificationactive)
  }
  const handleProfilePicActive = () => {
    setProfilePicActive(!profilepicactive)
  }
  const handleDeleteAccountActive = () => {
    setDeleteAccountActive(!deleteaccountactive)
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setFinalImg(croppedAreaPixels)    
  }

  const { data, isLoading: getLoading } = useProviderAccountDetailsQuery()
  const [profilePicUpload, { data: profilePicData, isLoading: loading }] =
    useUploadProfilepicMutation()
  const [DocUpload, { error, isLoading: docLoading }] =
    useUploadProfileDocumentsMutation()

  const handleCrop = async () => {
    if (imgSrc && finalImg) {
      try {
        const croppedImgUrl = await getCroppedImg(imgSrc, finalImg)
        const blob = await fetch(croppedImgUrl).then((res) => res.blob());
        setImgSrc(croppedImgUrl)
        const formData = new FormData();
        formData.append("profilePic", blob,Math.random().toString());
        const response = await profilePicUpload(formData)
        if (response) {
          setShowModal(false)
          setZoom(1)
          toast.success("Photo Uploaded Successfully")
        } else {
          toast.error("Failed to upload cropped image.")
        }
      } catch (error) {
        console.error("Error cropping/uploading image:", error)
        toast.error("An error occurred while cropping/uploading image.")
      }
    }
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    if (
      file &&
      (file.type === "application/msword" || file.type === "application/pdf")
    ) {
      const formData = new FormData()
      formData.append("credential", file)
      DocUpload(formData)
      const reader = new FileReader()
      reader.readAsDataURL(file)
    } else {
      if (error) {
        toast.error("Something went wrong !")
      }
      toast.error("Please select a document file (Word file or PDF).")
      event.target.value = null
    }
  }

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    // const formData = new FormData()
    // // formData.append("profilePic", file)
    // // profilePicUpload(formData)
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setImgSrc((reader as any).result)
      }
      setShowModal(true)
      // toast.success("Photo Uploaded Successfully")
    }
  }

  const url = data?.data?.credentials
  const lastSlashIndex = url?.lastIndexOf("/")
  const fileNameWithExtension = url?.substring(lastSlashIndex + 1)
  const fileNameWithExtensionfinal = fileNameWithExtension?.substring(24)
  const handleDownload = () => {
    const link = document.createElement("a")
    link.href = data?.data?.credentials
    link.download = fileNameWithExtensionfinal
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const [generaForm, { isLoading: formloading, isSuccess: addressSuccess }] =
    useUpdateAccountMutation()

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<any>({
    resolver: providerDashboardMyAccountSchema,
  })
  const onSubmit = async (values: any) => {
    const trimmedValue = values.postalAddress.trim()
    if (trimmedValue === "") {
      toast.dismiss()
      toast.error("please enter your postal address")
    } else {
      const payload = {
        postalAddress: values.postalAddress,
      }
      const res = await generaForm(payload)
      if (res) {
        toast.dismiss()
        toast.success("Postal Address updated Successfully")
      }
    }
  }

  useEffect(() => {
    setValue("postalAddress", data?.data?.postalAddress)
  }, [data])
  const verificationModels = useAppSelector((state: any) => state.auth)

  return (
    <>
      <DashboardHeader userType={USERTYPE.PROVIDER} />
      <section className="my-account">
        <Tabs
          defaultActiveKey="tab1"
          className="justify-content-start myaccount-tabs"
          id="tab-example"
        >
          <Tab eventKey="tab1" title="My Profile">
            <section className="my-account-sec py-5">
              <Container fluid>
                <div className="inner-account pt-0">
                  <div className="account-grid">
                    <div className="account-gridbx">
                      <FaBook />
                      <h4>General Information</h4>
                    </div>
                    <div
                      className="account-gridbx"
                      onClick={handlepostalActive}
                    >
                      <FaBook />
                      <h4>Postal Address</h4>
                    </div>
                    <div
                      className="account-gridbx"
                      onClick={handleQualificationActive}
                    >
                      <FaBook />
                      <h4>Qualification Documents</h4>
                    </div>
                    <div
                      className="account-gridbx"
                      onClick={handleProfilePicActive}
                    >
                      <FaBook />
                      <h4>Profile Photo</h4>
                    </div>
                    <div className="account-gridbx" onClick={handleShow}>
                      <FaBook />
                      <h4>Change Password</h4>
                    </div>
                    <div
                      className="account-gridbx"
                      onClick={handleDeleteAccountActive}
                    >
                      <FaBook />
                      <h4>Delete Account</h4>
                    </div>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <GeneralInformation Data={data} />
                      {/* <Notification /> */}
                    </Col>
                    <Col lg={4}>
                      <div className="block-card mb-2 pb-1">
                        <h2 className="card-heading">Profile Verification</h2>
                        <div>
                          <h6>
                            {(verificationModels?.user?.isEmailVerified
                              ? 50
                              : 0) +
                              (verificationModels?.user?.isMobileNoVerified
                                ? 50
                                : 0) ===
                            100
                              ? "Verified"
                              : ""}
                          </h6>

                          <input
                            type="range"
                            min="0"
                            max="100"
                            step="50"
                            disabled
                            value={
                              (verificationModels?.user?.isEmailVerified
                                ? 50
                                : 0) +
                              (verificationModels?.user?.isMobileNoVerified
                                ? 50
                                : 0)
                            }
                          />
                        </div>
                        <div className="checkboxContainer">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="email-checkbox"
                              disabled={true}
                              checked={
                                verificationModels?.user?.isEmailVerified
                              }
                            />
                            <label htmlFor="email-checkbox">
                              Email verified
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="phone-checkbox"
                              disabled={true}
                              checked={
                                verificationModels?.user?.isMobileNoVerified
                              }
                            />
                            <label htmlFor="phone-checkbox">
                              Phone number verified
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          postalactive
                            ? "active block-card account-block-card mb-4"
                            : "block-card account-block-card mb-4"
                        }
                      >
                        <button
                          className="close-account-popup"
                          onClick={handlepostalActive}
                        >
                          <IoCloseOutline />
                        </button>
                        <div className="w-100">
                          <h2 className="card-heading">Postal Address</h2>
                          <Form
                            className="form-general"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Form.Group className="mb-4">
                              <Form.Control
                                as="textarea"
                                {...register("postalAddress")}
                                name="postalAddress"
                                // defaultValue={data?.data?.postalAddress}
                                className="form-control postal-address"
                                // disabled={true}
                              ></Form.Control>
                              <span className="text-danger">
                                {(errors as any)?.postalAddress?.message}
                              </span>
                            </Form.Group>
                            <Button type="submit" className="addressbtn">
                              {formloading ? <Spinner /> : " Update"}
                            </Button>
                          </Form>
                        </div>
                      </div>
                      <div
                        className={
                          qualificationactive
                            ? "active block-card account-block-card mb-4"
                            : "block-card account-block-card mb-4"
                        }
                      >
                        <button
                          className="close-account-popup"
                          onClick={handleQualificationActive}
                        >
                          <IoCloseOutline />
                        </button>
                        <div className="w-100">
                          <h2 className="card-heading">
                            Qualification Documents
                          </h2>

                          <div className="degree-icon text-center">
                            <FaGraduationCap />
                          </div>
                          {
                            <div className="filename d-flex justify-content-center">
                              {fileNameWithExtensionfinal}
                            </div>
                          }
                          <div className="mb-4 text-center">
                            <i className="fa-solid fa-graduation-cap ident-icon"></i>
                          </div>
                          <input
                            type="file"
                            className="d-none"
                            id="upload-degree"
                            onChange={handleFileChange}
                            accept=".pdf,docx,.xlsx,"
                          />
                          <div className="d-flex justify-content-between">
                            <label
                              className="uploaddownload "
                              htmlFor="upload-degree"
                            >
                              {docLoading ? <Spinner /> : "Upload"}
                            </label>
                            {data?.data?.credentials && (
                              <label
                                className="uploaddownload"
                                onClick={handleDownload}
                              >
                                Download
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div
                        className={
                          profilepicactive
                            ? "active account-block-card"
                            : "account-block-card"
                        }
                      >
                        <button
                          className="close-account-popup"
                          onClick={handleProfilePicActive}
                        >
                          <IoCloseOutline />
                        </button>
                        <div className="w-100 px-xl-5">
                          <div className="block-card mb-4">
                            <h2 className="card-heading">Profile Photo</h2>
                            <div>
                              <div className="profile-imgbx" id="image-upload">
                                <img
                                  src={
                                    imgSrc
                                      ? imgSrc
                                      : data?.data?.profilePic || dummy
                                  }
                                  alt="uploaded"
                                />
                              </div>
                              <div>
                                <input
                                  type="file"
                                  id="file-upload"
                                  onChange={handleImageChange}
                                  accept=".jpg, .jpeg, .png"
                                  className="file-upload-input d-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <input
                              type="file"
                              id="file-upload"
                              // onChange={handleImageChange}
                              accept=".jpg, .jpeg, .png"
                              className="file-upload-input d-none"
                            />
                            <Modal show={showModal} style={{ top: "10%" }}>
                              {
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "8px",
                                    width: "500px",
                                    height: "500px",
                                  }}
                                >
                                  <div
                                    className="w-100"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <p className="text-black fw-bold">
                                        Crop Image
                                      </p>
                                      <div
                                        className="bg-white p-1"
                                        onClick={handleCloseModal}
                                      >
                                        X
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      height: "400px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Cropper
                                      image={imgSrc}
                                      crop={crop}
                                      zoom={zoom}
                                      aspect={4 / 3}
                                      onCropChange={setCrop}
                                      onCropComplete={onCropComplete}
                                      onZoomChange={setZoom}
                                    />
                                  </div>
                                  <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e) => {
                                      setZoom(e.target.value as any)
                                    }}
                                    className="mt-2"
                                  />
                                  <button
                                    style={{
                                      height: "60px",
                                      width: "50%",
                                      color: "white",
                                      background: "#53dac1",
                                      border: "none",
                                      marginTop: "20px",
                                      borderRadius: "25px",
                                    }}
                                    onClick={handleCrop}
                                  >
                                    Crop
                                  </button>
                                </div>
                              }
                            </Modal>
                          </div>
                        </div>
                      </div>
                      <div className="update-profile-card mb-2 pb-1">
                        <label
                          htmlFor="file-upload"
                          className="file-upload-label p-3"
                        >
                          Update Profile Picture
                        </label>
                      </div>

                      <div
                        className="block-card mb-4 cursor-pointer"
                        onClick={handleShow}
                      >
                        <h2 className="card-heading mb-0">
                          Change Password <FcLock />
                        </h2>
                      </div>
                      <CommonDeleteCard
                        heading={"Delete Account"}
                        description={
                          "By clicking on button below will permanently delete your Account"
                        }
                        btnStr={" Delete Account"}
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </Tab>
          <Tab eventKey="tab2" title="Status">
            <MyStatus />
          </Tab>
          <Tab eventKey="tab3" title="Invoice">
            <MyInvoice />
          </Tab>
        </Tabs>
      </section>
      <ChangePasswordPopup
        show={show}
        handleClose={handleClose}
        userType={""}
      />
    </>
  )
}

export default MyAccount
