import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import "./confirmModal.css"

interface propsModal {
  show: boolean
  handleClose: () => void
  acceptRequest: (req: any) => void
  title: string
}

function ConfirmModal({ show, handleClose, acceptRequest, title }: propsModal) {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog className="m-0">
          <Modal.Header className="request-header">
            <Modal.Title className="request-title">
              Request {title == "Accept" ? "Accept" : "Reject"}{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="request-text">Are you sure to {title} the request</p>
          </Modal.Body>

          <Modal.Footer className="header-footer px-4">
            <Button variant="secondary" onClick={handleClose} className="cancelbtn">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                acceptRequest(title === "Accept" ? "Accepted" : "Declined")
              }
              className= "Confirmbth" 
            >
              {title == "Accept" ? "Confirm" : "Reject"}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </div>
  )
}

export default ConfirmModal
