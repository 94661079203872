import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { db } from "../../app/firebase"

import {
  addDoc,
  collection,
  getDocs,
  query,
  onSnapshot,
  serverTimestamp,
  orderBy,
} from "firebase/firestore"

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  tagTypes: ["getMessages"],
  endpoints: (builder) => ({
    getMessages: builder.query({
      queryFn: async (chatId: any) => {
        try {
          const messagesCollection = collection(db, "messages")
          const q = query(messagesCollection, orderBy("createdAt"))
          const messages: any = []
          return new Promise((resolve, reject) => {
            onSnapshot(q, (snapshot) => {
              messages.push(
                snapshot.docs
                  .filter((doc) => doc.data().chatId === chatId)
                  .map((doc) => {
                    const data = doc.data()
                    data.createdAt = data.createdAt.toDate().toString()
                    return data
                  }),
              )
              return { data: messages }
            })
          })
        } catch (error) {
          console.log(error)
          throw error
        }
      },
    }),
    postMessage: builder.mutation({
      queryFn: async ({ chatId, message, userId, username }: any) => {
        const messagesCollection = collection(db, "messages")

        const docRef = await addDoc(messagesCollection, {
          chatId,
          message,
          userId,
          username,
          createdAt: serverTimestamp(),
        })
        return new Promise((resolve, reject) => {
          const unsubscribe = onSnapshot(
            docRef,
            (doc) => {
              const data = (doc as any).data()
              // Convert Timestamp to Date
              data.createdAt = data.createdAt.toDate().toString()
              resolve(data)
                unsubscribe()
            },
            reject,
          )
        })
      },
      // invalidatesTags: ["getMessages"],
    }),
  }),
})

export const { useGetMessagesQuery, usePostMessageMutation } = chatApi
