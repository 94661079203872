import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useAppDispatch } from "../../../../app/store"
import { useAppSelector } from "../../../../app/hooks"
import {
  useProviderEmailverifyMutation,
  useResendOtpProviderMutation,
} from "../../../../features/Auth/authApi"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const ProviderOtpVerification = () => {
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const user = useAppSelector((state: any) => state.auth)
  const navigate = useNavigate()
  const [
    providerEmailVerify,
    {
      error: providererror,
      isLoading: peoviderloading,
      isSuccess: providerverifySuccess,
    },
  ] = useProviderEmailverifyMutation()

  const [providerError, setProviderError] = useState<any>(null)

  const [
    resendOtpProvider,
    {
      isLoading: resendOTPproviderloading,
      error: resendOTPproviderError,
      isSuccess: resendOTPproviderSuccess,
    },
  ] = useResendOtpProviderMutation()
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const [currentTime, setCurrentTime] = useState(0)
  const inputRefs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ]
  const [valerror, setValError] = useState<any>(null)
  const handleInputChange = (index: number, event: any) => {
    const value = event.target.value

    if (isNaN(Number(value))) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, event: any) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current?.focus()
    }
  }
  const handleEmailVerify = () => {
    if (otp.join("") === "") {
      setValError("Plese enter the OTP shared")
    } else {
      providerEmailVerify({ otp: otp.join(""), email: user?.user?.email })
      setValError("")
    }
  }
  const handleResendOtp = () => {
    resendOtpProvider(user?.user?.email)
    setOtp(["", "", "", "", "", ""])
    setProviderError(null)
  }
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`
  }
  useEffect(() => {
    if (providerverifySuccess) {
      toast.success("Successfully verified  your account! Please login.")
      navigate("/source-signin")
      setOtp(["", "", "", "", "", ""])
    }
    if (providererror) {
      setProviderError(providererror)
    }
  }, [providerverifySuccess, providererror])

  useEffect(() => {
    setCurrentTime(180)
    const timerId = setInterval(() => {
      setCurrentTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerId)
          return 0
        } else {
          return prevTime - 1
        }
      })
    }, 1000)
    return () => clearInterval(timerId)
  }, [resendOTPproviderSuccess])

  return (
    <>
      <section className="signup-tutor-section">
        <div className="container-fluid">
          <div className="inner-tutor">
            <div className="row justify-content-end">
              <div className="col-xxl-4 col-lg-5 py-5 h-100">
                <div className="signup-card mt-5">
                  <div>
                    <p className="verification-heading"> Verification</p>
                    <p className="get-started-text mb-1">
                      You are Successfully registered pleasewdwdw verify your
                      Email to Continue !
                    </p>
                    <p className="get-started-text mb-2">
                      Enter a 6 digit OTP sent to your Email
                    </p>
                    {resendOTPproviderSuccess && (
                      <p className="resend-otp mb-1">
                        Otp has been sent successfully to your email
                      </p>
                    )}
                  </div>
                  <form>
                    <div className="input-field">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={(event) => handleInputChange(index, event)}
                          onKeyDown={(event) => handleKeyDown(index, event)}
                          ref={inputRefs[index] as any}
                        />
                      ))}
                    </div>
                    {providerError && (
                      <span className="error">
                        {providerError.data?.message}
                      </span>
                    )}
                    {valerror && <span className="error">{valerror}</span>}
                    <div className="otp-heading mt-4">
                      {currentTime === 0 ? (
                        <p style={{ color: "red" }}>
                          Time's up! Click Resend for new OTP.
                        </p>
                      ) : (
                        <p>Time Remaining: {formatTime(currentTime)}</p>
                      )}
                    </div>
                  </form>
                  <div className="otp-heading mt-4">
                    {(currentTime === 0 || providerError) && (
                      <>
                        <p>Don’t receive the OTP?</p>
                        <p className="resend-otp" onClick={handleResendOtp}>
                          Resend OTP{" "}
                          {resendOTPproviderloading && (
                            <span>
                              <Spinner />
                            </span>
                          )}
                        </p>
                      </>
                    )}
                  </div>
                  <button
                    type="button"
                    className="send-button mt-5"
                    onClick={handleEmailVerify}
                  >
                    {peoviderloading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      "VERIFY"
                    )}
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ProviderOtpVerification
