import { useEffect, useState } from "react"
import {
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import mailicon from "../../../assets/img/mail-icon.svg"
import passwordicon from "../../../assets/img/password-icon.svg"
import facebookimage from "../../../assets/img/facebook-icon.png"
import googleimage from "../../../assets/img/google-icon.png"
import backicon from "../../../assets/img/back-icon.svg"
import keyicon from "../../../assets/img/key-icon.svg"
import lockimage from "../../../../assets/img/lock.png"
import "../authComponent.css"
import { useAppSelector } from "../../../../app/hooks"
import { useAppDispatch } from "../../../../app/store"
import { MODALNAME } from "../../../../constants"
import { closeModal } from "../../../../features/Auth/ModalHandler/modalSlice"
import { useNavigate } from "react-router-dom"

interface CardProps {}
const SuccesfullPassword = () => {
  const modalName = useAppSelector((state: any) => state.authModal.modalName)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const token = urlSearchParams.get("token")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/")
  //   }, 2000)
  // }, [token])
  return (
    <>
      <Modal
        show={!!token}
        onHide={() => dispatch(closeModal())}
        className="login-modal"
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0"></Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <div className="change-password-card success-message">
                <div className="text-center">
                  <img src={lockimage} className="mb-4 lock-img" />
                  <p className="success-text">
                    Your password is successfully changed
                  </p>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default SuccesfullPassword
