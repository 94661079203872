import React, { useState } from "react"
import {
  Container,
  Card,
  Modal,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import "./EditTitle.css"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useUpdateAdvertisementMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { editAdTitle } from "../../../../utils/yupSchema/Schema"
import { toast } from "react-toastify"

interface CardProps {
  show: boolean
  handleClose: () => void
  title: any
}
const EditTitle = ({ show, handleClose, title }: CardProps) => {
  const [EditTitle, { data, error }] = useUpdateAdvertisementMutation()
  const [wordCount, setWordCount] = useState<number>(0)
  const [maxLetterCount, setMaxLetterCount] = useState<number>()
  const [focus, setFocus] = useState<boolean>(false)
  const { handleSubmit, register, resetField } = useForm<any>({
    resolver: editAdTitle,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      title: values.newTitle,
    }
    const response = await EditTitle({ servicedata: payload, id: title._id })

    if ((response as any)?.data) {
      resetField("newTitle")
      handleClose()
      setFocus(!focus)
    }
  }

  const handleTitleChange = (e: any) => {
    const content = e.target.value
    setFocus(true)
    const words = content
      .trim()
      .split(/\s+/)
      .filter((word: string) => word !== "")
    setWordCount(words.length)
    if (words.length > 50) {
      toast.dismiss()
      toast.error("Maximum words limit reached")
      const truncatedContent = words.slice(0, 50).join(" ")
      e.target.value = truncatedContent
      setWordCount(50)
      setMaxLetterCount(50)
      return
    }
  }
const handleModalClose =()=>{
  resetField("newTitle")
  handleClose()
  setFocus(false)
  
}
  return (
    <>
      <Modal show={show}>
        <Row>
          <Col lg={6}>
            <div className="edit-card">
              <h3 className="mb-4 text-center teaching-head">Ad Title</h3>
              <div className="title-1 px-3 py-2">
                <Form.Control
                  as="textarea"
                  rows={4}
                  className="rounded-3 custom-edit-textarea-lg"
                  disabled={true}
                  defaultValue={title?.title}
                />
              </div>

              <div className="title-2 px-3 py-2 mt-3">
                <h6>Make it better!</h6>
                <Form.Control
                  as="textarea"
                  rows={4}
                  {...register("newTitle")}
                  name="newTitle"
                  className="rounded-3 custom-edit-textarea-sm"
                  placeholder="Write your new title here"
                  onChange={handleTitleChange}
                 
                />
              </div>
              {wordCount < 10 && focus && (
                <p className=" text-danger">Minimum 10 words are required</p>
              )}
              <div className="word-limit py-2">
                <p>{wordCount}/50 words</p>
              </div>
              <div className="active-button">
                <div className="d-sm-flex justify-content-between px-md-4 px-2 w-100 mt-5">
                  <Button
                    className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn bottom-btn save-edit-btn"
                    onClick={handleSubmit(onSubmit)}
                    disabled={wordCount < 10}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditTitle
