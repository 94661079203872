import React, { useState, useEffect } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Tooltip,
  Spinner,
} from "react-bootstrap"
import "./DashboardComponent.css"
import avatar from "../../../../assets/img/avatar.png"
import dashboardimage from "../../../../assets/img/card-image.png"
import verifiedbandage from "../../../../assets/img/verified-bandage.svg"
import { useAccountDetailsMutation, useResendOtpMutation, useResendOtpProviderMutation } from "../../../../features/Auth/authApi"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import premimumStar from "../../../../assets/images/premium-icon.svg"
import { useProviderAccountDetailsQuery, useProviderPhoneVerificationMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { useGetseekerProfileQuery, useSeekerPhoneVerificationMutation } from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { OverlayTrigger } from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../../constants"
import { toast } from "react-toastify"
import VerificationModal from "../../commonDashboard/generalInformation/verificationModal/VerificationModal"
const DashboardComponent = ({ userType }: any) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {userType === "SEEKER"
        ? seekerProfile?.data?.email
        : data?.data?.email}    </Tooltip>
  );
  const [open, setOpen] = React.useState(false)
  const dashboardPremium = "dashboardPremium"
  const loginData = useAppSelector((state: any) => state.auth)
  const { data, isLoading: getLoading, refetch: refetchProvider } = useProviderAccountDetailsQuery()
  const { data: seekerProfile, isLoading, refetch } = useGetseekerProfileQuery()
  const navigate = useNavigate()
  const auth = useAppSelector((state: any) => state.auth)

  const dispatch = useAppDispatch()
  // phone verification
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification

  const [
    verifyEmail,
    {
      data: verifySeekerEmaildata,
      error: verifySeekerEmailError,
      isSuccess: verifySeekerEmailSuccess,
      isLoading: verifySeekerEmailLoading,
    },
  ] = useResendOtpMutation()
  const [
    verifyProviderEmail,
    {
      data: verifyProviderEmaildata,
      error: verifyProviderEmailError,
      isSuccess: verifyProviderEmailSuccess,
      isLoading: verifyProviderEmailLoading,
    },
  ] = useResendOtpProviderMutation()
  const handlePhoneVerify = async () => {
    
    if (auth?.currentUserRole === USERTYPE.PROVIDER) {
      if (!phoneVerificationLoading) {
        const res = await phoneVerify()
        if ((res as any)?.data?.success) {
          dispatch(
            setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
          )
        } else {
          toast.dismiss()
          toast.error("Something went Wrong, please try again later")
        }
      }
    } else {

      if (!seekerphoneVerificationLoading) {
        const res = await seekerphoneVerify()
        if ((res as any)?.data?.success) {
          dispatch(
            setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
          )
        } else {
          toast.dismiss()
          toast.error("Something went Wrong, please try again later")
        }
      }
    }
  }
  const handleEmailVerify = async () => {
    try {
      if (auth?.currentUserRole === USERTYPE.PROVIDER) {
        if (!verifyProviderEmailLoading) {
          const res = await verifyProviderEmail(auth?.user?.email)
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }
        }
      } else {

        if (!verifySeekerEmailLoading) {
          const res = await verifyEmail(auth?.user?.email)
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }

        }
      }
    } catch (error) {
      console.error("Error while handling email verification:", error)
      toast.error("An unexpected error occurred. Please try again later.")
    }
  }
  useEffect(() => {
    if (auth?.user?.isMobileNoVerified) {
      if (auth?.currentUserRole === USERTYPE.PROVIDER) {
        refetchProvider()
      }
      else {
        refetch()
      }
    }
  }, [auth?.user?.isMobileNoVerified])
  useEffect(() => {
    refetch()
    refetchProvider()
  }, [])
  return (
    <>
      <section className="p-0 mt-4">
        <Container fluid="lg">
          <Row>
            <Col lg={12}>
              <section className="dashboard-status mt-4 d-lg-none">
                <div className="status-stepper ">
                  <div id="employer-post-new-job">
                    <div className="inner-step-form mb-5">
                      <h5>Upgrade My Status</h5>
                      <Row>
                        <Col id="container">
                          <div className="res-steps-container">
                            <div
                              className="res-steps res-step-one active"
                              data-class=".res-form-one"
                            >
                              <div className="res-step-bar"></div>
                              <div className="res-progress-bar"></div>
                              <div id="res-progress">
                                <li
                                  className="res-progress-title"
                                  data-name="New"
                                >
                                  <span className="arrow"></span>
                                </li>
                              </div>
                            </div>
                            <div
                              className="res-steps res-step-two"
                              data-class=".res-form-two"
                            >
                              <div className="res-step-bar"></div>
                              <div className="res-progress-bar"></div>
                              <div id="res-progress">
                                <li
                                  className="res-progress-title"
                                  data-name="Experienced Teacher"
                                >
                                  <span className="arrow"></span>
                                </li>
                              </div>
                            </div>
                            <div
                              className="res-steps res-step-three"
                              data-class=".res-form-three"
                            >
                              <div className="res-step-bar"></div>
                              <div className="res-progress-bar"></div>
                              <div id="res-progress">
                                <li
                                  className="res-progress-title"
                                  data-name="Super Teacher"
                                >
                                  <span className="arrow"></span>
                                </li>
                              </div>
                            </div>
                            <div
                              className="res-steps res-step-four"
                              data-class=".res-form-four"
                            >
                              <div className="res-step-bar"></div>
                              <div className="res-progress-bar"></div>
                              <div id="res-progress">
                                <li
                                  className="res-progress-title"
                                  data-name="Ambassador"
                                >
                                  <span className="arrow"></span>
                                </li>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </section>
              <div className="prouser-card text-center mb-5 d-lg-block d-none">
                <div className="pro-imgbx mb-3">
                 

                  <img
                    src={
                      userType === "SEEKER"
                        ? seekerProfile?.data?.profilePic || dummy
                        : data?.data?.profilePic || dummy
                    }
                    alt="profile-pic"
                  />
                </div>
                <div className="prouser-name mb-2">
                  {userType === "SEEKER"
                    ? seekerProfile?.data &&
                    seekerProfile?.data?.firstName +
                    " " +
                    seekerProfile?.data?.lastName
                    : data?.data &&
                    data?.data?.firstName + " " + data?.data?.lastName}
                    
                     {auth?.user?.isMobileNoVerified && <img src={verifiedbandage} className="verifedBadgeOverProfile" style={{ height: "40px", left: "75%" }} />
                  }
                </div>
                
                {/* <p className="prouser-info">
                  {ProviderData?.user?.data?.allAdvertisements[0]?.aboutYou}
                </p> */}
              </div>
              <div className="verify-profile mb-5">
                <div
                  className="verify-card-btn"
                  onClick={() => setOpen(!open)}
                  aria-controls="verifyprofile"
                  aria-expanded={open}
                >
                  <img src={verifiedbandage} />
                  Verify your profile
                </div>
                {/* <Collapse in={open}> */}
                <div id="verifyprofile" className="verifyprofile-info">
                  <div className="row mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    {(seekerProfile?.data?.mobileNo ||
                      data?.data?.mobileNo) && (
                        <>
                          <div className="col-auto mb-0 verify-text text-truncate">
                            {userType === "SEEKER"
                              ? seekerProfile?.data?.mobileNo
                              : data?.data?.mobileNo}
                          </div>
                          <div className="col-12 col-sm-4 mb-md-0 mb-2 verify-text">
                            {
                              isLoading || getLoading ? <Spinner /> : (userType === "SEEKER" && seekerProfile?.data?.isMobileNoVerified) ||
                                (userType === "PROVIDER" && data?.data?.isMobileNoVerified) ? <span style={{ color: "#53dac1", cursor:"pointer" }}>Verified</span> : <span onClick={handlePhoneVerify} style={{ color: "red", cursor:"pointer" }}>Verify</span>
                            }
                          </div>
                        </>
                      )}
                  </div>
                  <div className="row position-relative">

                    <div className="col-8 mb-0 verify-text text-truncate">
                      {userType === "SEEKER"
                        ? seekerProfile?.data?.email
                        : data?.data?.email}
                    </div>
                    <div className="col-4 mb-md-0 mb-2 verify-text">
                      {isLoading || getLoading ? <Spinner /> :
                        (userType === "SEEKER" && seekerProfile?.data?.isEmailVerified) ||
                          (userType === "PROVIDER" && data?.data?.isEmailVerified) ? <span style={{ color: "#53dac1", cursor:"pointer" }}>Verified </span> : <span onClick={handleEmailVerify} style={{ color: "red", cursor:"pointer" }}>Verify</span>}
                    </div>

                  </div>
                </div>
                {/* </Collapse> */}
              </div>
              {loginData?.user?.isPremium === false ? (
                <div
                  className="upgrade"
                  onClick={() =>
                    navigate(`/paymentPlans/${loginData?.user?._id}`, {
                      state: { dashboardPremium },
                    })
                  }
                >
                  <a className="upgarde-card">
                    <p className="upgrade-title">
                      {"Upgrade to premium"}
                      <img src={premimumStar} alt="premimumStar" />
                    </p>
                    <p className="upgrade-text">
                      Lorem ipsum dolor sit amet consectetur. Diam gravida nisl
                      eleifend lacus.{" "}
                    </p>
                  </a>
                </div>
              ) : (
                <div
                  className="upgrade"
                  onClick={() =>
                    navigate(`/paymentPlans/${loginData?.user?._id}`)
                  }
                >
                  <a className="upgarde-card">
                    Update your premium package
                    <img src={premimumStar} alt="premimumStar" />
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <VerificationModal />
    </>
  )
}

export default DashboardComponent
