import React from "react"
import "./providerStepper.css"
type Props = {
  stepCount: number
  activeStep: number
}

const SeekerStepper = ({ stepCount, activeStep }: Props) => {
  let stepperCount = []
  for (let i = 0; i < stepCount; i++) {
    stepperCount.push(i)
  }
  return (
    <>
      {stepperCount.map((step: number) => {
        return (
          <React.Fragment key={step}>
            <div className="indicators mx-xl-3 mx-2 mb-5">
              <span
                className={`started-indicator ${
                  activeStep === step ? "active" : ""
                }`}
              />
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default SeekerStepper
