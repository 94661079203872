import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
import { providerDashboardApi } from "../featProviderDashboard/FeatProviderApi"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["seeker", "provider", "authToken", "getProviderDetails", 'chatToken', "getseekerProfile"],

  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "seeker/login",
        method: "POST",
        body: credentials,
      }),
      providesTags: ["seeker", "getseekerProfile"] as never,
      invalidatesTags: ["authToken"]
    }),
    getChatToken: builder.query<any, void>({
      query: (arg: void) => ({
        url: "static-data/get-custom-token",
        method: "GET",
      }),
      providesTags: ["chatToken"] as any,
    }),

    tokenStatus: builder.query<any, any>({
      query: () => ({
        url: "static-data/token-status",
        method: "GET",
      }),
    }),


    emailVerify: builder.mutation({
      query: (data) => ({
        url: "seeker/verify-email",
        method: "POST",
        body: data
      }),
      providesTags: ["authToken"] as any,

    }),
    userRegister: builder.mutation({
      query: (userRegisterData) => ({
        url: "seeker/register",
        method: "POST",
        body: userRegisterData,
      }),
    }),
    providerRegister: builder.mutation<any, any>({
      query: (userRegisterData) => ({
        url: "provider/register",
        method: "POST",
        body: userRegisterData,
      }),
    }),

    forgetPassword: builder.mutation<any, any>({
      query: (forgetRequestData) => ({
        url: "seeker/forget-password",
        // mode: "cors",
        method: "POST",
        body: forgetRequestData,
      }),
    }),

    resetPassword: builder.mutation<any, any>({
      query: (anyData) => ({
        url: "seeker/reset-password",
        headers: {
          Authorization: `${anyData?.token}`,
        },
        method: "POST",
        body: anyData?.password,
      }),
    }),
    seekerresetPasswordOtpVerify: builder.mutation<any, any>({
      query: (anyData) => ({
        url: "seeker/reset-pass-otp-verify",
        method: "POST",
        body: anyData,
      }),
    }),
    changePassword: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "seeker/reset-password",
        // mode: "cors",
        method: "POST",
        body: resetData,
      }),
    }),

    searchSortProviders: builder.mutation<any, any>({
      query: (payload) => ({
        url: `seeker/search-sort-providers?services=${payload.service}&vedicSubject=${payload.vedicSubject}&skillSet=${payload.skillSet}&language=${payload.language}&modeOfClass=${payload.modeOfClass}`,
        method: "GET",
        transformResponse: (response: any) => {
          return response.data
        },
      }),
    }),

    getProviderDetails: builder.mutation<any, any>({
      query: (id: string) => ({
        url: `/seeker/get-provider-ad-details-reviews?adId=${id}`,
        method: "GET",
        providesTags: ["getProviderDetails"],
      }),

    }),

    postCreateRquest: builder.mutation<any, any>({
      query: (payload) => ({
        url: `/seeker/create-request`,
        method: "POST",
        body: payload,
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
    // providerlogin: builder.mutation<any, any>({
    //   query: (credentials) => ({
    //     url: "provider/login",
    //     method: "POST",
    //     body: credentials,
    //   }),
    // }),

    providerEmailverify: builder.mutation({
      query: (data) => ({
        url: "provider/verify-email",
        method: "POST",
        body: data,
      }),
    }),
    providerforgetPassword: builder.mutation<any, any>({
      query: (anyData) => ({
        url: "provider/forget-password",
        method: "POST",
        body: anyData,
      }),
    }),

    providerresetPassword: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "provider/reset-password",
        headers: {
          Authorization: `${resetData?.token}`,
        },
        method: "POST",
        body: resetData?.password,
      }),
    }),
    providerresetPasswordOtpVerify: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "provider/reset-pass-otp-verify",
        method: "POST",
        body: resetData,
      }),
    }),

    accountDetails: builder.mutation<any, any>({
      query: () => ({
        url: "provider/get-provider-account-details",
        method: "GET",
        // body: data,
      }),
    }),
    updateDetails: builder.mutation<any, any>({
      query: (data) => ({
        url: "provider/update-provider-account-details",
        method: "POST",
        body: data,
      }),
    }),
    requestDetails: builder.mutation<any, any>({
      query: (data) => ({
        url: "provider/get-provider-requests",
        method: "GET",
        body: data,
      }),
    }),
    sortRequests: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/sort-requests?sortOrder=asc`,
        method: "GET",
        body: data,
      }),
    }),
    dscsortRequests: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/sort-requests?sortOrder=dsc`,
        method: "GET",
        body: data,
      }),
    }),
    getproviderAdd: builder.mutation<any, any>({
      query: (data) => ({
        url: "provider/get-provider-ad",
        method: "POST",
        body: data,
      }),
    }),

    getVedicSubject: builder.query({
      query: (args: void) => ({
        url: "static-data/get-vedic-subject",
      }),
    }),

    getVedicLanguage: builder.query({
      query: (args: void) => ({
        url: "static-data/get-languages",
      }),
    }),

    userMailSubscription: builder.mutation<any, any>({
      query: (email) => ({
        url: "static-data/mail-subscription",
        method: "POST",
        body: email,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: (args: void) => ({
        url: "seeker/logout",
        method: "GET",
      }),
    }),
    resendOtp: builder.mutation<any, any>({
      query: (email) => ({
        url: `seeker/resend-otp?email=${email}`,
      }),
    }),
    resendOtpProvider: builder.mutation<any, any>({
      query: (email) => ({
        url: `/provider/resend-otp?email=${email}`,
      }),
    }),
    getNotifications: builder.mutation({
      query: (data) => ({
        url: `notification/get-notifications-from-cache`,
        validateStatus: (response, result) => {
          return response.status === 200 || response.status === 302
        },
      }),
    }),
    closeNotification: builder.mutation<any, any>({
      query: (data) => ({
        url: "notification/del-notifications-from-cache",
        method: "POST",
        body: data,
      }),
    }),

    // google login
    googleSignup: builder.mutation({
      query: (args: void) => ({
        url: `provider/auth/google`,
      }),
    }),
    googleSignupSeeker: builder.mutation({
      query: (args: void) => ({
        url: `seeker/auth/google`,
      }),
    }),
    logoutProvider: builder.mutation<void, void>({
      query: (args: void) => ({
        url: "provider/logout",
        method: "GET",

      }),

    }),
    AdvertisementSuggetions: builder.query({
      query: (args: void) => ({
        url: "static-data/get-ad-suggestion",
        method: "GET",
      }),
    }),
    getHelpFaq: builder.mutation({
      query: (data :any) => ({
        url: `static-data/help-and-support-faqs?search=${data.search}&userType=${data.userType}`,
        method: "GET",
      }),
    }),

  }),

})

export const {

  useLoginMutation,
  useLogoutMutation,
  useLogoutProviderMutation,
  useEmailVerifyMutation,
  useForgetPasswordMutation,
  useUserRegisterMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useSearchSortProvidersMutation,
  usePostCreateRquestMutation,
  // useProviderloginMutation,
  useProtectedMutation,
  useProviderRegisterMutation,
  useProviderEmailverifyMutation,
  useProviderforgetPasswordMutation,
  useProviderresetPasswordMutation,
  useAccountDetailsMutation,
  useUpdateDetailsMutation,
  useRequestDetailsMutation,
  useSortRequestsMutation,
  useDscsortRequestsMutation,
  useGetproviderAddMutation,
  useGetVedicSubjectQuery,
  useGetVedicLanguageQuery,
  useGetProviderDetailsMutation,
  useResendOtpMutation,
  useResendOtpProviderMutation,
  useGetNotificationsMutation,
  useCloseNotificationMutation,
  useGoogleSignupMutation,
  useGoogleSignupSeekerMutation,
  useProviderresetPasswordOtpVerifyMutation,
  useSeekerresetPasswordOtpVerifyMutation,

  useUserMailSubscriptionMutation,
  useGetChatTokenQuery,
  useTokenStatusQuery,
  useAdvertisementSuggetionsQuery,
  useGetHelpFaqMutation

} = api
