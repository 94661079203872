import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import { useGetVedicLanguageQuery } from "../../../../features/Auth/authApi"

interface LanguageOption {
  value: string
  label: string
}

const SeekerStepThree = () => {
  const funnelData = useAppSelector((state: any) => state.seekerFunnel)
  type StateType = { value: string; label: string }[]
  const [selectedValue, setSelectedValue] = useState<StateType>([])

  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    // resolver: schemaResolver,
  })

  // const subOptions: LanguageOption[] = [
  //   { value: "Hindi", label: "Hindi" },
  //   { value: "English", label: "English" },
  //   { value: "Marathi", label: "Marathi" },
  //   { value: "Punjabi", label: "Punjabi" },
  //   { value: "Bengali", label: "Bengali" },
  //   { value: "Tamil", label: "Tamil" },
  // ]
  const { isError, error, data } = useGetVedicLanguageQuery()
  const seekerData = useAppSelector((state) => state.seekerFunnel)

  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
  ) => {
    const payloadLanguages = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    const isStepValid = selectedValues.length > 0
    dispatch(
      updateFunnel({ ...seekerData, language: payloadLanguages, isStepValid }),
    )
  }

  return (
    <>
      <section>
        <Container>
          <div className="inner-fav">
            <Row className="justify-content-center">
              <Col xl={6} lg={8}>
                <div className="d-flex justify-content-between flex-column">
                  <div>
                    <form>
                      <label className="get-started-text mb-2">
                        What Language do you prefer for this session?
                      </label>
                      <FormMultiSelect
                        title=""
                        options={data?.data}
                        control={control}
                        onChange={(option) => handleSelectChange(option)}
                        name="select"
                        value={selectedValue as any}
                      />
                    </form>
                  </div>
                  <div></div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepThree
