import React from "react"
import { Button, Col, Container, Row, Form } from "react-bootstrap"
import "./NotificationScreen.css"
import { IoChevronBackOutline } from "react-icons/io5"
import { IoChevronForward } from "react-icons/io5"
import navImg from "../../../../assets/img/banner-image.png"
import { Link } from "react-router-dom"
const NotificationScreen = () => {
  return (
    <>
      <section className="notification-section section-padding pb-5">
        <Container>
          <div>
            <Row>
              <Col xl={5} md={6}>
                <div className="notification-wrapper">
                  <div className="notification-header">
                    <div className="d-flex justify-content-between align-items-center mb-3 pb-2">
                      <h3 className="mb-0">Notification</h3>
                      <div className="d-flex align-items-center">
                        <p className="mb-0 notification-pagination me-2">
                          <span>1-12 </span>
                          of
                          <span> 40</span>
                        </p>
                        <div className="d-flex">
                          <Button
                            className="p-0 arrow-btn disabled me-2"
                            variant="transparent"
                          >
                            <IoChevronBackOutline />{" "}
                          </Button>
                          <Button
                            className="p-0 arrow-btn"
                            variant="transparent"
                          >
                            <IoChevronForward />{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="navigation-filter d-flex justify-content-end">
                      <div className="d-flex jutfify-content-end align-items-center gap-3">
                        <Form.Check
                          type="radio"
                          name="notify-radio"
                          id="all-notify"
                          label="All"
                        />
                        <Form.Check
                          type="radio"
                          name="notify-radio"
                          id="unread-notify"
                          label="Unread"
                        />
                        <Form.Check
                          type="radio"
                          name="notify-radio"
                          id="read-notify"
                          label="Read"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="notification-item">
                    <div className="inner-nav-item d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-3">
                        <img src={navImg} className="nav-img" />
                        <div>
                          <h3 className="navigation-name">Aryan</h3>
                          <p className="navigation-text mb-0">
                            Aryan sent you yoga class request
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="navigation-time mb-0">Just now</p>
                        <p className="navigation-date mb-0">12/Jan/24</p>
                      </div>
                    </div>
                  </div>
                  <div className="navigation-item-container">
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item active">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <div className="inner-nav-item d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-3">
                          <img src={navImg} className="nav-img" />
                          <div>
                            <h3 className="navigation-name">Aryan</h3>
                            <p className="navigation-text mb-0">
                              Aryan sent you yoga class request
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="navigation-time mb-0">Just now</p>
                          <p className="navigation-date mb-0">12/Jan/24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={7} md={6}>
                <div className="detail-notification-item">
                  <div className="inner-nav-item d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      <img src={navImg} className="nav-img" />
                      <div>
                        <h3 className="navigation-name">Aryan</h3>
                        <p className="navigation-text mb-0">Yoga student</p>
                      </div>
                    </div>
                    <div>
                      <p className="navigation-time mb-0">Just now</p>
                      <p className="navigation-date mb-0">12/Jan/24</p>
                    </div>
                  </div>
                  <div className="detail-notification">
                    <p className="mb-2 fw-bold">Sent you request</p>
                    <p className="color-AEAEAE">
                      Lorem ipsum dolor sit amet consectetur. Tristique enim
                      egestas morbi tincidunt leo. Volutpat tellus mi eu
                      facilisi sed pellentesque sem. Ut est sagittis egestas
                      nunc. Morbi tellus odio venenatis congue quis et felis.
                      Est semper adipiscing cursus eget turpis sit. Orci nisl .
                    </p>
                    <Link className="common-btn notification-btn" to={""}>
                      See Request
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}
export default NotificationScreen
