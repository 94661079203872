import ProfileAccountImg from "../../assets/images/profile-a.png"
import styles from "./ChatUi.module.css"

const AvatarRow = () => {
  return (
    <>
      <div className={`${styles["avatar-row"]}`}>
        <div className={`${styles["avatar-box"]}`}>
          <img
            src={ProfileAccountImg}
            alt="Avatar 1"
            width={100}
            height={100}
            className={`${styles.avatar}`}
          />
        </div>
        <div className={`${styles["avatar-box"]}`}>
          <img
            src={ProfileAccountImg}
            alt="Avatar 2"
            className={`${styles.avatar}`}
          />
        </div>
        <div className={`${styles["avatar-box"]}`}>
          <img
            src={ProfileAccountImg}
            alt="Avatar 3"
            className={`${styles.avatar}`}
          />
        </div>
        <div className={`${styles["avatar-box"]}`}>
          <img
            src={ProfileAccountImg}
            alt="Avatar 4"
            className={`${styles.avatar}`}
          />
        </div>
        <div className={`${styles["avatar-box"]}`}>
          <img
            src={ProfileAccountImg}
            alt="Avatar 5"
            className={`${styles.avatar}`}
          />
        </div>
      </div>
    </>
  )
}

export default AvatarRow
