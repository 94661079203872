import React, { Suspense, lazy } from "react"
import FavouriteScreen from "./components/ScheduleSession/FavouriteScreen/FavouriteScreen"
import HelpCenter from "./components/ScheduleSession/HelpCenter/HelpCenter"
import PaymentCard from "./components/ScheduleSession/PaymentCard/PaymentCard"
import PaymentSub from "./components/ScheduleSession/PaymentSubscription/PaymentSub"
import PaymentSuccess from "./components/ScheduleSession/PaymentSuccesfull/PaymentSuccess"
import ScheduleSession from "./components/ScheduleSession/ScheduleSession/ScheduleSession"
import TeacherProfile from "./components/ScheduleSession/TeacherProfile/TeacherProfile"
import MasterStepper from "./components/funnels/ProviderFunnel/mastersStepper/ProviderStepper"
import SeekerStepFive from "./components/funnels/seekersFunnel/seekerStepFive/SeekerStepFive"
import SeekerStepFour from "./components/funnels/seekersFunnel/seekerStepFour/SeekerStepFour"
import SeekerStepOne from "./components/funnels/seekersFunnel/seekerStepOne/SeekerStepOne"
import ThankyouVedic from "./components/funnels/seekersFunnel/seekerStepSix/SeekerStepSix"
import SeekerStepThree from "./components/funnels/seekersFunnel/seekerStepThree/SeekerStepThree"
import SeekerStepTwo from "./components/funnels/seekersFunnel/seekerStepTwo/SeekerStepTwo"
// import LandingPage from "./components/pages/LandingPage/LandingPage"
const LandingPage = lazy(
  () => import("./components/pages/LandingPage/LandingPage"),
)
import SelectPath from "./components/funnels/selectPath/SelectPath"
import SubjectDetail from "./components/funnels/ProviderFunnel/subjectDetail/SubjectDetail"
import AdDetail from "./components/funnels/ProviderFunnel/CreateAdvert/CreateAdvert"
import CreateAdvert from "./components/funnels/ProviderFunnel/CreateAdvert/CreateAdvert"
import Reset from "./components/modals/AuthComponents/Reset/Reset"
import SuccesfullPassword from "./components/modals/AuthComponents/ChnageSuccess/SuccesfulChange"
import SignUpSuccess from "./components/modals/AuthComponents/SignupSuccess/SignUpSuccessfull"
import Providerlogin from "./components/funnels/ProviderFunnel/providerlogin/providerlogin"
import ClassInfo from "./components/funnels/ProviderFunnel/classInfo/ClassInfo"
import ProviderInfo from "./components/funnels/ProviderFunnel/providerInfo/ProviderInfo"
import UploadProviderPhoto from "./components/funnels/ProviderFunnel/uploadPhoto/UploadPhoto"
import DashboardOverview from "./components/dasboard/Providerdashboard/DashboardOverview/DashboardOverview"
import MyAds from "./components/dasboard/Providerdashboard/Myads/MyAds"
import SubProviderDashWrapper from "./components/wrappers/subProviderDashWrapper/SubProviderDashWrapper"
import ExploreWrapper from "./components/wrappers/ExploreWrapper"
import RequestScreen from "./components/dasboard/Providerdashboard/requestScreen/RequestScreen"
import ReceivedReview from "./components/dasboard/Providerdashboard/receivedReview/ReceivedReview"
import MyAccount from "./components/dasboard/Providerdashboard/myAccount/MyAccount"
import DashboardDesign from "./components/dasboard/seekerDasboard/CustomerDashOverview/CustomerDashOverview"
import RequestCustomerScreen from "./components/dasboard/seekerDasboard/requestScreen/RequestScreen"
import MyAccountCustomer from "./components/dasboard/seekerDasboard/myAccount/MyAccount"
import ReceivedReviewCustomer from "./components/dasboard/seekerDasboard/writeReview/MyReview"
import WriteReviewCustomer from "./components/dasboard/seekerDasboard/writeReview/WriteReview"
import UploadIdentification from "./components/dasboard/Providerdashboard/uploadIdebtification/UploadIdentification"
import DoneScreen from "./components/funnels/ProviderFunnel/doneScreen/DoneScreen"
import RequestSuccessfull from "./components/ScheduleSession/RequestSuccessfull/RequestSuccessfull"
import StudentView from "./components/dasboard/Providerdashboard/Myads/studentView"
import { Spinner } from "react-bootstrap"
import FlexiblePricing from "./components/FlexiblePricing/FlexiblePricing"
import ProviderDashboardPayment from "./components/ScheduleSession/Providerpackages/ProviderDashboardPayment"
import LandingHandler from "./utils/routeHandlers/landingHandler"
import ExploreMoreWrapper from "./components/wrappers/ExploreMore"
import ProviderOtpVerification from "./components/funnels/ProviderFunnel/providerOtpVerification/ProviderOtpVerification"
import SingleHelp from "./components/ScheduleSession/HelpCenter/SingleHelp"
import SpinnerMain from "./components/spinner/SpinnerMain"
import NotificationScreen from "./components/dasboard/Providerdashboard/NotificationScreen/NotificationScreen"
import GoogleCallBackHandler from "./utils/GoogleCallBackHandler"
import { USERTYPE } from "./constants"
import PrivateRoute from "./utils/PrivateRoute"
import ChatAuthenticator from "./features/chat/ChatAuthenticator"
import CustomerDashLayout from "./components/wrappers/DashLayoutWrapper/DashlayoutWrapper"
import DashLayoutWrapper from "./components/wrappers/DashLayoutWrapper/DashlayoutWrapper"
import DashboardComponent from "./components/dasboard/Providerdashboard/Dashboardcomponent/DashboardComponent"
import { store } from "./app/store"
import { api } from "./features/Auth/authApi"
import DashboardLayoutController from "./components/dasboard/Providerdashboard/DashboardLayoutController"
import MyAdvtCard from "./components/dasboard/Providerdashboard/Myads/MyAdvtCard"
import { useAppSelector } from "./app/hooks"
// import ProviderSignUp from "./components/funnels/ProviderFunnel/providerSignup/providerSignUp"
const ProviderSignUp = lazy(
  () =>
    import("./components/funnels/ProviderFunnel/providerSignup/providerSignUp"),
)




export const baseRoutes = [
  {
    index: true,

    element: (
      <Suspense fallback={<SpinnerMain />}>
        <LandingHandler>
          <LandingPage />
        </LandingHandler>
      </Suspense>
    ),
  },
  { path: "/explore-more", element: <ExploreMoreWrapper /> },
  { path: "/teacher-profile", element: <TeacherProfile /> },
  {
    path: "/schedule-session/:id",
    element: <ScheduleSession />,
  },

  {
    path: "/provider/auth/:token",
    element: <GoogleCallBackHandler userType={USERTYPE.PROVIDER} />,
  },
  {
    path: "/seeker/auth/:token",
    element: <GoogleCallBackHandler userType={USERTYPE.SEEKER} />,
  },
  {
    path: "/payment-card",
    element: (
      <PrivateRoute>
        <PaymentCard />
      </PrivateRoute>
    ),
  },
  {
    path: "/payment-sub/:id",
    element: (
      <PrivateRoute>
        <PaymentSub />
      </PrivateRoute>
    ),
  },
  {
    path: "/payment-success/:id",
    element: (
      <PrivateRoute>
        <PaymentSuccess />
      </PrivateRoute>
    ),
  },
  {
    path: "/paymentPlans/:id",
    element: (
      <PrivateRoute>
        <FlexiblePricing />
      </PrivateRoute>
    ),
  },

  {
    path: "/packagePayment/:id",
    element: (
      <PrivateRoute>
        <ProviderDashboardPayment />
      </PrivateRoute>
    ),
  },
  {
    path: "/request-success/:id",
    element: (
      <PrivateRoute>
        <RequestSuccessfull />
      </PrivateRoute>
    ),
  },

  {
    path: "/favourite-screen",
    element: (
      <PrivateRoute>
        <FavouriteScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/help-center",
    element: (
      <PrivateRoute>
        <HelpCenter />
      </PrivateRoute>
    ),
  },

  {
    path: "/journey",
    element: <SelectPath />,
  },
  {
    path: "/journey-success",
    element: <ThankyouVedic />,
  },

  {
    path: "/reset-password",
    element: <Reset />,
  },

  {
    path: "/password-successfully-change",
    element: <SuccesfullPassword />,
  },
  {
    path: "/source-signin",
    element: <Providerlogin />,
  },
  {
    path: "/source-otp-verification",
    element: <ProviderOtpVerification />,
  },
  {
    path: "/single-help",
    element: <SingleHelp />,
  },
  {
    path: "/notification",
    element: <NotificationScreen />,
  },
  {
    path: "/source-signup",
    element: (
      <Suspense
        fallback={
          <div className="landing-spinner d-flex justify-content-center align-items-center">
            {/* <Spinner /> */}
          </div>
        }
      >
        <ProviderSignUp />
      </Suspense>
    ),
  },
  {
    path: "/verify-email",
    element: <SignUpSuccess />,
  },

  {
    path: "/results",
    element: <ExploreWrapper />,
  },
  {
    path: "/complete",
    element: <DoneScreen />,
  },
]

export const providerFunnelRoutes: any = [
  { index: true, element: <SubjectDetail /> },

  {
    path: "/journey-source/avt-details",
    element: <CreateAdvert />,
  },

  {
    path: "/journey-source/source-details",
    element: <ProviderInfo />,
  },
  {
    path: "/journey-source/class-details",
    element: <ClassInfo />,
  },
  {
    path: "/journey-source/source-picture",
    element: <UploadProviderPhoto />,
  },
]

export const providerDashboardRoutes: any = [
  {
    index: true, element: <DashLayoutWrapper ColumnLeft={
      <DashboardComponent userType={USERTYPE.PROVIDER} />
    }
      ColumnRight={
        <DashboardOverview />
      }

    />,
  },
  {
    path: "/source-dashboard/my-advt", element:
      <DashLayoutWrapper ColumnLeft={
        <MyAdvtCard />
      }
        ColumnRight={
          <MyAds />
        }

      />,
  },
  { path: "/source-dashboard/my-requests", element: <RequestScreen /> },
  { path: "/source-dashboard/my-evaluations", element: <ReceivedReview /> },
  { path: "/source-dashboard/my-account", element: <MyAccount /> },
  { path: "/source-dashboard/identity", element: <UploadIdentification /> },
]

export const customerDashboardRoutes: any = [
  {
    index: true, element: <DashLayoutWrapper ColumnLeft={
      <DashboardComponent userType={USERTYPE.SEEKER} />
    }
      ColumnRight={
        <DashboardDesign />
      }

    />,
  },
  {
    path: "/seeker-dashboard/my-requests",
    element:
      // <ChatAuthenticator>
      <RequestCustomerScreen />
    // </ChatAuthenticator>,
  },
  {
    path: "/seeker-dashboard/my-account",
    element: <MyAccountCustomer />,
  },


  {
    path: "/seeker-dashboard/my-evaluations",
    element: <ReceivedReviewCustomer />,
  },

  {
    path: "/seeker-dashboard/write-review",
    element: <WriteReviewCustomer />,
  },
]
export const selectPath = [{ index: true, element: <SelectPath /> }]
export const seekerFunnelRoutes: any = [
  { element: <SeekerStepOne />, index: true },
  {
    path: "/journey-seeker/seeker-select-subjects",
    element: <SeekerStepTwo />,
  },
  {
    path: "/journey-seeker/seeker-language",
    element: <SeekerStepThree />,
  },
  {
    path: "/journey-seeker/seeker-session",
    element: <SeekerStepFour />,
  },
  {
    path: "/journey-seeker/seeker-lesson",
    element: <SeekerStepFive />,
  },

  ,
]