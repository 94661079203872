import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../../navbars/TopNavbar"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { useAppDispatch } from "../../../../app/store"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppSelector } from "../../../../app/hooks"
import "../seekerStepFour/seekerStepFour.css"
const SeekerStepFive = () => {
  const funnelData = useAppSelector((state: any) => state.seekerFunnel)

  const dispatch = useAppDispatch()
  const handleSelectChange = (e: any, name: string) => {
    const isStepValid = name === "" ? false : true
    dispatch(updateFunnel({ ...funnelData, skillSet: name, isStepValid }))
  }

  return (
    <>
      <section>
        <Container>
          <div className="inner-fav">
            <Row className="justify-content-center">
              <Col xl={6} lg={8}>
                <div className="d-flex justify-content-between flex-column">
                  <div>
                    <form>
                      <label className="get-started-text mb-2">
                        Select Lesson
                      </label>
                      <div className="mb-4">
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="private-place"
                          onChange={(e) => handleSelectChange(e, "Private")}
                        />
                        <label
                          className="session-label"
                          htmlFor="private-place"
                        >
                          Private
                        </label>
                      </div>
                      <div className="mb-4">
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="group-place"
                          onChange={(e) => handleSelectChange(e, "Group")}
                        />
                        <label className="session-label" htmlFor="group-place">
                          Group
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="any-place"
                          onChange={(e) => handleSelectChange(e, "Any")}
                        />
                        <label className="session-label" htmlFor="any-place">
                          Any
                        </label>
                      </div>
                    </form>
                  </div>
                  <div></div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepFive
