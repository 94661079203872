import React, { useEffect, useState } from "react"
import { Modal, Form, Row, Col } from "react-bootstrap"
import "./EditPrice.css"
import { useForm } from "react-hook-form"
import { useUpdateAdvertisementMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { editAdPriceSchema } from "../../../../utils/yupSchema/Schema"
interface CardProps {
  show: boolean
  Data: any
  handleClose: () => void
}

const EditPrice = ({ show, handleClose, Data }: CardProps) => {
  const [editPrice, setEditPrice] = useState({
    realTime: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
    virtual: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
  })

  const [firstClassFree, setFirstClassFree] = useState<boolean>(false)
  const [selectedMode, setSelectedMode] = useState<boolean>(false)
  // const [hourlyRateChange,setHourlyRateChange] = useState()
  const [errormsg, setErrorMsg] = useState<string>("")
  const [updatePricing, { data, error }] = useUpdateAdvertisementMutation()
  const handleClass = () => {
    setFirstClassFree(!firstClassFree)
  }
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    resetField,
    formState: { errors },
  } = useForm<any>({
    resolver: editAdPriceSchema,
  })

  const onSubmit = async (values: any) => {
    const payload = {
      prices: editPrice,
    }
    const response = await updatePricing({
      servicedata: payload,
      id: Data._id,
    })
    if (response) {
      reset()
      handleClose()
    }
  }

  const handleClosePrice = () => {
    setValue(
      "hourlyFees",
      !selectedMode
        ? Data?.prices?.realTime?.group?.hourlyFee
        : Data?.prices?.virtual?.group?.hourlyFee,
    )
    setValue(
      "hourlyFeesPrivate",
      !selectedMode
        ? Data?.prices?.realTime?.private?.hourlyFee
        : Data?.prices?.virtual?.private?.hourlyFee,
    )
    handleClose()
  }
  const handleSpecialCharacter = (e: any) => {
    if (
      !(
        (e.key >= "0" && e.key <= "9") ||
        e.key === "Backspace" ||
        e.key === "Delete"
      )
    ) {
      e.preventDefault()
    }
  }

  const handleRadioMode = () => {
    setSelectedMode(!selectedMode)
  }
  const handleHourlyFeeChange = (e: any, type: any, category: any) => {
    const { name, value } = e.target
    setEditPrice((prevState: any) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [category]: {
          ...prevState[type]?.[category],
          hourlyFee: parseFloat(value),
        },
      },
    }))
  }
  const handlefirstClassToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    category: string,
  ) => {
    const { name, checked } = e.target
    setEditPrice((prevState: any) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [category]: {
          ...prevState[type]?.[category],
          firstClassFree: checked,
        },
      },
    }))
  }
  const handleButtonClick = async () => {
    const payload = {
      prices: editPrice,
    }
    const response = await updatePricing({
      servicedata: payload,
      id: Data._id,
    })
    if (response) {
      reset()
      handleClose()
    }
  }
  useEffect(() => {
    setEditPrice(Data?.prices)
    setValue(
      "hourlyFees",
      !selectedMode
        ? Data?.prices?.realTime?.group?.hourlyFee
        : Data?.prices?.virtual?.group?.hourlyFee,
    )
    setValue(
      "hourlyFeesPrivate",
      !selectedMode
        ? Data?.prices?.realTime?.private?.hourlyFee
        : Data?.prices?.virtual?.private?.hourlyFee,
    )
  }, [Data])

  useEffect(() => {
    if (!selectedMode) {
      setValue("hourlyFees", Data?.prices?.realTime?.group?.hourlyFee)
      setValue(
        "hourlyFeesPrivate",
        !selectedMode
          ? Data?.prices?.realTime?.private?.hourlyFee
          : Data?.prices?.virtual?.private?.hourlyFee,
      )
    } else {
      setValue("hourlyFees", Data?.prices?.virtual?.group?.hourlyFee)
      setValue("hourlyFeesPrivate", Data?.prices?.virtual?.private?.hourlyFee)
    }
  }, [selectedMode])

 
  

  return (
    <>
      <Modal show={show}>
        <Row>
          <Col lg={6}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="edit-card">
                <h3 className="mb-4 text-center teaching-head">Price</h3>
                <div className="d-flex ">
                  {" "}
                  <Col
                    className={`real-virtual-btn mx-2 ${
                      !selectedMode ? "selected" : ""
                    }`}
                    onClick={handleRadioMode}
                  >
                    <Form.Check
                      type="radio"
                      id="realTime"
                      label="Real Time"
                      checked={!selectedMode}
                      onChange={handleRadioMode}
                      style={{ paddingLeft: "0px" }}
                    />
                  </Col>
                  <Col
                    className={`real-virtual-btn mx-2 ${
                      selectedMode ? "selected" : ""
                    }`}
                    onClick={handleRadioMode}
                  >
                    <Form.Check
                      type="radio"
                      id="Virtual"
                      label="Virtual"
                      checked={selectedMode}
                      onChange={handleRadioMode}
                      style={{ paddingLeft: "0px" }}
                    />
                  </Col>
                </div>
                <div className="modecontainer">
                  {/* real time division  */}
                  <div>
                    <div className="mb-3">Group</div>
                    <div className="teaching-as mb-4">
                      <p className="mb-1 edit-class-label d-inline-block">
                        Hourly Fees
                      </p>
                      <Form.Control
                        type="number"
                        className="edit-price-field"
                        {...register("hourlyFees")}
                        onKeyDown={handleSpecialCharacter}
                        name="hourlyFees"
                        placeholder="Enter hourly charges"
                        onChange={(e) => {
                          handleHourlyFeeChange(
                            e,
                            !selectedMode ? "realTime" : "virtual",
                            "group",
                          )
                        }}
                      />
                      {errors?.hourlyFees?.type !== "nullable" && (
                        <span className="error">
                          {" "}
                          {(errors as any)?.hourlyFees?.message}
                        </span>
                      )}
                    </div>
                    <div className="teaching-as mb-4">
                      <div className="d-flex mb-1 align-items-center">
                        <label
                          className="me-2 edit-class-label d-inline-block"
                          htmlFor="firstfreeclass"
                        >
                          First Free Class
                        </label>
                        <Form.Check
                          type="switch"
                          id="firstfreeclass"
                          className="notify-toggle"
                          onClick={handleClass}
                          defaultChecked={
                            !selectedMode
                              ? Data?.prices?.realTime?.group?.firstClassFree
                              : Data?.prices?.virtual?.group?.firstClassFree
                          }
                          onChange={(e) => {
                            handlefirstClassToggleChange(
                              e,
                              !selectedMode ? "realTime" : "virtual",
                              "group",
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* private division */}

                  <div>
                    <div className="mb-3">Private</div>
                    <div className="teaching-as mb-4">
                      <p className="mb-1 edit-class-label d-inline-block">
                        Hourly Fees
                      </p>

                      <Form.Control
                        type="number"
                        className="edit-price-field"
                        {...register("hourlyFeesPrivate")}
                        onKeyDown={handleSpecialCharacter}
                        name="hourlyFeesPrivate"
                        placeholder="Enter hourly charges"
                        onChange={(e) => {
                          handleHourlyFeeChange(
                            e,
                            !selectedMode ? "realTime" : "virtual",
                            "private",
                          )
                        }}
                      />
                      {errors?.hourlyFeesPrivate?.type !== "nullable" && (
                        <span className="error">
                          {" "}
                          {(errors as any)?.hourlyFeesPrivate?.message}
                        </span>
                      )}
                    </div>

                    <div className="teaching-as mb-4">
                      <div className="d-flex mb-1 align-items-center">
                        <label
                          className="me-2 edit-class-label d-inline-block"
                          htmlFor="firstfreeclass"
                        >
                          First Free Class
                        </label>
                        <Form.Check
                          type="switch"
                          id="firstfreeclass"
                          className="notify-toggle"
                          onClick={handleClass}
                          defaultChecked={
                            !selectedMode
                              ? Data?.prices?.realTime?.private?.firstClassFree
                              : Data?.prices?.virtual?.private?.firstClassFree
                          }
                          onChange={(e) => {
                            handlefirstClassToggleChange(
                              e,
                              !selectedMode ? "realTime" : "virtual",
                              "private",
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex justify-content-between px-md-4 px-2 w-100 mt-5">
                  <button
                    className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                    type="button"
                    onClick={handleClosePrice}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn bottom-btn save-edit-btn"
                    type="submit"
                    onClick={handleButtonClick}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditPrice
