import React, { useEffect, useState } from "react"
import {
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
} from "react-bootstrap"
import headerimage from "../../assets/img/header-image.png"
import headerlogo from "../../assets/img/logo.png"
// import Login from "../Modals/Login/Login"
import avatar from "../../assets/img/avatar.png"
import "./TopNav.css"
import { MODALNAME, USERTYPE } from "../../constants"
import { setModalName } from "../../features/Auth/ModalHandler/modalSlice"
import { useAppDispatch } from "../../app/store"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { useAppSelector } from "../../app/hooks"
import userImg from "../../assets/img/card-image.png"
import deleteicon from "../../assets/img/deleteIcon.svg"
import msgicon from "../../assets/img/msgicon.svg"
import { IoCloseCircle } from "react-icons/io5"
import {
  resetFunel,
  reset,
} from "../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import AuthCollection from "../../features/Auth/AuthCollection"
import {
  useCloseNotificationMutation,
  useGetNotificationsMutation,
  useLogoutMutation,
  useLogoutProviderMutation,
} from "../../features/Auth/authApi"
import { logout } from "../../features/Auth/authSlice"
import {
  resetProviderFunel,
  resetProviderStepper,
} from "../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import {
  useProviderAccountDetailsQuery,
  useProviderSearchMutation,
} from "../../features/featProviderDashboard/FeatProviderApi"
import {
  useGetseekerProfileQuery,
  useSearchLandingPageMutation,
  useSeekerSearchMutation,
} from "../../features/featSeekerDashboard/seekerDashboardApi"
import premimumStar from "../../assets/images/premimumStar.png"
import notification from "../../assets/img/notification.svg"
import { Controller, useForm } from "react-hook-form"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import dummy from "../../assets/images/dummy-profile-pic.jpg"
import LandingSearch from "../searchBars/LandingSearchBar"
import TopNavSearchBar from "../searchBars/topNavSearchBar"
import { T } from "vitest/dist/types-e3c9754d.js"
import { toast } from "react-toastify"
import {
  setMessageNotificationClick,
  setSeekerLandingPageSearchData,
  setSeekerRequest,
} from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { topNavBarSchema } from "../../utils/yupSchema/Schema"
interface navProps {
  navClass?: string
}

const subjects = [{ name: "Yoga" }, { name: "Mantra" }]
const TopNavbar = ({ navClass }: navProps) => {
  const ProviderData = useAppSelector((state: any) => state.providerSlice)
  const auth = useAppSelector((state: any) => state.auth)
  const { seekerToken, providerToken } = useAppSelector(
    (state: any) => state.auth,
  )  
  
  const adDetails = useAppSelector((state: any) => state.providerSlice)
  const [isScrolled, setIsScrolled] = useState(false)
  const [msgNotification, setMsgNotification] = useState<Array<T>>([])
  const [allNotification, setAllNotification] = useState<Array<T>>([])
  const userData = useAppSelector((state) => state.auth)      
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // to disable tabs for new user until user has no ad
  const newUserAdvertisementLength =
    adDetails?.newUserAdvertisementLength === -1 ||
    adDetails?.newUserAdvertisementLength === 0    
  //  search bar location

  const { handleSubmit, setValue, register, control } = useForm<any>({
    resolver: topNavBarSchema,
  })
  const [
    logoutProvider,
    { data: providerData, isLoading: providerisLoading, error: providerError },
  ] = useLogoutProviderMutation()

  // const { data, isLoading: getLoading } = seekerToken
  //   ? useGetseekerProfileQuery()
  //   : useProviderAccountDetailsQuery()
  const [
    getNotification,
    { data: notificationData, isLoading, isError, isSuccess },
  ] = useGetNotificationsMutation()

  const [
    closeNotification,
    {
      data: closeNotificationData,
      isLoading: closeNotificationloading,
      isError: closeNotificationError,
      isSuccess: closeNotificationSuccess,
    },
  ] = useCloseNotificationMutation()
  const [
    logoutApi,
    { data: logoutData
      , isSuccess: logoutSuccess, isLoading: logoutLoading },
  ] = useLogoutMutation()
  const handleNotificationClose = (id: any) => {
    closeNotification({ notificationId: id })
  }
  const handleCloseAll = async () => {
    const res = await closeNotification({
      closeAll: true,
    })
    if (res) {
      setAllNotification([])
    }
    setMsgNotification([])
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (seekerToken || providerToken) {
      const intervalId = setInterval(() => {
        getNotification({
          pageNo: 1,
          pageLimit: 10,
        })
      }, 5000)
      return () => clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    if (notificationData?.data) {
      setAllNotification(notificationData?.data)
    }
  }, [notificationData])
  const handleNotification = (id: any, index: number) => {
    // handleNotificationClose(id)
    setAllNotification((prevNotification) =>
      prevNotification.filter((item: any) => item._id !== id),
    )
    dispatch(setMessageNotificationClick((allNotification as any)[index]))
    navigate(`/seeker-dashboard/my-requests`)
  }
  useEffect(() => {
    if ((logoutData as any)?.message || (providerData as any)?.message) {
      toast.success(
        (logoutData as any)?.message || (providerData as any)?.message,
      )
      navigate("/") 
      dispatch(logout())
      dispatch(resetProviderFunel())
      dispatch(resetFunel())
      dispatch(reset())
      dispatch(resetProviderStepper())
    }
  }, [logoutData, providerData])
  
  // Notification Part
  const [search, { data: searchedData, isLoading: loading }] = seekerToken
    ? useSeekerSearchMutation()
    : useProviderSearchMutation()

  useEffect(() => {
    const foundItem = searchedData?.data?.find(
      (item: any) =>
        item._id === ProviderData?.ProviderNewMsgNotification?.chatId,
    )
    if (foundItem) {
      const username = ProviderData?.ProviderNewMsgNotification?.username
      setMsgNotification((prevState) => {
        const existingIds = prevState.map((item: any) => item._id)
        const newId = ProviderData?.ProviderNewMsgNotification?.chatId

        if (newId && !existingIds.includes(newId)) {
          return [
            ...prevState,
            {
              title: `You have a new message from ${username}`,
              _id: newId,
              data: foundItem,
            } as any,
          ]
        }

        return prevState
      })
    }
  }, [ProviderData?.ProviderNewMsgNotification])
  useEffect(() => {
   async function searchfun () {
      if (seekerToken || providerToken) {
         search({
          status: "Accept",
          sort: -1,
          search: "",
        })
      }
      searchAdvts({
        VedicSubject: "",
        teacherType: "",
        sortBy: "price",
        sortOrder: "ascending",
      pageNo: 1,
      })
    }
   searchfun()
  }, [seekerToken, providerToken])

  const handleMessageNotification = (requestId: any, index: number) => {
    if (auth?.role === USERTYPE.SEEKER) {
      navigate(`/seeker-dashboard/my-requests`)
    } else {
      navigate(`/source-dashboard/my-requests`)
    }
    dispatch(setMessageNotificationClick((msgNotification as any)[index]?.data))
    setMsgNotification((prevState) =>
      prevState.filter((item: any) => item._id !== requestId),
    )
  }
  const [
    searchAdvts,
    {
      data: exploreData,
      error: searchAdvtsError,
      isSuccess: searchAdvtsSuccess,
    },
  ] = useSearchLandingPageMutation()
  const handleExploreMore = async () => {
    const payload = {
      VedicSubject: "",
      teacherType: "",
      sortBy: "price",
      sortOrder: "ascending",
      pageNo: 1,
    }
    dispatch(setSeekerLandingPageSearchData(payload as any))
    navigate("/results")
    await searchAdvts(payload)
  }  



  return (
    <>
      <section className={`header-component ${isScrolled ? "scrolled" : ""}`}>
        <Navbar expand="lg" className={navClass} fixed="top">
          <Container fluid >
            <div className="d-flex align-items-center">
              <Navbar.Brand
                className="align-items-center"
                onClick={() => {
                  userData.seekerToken
                    ? navigate("/results")
                    : userData.providerToken
                    ? navigate("/source-dashboard")
                    : navigate("/")
                }}
              >
                <img
                  src={headerlogo}
                  alt="Logo"
                  className="cursor-pointer img-fluid w-100" title="Book private classes"
                  // style={{width:"100px",height:"80px"}}
                />
              </Navbar.Brand>
              <div className="topnavbar-search">
                {(location?.pathname === "/" ||
                  location?.pathname === "/results" ||
                  location?.pathname === "/explore-more") && (
                  <TopNavSearchBar />
                )}
              </div>
            </div>
            <Navbar.Collapse
              id="navbarTogglerDemo01"
              className="justify-content-end navigation-collapse"
            >
              <Navbar.Toggle className="close-navbar border-0 shadow-none">
                <IoCloseCircle />
              </Navbar.Toggle>
              <Nav className="d-lg-flex align-items-center">
                <a className="d-block mb-lg-0 mb-2 me-3 get-help-link text-center">
                  <i className="fa-regular fa-circle-question"></i>
                </a>
                {providerToken || seekerToken ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <Button
                      variant="transparent"
                      className="d-block mb-lg-0 mb-2 header-link me-3"
                      onClick={() => navigate("/source-signup")}
                    >
                      Become a tutor
                    </Button>
                    <Button
                      variant="transparent"
                      onClick={() => dispatch(setModalName(MODALNAME.LOGIN))}
                      className="d-block mb-lg-0 mb-2 header-link login-link me-3"
                    >
                      Login
                    </Button>
                  </>
                )}
                {userData?.providerToken &&
                !window.location.href.includes("journey-source") ? (
                  <Button
                    className="d-block mb-lg-0 mb-2 header-link me-3 get-started"
                    onClick={() => {
                      navigate("/journey-source", { replace: true })
                    }}
                  >
                    Guide and Earn
                  </Button>
                ) : (
                  !window.location.href.includes("journey-source") && (
                    <Button
                      className={`${
                        location?.pathname === "/results"
                          ? "d-none"
                          : "d-block mb-lg-0 mb-2 header-link me-3 get-started"
                      }`}
                      onClick={() => {
                        userData?.seekerToken || userData.providerToken
                          ? handleExploreMore()
                          : navigate("/journey", { replace: true })
                      }}
                    >
                      {userData.seekerToken || userData.providerToken ? (
                        <span>Explore More</span>
                      ) : (
                        <>Get Started</>
                      )}{" "}
                    </Button>
                  )
                )}
                {providerToken || seekerToken ? (
                  <Dropdown className="common-dropdown notification-panel">
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="dropdown-toggle"
                    >
                      <img src={notification} alt="notification" />
                      {(allNotification?.length > 0 ||
                        msgNotification.length > 0) && (
                        <div className="notify"></div>
                      )}
                    </Dropdown.Toggle>

                    {allNotification?.length > 0 ||
                    msgNotification.length > 0 ? (
                      <Dropdown.Menu className="menu-setting custom-scrollbar">
                        <Dropdown.Item className="close-navbar p-0 d-lg-none">
                          <IoCloseCircle />
                        </Dropdown.Item>
                        {allNotification.map(
                          (notification: any, index: number) => {
                            return (
                              <Dropdown.Item key={index}>
                                <div
                                  className="mb-0 "
                                  onClick={() =>
                                    handleNotification(notification?._id, index)
                                  }
                                   key={index}
                                >
                                  <p className="d-flex justify-content-between align-items-center notification-text mb-0">
                                    {notification?.title}{" "}
                                  </p>
                                </div>
                                {/* <img
                                  src={deleteicon}
                                  onClick={() =>
                                    handleNotificationClose(notification?._id)
                                  }
                                /> */}
                              </Dropdown.Item>
                            )
                          },
                        )}
                        {msgNotification.length > 0 &&
                          msgNotification.map(
                            (notification: any, index: number) => {
                              return (
                                <Dropdown.Item key={index}>
                                  <div
                                    className="mb-0 "
                                    onClick={() =>
                                      handleMessageNotification(
                                        notification?._id,
                                        index,
                                      )
                                    }
                                    key={index}
                                  >
                                    <p className="d-flex justify-content-between align-items-center notification-text">
                                      {notification?.title}{" "}
                                      <img src={msgicon} />
                                    </p>
                                  </div>
                                </Dropdown.Item>
                              )
                            },
                          )}
                          <div className="py-2" onClick={handleCloseAll}>
                            <p className="notification-text text-danger text-center mb-0 cursor-pointer">
                              Clear All
                            </p>
                          </div>
                        {/* <Dropdown.Item>
                          <Link
                            className="py-2 d-block text-decoration-none"
                            to="/notification"
                          >
                            <p className="notification-text text-danger text-center mb-0">
                              See all
                            </p>
                          </Link>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        {" "}
                        <Dropdown.Item className="close-navbar d-lg-none p-0">
                          <IoCloseCircle />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="no-notify">
                            {/* <p className="chat-profile"> */}
                            No Notification {/* </p/> */}
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                ) : (
                  ""
                )}

                {/* Add user-related dropdown items here */}
                {(userData?.providerToken) ||
                userData?.seekerToken ? (
                  <Dropdown className="common-dropdown">
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="dropdown-toggle "
                    >
                      <img
                        src={userData?.user?.profilePic  || dummy}
                        alt={avatar}
                        className="defavatar custom-border"
                      />
                      {userData?.user?.isPremium && (
                        <img
                          src={premimumStar}
                          alt="premimumStar"
                          className="premimumStartopnav custom-border"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu-setting">
                      <Dropdown.Item className="close-navbar d-lg-none">
                        <IoCloseCircle />
                      </Dropdown.Item>
                      <Dropdown.Item  disabled={true}>
                        <div className="headerusername" style={{color:"#53dac1"}}>
                        
                      Hi ! {userData?.user?.firstName}</div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(
                            providerToken
                              ? "/source-dashboard"
                              : seekerToken
                              ? "/seeker-dashboard"
                              : "/",
                          )
                        }
                        
                      >
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(
                            seekerToken
                              ? "/seeker-dashboard/my-requests"
                              : "/source-dashboard/my-requests",
                          )
                        }
                        disabled={newUserAdvertisementLength && providerToken}
                      >
                        Request
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(
                            seekerToken
                              ? "/seeker-dashboard/my-account"
                              : "/source-dashboard/my-account",
                          )
                        }
                        disabled={newUserAdvertisementLength && providerToken}
                      >
                        My account
                      </Dropdown.Item>
                      {seekerToken ? (
                        <Dropdown.Item
                          onClick={() => navigate("/favourite-screen")}
                        >
                          Favourites
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => navigate("/source-dashboard/my-advt")}
                          disabled={newUserAdvertisementLength}
                        >
                          My Ads
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item onClick={() => navigate("/help-center")}>
                        Help
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          if (seekerToken) {
                            logoutApi()
                            dispatch(logout())
                            dispatch(resetProviderFunel())
                            dispatch(resetFunel())
                            dispatch(reset())
                            dispatch(resetProviderStepper())
                          } else {
                            logoutProvider()
                            dispatch(logout())
                            dispatch(resetProviderFunel())
                            dispatch(resetFunel())
                            dispatch(reset())
                            dispatch(resetProviderStepper())
                          }
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
              </Nav>
            </Navbar.Collapse>
            <div className="d-lg-none d-flex align-items-center">
              {providerToken || seekerToken ? (
                <Dropdown className="common-dropdown notification-panel">
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <img src={notification} alt="notification" />
                    {notificationData?.notifications?.length > 0 && (
                      <div className="notify"></div>
                    )}
                  </Dropdown.Toggle>

                  {notificationData?.notifications?.length > 0 ? (
                    <Dropdown.Menu
                      className="menu-setting"
                      style={{ width: "450px" }}
                    >
                      <Dropdown.Item className="close-navbar p-0 d-lg-none">
                        <IoCloseCircle />
                      </Dropdown.Item>
                      {notificationData?.notifications.map(
                        (notification: any, index: number) => {
                          return (
                            <Dropdown.Item key={index}>
                              <div className="mb-0 " >
                                <p className="d-flex justify-content-between align-items-center w-100 notification-text">
                                  {notification?.title}{" "}
                                </p>
                              </div>
                              <img
                                src={deleteicon}
                                onClick={() =>
                                  handleNotificationClose(notification?._id)
                                }
                              />
                            </Dropdown.Item>
                          )
                        },
                      )}
                        <div className="mb-0 " onClick={handleCloseAll}>
                          <p className="notification-text text-danger mb-3 text-center justify-content-center">
                            Clear All
                          </p>
                        </div>
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu>
                      {" "}
                      <Dropdown.Item className="close-navbar p-0 d-lg-none">
                        <IoCloseCircle />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="no-notify">No Notification</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : (
                ""
              )}

              {/* Add user-related dropdown items here */}
              {userData?.providerToken || userData?.seekerToken ? (
                <Dropdown className="common-dropdown dropdown-user">
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <img
                      src={userData?.user?.profilePic || avatar}
                      alt={avatar}
                      className="defavatar"
                    />
                    {userData?.user?.isPremium && (
                      <img
                        src={premimumStar}
                        alt="premimumStar"
                        className="premimumStartopnav"
                      />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menu-setting">
                    <Dropdown.Item className="close-navbar d-lg-none">
                      <IoCloseCircle />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        navigate(
                          providerToken
                            ? "/source-dashboard"
                            : seekerToken
                            ? "/seeker-dashboard"
                            : "/",
                        )
                      }
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        navigate(
                          seekerToken
                            ? "/seeker-dashboard/my-requests"
                            : "/source-dashboard/my-requests",
                        )
                      }
                    >
                      Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        navigate(
                          seekerToken
                            ? "/seeker-dashboard/my-account"
                            : "/source-dashboard/my-account",
                        )
                      }
                    >
                      My account
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/favourite-screen")}
                    >
                      Favourites
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/help-center")}>
                      Help
                    </Dropdown.Item>
                    <Dropdown.Item
                     onClick={() => {
                      if (seekerToken) {
                        logoutApi()
                        dispatch(logout())
                        dispatch(resetProviderFunel())
                        dispatch(resetFunel())
                        dispatch(reset())
                        dispatch(resetProviderStepper())
                      } else {
                        logoutProvider()
                        dispatch(logout())
                        dispatch(resetProviderFunel())
                        dispatch(resetFunel())
                        dispatch(reset())
                        dispatch(resetProviderStepper())
                      }
                    }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Navbar.Toggle className="shadow-none border-0" />
              )}
            </div>
          </Container>
          <AuthCollection />
        </Navbar>

        {/* <Login/> */}
      </section>
    </>
  )
}

export default TopNavbar
