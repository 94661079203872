import React, { useEffect, useState } from "react"
import ScheduleCard from "../../../ScheduleSession/scheduleCard/ScheduleCard"
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap"
import { BsEye } from "react-icons/bs"
import { useAppSelector } from "../../../../app/hooks"
import { useNavigate } from "react-router-dom"
import {
  useDeleteAdMutation,
  useProviderAdvertisementQuery,
  useUpdateAdStatusMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import deleteIcon from "../../../../assets/img/deleteIcon.svg"
import { toast } from "react-toastify"
import "../../../cards/commonCard/CommmonCard.css"
import { useSelector } from "react-redux"

const MyAdvtCard = (
  
) => {
  const navigate = useNavigate()
  const AdData = useAppSelector((state: any) => state.providerSlice)
  const {  data, isLoading:adLoading,status } = useProviderAdvertisementQuery()
  const [checkedState, setCheckedState] = useState<boolean>(false)
  const [addDelete, setAddDelete] = useState<boolean>(false)
  const [updateAdStatus, { data: statusData, isSuccess, isLoading, isError }] =
    useUpdateAdStatusMutation()
  const [DeleteAd, { isLoading: loading, isError: deleteError }] =
    useDeleteAdMutation()
  const handleNavigate = () => {
    navigate("/teacher-profile-view", {
      state: { data: AdData },
    })
  }
  const handleStatus = async () => {
    if (AdData?.user?.data?.allAdvertisements[AdData.id]?._id) {
      setCheckedState(!checkedState)

      await updateAdStatus({
        adId: AdData?.user?.data?.allAdvertisements[AdData?.id]?._id,
      })
    } else {
      setCheckedState(!checkedState)

      await updateAdStatus({
        adId: AdData?.user?.allAdvertisements[AdData?.id]?._id,
      })
      console.error("AdData or advertisement ID is missing.")
    }
  }
  const handleDeleteAd = () => {
    const res = DeleteAd({
      adId:AdData?.user?.data?.allAdvertisements[AdData.id]?._id || AdData?.user?.allAdvertisements[AdData.id]?._id,
    })
    if (res as any) {
      toast.success("Ad deleted Successfully")
      addModalClose()
    }
  }

  const addModalShow = () => {
    setAddDelete(true)
  }

  const addModalClose = () => {
    setAddDelete(false)
  }

  useEffect(() => {
    if ( AdData.user?.data?.allAdvertisements?.length > 0 ) {
      loading
      setCheckedState(
        AdData.user?.data?.allAdvertisements[AdData.id]?.status === 'Active'
         
      );
    }
  }, [AdData]);

  return (
    <>
      <div className="mb-4 mt-md-4 dashboard-schedule-card">
        {
          data &&
         <ScheduleCard data={data} key={""} seekerData={""} types={""} /> }
        
      </div>
      <Card className="ad-card d-flex align-items-center mb-3 py-3">
        <Card.Body className="p-0">
          <Form.Group className="mb-0">
           
              <Form.Check
              type="switch"
              id="adonline"
              label="Ad is Online"
              className="notify-toggle mb-0 mt-0"
              onChange={handleStatus}
              checked={checkedState}
            />
          
          </Form.Group>
        </Card.Body>
      </Card>

      <Card className="ad-card mb-3">
        <Card.Body className="p-0 text-center">
          <p className="ad-text mb-0">
            Student View{" "}
            <button
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                width: "auto",
                height: "auto",
                background: "none",
              }}
            >
              <BsEye
                className="ms-4"
                onClick={handleNavigate}
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          </p>
        </Card.Body>
      </Card>
      <Card className="mb-3 border-0 bg-transparent">
        <Card.Body className="p-0 border-0" onClick={addModalShow}>
          <p className="delete-ad-text text-center mb-0">
            Delete Ad{" "}
            {loading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <img src={deleteIcon} className="ms-0" />
            )}
          </p>
        </Card.Body>
      </Card>

      <Modal show={addDelete} onHide={addModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="delete-heading">Delete Add</Modal.Title>
        </Modal.Header>
        <Modal.Body className="get-started-text mb-1">
          Are you sure to delete your Ad Permanently !
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-button"
            variant="secondary"
            onClick={addModalClose}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="Delete-button"
            onClick={handleDeleteAd}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MyAdvtCard
