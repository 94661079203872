// import React, { useEffect, useState } from "react"
// import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
// import filterIcon from "../../assets/img/filter-icon.svg"
// import sorticon from "../../assets/img/sort.svg"
// import "./ExploreWrapper.css"
// import ProviderCards from "../cards/providerCards"
// import { useAppSelector } from "../../app/hooks"
// import { useLocation } from "react-router"
// import { useSearchLandingPageMutation } from "../../features/featSeekerDashboard/seekerDashboardApi"
// import { useForm, Controller } from "react-hook-form"
// import { yupResolver } from "@hookform/resolvers/yup"
// import * as yup from "yup"
// import { setSeekerLandingPageSearchData } from "../../features/featSeekerDashboard/seekerDashboardSlice"
// import { useDispatch } from "react-redux"
// import LandingSearch from "../searchBars/LandingSearchBar"
// import {
//   GiMeditation,
//   GiMusicSpell,
//   GiNotebook,
//   GiPiercingSword,
//   GiSpotedFlower,
// } from "react-icons/gi"
// import { GrYoga } from "react-icons/gr"
// import { PiFlowerLotusThin } from "react-icons/pi"
// import { MdSunnySnowing } from "react-icons/md"
// import {
//   selectedLocation,
//   setSearchText,
//   setSelectedSearchText,
// } from "../../features/featSearchAdvts/SearchAdvtSlice"
// import SpinnerSecondary from "../spinner/SpinnerSecondary"

// const subjects = [
//   { name: "Yoga", icon: <GrYoga /> },
//   { name: "Mantra", icon: <GiMusicSpell /> },

//   { name: "Meditation", icon: <GiMeditation /> },
//   { name: "Kalaripayalty", icon: <GiPiercingSword /> },
//   { name: "Ayurveda", icon: <GiSpotedFlower /> },
//   { name: "Sanskrit", icon: <GiNotebook /> },
//   { name: "Tantra", icon: <PiFlowerLotusThin /> },
//   { name: "Surya Namaskar", icon: <MdSunnySnowing /> },
// ]

// const ExploreMoreWrapper = () => {
//   const { providerToken, seekerToken } = useAppSelector((state) => state.auth)
//   const { selectedSearchText } = useAppSelector(selectedLocation)

//   const [searchbar, setSerachbar] = useState(true)
//   const { state } = useLocation()
//   const dispatch = useDispatch()
//   const [showFilter, setShowFilter] = useState(false)
//   const [details, setDetails] = useState()
//   const [asc, SetAsc] = useState<boolean>(false)
//   const [finalData, SetFinalData] = useState()
//   const [checkedCheckboxes, setCheckedCheckboxes] = useState<string[]>([])
//   const [vedicSubject, setvedicSubject] = useState("")
//   const [dataProviders, setDataProviders] = useState([])
//   const [page, setPage] = useState<number>(1)
//   const [pageLoading, setPageLoading] = useState<boolean>(false)
//   const schemaResolver = yupResolver(
//     yup.object().shape({
//       VedicSubject: yup.string(),
//     }),
//   )
//   const { handleSubmit, register, control } = useForm<any>({
//     resolver: schemaResolver,
//   })
//   const onSubmit = (details: any) => {
//     const { Location, VedicSubject, teacherType } = details
//     const payload = {
//       Location,
//       VedicSubject: vedicSubject,
//       teacherType,
//       sortBy: "price",
//       sortOrder: "ascending",
//     }
//     setDetails(details)
//     dispatch(setSeekerLandingPageSearchData(details))

//     search(payload)
//   }
//   const dataProvider = useAppSelector((state: any) => state.auth.data)

//   useEffect(() => {
//     // Add page attribute to each item
//     const updatedDataProviders = dataProvider.map(
//       (item: any, index: number) => {
//         const page = Math.floor(index / 6) + 1
//         return { ...item, page }
//       },
//     )

//     // Filter the data for the current page
//     const filteredDataProviders = updatedDataProviders.filter(
//       (item: any) => item.page <= page,
//     )

//     //for page loading animationation at the end
//     setPageLoading(true)
//     const timeout = setTimeout(() => {
//       setPageLoading(false)
//       setDataProviders(filteredDataProviders)
//     }, 2000)

//     return () => clearTimeout(timeout)
//   }, [page])

//   const landingPageSearchedData = useAppSelector(
//     (state: any) => state.seekerDashboardSlice,
//   )

//   const [search, { error, isSuccess, isLoading }] =
//     useSearchLandingPageMutation()
//   const handleShow = () => {
//     setShowFilter(!showFilter)
//   }
//   const dataProviderArray: any = dataProviders
//   const [loading, setLoading] = useState(true)
//   setTimeout(() => setLoading(false), 2000)

//   const handleCheckboxChange = (checkboxId: string) => {
//     setCheckedCheckboxes((prev) =>
//       prev.includes(checkboxId)
//         ? prev.filter((id) => id !== checkboxId)
//         : [...prev, checkboxId],
//     )
//   }
//   const handleClearAll = () => {
//     setCheckedCheckboxes([])
//   }

//   const handleSort = () => {
//     SetAsc(!asc)
//   }
//   useEffect(() => {
//     SetFinalData(dataProviders as any)
//     SetFinalData(dataProvider as any)
//   }, [dataProviders,dataProvider])
//   useEffect(() => {
//     const payload = {
//       services:"",
//       sortBy: "price",
//       sortOrder: asc ?"descending" :  "ascending",
//     }
//     const response = search({
//       ...landingPageSearchedData?.searchedData,
//       ...payload,
//     })
//     SetFinalData(response as any)
//   }, [asc])

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
// window.innerHeight + document.documentElement.scrollTop ===
// document.documentElement.offsetHeight
//       ) {
//         setPage((prevPage) => prevPage + 1)
//       }
//     }

//     window.addEventListener("scroll", handleScroll)

//     return () => {
//       window.removeEventListener("scroll", handleScroll)
//     }
//   }, [])

//   return (
//     <>
//       <section className="pt-100">
//         <div className={`explore-wrapper ${showFilter ? "active" : ""}`}>
//           <Container>
//             <div className="d-lg-flex justify-content-between align-items-start">
//               {providerToken || seekerToken ? (
//                 <>
//                   <Container>
//                     <LandingSearch />
//                   </Container>
//                   {/* <Container className="d-flex justify-content-center">
//                </Container>
//                <br/>
//                  <Container className="d-flex justify-content-center">
//             //    <div className="keywords-wrapper">
//             //    <div className="inner-keyword-wrapper">
//                 //  {subjects.map((subject, index) => (
//             //        <button
//             //          disabled={!!selectedSearchText}
//             //          id="yourButtonId"
//             //          key={index}
//             //          className="keyword-btn"
//             //          onClick={() => dispatch(setSelectedSearchText(subject.name))}
//             //          onMouseEnter={() => dispatch(setSearchText(subject.name))}
//             //        // onMouseLeave={()=> selectedSearchText ? null: dispatch(setSearchText(""))}
//             //        >
//             //          {subject.icon} <span>{subject.name}</span>
//             //        </button>
//             //      ))}
//             //    </div>
//               </div></Container></> */}
//                 </>
//               ) : (
//                 <>
//                   {/* <div
//                     className={`filter-sidebar ${showFilter ? "active" : ""}`}
//                   >
//                     <div className="filter-card">
//                       <h3 className="filter-head">Subjects</h3>
//                       <Form.Check
//                         type="checkbox"
//                         id="subject1"
//                         className="filter-radio"
//                         label="Vastu Shastra"
//                         checked={checkedCheckboxes.includes("subject1")}
//                         onChange={() => handleCheckboxChange("subject1")}
//                       />
//                       <Form.Check
//                         type="checkbox"
//                         id="subject2"
//                         className="filter-radio"
//                         label="Vedic Palmistry"
//                         checked={checkedCheckboxes.includes("subject2")}
//                         onChange={() => handleCheckboxChange("subject2")}
//                       />
//                     </div>
//                     <div className="filter-card">
//                       <Form.Check
//                         type="checkbox"
//                         id="subject3"
//                         className="filter-radio"
//                         label="Guides"
//                         checked={checkedCheckboxes.includes("subject3")}
//                         onChange={() => handleCheckboxChange("subject3")}
//                       />
//                       <Form.Check
//                         type="checkbox"
//                         id="subject4"
//                         className="filter-radio"
//                         label="Teachers"
//                         checked={checkedCheckboxes.includes("subject4")}
//                         onChange={() => handleCheckboxChange("subject4")}
//                       />
//                       <Form.Check
//                         type="checkbox"
//                         id="subject5"
//                         className="filter-radio"
//                         label="Mentors"
//                         checked={checkedCheckboxes.includes("subject5")}
//                         onChange={() => handleCheckboxChange("subject5")}
//                       />
//                       <Form.Check
//                         type="checkbox"
//                         id="subject6"
//                         className="filter-radio"
//                         label="Vedic Gurus"
//                         checked={checkedCheckboxes.includes("subject6")}
//                         onChange={() => handleCheckboxChange("subject6")}
//                       />
//                     </div>
//                     <div className="filter-card">
//                       <h3 className="filter-head">Class mode</h3>
//                       <Form.Check
//                         type="checkbox"
//                         id="subject7"
//                         className="filter-radio"
//                         label="Virtual"
//                         checked={checkedCheckboxes.includes("subject7")}
//                         onChange={() => handleCheckboxChange("subject7")}
//                       />
//                       <Form.Check
//                         type="checkbox"
//                         id="subject8"
//                         className="filter-radio"
//                         label="Real time"
//                         checked={checkedCheckboxes.includes("subject8")}
//                         onChange={() => handleCheckboxChange("subject8")}
//                       />
//                     </div>
//                     <div className="button-list d-flex justify-content-between">
//                       <Button
//                         variant="transparent"
//                         className="clear-btn"
//                         onClick={handleClearAll}
//                       >
//                         Clear all
//                       </Button>
//                       <Button variant="transparent" className="apply-btn">
//                         Apply
//                       </Button>
//                     </div>
//                   </div> */}
//                   <div>
//                     <h2 className="heading-explore">Explore Vedic Guides</h2>
//                     <p className="result-text">
//                       {state?.error ? 0 : dataProviders?.length} Guides
//                       Available
//                     </p>
//                   </div>
//                   <div className="button-list d-flex align-items-start">
//                     <Button
//                       variant="transparent"
//                       className="filter-btn me-3 shadow-none"
//                       onClick={handleShow}
//                     >
//                       <img src={filterIcon} /> Filter
//                     </Button>
//                     <Button variant=" filter-btn transparent shadow-none">
//                       Sort
//                       <img src={sorticon} onClick={handleSort} />
//                     </Button>
//                   </div>
//                 </>
//               )}
//             </div>
//             {(error as any)?.data && (
//               <div className="d-flex mt-4 justify-content-center">
//                 {(error as any)?.data?.message}!
//                 <span className="ms-2 similarmatches">
//                   {" "}
//                   Similar Matches are
//                 </span>{" "}
//               </div>
//             )}
//             <div className="cards-wrapper">
//               {loading || isLoading ? (
//                 <SpinnerSecondary wrapperClass="spin-loading" />
//               ) : state?.error ? (
//                 state?.error?.data?.message
//               ) : (
//                 <>
//                   <ProviderCards
//                     data={finalData}
//                     key={""}
//                     sortBy={asc}
//                     searchError={(error as any)?.data}
//                   />
//                 </>
//               )}
//             </div>
//           </Container>
//           {/* {
//       isLoading && <div>
//         <Spinner/>
//       </div>
//     } */}
//         </div>
//         <div className=" d-flex justify-content-center align-items-center ">
//           {pageLoading && <Spinner />}
//         </div>
//       </section>
//     </>
//   )
// }

// export default ExploreMoreWrapper
import React from "react"

const ExploreMore = () => {
  return <div>ExploreMore</div>
}

export default ExploreMore
