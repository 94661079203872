import { Container, Row, Col, Form, Button, Stack } from "react-bootstrap"
import sendIcon from "../../assets/img/send-icon.svg"
import styles from "./ChatUi.module.css"
import ChatDialogues from "./ChatDialogues"
import PaperClipIcon from "../../assets/img/paper-clip.svg"

const ChatArea = () => {
  return (
    <div
      className={`${styles["chat-area"]} p-2 p-lg-3 pt-4 mb-2 bg-white ${styles["rounded-xl"]} h-100`}
    >
      <ChatDialogues />
      <Row>
        <Col>
          <div
            className={`position-relative m-auto my-1 ${styles["message-field-group"]}`}
          >
            <Form.Control
              type="text"
              className={`${styles["message-field"]}`}
              placeholder="Type a message"
            />
            <button className={`btn border-0 ${styles["emoji-btn"]}`}>
              😊
            </button>
            <button className={`btn ${styles["files-btn"]}`}>
              <img src={PaperClipIcon} />
            </button>
          </div>
        </Col>
        <Col xs="auto" className="d-flex align-items-center ps-0">
          <button
            className={`${styles["send-btn--type-2"]}`}
          >
            <img src={sendIcon} />
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default ChatArea
