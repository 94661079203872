import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { fetchStepNumber } from "../stepApi"
import { api } from "../../Auth/authApi"
import { providerDashboardApi } from "../../featProviderDashboard/FeatProviderApi"
interface stepUserData {}

export interface funnelState {
  funnelStepperCount: number
  stepCount: number
  activeStep: number
  status: "idle" | "loading" | "failed"
  service: string
  vedicSubject: string
  skillSet: string
  language: string
  modeOfClass: string
  maxHourlyRate: number
  minHourlyRate: number
  errors: boolean
  maxStep: number
  subjectDetails: object
  addDetails: object
  personInfo: object
  classInfo: object
  isStepValid: boolean
}

const initialState: funnelState = {
  stepCount: 0,
  maxStep: 0,
  funnelStepperCount: 0,
  status: "idle",
  service: "",
  vedicSubject: "",
  skillSet: "",
  language: "",
  modeOfClass: "",
  maxHourlyRate: 5000,
  minHourlyRate: 0,
  errors: true,
  activeStep: 0,
  subjectDetails: {},
  addDetails: {},
  personInfo: {},
  classInfo: {},
  isStepValid: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "funnel/fetchCount",
  async (amount: number) => {
    const response = await fetchStepNumber(amount)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const decrementAsync = createAsyncThunk(
  "funnel/fetchCountDecrement",
  async (amount: number) => {
    const response = await fetchStepNumber(amount)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const SeekerFunnelSlice = createSlice({
  name: "seekerFunnel",
  initialState,
  reducers: {
    updateFunnel: (
      state: funnelState,
      {
        payload: {
          service,
          vedicSubject,
          skillSet,
          language,
          modeOfClass,
          maxHourlyRate,
          minHourlyRate,
          isStepValid,
        },
      }: PayloadAction<{ [key: string]: any }>,
    ) => {
      ;(state.service = service), (state.vedicSubject = vedicSubject)
      state.skillSet = skillSet
      state.language = language
      state.modeOfClass = modeOfClass
      state.maxHourlyRate = maxHourlyRate
      state.minHourlyRate = minHourlyRate
      state.isStepValid = isStepValid
    },

    resetFunel: (state: funnelState) => {
      state.service = ""
      state.vedicSubject = ""
      state.skillSet = ""
      state.language = ""
      state.modeOfClass = ""
      state.isStepValid = false
    },

    setActiveStep: (
      state: funnelState,
      { payload: stepNumber }: PayloadAction<number>,
    ) => {
      state.activeStep = stepNumber
    },
    setFunnelStepNumber: (
      state: funnelState,
      { payload: stepNumber }: PayloadAction<number>,
    ) => {
      state.funnelStepperCount = stepNumber
    },
    reset: (state) => {
      ;(state.activeStep = 0), (state.stepCount = 0)
    },
    setMaxStep: (state, { payload: maxStep }: PayloadAction<number>) => {
      state.maxStep = maxStep
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(
        incrementAsync.fulfilled,
        (state, { payload: step }: PayloadAction<number>) => {
          state.status = "idle"
          state.stepCount = step + 1
          state.isStepValid = false
        },
      )
      .addCase(incrementAsync.rejected, (state) => {
        state.status = "failed"
      })
    builder
      .addCase(decrementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(decrementAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.stepCount = action.payload - 1
      })
      .addCase(decrementAsync.rejected, (state) => {
        state.status = "failed"
      })
    builder.addMatcher(
      api.endpoints.providerRegister.matchFulfilled,
      (state) => {
        ;(state.activeStep = 0), (state.stepCount = 0)
        // state.user = payload.result.user;
      },
    )
    builder.addMatcher(providerDashboardApi.endpoints.providerlogin.matchFulfilled, (state) => {
      ;(state.activeStep = 0), (state.stepCount = 0)
      // state.user = payload.result.user;
    })
  },
})

export const {
  setMaxStep,
  setFunnelStepNumber,
  setActiveStep,
  reset,
  resetFunel,
  updateFunnel,
} = SeekerFunnelSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default SeekerFunnelSlice.reducer
export const funnelState = (state: RootState) => state.seekerFunnel
