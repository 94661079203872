import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Table,
  Spinner,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import reviewimage from "../../../../assets/img/card-image.png"
import "./ReceivedReview.css"
import ReviewCard from "../../commonDashboard/reviewCard/ReviewCard"
import { useProviderRecievedReviewsQuery } from "../../../../features/featProviderDashboard/FeatProviderApi"
import ReplyReview from "./ReplyReview"
import ReviewBlank from "../../Blanks/ReviewsBlank/ReviewBlank"
import { USERTYPE } from "../../../../constants"

const ReceivedReview = () => {
  const { data, isLoading } = useProviderRecievedReviewsQuery()
  const [open, setOpen] = useState(false)
  const [dataId, setDataId] = useState()
  const handleClose = (id: any) => {
    setDataId(id)
    setOpen(true)
  }
  return (
    <>
      <DashboardHeader userType={USERTYPE.PROVIDER} />
      {isLoading && (
        <div className="spin-loading">
          <Spinner />
        </div>
      )}
      {!open ? (
        <section className="my-account-sec py-3">
          <Container fluid>
            <div className="inner-account">
              <Row>
                <Col xl={7} lg={9}>
                  <div className="evolution-heading ">
                    <h3 className="evolution-heading my-3">Received reviews</h3>
                    {data?.data?.providersReview.length > 0 ? (
                      <Card>
                        <Card.Body>
                          {data?.data?.providersReview?.map(
                            (reviews: any, index: number) => {
                              return (
                                <ReviewCard
                                  key={index}
                                  id={index}
                                  name={reviews.seekerId.firstName}
                                  course={""}
                                  para={reviews.message}
                                  btnStr={"Reply"}
                                  handleClose={handleClose}
                                  userType={""}
                                />
                              )
                            },
                          )}
                        </Card.Body>
                      </Card>
                    ) : (
                      <ReviewBlank />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      ) : (
        <ReplyReview data={data} id={dataId} />
      )}
    </>
  )
}

export default ReceivedReview
