import React from "react"
import SeekerFunnelWrapper from "../../../components/wrappers/funnelWrapper/SeekerFunnelWrapper"

type Props = {}

const FeatSeekerFunnel = (props: Props) => {
  return <SeekerFunnelWrapper />
}

export default React.memo(FeatSeekerFunnel)
