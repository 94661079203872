import React from "react"
import requestBlankImg from "../../../../assets/images/requestBlankImg.svg"
import "./RequestBlank.css"
function RequestBlank() {
  return (
    <div className="Reqmain mt-4">
      <div className="requestBlankImg">
        <img src={requestBlankImg} alt="" />
      </div>
    </div>
  )
}

export default RequestBlank
