import * as React from "react"
import { ThreeDots } from "react-loader-spinner"
interface spinnerProps {
  className?:string
}

const SpinnerMain = ({className}: spinnerProps) => {
  return (
    <>
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#53DAC1"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass={className ? className : "d-flex justify-content-center align-items-center landing-spinner"}
          
        />
    </>
  )
}

export default SpinnerMain
