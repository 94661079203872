import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"
import TopNavbar from "../navbars/TopNavbar"

interface Props {}

const AppWrapper = (props: Props) => {
  return (
    <>
      <Container fluid className="px-0">
        <Row className="mx-0">
          <Col className="px-0">
            <TopNavbar navClass="fixed-top py-0" />
          </Col>
        </Row>
        <Row className="mx-0">
          <Col className="px-0">
            <Outlet />
          </Col>
        </Row>
      </Container>{" "}
    </>
  )
}

export default AppWrapper
