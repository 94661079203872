import { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import playicon from "../../../../assets/img/play-icon.svg"
// import ProviderCards from "../../../cards/providerCards"
import righticon from "../../../../assets/img/chevron-right.svg"
import { useNavigate } from "react-router-dom"
const DoneScreen = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>

      <section className="py-5">
        <Container>
          <Row className="justify-content-center py-5">
            {/* <Col lg={4}>
              <ProviderCards key={undefined} data={undefined} />
            </Col> */}
            <Col lg={6}>
              <h1 className="success-heads mt-5 pt-5">Congratulations</h1>
              <p className="para-heading">Your Ad is published</p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Eget quis dignissim ac
                vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                sagittis nulla ac luctus mus imperdiet. Tellus dui tem.
              </p>
              <div>
                <a
                  className="explore-link"
                  onClick={() => navigate("/source-dashboard")}
                >
                  Go to dashboard
                  <img src={righticon} alt="Chevron Right" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default DoneScreen
