import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Successimage from "../../../../assets/img/success.png"
import "../authComponent.css"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../../app/store"

interface successfullProps {}
const SignUpSuccess = ({}: successfullProps) => {
  const [isSeeker, setIsSeeker] = useState<boolean>(false)
  const [isProvider, setIsProvider] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const token = urlSearchParams.get("token")
  const role = urlSearchParams.get("role")
  return (
    <>
      {/* {isLoading && (
        <div>
          <Spinner />
        </div>
      )} */}
      <Modal
        show={token ? true : false}
        className="login-modal"
        size="lg"
        aria-labelledby="successful-toggle"
      >
        <Modal.Dialog className="w-100 my-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0"></Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <div className="change-password-card success-message">
                <div className="text-center">
                  <img src={Successimage} className="mb-4 lock-img" />
                  <p className="success-text"> You Signup Successfully!</p>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default SignUpSuccess
