import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import "./TeacherProfile.css"
import verifiedicon from "../../../assets/img/verified-icon.svg"
import locationicon from "../../../assets/img/location-icon.svg"
import webcam from "../../../assets/img/cam-icon.svg"
import reviewimage from "../../../assets/img/certified-image2.png"
import starimage from "../../../assets/img/star-active-icon.svg"
import cardimage from "../../../assets/img/card-image.png"
import dummy from "../../../assets/images/dummy-profile-pic.jpg"
import heartimage from "../../../assets/img/heart.svg"
import playbutton from "../../../assets/img/play-icon.svg"
import hearticon from "../../../assets/img/heart.svg"
import hearticonWhite from "../../../assets/images/heartIconWhite.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  useGetProviderDetailsMutation,
  useResendOtpMutation,
} from "../../../features/Auth/authApi"
import { useAppSelector } from "../../../app/hooks"
import { useAppDispatch } from "../../../app/store"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME } from "../../../constants"
import {
  setEmailVerification,
} from "../../../features/Auth/authSlice"
import {
  useAddToFavMutation,
  useGetFAQQuery,
  useGetSeekerFAQMutation,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { useAskFAQMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import shareicon from "../../../assets/img/share.svg"
import profileverified from "../../../assets/img/profile-verified.svg"
import { IoIosSend } from "react-icons/io"
import LoginSeeker from "../../modals/AuthComponents/loginModal/LoginSeeker"
import { toast } from "react-toastify"
interface CardProps { }
const TeacherProfile = () => {
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const id = state?.data[state?.id]?._id
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const seekerToken = useAppSelector((state) => state.auth.seekerToken)
  const user = useAppSelector((state: any) => state.auth)
  const [loggedinUser, setLoggedinuser] = useState<boolean>(false)
  const [show, setshow] = useState<boolean>(false)
  const [isHovered, setHovered] = useState(false)
  const [question, setQuestion] = useState("")
  const [isScrolled, setIsScrolled] = useState(false)
  const [questionError, setQuestionError] = useState<string>("")
  const [schedule, setSchedule] = useState<boolean>(false)
  const [profileData, setProfileData] = useState<any>([])
  const [intialLoading, setInitialLoading] = useState<boolean>(true)
  const auth = useAppSelector((state: any) => state.auth)
  const [selectedOption, setSelectedOption] = useState("realTime")


  setTimeout(() => setLoading(false), 3000)
  const [profile, { isError, error, data, isLoading }] =
    useGetProviderDetailsMutation()
  console.log(data);

  useEffect(() => {
    if (data) {
      setProfileData(data)
    }
  }, [data])
  const [
    sendOtp,
    { isLoading: otploading, error: resendError, isSuccess: resendsuccess },
  ] = useResendOtpMutation()
  const [
    addToFavourites,
    {
      data: favData,
      isError: favError,
      isLoading: favLoading,
      isSuccess: favSuccess,
    },
  ] = useAddToFavMutation()
  const [
    ask,
    {
      data: askFAQData,
      isLoading: askFAQLoading,
      isError: askFAQError,
      isSuccess,
    },
  ] = useAskFAQMutation()
  const [
    GetFAQ,
    { data: FAQdata, isSuccess: FAQSuccess, isLoading: FAQLoading },
  ] = useGetSeekerFAQMutation()

  const handleSchedule = () => {
    if (data?.data?.ifRequestAlreadyExist?.isRequestDeleted && data?.data?.ifRequestAlreadyExist?.requestSent) {
      toast.dismiss()
      toast.error("Tutor has Ignored your Request, Try again after few Days")
    }
    if (!(data?.data?.ifRequestAlreadyExist?.isRequestDeleted) && (data?.data?.ifRequestAlreadyExist?.requestSent)) {
      toast.dismiss()
      toast.error("Request alredy sent")
    }
    else {
      setSchedule(!schedule)
      if (seekerToken) {
        if (!user?.user?.isEmailVerified) {
          setshow(!show)
        } else {
          setLoggedinuser(true)
          navigate(`/schedule-session/${id}`)
        }
      } else {
        setLoggedinuser(false)
        dispatch(setModalName(MODALNAME.LOGIN))
      }
    }
  }



  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleQuestionChange = (event: any) => {
    setQuestionError("")
    setQuestion(event.target.value)
  }
  const handleEnterPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSendClick()
    }
  }

  const handleSendClick = async () => {
    if ((auth?.providerToken || auth?.seekerToken) !== null) {
      if (question !== "" && question !== undefined) {

        const res = await ask({

          adId: state?.data[state?.id]?._id,
          question: question,
        })
        if ((res as any)?.data?.message) {
          setQuestion("")
          GetFAQ({
            adId: data?.data?.adDetail?._id,
          })
        }
        else {
          if ((res as any)?.error?.data?.message) {
            setQuestion("")
            toast.dismiss()
            toast.error("Please enter your Question")
          }

        }
      } else {
        setQuestionError("Please enter your Question")
      }
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }

  const handleSendOtp = () => {
    sendOtp(user?.user?.email)
  }
  const handleToggle = async (id: any) => {
    if (user.seekerToken || user.providerToken) {
      const res = await addToFavourites(id)
      if ((res as any)?.data?.message) {
        toast.dismiss()
        toast.success((res as any)?.data?.message)
      }
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }

  useEffect(() => {
    profile(state?.data[state?.id]?._id)
  }, [state?.data[state?.id]?._id, favSuccess])
  useEffect(() => {
    if (resendsuccess) {
      setshow(!show)
      dispatch(setEmailVerification(true as any))
      dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
    }
  }, [resendsuccess])
  useEffect(() => {
    if (user?.user && schedule) {
      setLoggedinuser(false)
      handleSchedule()
    }
  }, [user?.user])

  useEffect(() => {
    if (data?.data?.adDetail?._id) {
      GetFAQ({
        adId: data?.data?.adDetail?._id,
      })
    }
  }, [data?.data?.adDetail?._id])

  useEffect(() => {
    setTimeout(() => {
      setInitialLoading(false)
    }, 3000)
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])



  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      {intialLoading ? (
        <div className="spin-loading">
          <Spinner />
        </div>
      ) : (
        <section className="explore-section section-padding teacher-explore-section">
          <div
            className={
              isScrolled
                ? "inner-teacher-drawer-card scrolled"
                : "inner-teacher-drawer-card"
            }
          >
            <div className="fav-card teacher-card">
              <div className="option-fav w-auto d-flex justify-content-end flex-column align-items-center">
                {profileData?.data?.adDetail?.profileVideo && (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <button className="play-btn">
                      <img src={playbutton} />
                    </button>
                  </div>
                )}

                <OverlayTrigger
                  key={`overlay`}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip`}>
                      {profileData?.data?.adDetail?.isFavourite
                        ? "Click to Remove from favorites!"
                        : user.providerToken || user.seekerToken
                          ? "Click to add to favorites!"
                          : "Login First"}
                    </Tooltip>
                  }
                >
                  <div
                    className="fav-icon"
                    onClick={() =>
                      handleToggle(profileData?.data?.adDetail?._id)
                    }
                    id={`fav-icon`}
                  >
                    {profileData?.data?.adDetail?.isFavourite ? (
                      <img src={hearticonWhite} alt="showWhiteHeart" />
                    ) : (
                      <img src={hearticon} alt="showWhiteHeart" />
                    )}
                  </div>
                </OverlayTrigger>
              </div>
              <div className="d-flex gap-4">
                <div className="position-relative fav-img-wrapper">
                  <div className="fav-img">
                    {isHovered ? (
                      <video muted loop controls autoPlay={true}>
                        <source
                          src={profileData?.data?.adDetail?.profileVideo}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={profileData?.data?.adDetail?.profileImage || dummy}
                      />
                    )}
                  </div>
                  {profileData?.data?.adDetail?.providerId
                    ?.isMobileNoVerified && (
                      <div className="verified-icon">
                        <img src={profileverified} />
                      </div>
                    )}
                </div>
                <div className="info-astrologer">
                  <div>
                    <div className="rating justify-content-start mb-2">
                      <img src={starimage} />
                      <span className="total-rating">
                        {" "}
                        {
                          profileData?.data?.adDetail?.providerId?.rating
                            ?.$numberDecimal
                        }
                      </span>{" "}
                      <small className="reviews">
                        ({profileData?.data?.countReviews} Reviews)
                      </small>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      {/* <div> */}
                      <div className="ast-ocp font-xl-20 font-16 fw-bold mb-lg-3 mb-0">
                        ₹{profileData?.data?.adDetail?.hourlyRate}/hr
                      </div>
                      {/* </div> */}
                      {
                        profileData?.data?.adDetail?.packages?.firstClassFree && <div className="mb-0 first-class-text">
                          1st class free
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              <a
                onClick={handleSchedule}
                className="schedule-session-btn cursor-pointer font-14 py-2"
              >
                <span className="send-icon me-2">
                  <IoIosSend />
                </span>
                Schedule a Session
              </a>
            </div>
          </div>
          <Container fluid="lg">
            <div className="inner-explore">
              <Row>
                <Col xl={4} lg={5} className="mb-lg-0 mb-4 px-lg-3 px-0">
                  <div className="inner-teacher-card">
                    <div className="fav-card teacher-card">
                      <div className="position-relative fav-img-wrapper">
                        <div className="fav-img">
                          {isHovered ? (
                            <video muted loop controls autoPlay={true}>
                              <source
                                src={profileData?.data?.adDetail?.profileVideo}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={
                                profileData?.data?.adDetail?.profileImage ||
                                dummy
                              }
                            />
                          )}
                        </div>
                        {profileData?.data?.adDetail?.providerId
                          ?.isMobileNoVerified && (
                            <div className="verified-icon">
                              <img src={profileverified} />
                            </div>
                          )}
                      </div>
                      <div className="option-fav w-auto d-flex justify-content-end flex-column align-items-center">
                        {profileData?.data?.adDetail?.profileVideo && (
                          <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            <button className="play-btn">
                              <img src={playbutton} />
                            </button>
                          </div>
                        )}

                        <OverlayTrigger
                          key={`overlay`}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip`}>
                              {profileData?.data?.adDetail?.isFavourite
                                ? "Click to Remove from favorites!"
                                : user.providerToken || user.seekerToken
                                  ? "Click to add to favorites!"
                                  : "Login First"}
                            </Tooltip>
                          }
                        >
                          <div
                            className="fav-icon"
                            onClick={() =>
                              handleToggle(profileData?.data?.adDetail?._id)
                            }
                            id={`fav-icon`}
                          >
                            {profileData?.data?.adDetail?.isFavourite ? (
                              <img src={hearticonWhite} alt="showWhiteHeart" />
                            ) : (
                              <img src={hearticon} alt="showWhiteHeart" />
                            )}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="info-astrologer text-center">
                        <div className="mb-3">
                          <h2 className="ast-name mb-3">
                            {profileData?.data?.adDetail?.providerId
                              ?.firstName +
                              " " +
                              profileData?.data?.adDetail?.providerId?.lastName}
                          </h2>
                          <div className="rating mb-0">
                            <img src={starimage} />
                            <span className="total-rating">
                              {" "}
                              {
                                profileData?.data?.adDetail?.providerId?.rating
                                  ?.$numberDecimal
                              }
                            </span>{" "}
                            <small className="reviews">
                              ({profileData?.data?.countReviews} Reviews)
                            </small>
                          </div>
                        </div>
                        <div className="extra-info-tutor">
                          <div className="d-lg-flex justify-content-between align-items-center pt-xl-3 pt-lg-2 pt-0">
                            <div className="ast-ocp font-lg-12 mb-lg-3 mb-2">
                              Vedic Subject
                            </div>
                            <div className="ast-ocp font-xl-20 font-16 fw-bold mb-lg-3 mb-0">
                              {profileData?.data?.adDetail?.vedicSubject}
                            </div>
                          </div>
                          {profileData?.data?.adDetail?.language && (
                            <div className="d-lg-flex justify-content-between align-items-start mb-xl-4 mb-lg-3 mb-0">
                              <div className="ast-ocp font-lg-12 mb-lg-3 mb-2">
                                Language
                              </div>
                              <div className="d-flex gap-1 ms-3  flex-wrap">
                                {profileData.data.adDetail.language.map(
                                  (language: string, index: number) => (
                                    <div
                                      key={index}
                                      className="ast-ocp font-xl-20 font-16 fw-bold mb-lg-3 mb-0"
                                    >
                                      {language.trim()}
                                      {index !==
                                        profileData.data.adDetail.language
                                          .length -
                                        1 && <span>,</span>}
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <a
                          onClick={handleSchedule}
                          className="schedule-session-btn cursor-pointer font-16"
                        >
                          <span className="send-icon me-2">
                            <IoIosSend />
                          </span>
                          Schedule a Session
                        </a>
                      </div>
                      {/* <div className="text-center mb-0">1st class free</div> */}
                    </div>
                  </div>
                </Col>
                {/* <Col xl={1} lg={}></Col> */}
                <Col xl={8} lg={7}>
                  <div className="ads-section teacher-ad-wrapper">
                    <div className="ad-card shadow-none rounded-0 mb-4 pt-0">
                      <h3 className="ad-heading">Services Offered</h3>
                      <div className="session-listing-pill">
                        {profileData?.data?.adDetail?.services?.map(
                          (item: any) => (
                            <span className="ses-pill-list" key={item}>
                              #{item}
                            </span>
                          ),
                        )}
                        {profileData?.data?.adDetail?.providerId?.skills.map(
                          (skills: any) => {
                            ; <span className="ses-pill-list" key={skills}>
                              #{skills}
                            </span>
                          },
                        )}
                        {profileData?.data?.adDetail?.providerId?.skills?.map(
                          (item: any) => (
                            <span className="ses-pill-list" key={item}>
                              #{item}
                            </span>
                          ),
                        )}
                      </div>
                      <div className="session-listing-pill">
                        {profileData?.data?.adDetail?.providerId?.skills?.map(
                          (skills: any) => {
                            ; <span className="ses-pill-list" key={skills}>
                              #{skills}
                            </span>
                          },
                        )}
                      </div>
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <div className="about-teacher mb-0">
                        {profileData?.data?.adDetail?.title}
                      </div>
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">
                        About{" "}
                        {profileData?.data?.adDetail?.providerId?.firstName}
                      </h3>
                      <div className="ad-text mb-0">
                        {profileData?.data?.adDetail?.aboutYou}
                      </div>
                    </div>
                    <div className="ad-card shadow-none mb-0">
                      {profileData?.data?.adDetail?.providerId
                        ?.isCredsVerified && (
                          <div className="verified-card">
                            <h3 className="ad-heading">
                              <img src={verifiedicon} className="me-2" />
                              {
                                profileData?.data?.adDetail?.providerId
                                  ?.isEmailVerified
                              }{" "}
                              <span>This is a Verified Profile</span>
                            </h3>
                          </div>
                        )}
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">About Session</h3>
                      {/* <div className="session-listing-pill mb-3">
                        {profileData?.data?.adDetail?.providerId?.skills?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}
                        {profileData?.data?.adDetail?.services?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}{" "}
                      </div> */}
                      <ul className="session-list">
                        <li>
                          <b>Session Description</b> -{" "}
                          {profileData?.data?.adDetail?.aboutClass}
                        </li>
                        {/* <li>
                          <b>Techniques Used</b> -
                        </li> */}
                      </ul>
                    </div>
                    {/* <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">Class Format</h3>
                      <div className="secondary-listing-pill">
                        {
                          <span className="second-pill-list">
                            <img src={webcam} className="me-2" />
                            {profileData?.data?.adDetail?.modeOfClass}
                          </span>
                        }
                      </div>
                    </div> */}
                    {profileData?.data?.countReviews != 0 && (
                      <div className="ad-card shadow-none rounded-0 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className="ad-heading mb-0">Reviews</h3>
                          <div className="ad-text mb-0">
                            {profileData?.data?.countReviews}
                          </div>
                        </div>
                        <div className="review-wrapper">
                          {profileData?.data?.providerReviews?.map(
                            (item: any, index: number) => (
                              <div
                                className="review-card-profile mb-3"
                                key={index}
                              >
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={reviewimage}
                                      className="me-2 review-img"
                                    />
                                    <div className="review-name">
                                      {item?.seekerId?.firstName +
                                        " " +
                                        item?.seekerId?.lastName}
                                    </div>
                                  </div>
                                  <span className="review-given d-flex align-items-center">
                                    <img src={starimage} />
                                    {item?.rating}
                                  </span>
                                </div>
                                <div className="review-text mb-0">
                                  {item?.message}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    )}

                    {profileData?.data?.recommendations && (
                      <div className="ad-card shadow-none rounded-0 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className="ad-heading mb-0">Recommendations</h3>
                        </div>
                        <div className="review-wrapper">
                          {profileData?.data?.recommendations ? (
                            <div className="review-card-profile mb-3">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={reviewimage}
                                    className="me-2 review-img"
                                  />
                                  <div className="review-name">Supreet</div>
                                </div>
                              </div>
                              <div className="review-text mb-0">
                                Lorem ipsum dolor sit amet consectetur. A nisi a
                                egestas sit faucibus. Nulla hendrerit at at
                                magna consequat ut adipiscing. Elit enim
                                placerat purus netus in. Volutpat sed fermentum
                                ornare in pellente lectus. Pretium pharetra
                                mattis eu quis.
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="text-end">
                            <a className="view-text">View All</a>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="ad-card shadow-none rounded-0 mb-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3 className="ad-heading mb-0">Modes</h3>
                              </div>
                              <Form >
                                <Row>
                                  <Col
                                    className={`real-virtual-btn mx-2 ${selectedOption === "realTime"
                                      ? "selected"
                                      : ""
                                      }`}
                                  >
                                    <div
                                      onClick={() => setSelectedOption("realTime")}
                                    >
                                      <Form.Check
                                        type="radio"
                                        id="realTime"
                                        label="Real Time"

                                        checked={selectedOption === "realTime"}
                                        onChange={() =>
                                          setSelectedOption("realTime")
                                        }
                                        style={{ paddingLeft: "0px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    className={`real-virtual-btn ${selectedOption === "virtual" ? "selected" : ""
                                      }`}
                                  >
                                    <div
                                      onClick={() => setSelectedOption("virtual")}
                                    >
                                      <Form.Check
                                        type="radio"
                                        id="virtual"
                                        label="Virtual"
                                        checked={selectedOption === "virtual"}
                                        onChange={() =>
                                          setSelectedOption("virtual")
                                        }
                                        style={{ paddingLeft: "0px" }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>

                              {selectedOption === "realTime" && (
                                <div className="secondary-listing-pill d-sm-flex gap-5">
                                  <div className="price-feature-box border-0 my-1">
                                    <div className="feature-title">GROUP</div>
                                    <div className="d-flex justify-content-between align-items-center feature-details">
                                      <p>Hourly Fee:</p>
                                      <p>
                                        <strong>
                                          {profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                            ? "₹ " +
                                            profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                              ?.hourlyFee
                                            : " Not Provided"}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="feature-toggle">
                                      <Form.Check
                                        type="switch"
                                        id="groupSwitch"
                                        label="First Class Free"
                                        className="notify-toggle mb-0 mt-2 "
                                        checked={
                                          profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.firstClassFree
                                        }
                                        style={{
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="price-feature-box border-0  my-1">
                                    <div className="feature-title">PRIVATE</div>
                                    <div className="d-flex justify-content-between align-items-center feature-details">
                                      <p>Hourly Fee:</p>
                                      <p>
                                        <strong>
                                          {" "}
                                          {profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                            ? "₹" +
                                            profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                              ?.hourlyFee
                                            : "Not Provided"}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="feature-toggle">
                                      <Form.Check
                                        type="switch"
                                        id="privateSwitch"
                                        label="First Class Free"
                                        className="notify-toggle mb-0 mt-2"
                                        checked={
                                          profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.firstClassFree
                                        }
                                        style={{
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedOption === "virtual" && (
                                <div className="secondary-listing-pill d-sm-flex gap-5">
                                  <div className="price-feature-box border-0 my-1">
                                    <div className="feature-title">GROUP</div>
                                    <div className="d-flex justify-content-between align-items-center feature-details">
                                      <p>Hourly Fee:</p>
                                      <p>
                                        <strong>
                                          {profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                            ? "₹ " +
                                            profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                              ?.hourlyFee
                                            : " Not Provided"}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="feature-toggle">
                                      <Form.Check
                                        type="switch"
                                        id="groupSwitch"
                                        label="First Class Free"
                                        className="notify-toggle mb-0 mt-2"
                                        checked={
                                          profileData?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.firstClassFree
                                        }
                                        style={{
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="price-feature-box border-0  my-1">
                                    <div className="feature-title">PRIVATE</div>
                                    <div className="d-flex justify-content-between align-items-center feature-details">
                                      <p>Hourly Fee:</p>
                                      <p>
                                        <strong>
                                          {" "}
                                          {profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                            ? "₹" +
                                            profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                              ?.hourlyFee
                                            : "Not Provided"}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="feature-toggle">
                                      <Form.Check
                                        type="switch"
                                        id="privateSwitch"
                                        label="First Class Free"
                                        className="notify-toggle mb-0 mt-2"
                                        checked={
                                          profileData?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.firstClassFree
                                        }
                                        style={{
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {profileData?.data?.adDetail.youtubeLink && (
                      <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                        <h3 className="ad-heading">
                          {profileData?.data?.adDetail?.providerId?.firstName}{" "}
                          video{" "}
                        </h3>
                        <iframe
                          width="100%"
                          height={315}
                          src={profileData?.data?.adDetail.youtubeLink}
                          title="YouTube video player"
                          className="video-ad"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        />
                      </div>
                    )}

                    <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                      <h3 className="ad-heading">Ask a Question</h3>

                      <div className="faq-section mt-2">
                        <Accordion
                          className="faq-accordion"
                          defaultActiveKey="0"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="faq-head">
                              <h6>Click to ask a question</h6>
                            </Accordion.Header>
                            <Accordion.Body className="faq-body">
                              <Form>
                                <Form.Group controlId="questionInput">
                                  <Form.Label>Your Question:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Type your question here..."
                                    value={question}
                                    onChange={handleQuestionChange}
                                    onKeyDown={handleEnterPress}
                                  />
                                  <span className="text-danger">
                                    {questionError}
                                  </span>
                                </Form.Group>

                                <Button
                                  className="mt-4 send-btn"
                                  variant="transparent"
                                  onClick={handleSendClick}
                                >
                                  {askFAQLoading ? <Spinner /> : "Send"}
                                </Button>
                              </Form>
                              {askFAQData && isSuccess && (
                                <span className="mb-2">
                                  {askFAQData?.message}
                                </span>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>

                    {FAQdata?.data.length > 0 && (
                      <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                        <h3 className="ad-heading">
                          Frequently{" "}
                          <span className="highlight-text">
                            Asked Questions
                          </span>
                        </h3>
                        {FAQdata?.data?.map((question: any, index: number) => {
                          return (
                            <div className="faq-section mt-2" key={index}>
                              <Accordion
                                className="faq-accordion"
                                defaultActiveKey="1"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="faq-head">
                                    <h6>{question.question}</h6>
                                  </Accordion.Header>
                                  {!question?.answer && (
                                    <span className="noanswer">
                                      No Answer yet
                                    </span>
                                  )}

                                  <Accordion.Body className="faq-body">
                                    {question.answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}

      <Modal
        show={show}
        onHide={() => setshow(!show)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col xl={6} lg={6}>
                  <div className="d-flex justify-content-between flex-column h-100 text-center">
                    <div>
                      <div className="verification-heading"> Verification</div>
                      <div className="get-started-text mb-1">
                        Please Verify your email to Continue
                      </div>
                    </div>

                    <div className="otp-heading mt-4">
                      <div className="resend-otp"></div>
                    </div>
                    <button
                      type="button"
                      className="send-button mt-5"
                      onClick={handleSendOtp}
                    >
                      {otploading ? <Spinner /> : "Send OTP"}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TeacherProfile
