export const data = {
  teaching: ["Guides", "Source", "Mentors", "Vedic Gurus", "Teachers"],
}
export const courses = {
  taught: ["Astrology", "Vastu Shastra", "Vedic Science", "Yoga", "Meditation"],
}
export const classFormat = {
  format: [
    {
      head: "Virtual",
      type: "radio",
      name: "classformatradio",
      id: "bywebcam",
      source: "By Webcam",
    },
    {
      head: "Real Time",
      type: "radio",
      name: "classformatradio",
      id: "byhome",
      source: "byhome",
    },
    {
      head: "Any",
      type: "radio",
      name: "classformatradio",
      id: "byplace",
      source: "Distance of travel",
    },
  ],
}
export const language = [
  "ENGLISH",
  "HINDI",
  "URDU",
  "MARATHI",
  "PUNJABI",
  "TELUGU",
]
