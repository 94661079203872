import * as React from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import star from "../../assets/img/solar_star-broken.svg"
import locationIcon from "../../assets/img/location.svg"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Form } from "react-bootstrap"
import { useAppDispatch } from "../../app/store"
import { useSearchAdvtsMutation } from "../../features/featSearchAdvts/SearchAdvtsApi"
import { useSearchLandingPageMutation } from "../../features/featSeekerDashboard/seekerDashboardApi"
import {
  setIsSelectedLocation,
  setSearchText,
  setSearchType,
  setSelectedLocation,
  setSelectedSearchText,
} from "../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppSelector } from "../../app/hooks"
import { useLocation, useNavigate } from "react-router-dom"
import { setSeekerLandingPageSearchData } from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { landingSearchBarSchema } from "../../utils/yupSchema/Schema"

interface searchProps { }

const LandingSearch = (props: searchProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { seekerToken } = useAppSelector((state: any) => state.auth)


  const dispatch = useAppDispatch()
  const [searchBar, setSearchBar] = React.useState<boolean>(false)
  const [search, { data: searchTextResponse, error, isSuccess }] =
    useSearchAdvtsMutation()
  const [
    searchAdvts,
    { data, error: searchAdvtsError, isSuccess: searchAdvtsSuccess },
  ] = useSearchLandingPageMutation()
  const {
    searchType,
    searchText,
    selectedSearchText,
    isSelectedLocation,
    selectedLocation,
  } = useAppSelector((state:any) => state.landingSearch)
  const handleSearchText = (text: string) => {
    dispatch(setSearchText(text))
    if (!selectedSearchText) search({ searchText: text })
  }

  const handleSearchType = (text: string) => {
    dispatch(setSearchType(text))
  }

  React.useEffect(() => {
    if (selectedSearchText) {
      inputRef?.current?.focus()
    }
  }, [selectedSearchText])

  React.useEffect(() => {
    if (searchText) search({ searchText: searchText })
  }, [searchText])

  const onSubmit = (details: any) => {
    const payload = {
      Location: selectedLocation,
      VedicSubject: selectedSearchText || searchText,
      teacherType: searchType,
      sortBy: "realtime-private",
      sortOrder: "ascending",
      pageNo: 1,
    }

    dispatch(
      setSeekerLandingPageSearchData({
        ...details,
        VedicSubject: selectedSearchText || searchText,
      }),
    )

    searchAdvts(payload)
  }
  const { handleSubmit, setValue, register, control } = useForm<any>({
    resolver: landingSearchBarSchema,
  })

  const handleSelect = (selectedAddress: any) => {
    geocodeByAddress(selectedAddress)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: any) => console.log("Success", latLng))
      .catch((error: any) => console.error("Error", error))
  }
  const handleChange = (newAddress: any) => {
    dispatch(setSelectedLocation(newAddress))
  }
  React.useEffect(() => {
    if (searchAdvtsSuccess || searchAdvtsError) {
      navigate("/results", { state: { error: searchAdvtsError, data } })
      window.scrollTo({
        top: 450,
        behavior: "smooth"
      });
    }
  }, [searchAdvtsSuccess, searchAdvtsError])
  
  React.useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setSearchText(""))
      dispatch(setSelectedSearchText(""))
    }
  }, [location.pathname])
  return (
    <>
      <form
        className="d-flex justify-content-center mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={
            searchBar || selectedSearchText
              ? "filter-section landing-search-bar w-100 active"
              : "filter-section landing-search-bar w-100"
          }
          onClick={() => setSearchBar(true)}
        >


          <div className="find-vedic position-relative d-flex align-items-center w-100">
            <img src={star} className="star-icon" />
            <Form.Control
              type="text"
              autoComplete="off"
              {...register("VedicSubject")}
              name="VedicSubject"
              placeholder="Find Your Vedic Expert"
              className="form-control find-field pe-2"
              value={selectedSearchText || searchText}
              onChange={(e: any) => handleSearchText(e.target.value)}
            />
            <>
              {!selectedSearchText && searchTextResponse?.length ? (
                <>
                  {searchTextResponse && (
                    <div className={`search-select-teacher `}>
                      {searchTextResponse.map((item: any) => (
                        <>
                          <p
                            className="tab text town locate choice-locate"
                            onClick={() => {
                              dispatch(setSelectedSearchText(item?.label))
                            }}
                          >
                            {item?.label}
                          </p>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          </div>

          <div className={"find-location align-items-center w-100"}>
            <img src={locationIcon} className="location-icon" />
            {selectedLocation === "Online" ||
              selectedLocation === "Around me" ? (
              <input
                {...register("Location")}
                name="Location"
                ref={inputRef}
                value={selectedLocation}
                onChange={() => dispatch(setSelectedLocation(""))}
              />
            ) : (
              // <PlacesAutocomplete
              //   onChange={handleChange}
              //   onSelect={handleSelect}
              //   value={selectedLocation}
              // >
              //   {({
              //     getInputProps,
              //     suggestions,
              //     getSuggestionItemProps,
              //     loading,
              //   }: any) => (

              <div>
                <input
                  // value={selectedLocation}
                  ref={inputRef}
                  // {...getInputProps({
                  //   placeholder: "Search Places ...",
                  //   className: "location-search-input",
                  // onClick: handleInputClick,
                  // })}
                  placeholder="Location"
                />
              </div>
              //   )}
              // </PlacesAutocomplete>
            )}
            {selectedSearchText && !selectedLocation ? (
              <div className={`search-top-town`}>
                <p
                  className="tab text town locate choice-locate"
                  onClick={() => {
                    dispatch(setSelectedLocation("Around me"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Around me
                </p>
                <p
                  className="tab text town choice-webcam"
                  onClick={() => {
                    dispatch(setSelectedLocation("Online"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Online
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-none dropdown_loc">
            <button
              type="submit"
              className="find-btn d-flex align-items-center justify-content-center"
            //   onClick={() =>
            //     onSubmit({
            //       Location: selectedLocation,
            //       VedicSubject: selectedSearchText || searchText,
            //       teacherType: searchType,
            //     })
            //   }
            >
              <img src={findSearch} className="me-md-3" />
              <span className="d-none d-md-block ">Find</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default LandingSearch
