import React, { useEffect, useState } from "react"
import {
  Form,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap"
import "./CommmonCard.css"
import { USERTYPE } from "../../../constants"
import { useDeleteAccountMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import { useAppSelector } from "../../../app/hooks"
import { useDeleteSeekerAccountMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { useLogoutMutation } from "../../../features/Auth/authApi"
import { toast } from "react-toastify"
interface CardProps {
  heading: string
  description: string
  btnStr: string
}

const CommonDeleteCard = (props: CardProps) => {  
  const [del, setDel] = useState<boolean>(false)
  const [showModal, setshowModal] = useState(false)
  const auth = useAppSelector((state: any) => state.auth)
  const [logout] = useLogoutMutation()
  const [Delete, { isSuccess, isLoading }] = useDeleteAccountMutation()
  const [DeleteSeeker, { isSuccess: success, isLoading: loading }] =
    useDeleteSeekerAccountMutation()
  const handleToggle = () => {
    setDel(!del)
  }
  const handleShowModal = () => {
    setshowModal(!showModal)
  }
  
  const handleDelete = async () => {
    if (auth?.currentUserRole === USERTYPE.PROVIDER) {
      const res = await Delete()
      if ((res as any)?.data?.message) {
        logout()
        toast.success((res as any)?.data?.message)
      }
      else {
        toast.dismiss()
        toast.error("something went wrong Please try again later")
      }

    } else {
      const res = await DeleteSeeker()
      if ((res as any)?.data?.message) {
        logout()
        toast.success((res as any)?.data?.message)
      }
      else {
        toast.dismiss()
        toast.error("something went wrong Please try again later")
      }
    }
  }

  return (
    <div>
      <div className="block-card account-block-card mb-4">
        <h2 className="card-heading">{props.heading}</h2>
        <p className="bank-details mb-4">{props.description}</p>

        <Form.Check
          type="switch"
          id="deleteaccunt"
          className="notify-toggle mb-3"
          label="Delete Account"
          onClick={handleToggle}
        />

        <Button
          variant="Secondary"
          disabled={!del}
          className={
            del
              ? " btn btn-primary delete-account-btn-active"
              : " btn btn-secondary delete-account-btn"
          }
          onClick={handleShowModal}
        >
          {props.btnStr}
        </Button>
      </div>
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title className="delete-heading"> Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col>
                  <div className="d-flex justify-content-between flex-column h-70 text-center">
                    <div>
                      <p className="get-started-text mb-1">
                        Are you sure to delete your Account Permanently !
                      </p>
                      {isLoading ||
                        (loading && (
                          <div>
                            <Spinner />
                          </div>
                        ))}
                    </div>
                    <div className=" d-flex justify-content-center mt-3">
                      {" "}
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={handleShowModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="Delete-button"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CommonDeleteCard
