
export const handlePaymentPackages = async (
  event: any,
  key: any,
  id: any,
  data: any,
  paymentVerify: any,
  // requestSuccess: boolean,
) => {
  event.preventDefault()
  try {
    var options = {
      key: key?.key,
      amount: data?.data?.order.amount,
      currency: "INR",
      name: "Hitsho corp",
      description: "buying product",
      image:
        "https://zeevector.com/wp-content/uploads/Versace-Symbol-PNG@zeevector.com_.png",

      order_id: data?.data?.order.id,
      handler: function (payment: any) {
        paymentVerify({
          data: {
            razorpay_payment_id: payment.razorpay_payment_id,
            razorpay_signature: payment.razorpay_signature,
          },
          id: data?.data?.order.id,
        }).then((isSuccess: any) => {
          if (isSuccess) {
            window.location.href = `/source-dashboard`
          } else {
            console.log("Payment verification failed")
          }
        })
      },
      prefill: {
        name: "John Doe",
        email: "John.Doe@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }
    //@ts-ignore
    var razor = new window.Razorpay(options)
    razor.open()
  } catch (error) {
    console.error("Error sending amount:", error)
  }
}
