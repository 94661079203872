import { Col, Container, Row } from "react-bootstrap"
import starimage from "../../../assets/img/star-active-icon.svg"

import "./ScheduleCard.css"
import { useAppSelector } from "../../../app/hooks"
import dummy from "../../../assets/images/dummy-profile-pic.jpg"

interface providerProps {
  data: any // Adjust the type accordingly
  key: string | number // Adjust the type accordingly
  seekerData: any
  types: string
}

const ScheduleCard = ({ data, seekerData, types }: providerProps) => {
  const ProviderData = useAppSelector((state: any) => state.providerSlice)
  
  return (
    <>
      <div className="schedule-card">
        <Container fluid="lg">
          <Row>
            <Col lg={12}>
              <div className="inner-teacher-card">
                <div className="fav-card bg teacher-card schedule-card-wrapper">
                  <div className="fav-img-wrapper position-relative mb-2">
                    <div className="fav-img position-relative">
                      <img
                        src={
                          seekerData?.adDetail?.profileImage ||
                          data?.data?.allAdvertisements[ProviderData?.id]
                            ?.profileImage ||
                          dummy
                        }
                      />
                    </div>
                    {types == "seeker" ? (
                      <a className="price-info w-auto">
                        <span className="price-text">
                          ₹ {seekerData?.adDetail?.hourlyRate}
                        </span>
                        <span className="hour-text">/hr</span>
                      </a>
                    ) : (
                      data?.data?.allAdvertisements[ProviderData.id]
                        ?.hourlyRate && (
                        <a className="price-info w-auto">
                          {
                            <>
                              <span className="price-text">
                                <span>₹ </span>
                                {
                                  data?.data?.allAdvertisements[ProviderData.id]
                                    ?.hourlyRate
                                }
                              </span>
                              <span>/hr</span>
                            </>
                          }
                        </a>
                      )
                    )}
                  </div>
                  <div className="info-astrologer">
                    <div className="text-center">
                      {types == "seeker" ? (
                        <h2 className="ast-name mb-1">
                          {seekerData?.adDetail?.providerId?.firstName +
                            " " +
                            seekerData?.adDetail?.providerId?.lastName}
                        </h2>
                      ) : (
                        <h2 className="ast-name mb-1">
                          {(ProviderData?.user?.data?.allAdvertisements[0]
                            ?.providerId.firstName ||
                            ProviderData?.user?.allAdvertisements[0]?.providerId
                              .firstName) +
                            " " +
                            (ProviderData?.user?.data?.allAdvertisements[0]
                              ?.providerId.lastName ||
                              ProviderData?.user?.allAdvertisements[0]
                                ?.providerId.lastName)}
                        </h2>
                      )}

                      <div className="rating mb-3">
                        <img src={starimage} />
                        {types == "seeker" ? (
                          <span className="total-rating">
                            {
                              seekerData?.adDetail?.providerId?.rating
                                ?.$numberDecimal
                            }
                          </span>
                        ) : (
                          <span className="total-rating">
                            {
                              data?.data?.allAdvertisements[ProviderData?.id]
                                ?.providerId.rating.$numberDecimal
                            }
                          </span>
                        )}
                        {types == "seeker" ? (
                          <small className="reviews">
                            ({seekerData?.countReviews} Reviews)
                          </small>
                        ) : (
                          <span>
                            <small className="reviews">{`(${data?.data?.totalReviews} Reviews)`}</small>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-center mb-0">1st class free</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ScheduleCard
