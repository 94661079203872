import React from "react"
import ProviderFunnelWrapper from "../../../components/wrappers/providerFunnelWrapper/ProviderFunnelWrapper"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"

type Props = {}

const FeatProviderFunnel = (props: Props) => {
  const providerToken = useAppSelector((state) => state.auth.providerToken)

  return !providerToken ? (
    <Navigate to="/" replace />
  ) : (
    <ProviderFunnelWrapper />
  )
}

export default FeatProviderFunnel
