import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  CardImg,
  CardFooter,
  CardBody,
  CardSubtitle,
} from "react-bootstrap"
import "./SelectPath.css"
import learnimage from "../../../assets/img/explore-learn.jpg"
import guideimage from "../../../assets/img/meditation.png"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../app/store"
import { reset } from "../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { resetProviderFunel } from "../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useAppSelector } from "../../../app/hooks"
import { MODALNAME, USERTYPE } from "../../../constants"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { setCurrentUser } from "../../../features/Auth/authSlice"
const SelectPath = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const auth = useAppSelector((state: any) => state.auth)
  const handleClick = () => {
    if (auth.providerToken !== null) {
      dispatch(resetProviderFunel())
      navigate("/journey-source")
    } else {
      dispatch(setCurrentUser(USERTYPE.PROVIDER))
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }
  useEffect(() => {
    if (auth.providerToken !== null) {
      dispatch(resetProviderFunel())
      navigate("/journey-source")
    }
  }, [auth.user])

  return (
    <>
      <section className="my-account-sec min-h-100vh d-flex justify-content-center align-items-center">
        <Container>
          <div className="inner-account position-relative">
            <Row className="justify-content-center">
              <Col  xl={6} lg={8}>
                <div>
                  <div className="p-1 p-lg-0">
                    <h3 className="complete-para mb-1 heading-secondary">
                      Select any one
                    </h3>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet consectetur. Vitae in aenean
                      proin urna vestibulum. Consectetur sed at viverra aliquam
                      nunc lacinia semper.
                    </p>
                    <Row>
                      <Col xs={10} sm={6} className="mb-md-0 mb-4 mx-auto">
                        <Card
                          className="learn-card"
                          onClick={() => {
                            navigate("/journey-seeker"), dispatch(reset())
                          }}
                        >
                          <div className="learn-card-heading">
                            <h2>Seeker</h2>
                          </div>
                          <CardImg
                            className="learn-imgbx"
                            src={learnimage}
                          ></CardImg>
                          <CardBody className="learn-content">
                            <h2>Learn and Explore</h2>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={10} sm={6} className="mx-auto">
                        <Card className="learn-card">
                          <div className="learn-card-heading">
                            <h2>Tutor</h2>
                          </div>
                          <CardImg
                            className="learn-imgbx"
                            src={guideimage}
                            onClick={handleClick}
                          ></CardImg>
                          <CardBody className="learn-content">
                            <h2>Guide and Earn</h2>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SelectPath
