// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { Auth, GoogleAuthProvider, getAuth, signInWithCustomToken } from "firebase/auth"
import { getDatabase } from "@firebase/database"
import "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {  apiKey: "AIzaSyBS9w1esLY8Zk08pBdpdCbv9HWTXxr9_tY",
authDomain: "vedichat-65bb3.firebaseapp.com",
projectId: "vedichat-65bb3",
storageBucket: "vedichat-65bb3.appspot.com",
messagingSenderId: "947089950725",
appId: "1:947089950725:web:47a19782a634ebb456b46c",
measurementId: "G-97L3R6M4S7"}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore()
export const database = getDatabase(app)
export const auth = getAuth(app)
export const  signinChat = async (token:string) => await signInWithCustomToken(auth, token)
