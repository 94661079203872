import React, { useEffect, useRef, useState } from "react"
import {
  Container,
  Card,
  Button,
  Form,
  InputGroup,
  FormControl,
  Nav,
  Row,
  Col,
  Tab,
  Spinner,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import filtericon from "../../../../assets/img/filter-icon.svg"
import searchicon from "../../../../assets/img/icon-search.svg"
import sorticon from "../../../../assets/img/sort.svg"
import userImg from "../../../../assets/img/card-image.png"
import callIcon from "../../../../assets/img/call-icon.svg"
import sendIcon from "../../../../assets/img/send-icon.svg"
import acceptIcon from "../../../../assets/img/acceptedIcon.svg"
import pendingIcon from "../../../../assets/img/pendingIcon.svg"
import rejectIcon from "../../../../assets/img/rejectedIcon.svg"
import "./RequestScreen.css"
import { useAppSelector } from "../../../../app/hooks"
import {
  useAcceptDeleteRequestMutation,
  useAcceptRejectRequestMutation,
  useChatWriteMutation,
  useProviderAccountDetailsQuery,
  useProviderSearchMutation,
} from "../../../../features/featProviderDashboard/FeatProviderApi"
import RequestBlank from "../../Blanks/RequestBlank/RequestBlank"
import {
  useGetMessagesQuery,
  usePostMessageMutation,
} from "../../../../features/chat/ChatApi"
import EmojiPicker from "emoji-picker-react"
import ConfirmModal from "./confirmModal"
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDocs,
} from "firebase/firestore"
import { db } from "../../../../app/firebase"
import { Timestamp } from "firebase/firestore"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { FiArrowLeft } from "react-icons/fi"
import { useDispatch } from "react-redux"
import {
  setAcceptRejectRequest,
  setNewMessageSent,
} from "../../../../features/featSeekerDashboard/seekerDashboardSlice"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import {
  setProviderNewMsgNotification,
  setactiveRequestTab,
} from "../../../../features/featProviderDashboard/FeatProviderDashboardSlice"
import { toast } from "react-toastify"
import { useSeekerSearchMutation } from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import deleteduserimg from "../../../../assets/img/deleteduserimg.png"
const RequestScreen = () => {
  const dispatch = useDispatch()
  const { data: providerProfileData } = useProviderAccountDetailsQuery()
  const data = useAppSelector((state: any) => state.seekerDashboardSlice)
  const [messages, setMessages] = useState<any[]>([])
  const auth = useAppSelector((state: any) => state.auth)
  const [requestBtn, SetRequestBtn] = useState<string>("")
  const [msg, setMsg] = useState<string>("")
  const [finalData, setFinalData] = useState([])
  const [accessptReject, setAcceptReject] = useState<boolean>(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [newMsgSent, setnewMsgSent] = useState<boolean>(false)
  const [handleTitle, setHandleTitle] = useState<string>("")
  const handleEmojiClick = (event: any) => {
    setMsg((prevMessage) => prevMessage + event.emoji)
  }
  const [search, { data: searchedData, isLoading: Searchloading }] =
    useProviderSearchMutation()
  const [action, { data: reqData, isLoading: loading, isSuccess: success }] =
    useAcceptDeleteRequestMutation()
  const [postMsg, { data: writeMsgData, isSuccess, isLoading }] =
    useChatWriteMutation()
  const [
    postMessage,
    { data: sentData, isSuccess: messagePostSuccess, error: messageSendError },
  ] = usePostMessageMutation()
  const handleRequest = async (req: string) => {
    SetRequestBtn(req)
    const resposne = await action({
      requestId: data?.requestData?.data?._id,
      action: req === "Accepted" ? "Accept" : "Delete",
    })
    if (resposne) {
      if (req === "Accepted") {
        dispatch(setAcceptRejectRequest(true as any))
        toast.success("Request Accepted Successfully")
      } else {
        dispatch(setAcceptRejectRequest(true as any))
        toast.success("Request Rejected Successfully")
      }
      dispatch(setactiveRequestTab(requestBtn as any))
    }
    setAcceptReject(false)
  }
  const handleChange = (e: any) => {
    setMsg(e.target.value)
  }
  //To send  message
  const handleSendMessage = async () => {
    if (msg.trim() !== "") {
      try {
        await postMessage({
          chatId: data?.requestData?.data?._id,
          message: msg,
          userId: auth?.user?._id,
          username: providerProfileData?.data?.firstName,
        })
        dispatch(setNewMessageSent(!data?.newMessageSent as any))
        setMsg("")
      } catch (error) {
        console.error("Error sending message:", error)
      }
    }
  }
  // Sending Messages on Enter
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }
  // Emoji open
  const openEmojiPicker = () => {
    if (data?.requestData?.data?.requestStatus === "Accept") {
      setShowEmojiPicker(!showEmojiPicker)
    }
  }
  const messageDate = (createdAt: any) => {
    if (createdAt) {
      const date = createdAt.toDate()
      return {
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
      }
    } else {
      return {
        date: "N/A",
        time: "N/A",
      }
    }
  }

  const closeConfirmModal = () => {
    setAcceptReject(false)
  }

  useEffect(() => {
    dispatch(setNewMessageSent(false as any))
    setMsg("")
    const messagesCollection = collection(db, "messages")
    const q = query(messagesCollection, orderBy("createdAt"))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedMessages = snapshot.docs
        .filter((doc) => doc.data().chatId === data?.requestData?.data?._id)
        .map((doc) => {
          const data = doc.data()
          const createdAtTimestamp = data.createdAt as Timestamp
          const formattedDate = messageDate(createdAtTimestamp)
          return { ...data, formattedDate }
        })
      if (updatedMessages.length > messages.length) {
        dispatch(setNewMessageSent(true as any))
      }
      setMessages(updatedMessages)
    })

    return () => unsubscribe()
  }, [data?.requestData?.data?._id])

  return (
    <>
      {(isLoading || loading) && (
        <div className="spin-loading">
          <SpinnerSecondary />
        </div>
      )}
      {data?.requestData?.data !== undefined ? (
        <div className="block-card mb-4 py-4 message-container-block">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-4 pxlg-5 px-4 py-3 chat-header-wrapper">
              <div className="d-flex align-items-center gap-3">
                {/* <button className="chat-back-btn d-lg-none d-flex">
                  <FiArrowLeft />
                </button> */}
                <div
                  className={`chat-profile  ${
                    data?.requestData?.status === "Delete"
                      ? "deletedrequestprofile"
                      : ""
                  }`}
                >
                  <img
                    src={
                      data?.requestData?.data?.seekerId?.profilePic ||
                      (data?.requestData?.data?.requestStatus === "Delete"
                        ? deleteduserimg
                        : dummy)
                    }
                  />
                  <span
                    style={{
                      textDecoration:
                        data?.requestData?.data?.requestStatus === "Delete"
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {" "}
                    {data?.requestData?.data?.seekerId?.firstName}{" "}
                  </span>
                </div>
              </div>
              <Button
                className={
                  data?.requestData?.data?.requestStatus === "Accept"
                    ? "reqStatusBtnAccept gap-2"
                    : data?.requestData?.data?.requestStatus === "Pending"
                    ? "reqStatusBtnPending"
                    : "reqStatusBtnRejected"
                }
              >
                {data?.requestData?.data?.requestStatus === "Accept"
                  ? "Accepted"
                  : data?.requestData?.data?.requestStatus === "Pending"
                  ? "Pending"
                  : "Rejected"}
                {data?.requestData?.data?.requestStatus === "Accept" ? (
                  <img src={acceptIcon} className="statusIcon ms-2" />
                ) : data?.requestData?.data?.requestStatus === "Pending" ? (
                  <img src={pendingIcon} className="statusIcon ms-2" />
                ) : (
                  <img src={rejectIcon} className="statusIcon ms-2" />
                )}
              </Button>
            </div>
            <div className="allmessage-container mt-4 px-lg-5 px-4">
              <div
                className={`descriptioncontainer mb-4 p-3  ${
                  data?.requestData?.data?.requestStatus === "Accept"
                    ? "acceptedRequest"
                    : data?.requestData?.data?.requestStatus === "Pending"
                    ? "pendingRequest"
                    : "rejectedRequest"
                }`}
              >
                <div className="d-flex justify-content-between">
                  <div
                    className={`spantext   ${
                      data?.requestData?.data?.requestStatus === "Accept"
                        ? "acceptedspan"
                        : data?.requestData?.data?.requestStatus === "Pending"
                        ? "pendingspan"
                        : "rejectedspan"
                    }`}
                  >
                    {data?.requestData?.data?.seekerId?.firstName} would like to
                    take a class with you
                  </div>
                  <div
                    className="d-flex"
                    key={data?.requestData?.data?.seekerId?._id}
                  >
                    {data?.requestData?.data?.requestStatus === "Accept" ||
                    data?.requestData?.data?.requestStatus === "Delete" ? (
                      ""
                    ) : (
                      <>
                        <Button
                          variant="transparent"
                          className="accept-btn"
                          onClick={() => {
                            setAcceptReject(true), setHandleTitle("Accept")
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="transparent"
                          className="decline-btn"
                          onClick={() => {
                            setAcceptReject(true), setHandleTitle("Reject")
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div>
                  {data?.requestData?.data?.sessionForWhom && (
                    <button
                      className={`call-pill text-center d-inline-block ${
                        data?.requestData?.data?.requestStatus === "Accept"
                          ? "acceptedcallpill"
                          : data?.requestData?.data?.requestStatus === "Pending"
                          ? "pendingcallpill"
                          : "rejectedcallpill"
                      } `}
                    >
                      <i className="fa-solid fa-location-dot me-2"></i>
                      {data?.requestData?.data?.sessionForWhom}
                    </button>
                  )}
                  {data?.requestData?.data?.adId?.vedicSubject && (
                    <button
                      className={`call-pill text-center d-inline-block ${
                        data?.requestData?.data?.requestStatus === "Accept"
                          ? "acceptedcallpill"
                          : data?.requestData?.data?.requestStatus === "Pending"
                          ? "pendingcallpill"
                          : "rejectedcallpill"
                      }`}
                    >
                      {data?.requestData?.data?.adId?.vedicSubject}
                    </button>
                  )}

                  {data?.requestData?.data?.sessionPreference && (
                    <button
                      className={`call-pill text-center d-inline-block ${
                        data?.requestData?.data?.requestStatus === "Accept"
                          ? "acceptedcallpill"
                          : data?.requestData?.data?.requestStatus === "Pending"
                          ? "pendingcallpill"
                          : "rejectedcallpill"
                      }`}
                    >
                      <i className="fa-solid fa-video me-2"></i>
                      {data?.requestData?.data?.sessionPreference}
                    </button>
                  )}
                </div>
              </div>
              <div className="d-block">
                <div className="recive-msg">
                  <h2 className="username">
                    {data?.requestData?.data?.seekerId?.firstName}
                  </h2>
                  <p>{data?.requestData?.data?.requestMessage}</p>
                </div>
              </div>
              {messages &&
                messages.map((receivedMessage: any ,index:number) => (
                  <div className="d-block" key={index}>
                    <div
                      key={receivedMessage?.id}
                      className={`${
                        auth.user?._id === receivedMessage.userId
                          ? "sent-msg d-flex -justify-content"
                          : "recive-msg"
                      }`}
                    >
                      <div>
                        <div className="d-flex justify-content-between gap-5">
                          <h2 className="username">
                            {receivedMessage.username}
                          </h2>{" "}
                          {
                            <span className="senttext">
                              {receivedMessage?.formattedDate.date}{" "}
                              {receivedMessage?.formattedDate.time}
                            </span>
                          }
                        </div>
                        <p className="messageText">{receivedMessage.message}</p>
                        {auth.user?._id === receivedMessage.userId && (
                          <span className="senttext">sent</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="position-relative message-field-group mx-lg-5 mx-4 mb-4">
            <Form.Control
              type="text"
              value={msg}
              className="message-field"
              placeholder="Type a message"
              onChange={handleChange}
              onClick={() => setShowEmojiPicker(false)}
              onKeyPress={handleKeyPress}
              // disabled={data?.requestData.reqStatus === "Accept" ? false : true}
              disabled={
                data?.requestData?.data?.requestStatus === "Pending" ||
                data?.requestData?.data?.requestStatus === "Delete" ||
                data?.requestData?.data?.requestStatus === "Rejected"
              }
            />
            <div>
              <button
                onClick={openEmojiPicker}
                className="emoji-btn btn btn border-0"
                disabled={
                  data?.requestData?.class === "Pending" ||
                  data?.requestData?.class === "Delete" ||
                  data?.requestData?.class === "Rejected"
                }
              >
                😊
              </button>
              <button
                className={`sent-btn btn ${msg === "" ? "grey-btn" : ""}`}
                // onClick={handleSubmit}
                onClick={handleSendMessage}
                disabled={msg === "" ? true : false}
              >
                <img src={sendIcon} />
              </button>
            </div>
            {showEmojiPicker && (
              <EmojiPicker
                className="emoji-picker-custom"
                onEmojiClick={handleEmojiClick}
              />
            )}
          </div>
        </div>
      ) : (
        <RequestBlank />
      )}
      <ConfirmModal
        show={accessptReject}
        handleClose={closeConfirmModal}
        acceptRequest={handleRequest}
        title={handleTitle}
      />
    </>
  )
}

export default RequestScreen
