import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import {
  Navigate,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom"
import TopNavbar from "../../navbars/TopNavbar"
import { Dispatch } from "@reduxjs/toolkit"
import { seekerFunnelRoutes } from "../../../Routes"
import { useAppSelector } from "../../../app/hooks"
import { useAppDispatch } from "../../../app/store"
import arrowLeftArrow from "../../../assets/img/arrow-left-white.svg"
import arrowRightArrow from "../../../assets/img/arrow-right-white.svg"
import greenLeftArrow from "../../../assets/img/arrow-left-green.svg"
import greenRightArrow from "../../../assets/img/arrow-right-green.svg"
import {
  decrementAsync,
  incrementAsync,
  setActiveStep,
  setFunnelStepNumber,
  updateFunnel,
} from "../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useSearchSortProvidersMutation } from "../../../features/Auth/authApi"
import SeekerStepper from "../../funnels/funnelStepper/SeekerStepper"
type Props = {}

const SeekerFunnelWrapper = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector((state: any) => state.auth)
  const dispatch: Dispatch<any> = useAppDispatch()
  dispatch(setFunnelStepNumber(seekerFunnelRoutes.length - 1))
  const {
    status,
    stepCount,
    activeStep,
    funnelStepperCount,
    service,
    vedicSubject,
    skillSet,
    language,
    modeOfClass,
    isStepValid,
  } = useAppSelector((state) => state.seekerFunnel)
  const handleStepperCounts = (activePath: string) => {
    const seekerpaths = seekerFunnelRoutes.map(
      (item: { path: string }) => item.path,
    )

    const index = seekerpaths.indexOf(activePath)
    return index !== -1 ? index : 0 // Return -1 if the item is not found
  }
  const stepperNavigator = () => {
    const routeObj: any = seekerFunnelRoutes[stepCount]
    const path = routeObj?.path ? routeObj?.path : "/journey-seeker"
    navigate(path)
  }

  useEffect(() => {
    stepperNavigator()
  }, [stepCount])

  useEffect(() => {
    dispatch(setActiveStep(handleStepperCounts(location.pathname)))
  }, [location.pathname])

  // for api searchShort
  const [getProviderData, { isError, error, data, isSuccess }] =
    useSearchSortProvidersMutation()

  const submit = () => {
    const payload = { service, vedicSubject, skillSet, language, modeOfClass }
    getProviderData(payload)
  }
  useEffect(() => {
    if (data?.message || error) {
      navigate("/journey-success", { state: { error } })
    }
  }, [isSuccess, error])

  useEffect(() => {
    if (user.providerToken) {
      navigate("/source-dashboard")
    }
  }, [user])
  const handleDecrement = () => {
    dispatch(decrementAsync(activeStep))
    dispatch(updateFunnel({ isStepValid: false }))
  }
  return (
    <Container fluid className="">
      <Row className="mx-0">
        <Col className="px-0">
          <TopNavbar navClass="" />
        </Col>
      </Row>
      <div className="min-h-100 d-flex flex-column">
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-center align-items-center section-padding">
              <SeekerStepper
                stepCount={funnelStepperCount}
                activeStep={activeStep}
              />
            </div>
          </Col>
        </Row>

        <Row className="mx-0 flex-1 h-100 align-items-center">
          <Col className="px-0">
            {status === "loading" ? (
              <div className="text-center ">
                <Spinner variant="grow" />
              </div>
            ) : (
              <Outlet />
            )}
          </Col>
        </Row>
      </div>
      <div className="funnel-navigation">
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div className="btn-contain w-auto">
                {stepCount !== 0 && (
                  <Button
                    onClick={handleDecrement}
                    disabled={stepCount <= 0}
                    className="schedule-btn flex-none bg"
                  >
                    <img src={arrowLeftArrow} className="white-arrow" />
                    <img src={greenLeftArrow} className="green-arrow" />
                  </Button>
                )}
              </div>
              {/* <div className="mx-5">
                {activeStep === 4 ? (
                  ""
                ) : (
                  <Button
                    variant="transparent"
                    className="skip-text"
                    // onClick={submit}
                    onClick={() => dispatch(incrementAsync(activeStep))}
                  >
                    SkipPP
                  </Button>
                )}
              </div> */}
              <div className="btn-contain w-auto ms-5">
                {location.pathname === "/journey-seeker/seeker-lesson" ? (
                  <Button
                    type="submit"
                    className="schedule-btn2"
                    onClick={submit}
                    // disabled={
                    //   service === "" &&
                    //   vedicSubject === "" &&
                    //   skillSet === "" &&
                    //   language === "" &&
                    //   modeOfClass === ""
                    //     ? true
                    //     : false
                    // }
                    disabled={!isStepValid}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    onClick={() => dispatch(incrementAsync(activeStep))}
                    disabled={
                      stepCount >= seekerFunnelRoutes.length || !isStepValid
                    }
                    className="schedule-btn flex-none"
                  >
                    <img src={arrowRightArrow} className="white-arrow" />
                    <img src={greenRightArrow} className="green-arrow" />
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default React.memo(SeekerFunnelWrapper)
