import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ProfileAccountImg from "../../assets/images/profile-a.png"
import styles from "./ChatUi.module.css"

const ChatDialogues = () => {
  return (
    <div className={`${styles["chat-dialogues"]}`}>
      <div className={` ${styles["user-message"]} ${styles["message"]}`}>
        <div className={` ${styles["message-content"]}`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. minima enim
          aut dicta vel qui!
        </div>
      </div>
      <div className={`d-flex align-items-center ${styles["chat-message"]}`}>
        <div className={`p-1 ${styles["chat-imgbx"]} position-relative`}>
          <img
            src={ProfileAccountImg}
            alt="profile-image"
            className="roundedCircle me-1"
            width={52}
            height={52}
          />
          <div className={`${styles["time-text"]} d-flex justify-content-center`}>9:00</div>
        </div>
        <div className={` ${styles["other-message"]} ${styles["message"]}`}>
          <div className={` ${styles["message-content"]}`}>
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem, at odio voluptas id laudantium consectetur eligendi
            ex repellat dicta odit quidem numquam quam officia veritatis libero
            mollitia beatae, quas repudiandae re
          </div>
        </div>
      </div>
      <div className={` ${styles["other-message"]} ${styles["message"]}`}>
        <div className={` ${styles["message-content"]}`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem, at odio voluptas id laudantium consectetur eligendi ex
          repellat dicta odit quidem numquam quam officia veritatis libero
          mollitia beatae, quas repudiandae reiciendis corrupti iure esse nemo
          minima enim aut dicta vel qui!
        </div>
      </div>
      <div className={` ${styles["user-message"]} ${styles["message"]}`}>
        <div className={` ${styles["message-content"]}`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. reiciendis
          corrupti iure esse nemo minima enim aut dicta vel qui!
        </div>
      </div>
      <div className={` ${styles["other-message"]} ${styles["message"]}`}>
        <div className={` ${styles["message-content"]}`}>
          minima enim aut dicta vel qui!
        </div>
      </div>
      <div className={` ${styles["user-message"]} ${styles["message"]}`}>
        <div className={` ${styles["message-content"]}`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. minima enim
          aut dicta vel qui!
        </div>
      </div>
    </div>
  )
}

export default ChatDialogues
