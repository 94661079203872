import React, { useEffect, useState } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { language } from "../data"
import "./EditLanguage.css"
import { useUpdateAdvertisementMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { toast } from "react-toastify"
interface CardProps {
  show: boolean
  handleClose: () => void
  AdData: any
}
const EditLanguage = ({ show, handleClose, AdData }: CardProps) => {
  const [lang, setLang] = useState<any>([])
  const [selectedLabels, setSelectedLabels] = useState<any>([])
  const [deletedLanguage,setDeletedLanguage] = useState<any>([])
  const [newLanguage, setNewLanguage] = useState("")
  const addLanguage = () => {
    if (newLanguage.trim() !== "") {
      const lowercasedLang = lang.map((language: any) => language.toLowerCase())
      const lowercasedNewLanguage = newLanguage.toLowerCase()
      if (!lowercasedLang.includes(lowercasedNewLanguage)) {
        setLang([...lang, newLanguage])
        setNewLanguage("")
      } else {
        toast.error("Language already exists in the list.")
      }
    }
  }

  const handleLanguage = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault()
      addLanguage()
    }
    addLanguage()
  }

  const toggleLabel = (index: any) => {
    if (selectedLabels.includes(index as never)) {
      setSelectedLabels(selectedLabels.filter((i:any) => i !== index))
    } else {
      setSelectedLabels([...selectedLabels, index as never])
    }
  }
  useEffect(() => {
    if (language && AdData?.language) {
      // Combine values from language and AdData.language, ensuring no duplicates
      const combinedLanguages = [...new Set([...language, ...AdData.language])];
      setLang(combinedLanguages);
    } else if (language) {
      setLang(language);
    }
  }, [show])
  // update query
  const [updateAdvt, { data: UpdatedData }] = useUpdateAdvertisementMutation()
  const handleSubmit = async () => {
    const Editlanguage = selectedLabels.map((index : any) => {
      return lang[index]
    })
    const langdata = {
      language: Editlanguage,
    }
    const response = await updateAdvt({ servicedata: langdata, id: AdData._id })
    if (response) {
      handleClose()
    }
  }
  const handleCrossIconClick = (index: number) => {
    setSelectedLabels((prev: any) => {
      const filteredLabels = prev.filter((item: any) => item !== index)
      const newArrayState = filteredLabels.map((value: any) =>
        value > index ? value - 1 : value,
      )
      return newArrayState
    })

    const updatedLabels = [...lang] 
   setDeletedLanguage([...updatedLabels])
    updatedLabels.splice(index, 1)
    setLang(updatedLabels)
  }


  useEffect(() => {
    if (language && AdData?.language) {
      const servicesArray = AdData.language.map((service: any) => service)
      const commonElements = language.filter((teachingItem: any) => {
        return servicesArray.includes(teachingItem)
      })
      if (commonElements?.length === AdData?.language?.length) {
        const indicesOfCommonElements = commonElements?.map(
          (commonElement: any) => {
            return language.indexOf(commonElement)
          },
        )
        setSelectedLabels([...indicesOfCommonElements] as any)
      } else {
        language.push(
          ...AdData.language
            .map((service: any) => service)
            .filter((service: any) => !language.includes(service)),
        )
        const commonElements = language.filter((teachingItem: any) => {
          return servicesArray.includes(teachingItem)
        })

        const indicesOfCommonElements = commonElements?.map(
          (commonElement: any) => {
            return language.indexOf(commonElement)
          },
        )
        setSelectedLabels([...indicesOfCommonElements] as any)
      }
    }
  }, [language, AdData])

  const handleCloseModal = ()=>{
    setLang([...lang, ...deletedLanguage.filter((item:any) => !lang.includes(item))]);

    if (language && AdData?.language) {
      const servicesArray = AdData.language.map((service: any) => service)
      const commonElements = language.filter((teachingItem: any) => {
        return servicesArray.includes(teachingItem)
      })
      if (commonElements?.length === AdData?.language?.length) {
        const indicesOfCommonElements = commonElements?.map(
          (commonElement: any) => {
            return language.indexOf(commonElement)
          },
        )
        setSelectedLabels([...indicesOfCommonElements] as any)
      } else {
        language.push(
          ...AdData.language
            .map((service: any) => service)
            .filter((service: any) => !language.includes(service)),
        )
        const commonElements = language.filter((teachingItem: any) => {
          return servicesArray.includes(teachingItem)
        })

        const indicesOfCommonElements = commonElements?.map(
          (commonElement: any) => {
            return language.indexOf(commonElement)
          },
        )
        setSelectedLabels([...indicesOfCommonElements] as any)
      }
    }
    handleClose()
  }
  return (
    <>
      <Modal show={show} size="lg" centered>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="edit-card w-auto">
              <h3 className="mb-4 text-center teaching-head">Language</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Control
                  type="text"
                  className="edit-price-field mb-4"
                  placeholder="Write Language"
                  value={newLanguage}
                  onChange={(e) => setNewLanguage(e.target.value)}
                // onKeyPress={handleKeyPress}
                />
                <button className="addlangunagebtn" onClick={handleLanguage}>
                  Add
                </button>
                <Form.Group controlId="vedic-guru">
                  <Row>
                    {lang?.map((language: any, index: any) => {
                      return (
                        <Col md={4} sm={6} xs={12} className="p-1" key={index}>
                          <Form.Check className="teaching-checkbox" />
                          <Form.Label
                            className={`langContainer ${selectedLabels.includes(index as never)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => toggleLabel(index)}
                          >
                            {language}

                            <span
                              className="crossicon"
                              onClick={(e) => {
                                e.stopPropagation()
                                handleCrossIconClick(index)
                              }}
                            >
                              X
                            </span>
                          </Form.Label>
                        </Col>
                      )
                    })}
                  </Row>
                </Form.Group>
              </Form>
              <div className="active-button">
                <div className="d-sm-flex justify-content-between px-md-4 px-2 w-100 mt-5">
                  <Button
                    className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor:
                        !selectedLabels.length || lang?.length === 0
                          ? "grey"
                          : "",
                    }}
                    className="btn bottom-btn save-edit-btn"
                    onClick={handleSubmit}
                    disabled={!selectedLabels.length || lang?.length === 0}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditLanguage
