import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Tooltip,
  Overlay,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap"

import hearticon from "../../assets/images/heartIcon.svg"
import hearticonWhite from "../../assets/images/heartIconWhite.svg"
import { useNavigate } from "react-router-dom"
import staricon from "../../assets/img/star-active-icon.svg"
import dummy from "../../assets/images/dummy-profile-pic.jpg"
import {
  useAddToFavMutation,
  useSearchLandingPageMutation,
} from "../../features/featSeekerDashboard/seekerDashboardApi"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { setModalName } from "../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME } from "../../constants"
import { toast } from "react-toastify"

interface providerProps {
  data: any
  key: any
  searchError: any
  sortBy: boolean
  page: number
  sortOrder:boolean
}

const ProviderCards = ({ data, searchError, sortBy, page, sortOrder }: providerProps) => {  
  // sortbyOder
  const user = useAppSelector((state: any) => state.auth)
  const urlSearchParams = new URLSearchParams(window.location.search)
  // const sortOrder = urlSearchParams.get("sortOrder")
  const searchedData = useAppSelector(
    (state: any) => state.seekerDashboardSlice.searchedData,
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isMuted, setIsMuted] = useState<boolean>(true)
  const [activeItems, setActiveItems] = useState([])
  const [customMessages, setCustomMessages] = useState([])
  const [id, setId] = useState<number>()

  const [addToFavourites, { data: favData, isError, isLoading, isSuccess }] =
    useAddToFavMutation()
  const [search, { error, isLoading: loading, isSuccess: success }] =
    useSearchLandingPageMutation()

  const selectCard = (id: any) => {
    setId(id)
    navigate(`/teacher-profile`, { state: { data, id } })
  }
  useEffect(() => {
    if (isSuccess) {
      const payload = {
        teacherType: "",
        sortBy: "realtime-private",
        sortOrder: sortOrder ? "descending" : "ascending",
        pageNo: page,
        // ascending
      }

      search({ ...searchedData, ...payload })
    }
  }, [isSuccess, searchedData])

  const handleToggle = async (index: any, id: any) => {
    if (user.seekerToken || user.providerToken) {
      const res = await addToFavourites(id)
      if (res) {
        toast.dismiss()
        toast.success((res as any)?.data?.message)
      }
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
    setActiveItems((prevActiveItems: any) => {
      const updatedItems = [...prevActiveItems] as any
      updatedItems[index] = !updatedItems[index]
      return updatedItems
    })

    if (!activeItems[index]) {
      setCustomMessages((prevMessages: any) => {
        const updatedMessages = [...prevMessages] as any
        return updatedMessages
      })
    }
  }
  
useEffect(()=>{
  if (data) {
    setActiveItems(
      data?.map((item: any) => item?.isFavourite ?? false)
    );
    
  }
},[data])


  return (
    <>
      {data?.length &&
        data?.map((data: any, index: number) => {
          return (
            <div className="position-relative" key={index}>
              <div
                className="option-fav flex-row d-flex justify-content-end align-items-center"
                key={index}
              >
                <OverlayTrigger
                  key={`overlay-${index}`}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>
                      {customMessages[index] || data?.isFavourite
                        ? "Remove from favourites!"
                        : user.providerToken || user.seekerToken
                        ? "Add to favourites!"
                        : "Login First"}
                    </Tooltip>
                  }
                >
                  <div
                    className="fav-icon cursor-pointer"
                    onClick={() => handleToggle(index, data?._id)}
                    id={`fav-icon-${index}`}
                  >
                    {(activeItems[index] && user?.seekerToken) ? (
                      <img src={hearticonWhite} alt="showWhiteHeart" />
                    ) : (
                      <img src={hearticon} alt="showWhiteHeart" />
                    )}
                  </div>
                </OverlayTrigger>
              </div>
              <div
                className="provider-card cursor-pointer h-100"
                onClick={() => selectCard(index)}
              >
                <div className="fav-card guide-card">
                  <div
                    className="fav-img position-relative"
                    onMouseEnter={() => setIsMuted(false)}
                    onMouseLeave={() => setIsMuted(true)}
                  >
                    {data?.profileVideo ? (
                      <video
                        muted={isMuted}
                        loop
                        preload={"auto"}
                        autoPlay
                        poster={data?.profileImage || dummy}
                      >
                        <source src={data?.profileVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={data?.profileImage || dummy} />
                    )}
                    <div className="fav-ast-name w-100 d-flex justify-content-between">
                      <div className="">
                        <h2 className="ast-name mb-1">
                          {(data?.providerData?.firstName ||
                            data?.providerId?.firstName) +
                            " " +
                            (data?.providerData?.lastName ||
                              data?.providerId?.lastName)}
                        </h2>
                        <p className="ast-loc">
                          <span>{data?.location}</span>{" "}
                          <span className="online-ast">
                            ({data?.modeOfClass})
                          </span>
                        </p>
                      </div>
                      <div className="rating d-block mb-0 text-end">
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          <img src={staricon} className="review-star" />
                          <span className="total-rating">
                            {data?.providerData?.rating?.$numberDecimal}
                          </span>
                        </div>
                        <div>
                          <small className="reviews"> (85 Reviews)</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3">
                    <div className=""></div>
                    <div className="d-flex justify-content-between">
                      <p className="ast-ocp mb-3">{data?.vedicSubject}</p>
                      <span className=" w-auto p-0">
                        <i className="fa-regular fa-circle-check me-2" />
                        {data?.providerData?.isMobileNoVerified
                          ? "Verified"
                          : "Not Verified"}
                      </span>
                    </div>
                    <p className="ast-ocp ast-about font-14 mb-3">
                      {data?.aboutClass}
                    </p>
                    <div className="d-flex justify-content-between align-items-end mb-3">
                      <div>
                        {data?.prices  && (
                          <strong>
                          <span className=" text-start p-0">
                          {data?.prices?.realTime?.private?.hourlyFee    &&  "₹" + data?.prices?.realTime?.private?.hourlyFee+"/hr"}
                          </span>
                          </strong>
                        )}
                        <span className="d-block first-class">
                          1st class free
                        </span>
                      </div>
                      <div>
                        <span className="badge-amb">Ambassador</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default ProviderCards
