import "./App.css"
import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom"
import AppWrapper from "./components/wrappers/AppWrapper"
import { Spinner } from "react-bootstrap"

import {
  baseRoutes,
  customerDashboardRoutes,
  providerDashboardRoutes,
  providerFunnelRoutes,
  seekerFunnelRoutes,
} from "./Routes"

import { USERTYPE } from "./constants"
import { useAppSelector } from "./app/hooks"
import CustomerDashboardWrapper from "./components/wrappers/DashMainWrapper/DashMainWrapper"
import FeatProviderDashboard from "./features/featProviderDashboard/FeatProviderDashboard"
import FeatProviderFunnel from "./features/ServiceFunnel/FeatProviderFunnel/FeatProviderFunnel"
import FeatSeekerFunnel from "./features/ServiceFunnel/FeatSeekerFunnel/FeatSeekerFunnel"
import StudentView from "./components/dasboard/Providerdashboard/Myads/studentView"
import Enter from "./components/Verification/Number/Enter"
import OTP from "./components/Verification/Number/Otp"
import Verified from "./components/Verification/Number/Verified"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import {
  setNewMessageSent,
  setSeekerRequest,
} from "./features/featSeekerDashboard/seekerDashboardSlice"
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore"
import { setProviderNewMsgNotification } from "./features/featProviderDashboard/FeatProviderDashboardSlice"
import { db } from "./app/firebase"
import { useSeekerSearchMutation } from "./features/featSeekerDashboard/seekerDashboardApi"
import { useProviderSearchMutation } from "./features/featProviderDashboard/FeatProviderApi"
import PrivateRoute from "./utils/PrivateRoute"
import NotificationScreen from "./components/dasboard/Providerdashboard/NotificationScreen/NotificationScreen"
import ScrollToTop from "./components/hoc/ScrollToTop"
import ChatWrapper from "./components/chatUi/ChatWrapper"
import DashMainWrapper from "./components/wrappers/DashMainWrapper/DashMainWrapper"

function App() {
  const [messages, setMessages] = useState<any[]>([])
  const auth = useAppSelector((state: any) => state.auth)
  const [search, { data: searchedData, isLoading: loading }] =
    auth.role === "Seeker"
      ? useSeekerSearchMutation()
      : useProviderSearchMutation()
  const dispatch = useDispatch()
  const data = useAppSelector((state: any) => state.seekerDashboardSlice)
  const messageDate = (createdAt: any) => {
    if (createdAt) {
      const date = createdAt.toDate()
      return {
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
      }
    } else {
      return {
        date: "N/A",
        time: "N/A",
      }
    }
  }
  useEffect(() => {
    search({
      status: "Accept",
      sort: -1,
      search: "",
    })
  }, [])
  useEffect(() => {
    dispatch(setNewMessageSent(false as any))
    const messagesCollection = collection(db, "messages")
    const q = query(messagesCollection, orderBy("createdAt"))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((each) => {
        const newData = each.doc.data()
        const filterData = searchedData?.data?.filter(
          (item: any) => item?._id === newData?.chatId,
        )
        const isCurrentUser = newData.userId === auth?.user?._id
        if (!isCurrentUser) {
          if (filterData) {
            dispatch(setSeekerRequest(filterData[0]))
          }
          dispatch(
            setProviderNewMsgNotification({
              chatId: newData?.chatId,
              userId: newData?.userId,
              username: newData?.username,
            } as any),
          )
        }
      })
      const updatedMessages = snapshot.docs
        .filter((doc) => doc.data().chatId === data?.requestData?.data?._id)
        .map((doc) => {
          const data = doc.data()
          const createdAtTimestamp = data.createdAt as Timestamp
          const formattedDate = messageDate(createdAtTimestamp)
          return { ...data, formattedDate }
        })
      if (updatedMessages.length > messages.length) {
        dispatch(setNewMessageSent(true as any))
      }
      setMessages(updatedMessages)
    })
    return () => unsubscribe()
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppWrapper />,
      children: baseRoutes,
    },
    {
      path: "/journey-seeker",
      element: <FeatSeekerFunnel />,
      children: seekerFunnelRoutes,
    },

    {
      path: "/chat",
      element: <ChatWrapper/>,
    },
    {
      path: "/journey-source",
      element: (
        <PrivateRoute>
          <FeatProviderFunnel />
        </PrivateRoute>
        ),
      children: providerFunnelRoutes,
    },
    {
      path: "/source-dashboard",
      element: (
        <PrivateRoute>
          <FeatProviderDashboard />
        </PrivateRoute>
      ),
    children: providerDashboardRoutes,
    },

    {
      path: "/seeker-dashboard",
      element: (
        <PrivateRoute >
          <DashMainWrapper userType={USERTYPE.SEEKER} />
        </PrivateRoute>
      ),
      children: customerDashboardRoutes,
    },
    {
      path: "/teacher-profile-view",
      element: (
        <PrivateRoute>
          <StudentView />
        </PrivateRoute>
      ),
    },
    // verification-screen
    {
      path: "/enter",
      element: <Enter />,
      loader: () => <Spinner variant="grow" />,
    },
    {
      path: "/otp",
      element: <OTP />,
      loader: () => <Spinner variant="grow" />,
    },
    {
      path: "/verified",
      element: <Verified />,
      loader: () => <Spinner variant="grow" />,
    },
    {
      path: "/notification",
      element: (
        <PrivateRoute
        >
          <NotificationScreen />

        </PrivateRoute>
      ),
      loader: () => <Spinner variant="grow" />,
    },
  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
