import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./Enter.css"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import { useAppDispatch } from "../../../app/store"
import { useForm } from "react-hook-form"
const Enter = () => {
  //   const dispatch = useAppDispatch()
  // const [selectedOption, setSelectedOption] = useState("")
  // const handleSelectChange = (e: any) => {
  //   setSelectedOption(e.target.value)
  //   dispatch(updateFunnel({ service: e.target.value }))
  // }

  //   const [selectedValue, setSelectedValue] = useState<any>([])

  //   const {
  //     control,
  //     handleSubmit,
  //     register,
  //     formState: { errors },
  //   } = useForm<any>()

  //   const subOptions = [
  //     { value: "Teacher", label: "Teacher" },
  //     { value: "Vedic Guru", label: "Vedic Guru" },
  //     { value: "Mentor", label: "Mentor" },
  //     { value: "Guide", label: "Guide" },
  //   ]
  //   const seekerData = useAppSelector((state) => state.seekerFunnel)
  //   const handleSelectChange = (
  //     selectedValues: Array<{ value: string; label: string }>,
  //   ) => {
  //     const payloadPreference = selectedValues.map((option) => option.value)
  //     setSelectedValue(selectedValues)
  //     dispatch(updateFunnel({ ...seekerData, service: payloadPreference }))
  //   }

  return (
    <>
      <section>
        <Container>
          <div className="inner-fav mb-5">
            <Row className="justify-content-center">
              <Col xl={6} lg={6}>
                <div className="d-flex justify-content-between flex-column h-100 text-center">
                  <div>
                    <p className="verification-heading">Verification</p>
                    <p className="get-started-text mb-5">
                      We will send you an one time password(OTP) on your mobile
                      number
                    </p>
                  </div>
                  <div className="number-input">
                    <Form.Label className="label-heading">
                      Enter mobile number
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="+91 0000000000"
                    />
                  </div>
                  <button type="button" className="send-button mt-5">
                    Send verification code
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Enter
