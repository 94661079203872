import React, { Dispatch, SetStateAction, useEffect } from "react"
import FormInput from "../../../form/FormInput"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Button, Form, Spinner } from "react-bootstrap"
import mailicon from "../../../../assets/img/mail-icon.svg"
import passwordicon from "../../../../assets/img/password-icon.svg"
import { FaFacebook } from "react-icons/fa"
import googleimage from "../../../../assets/img/google-icon.png"
import { toast } from "react-toastify"
import { useAppDispatch } from "../../../../app/store"
import {
  closeModal,
  setModalName,
} from "../../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../../constants"
// import { useProviderloginMutation } from "../../../../features/Auth/authApi"
import { useLoginMutation } from "../../../../features/Auth/authApi"
import { useLocation, useNavigate } from "react-router-dom"
import { setCurrentUser } from "../../../../features/Auth/authSlice"
import { seekerLoginSchema } from "../../../../utils/yupSchema/Schema"
import { useProviderloginMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
  const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
const ENDPOINT_URL_PROVIDER = import.meta.env
  .VITE_REACT_APP_GOOGLE_SIGN_UP_PROVIDER
const ENDPOINT_URL_SEEKER = import.meta.env.VITE_REACT_APP_GOOGLE_SIGN_UP_SEEKER
type Props = {
  userType: string | null
}
interface LoginUser {
  email: string
  password: string
}
export default function LoginSeeker({ userType }: Props) {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [submitForm, { isSuccess, isError, error, data, isLoading }] =
    useProviderloginMutation()

  const [
    seekerForm,
    {
      isError: seekerisError,
      error: seekerError,
      data: seekerData,
      isLoading: seekerIsloading,
    },
  ] = useLoginMutation()

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginUser>({
    resolver: seekerLoginSchema,
  })

  const onSubmit = async (values: any) => {
    
    const payload = {
      email: values.email,
      password: values.password,
    }
    if (userType == USERTYPE.SEEKER) {
      seekerForm(payload)
    } else if (userType == USERTYPE.PROVIDER) {
      submitForm(payload)
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (data?.message || seekerData?.message) {
      if (data?.user.role === "Provider") {
        if (data?.isEmailVerified) {
          toast.success(data?.message)
          dispatch(closeModal())
        } else {
          toast?.error("Your email is not Verified" + " " + data?.message)
          dispatch(setModalName(MODALNAME.CLOSED))
          dispatch(setCurrentUser(USERTYPE.PROVIDER))
          dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
        }

      } else {
        if (seekerData?.isEmailVerified) {
          toast.success(seekerData?.message)
          dispatch(closeModal())
        }
        else{
          toast?.error("Your email is not Verified" + " " + seekerData?.message)
          dispatch(setModalName(MODALNAME.CLOSED))
          dispatch(setCurrentUser(USERTYPE.SEEKER))
          dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
        }
       
        if (location.pathname === "/source-signup") {
          navigate("/")
        }
      }
    } else {
      toast.dismiss()
      toast.error(
        (error as any)?.data?.message || (seekerError as any)?.data?.message,
      )
    }
  }, [data, seekerData, seekerError, error])

  useEffect(() => {
    reset()
  }, [userType])
  const handleGoogleLogin = async () => {
    if (userType === USERTYPE.PROVIDER) {
      window.location.replace(`${BASE_URL}/api/provider/auth/google`)
    } else {
      window.location.replace(`${BASE_URL}/api/seeker/auth/google`)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="popup-field-wrapper mb-1 W-100">
          <img src={mailicon} alt="Mail Icon" />
          <FormInput
            name="email"
            type="email"
            placeholder={
              userType === USERTYPE.SEEKER
                ? "Enter your email"
                : "Enter your email"
            }
            register={register}
            // errors={errors}
            className="popup-field w-100"
          />
        </div>
        <span className="error">{errors?.email?.message}</span>
        <div className="popup-field-wrapper mb-1 mt-4 w-100">
          <img src={passwordicon} alt="Password Icon" />
          <FormInput
            name="password"
            type="password"
            placeholder="Password"
            register={register}
            // errors={errors}
            className="popup-field w-100"
          />
        </div>
        <span className="error">{errors?.password?.message}</span>
        <div className="text-end mb-md-5 mb-3">
          <Button
            variant="transparent"
            onClick={() =>
              dispatch(
                userType == USERTYPE.SEEKER
                  ? setModalName(MODALNAME.FORGET_PASSWORD_SEEKER)
                  : setModalName(MODALNAME.FORGET_PASSWORD_TUTOR),
              )
            }
            className="forgot-link p-0"
          >
            Forgot Password?
          </Button>
        </div>
        <Button
          type="submit"
          className="common-btn auth-btn mb-3"
          disabled={seekerIsloading || isLoading}
        >
          {seekerIsloading || isLoading ? <Spinner /> : "Login"}
        </Button>

        <div className="d-md-flex gap-3 max-w-450 mx-auto mb-4">
          <a
            className="loginwith-button google-btn d-block mb-3"
            onClick={handleGoogleLogin}
          >
            <img src={googleimage} alt="Google Icon" />
            Log in with Google
          </a>
          {/* <a className="loginwith-button facebook-btn d-block mb-3">
            <FaFacebook />
            Login with Facebook
          </a> */}
        </div>
        <div className="text-center">
          <a
            onClick={() => dispatch(setModalName(MODALNAME.SIGNUP))}
            className="signup-link"
          >
            Don’t have an account? <span>Sign up</span>
          </a>
        </div>
        <div className="text-center mt-2">
          or <br />
          <div
           
            className="mt-2 cursor-pointer"
            onClick={() =>
              dispatch(
                setCurrentUser(
                  userType === USERTYPE.SEEKER
                    ? USERTYPE.PROVIDER
                    : USERTYPE.SEEKER,
                ),
              )
            }
          >
            Login as {userType === USERTYPE.SEEKER ? "Tutor" : "Seeker"}
          </div>
          {/* Login as a {userType === USERTYPE.SEEKER_LOGIN ? <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}>Tutor</span>: <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}> Seeker</span>}   */}
        </div>
      </Form>
    </>
  )
}
