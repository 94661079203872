import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "./ScheduleSession.css"
import webcam from "../../../assets/img/cam-icon.svg"
import reviewimage from "../../../assets/img/certified-image2.png"
import starimage from "../../../assets/img/star-active-icon.svg"
import cardimage from "../../../assets/img/card-image.png"
import heartimage from "../../../assets/img/heart.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import playbutton from "../../../assets/img/play-icon.svg"

import { useNavigate, useParams } from "react-router-dom"

import leftarrow from "../../../assets/img/left-arrow.svg"
import MeditationSingUp from "../../../assets/img/meditation_sign_up.jpg"
import ScheduleCard from "../scheduleCard/ScheduleCard"
import FormInput from "../../form/FormInput"
import {
  useGetProviderDetailsMutation,
  usePostCreateRquestMutation,
} from "../../../features/Auth/authApi"
import { useAppSelector } from "../../../app/hooks"
import {
  setMessageNotificationClick,
  setSeekerRequest,
  setSeekerScheduleSessionData,
} from "../../../features/featSeekerDashboard/seekerDashboardSlice"
import { useAppDispatch } from "../../../app/store"
import { MODALNAME, SESSION_PREFERENCE, USERTYPE } from "../../../constants"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import {
  useCreateSeekerRequestMutation,
  useGetseekerProfileQuery,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { useProviderAccountDetailsQuery } from "../../../features/featProviderDashboard/FeatProviderApi"
import LoginSeeker from "../../modals/AuthComponents/loginModal/LoginSeeker"

type Props = { userType?: USERTYPE }

interface UserDetails {
  fullname: any
  email: string
  phone: string
  address: string
  myself: string
  date: string
  sessionFormat: string
  sessionPreference: string
  requestMessage: string
}
// interface props { }
const ScheduleSession = ({ userType }: Props) => {
  const userData = useAppSelector((state) => state.auth)
  const { seekerToken, providerToken } = useAppSelector(
    (state: any) => state.auth,
  )
  const token = useAppSelector((state) => state.auth.seekerToken)

  const { data: userProfileData, isLoading: getLoading } = seekerToken
    ? useGetseekerProfileQuery()
    : useProviderAccountDetailsQuery()
  const loginUserData = useAppSelector((state) => state.seekerDashboardSlice)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [textError, setTextError] = useState("")
  const [dateformat, setDateFormat] = useState<string>("soon")
  const [profile, { isError, error, data }] = useGetProviderDetailsMutation()
  const [errors, setErrors] = useState({
    sessionForWhom: "",
    date: "",
    sessionPreference: "",
  })
  const [selectSession, setSelectSession] = useState({
    virtual: false,
    realTime: false,
    mySelf: true,
    someOneElse: false,
    soon: dateformat === "soon" ? true : false,
    any: false,
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  setTimeout(() => setLoading(false), 3000)

  const [request, { data: reqData, isSuccess, error: reqError }] =
    useCreateSeekerRequestMutation()

  const [fields, setFields] = useState<UserDetails>({
    fullname: selectSession.mySelf ? userData?.user?.firstName : "",
    email: "",
    phone: "",
    address: "",
    myself: "",
    date: "",
    sessionFormat: "",
    sessionPreference: "",
    requestMessage:
      " I am writing to express my sincere interest in enrolling in your course for the upcoming term. Having thoroughly researched your course structure and learning objectives, I am confident that your expertise aligns perfectly with my academic and professional aspirations.",
  })

  const handleVirtualClick = () => {
    setSelectSession((prevSession) => ({
      ...prevSession,
      virtual: !prevSession.virtual,
    }))
    setErrors((pre) => ({
      ...pre,
      sessionPreference: "",
    }))
  }

  //6 months limit
  const next1Month = new Date()
  next1Month.setMonth(next1Month.getMonth() + 1)

  useEffect(() => {
    if (selectSession?.mySelf === true) {
      setFields((prev) => ({
        ...prev,
        fullname:
          loginUserData?.userData?.firstName +
          " " +
          loginUserData?.userData?.lastName,
        email: loginUserData?.userData?.email,
      }))
    } else if (selectSession?.someOneElse === true) {
      setFields((prev) => ({
        ...prev,
        fullname: "",
        email: "",
        phone: "",
      }))
    }
  }, [loginUserData, selectSession])

  //  then response api success navigate

  // handlechnage for inputs
  const [wordCount, setWordCount] = useState<number>(0)

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFields((pre) => ({ ...pre, [name]: value }))
    if (name === "requestMessage") {
      setWordCount(value.length)
      // setWordCount(value.replace(/\s/g, '').length)
    }
    setIsValidEmail(true)
    if (name === "requestMessage" && wordCount < 50) {
      setTextError("Consultation must be at minimum of 50 letters long")
    } else {
      setTextError("")
    }
  }

  // submit all with api
  const onSubmit = async () => {
    let newErrors = {
      sessionForWhom: "",
      date: "",
      sessionPreference: "",
    }
    if (
      newErrors.sessionForWhom ||
      newErrors.date ||
      newErrors.sessionPreference
    ) {
      setErrors(newErrors)
    } else {
      const payload = {
        adId: id,
        sessionPreference: (() => {
          if (selectSession.any === true) {
            return "Any"
          } else if (selectSession.virtual === true) {
            return "Virtual"
          } else if (selectSession.realTime === true) {
            return "Real Time"
          } else {
            return "Any"
          }
        })(),
        date: dateformat,
        sessionForWhom: (() => {
          if (selectSession.mySelf === true) {
            return "Myself"
          } else if (selectSession.someOneElse === true) {
            return "Someone Else"
          } else {
            return ""
          }
        })(),
        requestMessage: fields?.requestMessage,
      }
      if (userProfileData?.data?.isPremium === false) {
        navigate(`/payment-sub/${id}`)
      } else if (userProfileData?.data?.isPremium === true) {
        const response = await request(payload as any)
        try {
          if ((response as any)?.data?.data) {
            dispatch(
              setSeekerRequest({ data: (response as any)?.data?.data } as any),
            )
            dispatch(setMessageNotificationClick((response as any)?.data))
            navigate(`/seeker-dashboard/my-requests`, {
              state: {
                id: (response as any)?.data?.data?._id,
                status: "Accept",
              },
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
      dispatch(setSeekerRequest(payload as any))
      setTextError("")
      setIsValidEmail(true)
    }
  }
  useEffect(() => {
    if (data?.data?.adDetail.modeOfClass === "Virtual") {
      setSelectSession((prevSession) => ({
        ...prevSession,
        virtual: true,
        realTime: false,
      }))
    } else if (data?.data?.adDetail.modeOfClass === "Real Time") {
      setSelectSession((prevSession) => ({
        ...prevSession,
        realTime: true,
        virtual: false,
      }))
    } else if (data?.data?.adDetail.modeOfClass === "Any") {
      setSelectSession((prevSession) => ({
        ...prevSession,
        realTime: false,
        virtual: false,
        any: true,
      }))
    }
  }, [data?.data?.adDetail.modeOfClass])
  useEffect(() => {
    profile(id)
  }, [id])
  return (
    <>
      {token === null ? (
        <div
          className="explore-section section-padding d-flex justify-content-end"
          style={{
            backgroundImage: `url(${MeditationSingUp})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="d-flex gap-2 flex-column border-5 p-5 me-4"
            style={{ backgroundColor: "rgb(154 154 154 / 56%)" }}
          >
            {" "}
            <h5 style={{ color: "white" }}>Login to Schedule</h5>
            <LoginSeeker userType={USERTYPE.SEEKER} />
          </div>
        </div>
      ) : loading ? (
        <div className="spin-loading">
          <Spinner />
        </div>
      ) : (
        <section className="explore-section section-padding py-5">
          <Container fluid="lg">
            <div className="inner-explore">
              <div className="back-btn-wrapper pt-4 px-lg-5 pb-xl-5 pb-3 d-lg-inline-block d-none">
                <a href="#" className="back-link">
                  <img src={leftarrow} alt="left-arrow" />
                </a>
              </div>
              <Row>
                <Col xl={4} lg={5} className="mb-lg-0 mb-4 px-lg-3 px-0">
                  <ScheduleCard
                    seekerData={data?.data}
                    types={"seeker"}
                    key={""}
                    data={undefined}
                  />
                </Col>
                <Col xl={8} lg={7}>
                  <div className="schedule-section ps-4">
                    <h2 className="sch-text mb-2">Schedule</h2>
                    <h4 className="mb-4 session-text fw-normal">
                      Your Session with{" "}
                      <span className="fw-semibold">
                        {data?.data?.adDetail?.providerId.firstName}
                      </span>
                    </h4>

                    <div className="mb-34">
                      <h3 className="font-16 fw-semibold">
                        Select Session Format
                      </h3>
                      <div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                virtual: !prevSelectSession.virtual,
                                realTime: false,
                                any: false,
                              }))
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sessionPreference: "",
                              }))
                            }}
                            type="checkbox"
                            name="selectSession"
                            className="radio-btn"
                            id="webcam-radio"
                            checked={selectSession.virtual}
                          />
                          <label
                            htmlFor="webcam-radio"
                            className={`label-radio `}
                          >
                            Virtual
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                realTime: !prevSelectSession.realTime,
                                virtual: false,
                                any: false,
                              }))
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sessionPreference: "",
                              }))
                            }}
                            type="checkbox"
                            name="selectSession"
                            className="radio-btn"
                            id="realtime-radio"
                            checked={selectSession.realTime}
                          />
                          <label
                            htmlFor="realtime-radio"
                            className={`label-radio `}
                          >
                            Real Time
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                any: !prevSelectSession.any,
                                realTime: false,
                                virtual: false,
                              }))
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                sessionPreference: "",
                              }))
                            }}
                            type="checkbox"
                            name="selectSession"
                            className="radio-btn"
                            id="any-radio"
                            checked={selectSession.any}
                          />
                          <label htmlFor="any-radio" className={`label-radio `}>
                            Any
                          </label>
                        </div>
                      </div>

                      <div style={{ color: "red" }}>
                        {errors.sessionPreference && (
                          <p>{errors.sessionPreference}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-34">
                      <h3 className="font-16 fw-semibold">Date</h3>
                      <div>
                        <div className="d-inline-block me-2">
                          <input
                            type="date"
                            name="date"
                            className="date-field form-control"
                            id="schedule-date"
                            onChange={(e) => {
                              setDateFormat(e.target.value)
                              setSelectSession((prev) => ({
                                ...prev,
                                soon: false,
                              }))
                              setErrors((pre) => ({
                                ...pre,
                                date: "",
                              }))
                            }}
                            min={new Date().toISOString().split("T")[0]}
                            max={next1Month.toISOString().split("T")[0]}
                          />
                        </div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="date"
                            className="radio-btn"
                            id="soon-radio"
                            checked={selectSession.soon}
                            onChange={() => {
                              setSelectSession((prev) => ({
                                ...prev,
                                soon: true,
                              }))
                              setDateFormat("soon")
                              const dateInput =
                                document.getElementById("schedule-date")
                              if (dateInput) {
                                ;(dateInput as any).value = ""
                              }
                            }}
                          />
                          <label htmlFor="soon-radio" className="label-radio">
                            Soon
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "red" }}>
                        {errors.date && <p>{errors.date}</p>}
                      </div>
                    </div>
                    <div className="mb-34">
                      <h3 className="font-16 fw-semibold">
                        Who is this Session for
                      </h3>
                      <div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="session-for"
                            className="radio-btn"
                            id="self-radio"
                            checked={selectSession.mySelf}
                            onChange={() => {
                              setSelectSession({
                                ...selectSession,
                                mySelf: !selectSession.mySelf,
                                someOneElse: false,
                              })

                              setErrors((pre) => ({
                                ...pre,
                                sessionForWhom: "",
                              }))
                            }}
                          />

                          <label htmlFor="self-radio" className="label-radio">
                            My Self
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="sessionfor"
                            className="radio-btn"
                            id="someone-radio"
                            checked={selectSession.someOneElse}
                            onChange={() => {
                              setSelectSession({
                                ...selectSession,
                                someOneElse: !selectSession.someOneElse,
                                mySelf: false,
                              })
                              setErrors((pre) => ({
                                ...pre,
                                sessionForWhom: "",
                              }))
                            }}
                          />
                          <label
                            htmlFor="someone-radio"
                            className="label-radio"
                          >
                            Someone else
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "red" }}>
                        {errors.sessionForWhom && (
                          <p>{errors.sessionForWhom}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-34">
                      <h3 className="font-16 fw-semibold">Contact Info</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              name="fullname"
                              placeholder="fullname"
                              className="schedule-field shadow-none form-control"
                              onChange={handleChange}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.firstName
                                  : fields.fullname
                              }
                              disabled={selectSession.mySelf}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="email"
                              placeholder="Email"
                              className="schedule-field shadow-none form-control"
                              name="email"
                              onChange={handleChange}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.email
                                  : fields.email
                              }
                              disabled={selectSession.mySelf}
                            />
                          </div>
                          {!isValidEmail && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              Invalid email address
                            </p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="number"
                              placeholder="Phone Number"
                              className="schedule-field shadow-none form-control"
                              name="phone"
                              onChange={handleChange}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.mobileNo
                                  : fields.phone
                              }
                              disabled={selectSession.mySelf}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Address"
                              className="schedule-field shadow-none form-control"
                              name="address"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-34">
                      <h3 className="font-16 fw-semibold">
                        Tell {data?.data?.adDetail.providerId.firstName} what
                        you want to consult{" "}
                        <span className="optional-text">(Optional)</span>
                      </h3>
                      <div className="row">
                        <div className="col-md-12">
                          <textarea
                            className="schedule-textarea shadow-none form-control"
                            name="requestMessage"
                            value={fields?.requestMessage}
                            placeholder="Description"
                            onChange={handleChange}
                            maxLength={500}
                            onFocus={(e) => {
                              e.target.select()
                            }}
                          />
                        </div>
                        {textError && (
                          <p className=" text-secondary ">{textError}</p>
                        )}
                      </div>
                      {/* <p className="letter-count-info">{wordCount}/500</p> */}
                    </div>
                    {!userData?.seekerToken ? (
                      <button
                        className="schedule-btn"
                        onClick={() => dispatch(setModalName(MODALNAME.LOGIN))}
                      >
                        Next
                      </button>
                    ) : (
                      <button className="next-btn" onClick={onSubmit}>
                        Next
                      </button>
                    )}
                  </div>
                  {
                    <div className="error d-flex justify-content-center ">
                      {" "}
                      <h6>{(reqError as any)?.data?.message} </h6>
                    </div>
                  }
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  )
}

export default ScheduleSession
