import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { passwordRegex } from "../regexPattern/Pattern"

// generalInfoSchema in my Account section
export const generalInfoSchema = yupResolver(
  yup.object().shape({
    email: yup.string().email("Invalid Email"),
    fname: yup
      .string()
      .min(3, "First Name must be at least 3 characters long")
      .max(20, "First Name cannot exceed 20 characters"),
    lname: yup
      .string()
      .min(3, "Last Name must be at least 3 characters long")
      .max(20, "Last Name cannot exceed 20 characters"),
    gender: yup.string(),
    phone: yup
      .string()
      .min(6, "phone number must be at least 6 digit long")
      .max(12, "phone number cannot exceed 12 characters"),
    landline: yup
      .string()
      .min(6, "landline number must be at least 6 digit long")
      .max(12, "landline number cannot exceed 12 characters"),
    skype: yup.string().max(36,"Skype id cannot exceed 36 characters"),
    TelegramId: yup.string().max(32,"Telegram id cannot exceed 22 characters"),
    dob: yup.string(),
  }),
)
//   customerDashboardMyAccountSchema
export const customerDashboardMyAccountSchema = yupResolver(
  yup.object().shape({
    postalAddress: yup.string().required("Please Enter your Postal Address"),
  }),
)
//   providerDashboardMyAccountSchema
export const providerDashboardMyAccountSchema = yupResolver(
  yup.object().shape({
    postalAddress: yup.string().required("please enter your postal address"),
  }),
)
//   Write Review Customer Dashboard
export const customerDashboardWriteReviewSchema = yupResolver(
  yup.object().shape({
    review: yup.string().required("please enter a review to deliver"),
  }),
)
//   providerDashboardReplyReviewSchema
export const providerDashboardReplyReviewSchema = yupResolver(
  yup.object().shape({
    replymsg: yup.string().required("please enter a message to deliver"),
  }),
)
//   providerLoginSchema
export const providerLoginSchema = yupResolver(
  yup.object().shape({
    email: yup.string().email().required("Please enter an email"),
    password: yup.string().required("Please Enter your password"),
  }),
)
//   providerSignupSchema
export const providerSignupSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first Name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters"),
    lastName: yup
      .string()
      .required("Please enter last Name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters"),
    mobileNo: yup
      .string()
      .required("Please enter the Phone number")
      .min(6, "Phone Number must be at least 6 characters long")
      .max(16, "Phone Number cannot exceed 16 characters"),
    email: yup.string().email().required("Please enter an email"),

    password: yup
      .string()
      .required("Please fill in a password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        passwordRegex,
        "Password must contain lowercase,uppercase,special character and numerical value",
      ),
  }),
)
//   forgotPasswordSchema
export const forgotPasswordSchema = yupResolver(
  yup.object().shape({
    email: yup.string().email().required("Please enter an email"),
  }),
)
//   seekerLoginSchema
export const seekerLoginSchema = yupResolver(
  yup.object().shape({
    email: yup.string().email().required("Please enter an email"),
    password: yup.string().required("Please enter your Password"),
  }),
)
//   resetPasswordSchema
export const resetPasswordSchema = yupResolver(
  yup.object().shape({
    password: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        passwordRegex,
        "Password must contain lowercase,uppercase,special character and numerical value",
      )
      .oneOf([yup.ref("confirmPassword")], "Passwords must match"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
  }),
)
//   seekerSignupSchema
export const seekerSignupSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first Name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters"),
    lastName: yup
      .string()
      .required("Please enter last Name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters"),
    email: yup.string().email().required("Please enter an email"),
    phone: yup
      .string()
      .required("Please enter the Phone number")
      .min(6, "Phone Number must be at least 6 characters long")
      .max(16, "Phone Number cannot exceed 16 characters"),
    password: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        passwordRegex,
        "Password must contain lowercase,uppercase,special character and numerical value",
      ),
  }),
)
// changePasswordSchema
export const changePasswordSchema = yupResolver(
  yup.object().shape({
    password: yup.string().required("Please enter your old password"),
    newpassword: yup
      .string()
      .required("Please enter your new password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        passwordRegex,
        "Password must contain lowercase,uppercase,special character and numerical value",
      ),
  }),
)
// editAboutAdSchema
export const editAboutAdSchema = yupResolver(
  yup.object().shape({
    aboutYou: yup.string(),
  }),
)
// editAdPriceSchema
export const editAdPriceSchema = yupResolver(
  yup.object().shape({
    hourlyFees: yup
      .string()
      // .min(1, "Hourly fees cannot be 0 and less than 0")
      // .required("This is a required Field")
      .max(100000, "Maximum length exceeded (100,000)"),
      hourlyFeesPrivate:yup
      .string()
      // .min(1, "Hourly fees cannot be 0 and less than 0")
      // .required("This is a required Field")
      .max(100000, "Maximum length exceeded (100,000)"),
    totalClasses: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),

    packageFees: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),

    webcamCharges: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),
    travelCost: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),
    additionaldetails: yup.string().required("This is a required Field"),
  }),
)
// editAdSessionSchema
export const editAdSessionSchema = yupResolver(
  yup.object().shape({
    classSession: yup.string(),
  }),
)
// editAdTitle
export const editAdTitle = yupResolver(
  yup.object().shape({
    newTitle: yup.string(),
  }),
)
// topNavBarSchema
export const topNavBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
// landingSearchBarSchema
export const landingSearchBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
// topNavSearchBarSchema
export const topNavSearchBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
export const footerSubscribemail = yupResolver(
  yup.object().shape({
    email: yup.string().email(),
  }),
)
