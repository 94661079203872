import React from "react"
import PlanCards from "../PlanCards/PlanCards"
import { Form, InputGroup } from "react-bootstrap"
import "./FlexiblePricing.css"
import { useParams } from "react-router-dom"

function FlexiblePricing() {
  const { id } = useParams()
  const planData1 = {
    planType: "Standard",
    price: "999",
    period: "Per month",
    perks: [
      "Accept the request of all student",
      "Unlock unlimited requests",
      "Get additional discount and offers.",
    ],
  }
  const planData2 = {
    planType: "Basic",
    price: "500",
    period: "Per month",
    perks: [
      "Accept the request of all student",
      "Unlock 10 requests",
      "See who sends you request",
    ],
  }
  const planData3 = {
    planType: "Premium",
    price: "1999",
    period: "3 months",
    perks: [
      "Get additional discount and offers.",
      "Get additional discount and offers.",
      "Get additional discount and offers.",
    ],
  }

  return (
    <div
      className=" container align-items-center min-vh-100"
      style={{ width: "100%", maxWidth: "915px", paddingTop: "144px" }}
    >
      <div className="text-black fs-1 text-center">
        Flexible plans &{" "}
        <span
          className=" fw-semibold"
          style={{ color: "#7653DA", borderBottom: "3.5px solid #7653DA" }}
        >
          Pricing
        </span>
      </div>
      <p
        className="mx-4 text-black-50 mt-4 text-center"
        style={{ width: "811px" }}
      >
        Lorem ipsum dolor sit amet consectetur. At mauris accumsan lorem
        consectetur augue dui venenatis varius. Turpis nunc sit erat vel sed
        vitae elementum.
      </p>
      <div className="d-flex justify-content-center align-items-center gap-2 mt-3 mb-5">
        <p className="mb-0 checkprice-text">Monthly</p>
        <InputGroup className="w-auto">
          <Form.Check
            type="switch"
            id="custom-switch"
            className="form-switch-purple notify-toggle-purple ps-0"
          />
        </InputGroup>
        <p className="mb-0 checkprice-text">Yearly</p>
      </div>

      <div className="d-flex gap-3">
        <PlanCards {...planData2} id={id} />
        <PlanCards {...planData1} id={id} />
        <PlanCards {...planData3} id={id} />
      </div>
    </div>
  )
}

export default FlexiblePricing
