import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { api } from "../Auth/authApi"
import { T } from "vitest/dist/types-e3c9754d.js"
import { providerDashboardApi } from "./FeatProviderApi"

interface User {
  aboutClass: string
  aboutYou: string
  createdAt: string
  hourlyRate: string
  language: string
  location: string
  modeOfClass: string
  phoneNo: string
  profileImage: string
  profileVideo: string
  providerId: Array<T>
  services: Array<T>
  status: string
  title: string
  updatedAt: string
  vedicSubject: string
  __v: string
  _id: string
  hourlyRatePricing: string
  activeRequestTab: string
  activeRequestButton: string
  ProviderNewMsgNotification: object
  newUserAdvertisementLength: number
}
const initialState = {
  aboutClass: "",
  aboutYou: "",
  createdAt: "",
  hourlyRate: "",
  language: "",
  location: "",
  modeOfClass: "",
  phoneNo: "",
  profileImage: "",
  profileVideo: "",
  providerId: [],
  services: [],
  status: "",
  title: "",
  updatedAt: "",
  vedicSubject: "",
  __v: "",
  _id: "",
  hourlyRatePricing: "",
  activeRequestTab: "",
  activeRequestButton: "",
  ProviderNewMsgNotification: {},
  newUserAdvertisementLength: -1,
}
const initialIndex = 0
const slice = createSlice({
  name: "providerSlice",
  initialState: { userData: initialState as User, id: initialIndex },
  reducers: {
    setProviderData: (state: any, action: any) => {
      state.user = action.payload
    },
    setProviderBasicDetails: (state: any, action: any) => {
      state.userData = action.payload
    },
    setProviderIndex: (state: any, action: any) => {
      state.id = action.payload
    },
    setactiveRequestTab: (state: any, action: any) => {
      state.activeRequestTab = action.payload
    },
    setactiveRequestButton: (state: any, action: any) => {
      state.activeRequestButton = action.payload
    },
    setProviderNewMsgNotification: (state: any, action: any) => {
      state.ProviderNewMsgNotification = action.payload
    },
    hourlyRatePricing: (state: any, action: any) => {
      state.hourlyRatePricing = action.payload
    },
    setNewUser: (state: any, action: any) => {
      state.newUserAdvertisementLength = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      providerDashboardApi.endpoints.providerlogin.matchFulfilled,
      (state, { payload: user }: PayloadAction<any>) => {
        state.userData = { ...state.userData, ...user }
      },
    ),
      builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
        state.userData = initialState
      })
    builder.addMatcher(
      providerDashboardApi.endpoints.providerAdvertisement.matchFulfilled,
      (state: any, { payload }) => {
        state.user = payload.data
      },
    )
    builder.addMatcher(
      api.endpoints.logoutProvider.matchFulfilled,
      (state: any) => {
        state.user =null
      },
    )
  },
})

export default slice.reducer
export const {
  setProviderData,
  setProviderIndex,
  setProviderBasicDetails,
  hourlyRatePricing,
  setactiveRequestButton,
  setactiveRequestTab,
  setProviderNewMsgNotification,
  setNewUser,
} = slice.actions
