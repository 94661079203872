import React from "react"
import { Link } from "react-router-dom"
import "./HelpCenter.css"
import { IoIosArrowRoundBack } from "react-icons/io"
import { BsFileEarmarkText } from "react-icons/bs"
import { FiInfo } from "react-icons/fi"
import { Accordion } from "react-bootstrap"
const SingleHelp = () => {
  return (
    <section className="single-help-section">
      <div className="inner-single-help">
        <div className="help-navigator d-lg-block d-none">
          <Link to="/help-center" className="help-link">
            <IoIosArrowRoundBack /> Help Center
          </Link>
          <ul className="help-navigator-listing">
            <li className="help-navigator-item">
              <Link to={" "} className="help-navigator-link active">
                Manage my ad
              </Link>
              <ul className="help-navigator-listing sub-listing">
                <li className="help-navigator-item">
                  <Link to={" "} className="help-navigator-link active">
                    Update my ad
                  </Link>
                </li>
                <li className="help-navigator-item">
                  <Link to={" "} className="help-navigator-link">
                    My ad's visibility
                  </Link>
                </li>
                <li className="help-navigator-item">
                  <Link to={" "} className="help-navigator-link">
                    Deactivate or reactivate my ad
                  </Link>
                </li>
              </ul>
            </li>
            <li className="help-navigator-item">
              <Link to={" "} className="help-navigator-link">
                Manage my lesson requests
              </Link>
            </li>
            <li className="help-navigator-item">
              <Link to={" "} className="help-navigator-link">
                Manage my lesson
              </Link>
            </li>
          </ul>
        </div>
        <div className="help-content">
          <div className="border-bottom pb-3">
            <Link to="/help-center" className="help-link">
              <IoIosArrowRoundBack /> Manage my ad
            </Link>
            <h4 className="help-category">Tutor Article</h4>
            <h3 className="help-heading">Create an ad</h3>
            <p className="help-text">
              Do you want to share and pass on your knowledge to incredible
              students?
            </p>
            <p className="help-text">
              Whether you're a teacher, student, artist, professional,
              self-taught or simply passionate, you can post your ad to give
              lessons and start the Superprof adventure.
            </p>
            <p className="help-text">
              Whether you're a teacher, student, artist, professional,
              self-taught or simply passionate, you can post your ad to give
              lessons and start the Superprof adventure.
            </p>
            <p className="help-text">
              Whether you're a teacher, student, artist, professional,
              self-taught or simply passionate, you can post your ad to give
              lessons and start the Superprof adventure.
            </p>
            <p className="tip-section">
              <FiInfo /> The quality of your ad is fundamental, take the time to
              ensure it looks as professional as possible. Also, be sure to put
              a nice photo of you, bright and smiling 😀
            </p>
          </div>
          <div className="mt-4">
            <h3 className="sub-help-heading">Related articles</h3>
            <div>
              <div>
                <Link to={" "} className="related-link">
                  <BsFileEarmarkText /> VedicHom Guidelines
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none mt-4 border-top pt-4">
          <h3 className="sub-help-heading">All Tutor Categories</h3>
          <Link to="/help-center" className="help-link-btn help-link">
            <IoIosArrowRoundBack /> Help Center
          </Link>
          <div>
            <div className="category-accordion">
              <Accordion flush>
                <Accordion.Item className="category-acc-item" eventKey="0">
                  <Accordion.Header className="category-acc-head">
                    Manage my ad
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div>
                      <ul className="category-acc-listing">
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Update my ad
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            My ad's visibility
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Deactivate or reactivate my ad
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="category-acc-item" eventKey="1">
                  <Accordion.Header className="category-acc-head">
                    Manage my lesson requests
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div>
                      <ul className="category-acc-listing">
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Update my ad
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            My ad's visibility
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Deactivate or reactivate my ad
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="category-acc-item" eventKey="2">
                  <Accordion.Header className="category-acc-head">
                    Manage my lessons
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div>
                      <ul className="category-acc-listing">
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Update my ad
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            My ad's visibility
                          </Link>
                        </li>
                        <li>
                          <Link className="category-acc-link" to="/single-help">
                            Deactivate or reactivate my ad
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SingleHelp
