import React from "react"
import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "../../../ScheduleSession/TeacherProfile/TeacherProfile.css"
import verifiedicon from "../../../../assets/img/verified-icon.svg"
import locationicon from "../../../../assets/img/location-icon.svg"
import webcam from "../../../../assets/img/cam-icon.svg"
import reviewimage from "../../../../assets/img/certified-image2.png"
import starimage from "../../../../assets/img/star-active-icon.svg"
import cardimage from "../../../../assets/img/card-image.png"
import heartimage from "../../../../assets/img/heart.svg"
import playbutton from "../../../../assets/img/play-icon.svg"
import profileverified from "../../../../assets/img/profile-verified.svg"

import LandingHeader from "../../../navbars/TopNavbar"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useGetProviderDetailsMutation } from "../../../../features/Auth/authApi"
import { useGetFAQQuery } from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import { useGetFAQMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import { useAppSelector } from "../../../../app/hooks"

const StudentView = () => {
  const { state } = useLocation()
  const {isMobileNoVerified} = useAppSelector((state:any)=>state.auth?.user)  
  const [selectedOption, setSelectedOption] = useState("realTime")
  const [loading, setLoading] = useState(true)
  setTimeout(() => setLoading(false), 3000)
  const [profile, { isError, error, data }] = useGetProviderDetailsMutation()
  useEffect(() => {
    if (state?.data?.user?.data?.allAdvertisements[state?.data?.id]?._id) {
      profile(state?.data?.user?.data?.allAdvertisements[state?.data?.id]?._id)
    } else {
      profile(state?.data?.user?.allAdvertisements[state?.data?.id]?._id)
    }
  }, [state])
  const [
    GetFAQ,
    { data: FAQdata, isSuccess: FAQSuccess, isLoading: FAQLoading },
  ] = useGetFAQMutation()
  useEffect(() => {
    if (data?.data?.adDetail?._id) {
      GetFAQ({
        adId: data?.data?.adDetail?._id,
      })
    }
  }, [data?.data?.adDetail?._id])
  
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      {loading ? (
        <div className="spin-loading">
          <SpinnerSecondary />
        </div>
      ) : (
        <section className="explore-section section-padding">
          <Container>
            <div className="inner-explore">
              <Row>
                <Col md={4}>
                  <div className="fav-card teacher-card">
                    <div className="fav-img relative">
                    <img src={data?.data?.adDetail?.profileImage || dummy} />
                   
                    
                    </div>
                   
                   
                    <div className="info-astrologer">
                      <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                        <div className="d-flex ms-2">
                        <h2 className="ast-name mb-2">
                          {data?.data?.adDetail?.providerId?.firstName +
                            " " +
                            data?.data?.adDetail?.providerId?.lastName}
                        </h2>
                        {isMobileNoVerified && (
                      
                        <img src={profileverified} />
                      
                      )}
                        </div>
                       
                          
                        <div className="rating mb-0">
                          <img src={starimage} />
                          <span className="total-rating">
                            {" "}
                            {
                              data?.data?.adDetail?.providerId?.rating
                                ?.$numberDecimal
                            }
                          </span>{" "}
                          <small className="reviews">
                            ({data?.data?.countReviews} Reviews)
                          </small>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="ast-ocp mb-2">Vedic Subject</p>
                        <p className="ast-ocp font-xl-20 font-16 fw-bold mb-lg-3 mb-0">
                          {data?.data?.adDetail?.vedicSubject}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap-wrap">
                        <p className="ast-ocp mb-lg-0 mb-2">Language</p>
                        <div className="d-flex gap-1 ms-3 flex-wrap">
                          {data?.data?.adDetail?.language &&
                            data.data.adDetail.language.map(
                              (language: string, index: number) => (
                                <p
                                  key={index}
                                  className="ast-ocp font-xl-20 font-16 mb-0 px-1"
                                >
                                  {language}
                                </p>
                              ),
                            )}
                        </div>
                      </div>

                      <a
                        // onClick={handleSchedule}
                        className="schedule-session-btn cursor-pointer"
                      >
                        Schedule a Session
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="ads-section">
                    <div className="ad-card shadow-none rounded-0 mb-4 pt-0">
                      <h3 className="ad-heading">Services Offered</h3>
                      <div className="session-listing-pill">
                        {data?.data?.adDetail?.providerId?.skills?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}
                        {data?.data?.adDetail?.services?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}{" "}
                      </div>
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <p className="about-teacher mb-0">
                        {data?.data?.adDetail?.title}
                      </p>
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">
                        About {data?.data?.adDetail?.providerId?.firstName}
                      </h3>
                      <p className="ad-text mb-0 mt-2">
                        {data?.data?.adDetail?.aboutYou}
                      </p>
                    </div>
                    <div className="ad-card shadow-none mb-4">
                      
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">About Session</h3>
                      {/* <div className="session-listing-pill mb-3">
                        {data?.data?.adDetail?.providerId?.skills?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}
                        {data?.data?.adDetail?.services?.map(
                          (item: any, index: number) => (
                            <span className="ses-pill-list" key={index}>
                              #{item}
                            </span>
                          ),
                        )}{" "}
                      </div> */}
                      <ul className="session-list">
                        <li>
                          <b>Session Description</b> -{" "}
                        {data?.data?.adDetail?.aboutClass}
                        </li>
                        {/* <li>
                          <b>Techniques Used</b> -
                        </li> */}
                      </ul>
                    </div>
                    <div className="ad-card shadow-none rounded-0 mb-4">
                      <h3 className="ad-heading">Modes</h3>
                      <div className="secondary-listing-pill">
                      <Form >
                            <Row>
                              <Col
                                className={`real-virtual-btn mx-2 ${
                                  selectedOption === "realTime"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div
                                  onClick={() => setSelectedOption("realTime")}
                                >
                                  <Form.Check
                                    type="radio"
                                    id="realTime"
                                    label="Real Time"
                                  
                                    checked={selectedOption === "realTime"}
                                    onChange={() =>
                                      setSelectedOption("realTime")
                                    }
                                    style={{paddingLeft:"0px"}}
                                  />
                                </div>
                              </Col>
                              <Col
                                className={`real-virtual-btn ${
                                  selectedOption === "virtual" ? "selected" : ""
                                }`}
                              >
                                <div
                                  onClick={() => setSelectedOption("virtual")}
                                >
                                  <Form.Check
                                    type="radio"
                                    id="virtual"
                                    label="Virtual"
                                    checked={selectedOption === "virtual"}
                                    onChange={() =>
                                      setSelectedOption("virtual")
                                    }
                                    style={{paddingLeft:"0px"}}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>

                            {selectedOption === "realTime" && (
                            <div className="secondary-listing-pill d-sm-flex gap-5">
                              <div className="price-feature-box border-0 my-1">
                                <div className="feature-title">GROUP</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {data?.data?.adDetail?.prices?.[selectedOption]?.group
                                        ?.hourlyFee
                                        ? "₹ " +
                                         data?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                        : " Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="groupSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2 "
                                    checked={
                                      data?.data?.adDetail?.prices?.[selectedOption]?.group
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="price-feature-box border-0  my-1">
                                <div className="feature-title">PRIVATE</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {data?.data?.adDetail?.prices?.[selectedOption]?.private
                                        ?.hourlyFee
                                        ? "₹" +
                                          data?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                        : "Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="privateSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                      data?.data?.adDetail?.prices?.[selectedOption]?.private
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedOption === "virtual" && (
                            <div className="secondary-listing-pill d-sm-flex gap-5">
                              <div className="price-feature-box border-0 my-1">
                                <div className="feature-title">GROUP</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {data?.data?.adDetail?.prices?.[selectedOption]?.group
                                        ?.hourlyFee
                                        ? "₹ " +
                                        data?.data?.adDetail?.prices?.[selectedOption]?.group
                                            ?.hourlyFee
                                        : " Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="groupSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                     data?.data?.adDetail?.prices?.[selectedOption]?.group
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="price-feature-box border-0  my-1">
                                <div className="feature-title">PRIVATE</div>
                                <div className="d-flex justify-content-between align-items-center feature-details">
                                  <p>Hourly Fee:</p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {data?.data?.adDetail?.prices?.[selectedOption]?.private
                                        ?.hourlyFee
                                        ? "₹" +
                                        data?.data?.adDetail?.prices?.[selectedOption]?.private
                                            ?.hourlyFee
                                        : "Not Provided"}
                                    </strong>
                                  </p>
                                </div>
                                <div className="feature-toggle">
                                  <Form.Check
                                    type="switch"
                                    id="privateSwitch"
                                    label="First Class Free"
                                    className="notify-toggle mb-0 mt-2"
                                    checked={
                                      data?.data?.adDetail?.prices?.[selectedOption]?.private
                                        ?.firstClassFree
                                    }
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    {data?.data?.countReviews != 0 && (
                      <div className="ad-card shadow-none rounded-0 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className="ad-heading mb-0">Reviews</h3>
                          <p className="ad-text mb-0">
                            {data?.data?.countReviews}
                          </p>
                        </div>
                        <div className="review-wrapper">
                          {data?.data?.providerReviews?.map(
                            (item: any, index: number) => (
                              <div
                                className="review-card-profile mb-3"
                                key={index}
                              >
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={reviewimage}
                                      className="me-2 review-img"
                                    />
                                    <p className="review-name">
                                      {item?.seekerId?.firstName +
                                        " " +
                                        item?.seekerId?.lastName}
                                    </p>
                                
                      
                                  </div>
                                  <span className="review-given d-flex align-items-center">
                                    <img src={starimage} />
                                    {item?.rating}
                                  </span>
                                </div>
                                <p className="review-text mb-0">
                                  {item?.message}
                                </p>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    )}

                    {data?.data?.recommendations && (
                      <div className="ad-card shadow-none rounded-0 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className="ad-heading mb-0">Recommendations</h3>
                        </div>
                        <div className="review-wrapper">
                          {data?.data?.recommendations ? (
                            <div className="review-card-profile mb-3">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={reviewimage}
                                    className="me-2 review-img"
                                  />
                                  <p className="review-name">Supreet</p>
                                </div>
                              </div>
                              <p className="review-text mb-0">
                                Lorem ipsum dolor sit amet consectetur. A nisi a
                                egestas sit faucibus. Nulla hendrerit at at
                                magna consequat ut adipiscing. Elit enim
                                placerat purus netus in. Volutpat sed fermentum
                                ornare in pellente lectus. Pretium pharetra
                                mattis eu quis.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="text-end">
                            <a className="view-text">View All</a>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="row">
                      <div className="col-md-12">
                        <div className="ad-card shadow-none rounded-0 mb-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="price-card border-0 pe-0">
                                <h3 className="ad-heading">Pricing</h3>
                                <ul className="pricing-list mb-0">
                                  <li>
                                    <div>
                                      <span>Hourly Fees</span>
                                      <span>
                                        ₹{data?.data?.adDetail?.hourlyRate}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <span>Packages</span>
                                      <p className="packing-list">
                                        <span>
                                          5hr-₹
                                          {data?.data?.adDetail?.hourlyRate * 5}
                                          /hr
                                        </span>
                                        <span>
                                          10hr-₹
                                          {data?.data?.adDetail?.hourlyRate *
                                            10}
                                          /hr
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                  <li className="mb-0">
                                    <div>
                                      <span>Webcam</span>
                                      <span>₹2,000/hr</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {data?.data?.adDetail?.youtubeLink && (
                      <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                        <h3 className="ad-heading">
                          {data?.data?.adDetail?.providerId?.firstName} video{" "}
                        </h3>
                        <iframe
                          width="100%"
                          height={315}
                          src={data?.data?.adDetail?.youtubeLink}
                          title="YouTube video player"
                          className="video-ad"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        />
                      </div>
                    )}

                    {FAQdata?.data.length > 0 && (
                      <div className="ad-card shadow-none rounded-0 bg-transparent shadow-none">
                        <h3 className="ad-heading">
                          Frequently
                          <span className="highlight-text">
                            Asked Questions
                          </span>
                        </h3>
                        {FAQdata?.data?.map((question: any, index: number) => {
                          return (
                            <div className="faq-section mt-2" key={index}>
                              <Accordion
                                className="faq-accordion"
                                defaultActiveKey="1"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="faq-head">
                                    <h6>{question.question}</h6>
                                  </Accordion.Header>
                                  <Accordion.Body className="faq-body">
                                    {question.answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  )
}

export default StudentView
