import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./SubjectDetail.css"
import { useGetVedicSubjectQuery } from "../../../../features/Auth/authApi"
import { useAppDispatch } from "../../../../app/store"
import { useNavigate } from "react-router-dom"

import {
  resetProviderFunel,
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../../app/hooks"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
// ;["Guides", "Source", "Mentors", "Vedic Gurus", "Teachers"]
const serviceOption = [
  // { value: "Teachers", label: "Teachers" },
  { value: "Source", label: "Source" },
  // { value: "Vedic Gurus", label: "Vedic Gurus" },
  // { value: "Mentors", label: "Mentors" },
  // { value: "Guides", label: "Guides" },
]
// const selectSkillOptions = [
//   { value: "Vedic Palmistry", label: "Vedic Palmistry" },
//   { value: "Vastu Shastra", label: "Vastu Shastra" },
// ]
const SubjectDetail = () => {
  const [selectedOption, setSelectedOption] = useState({
    service: null,
    subjects: null,
    skill: null,
  })

  interface VedicSubType {
    value: string
    label: string
  }

  type StateType = { value: string; label: string }[]
  const [selectedValue, setSelectedValue] = useState<any>([])
  const [skillSet, setSkillSet] = useState<any>([])
  const [vedicSub, setVedicSub] = useState<VedicSubType | null>(null)
  const [sub, setSub] = useState<any>([])
  const [infoactive, setInfoActive] = useState(false)
  const [selectSkillOptions, setSelectSkillOptions] = useState([
    { value: "", label: "" },
  ])

  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isError, error, data } = useGetVedicSubjectQuery()
  useEffect(() => {
    setSub(data?.data)
  }, [data])
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    mode: "onBlur",
  })
  const providerFunnelData = useAppSelector(
    (state: any) => state.providerFunnel.providerFunnelData,
  )

  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
    setSelectedValue: React.Dispatch<React.SetStateAction<StateType>>,
    updateField: string,
  ) => {
    const payload = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    dispatch(updateFunnel({ ...providerFunnelData, [updateField]: payload }))
  }

  const searchSkillSetBySubject = (subject: string) => {
    if ((vedicSub as any) !== (subject as any)) {
      setSkillSet([])
    }

    const subjectData = data?.data.find(
      (item: any) => item.label === (subject as any).label,
    )
    setSelectSkillOptions(
      subjectData.skillSet.map((skill: any) => ({
        value: skill,
        label: skill,
      })),
    )
  }
  useEffect(() => {
    const serviceOptions =
      Array.isArray(providerFunnelData?.services) &&
      providerFunnelData?.services?.map((serviceVal: any) => ({
        value: serviceVal,
        label: serviceVal,
      }))

    setSelectedValue(serviceOptions)

    setVedicSub((prev) => ({
      ...prev,
      value: providerFunnelData?.vedicSubject,
      label: providerFunnelData?.vedicSubject,
    }))
  }, [providerFunnelData])
  useEffect(() => {
    dispatch(setIsStepValid(true))
    if (skillSet.length > 0 && selectedValue.length > 0 && vedicSub) {
      dispatch(setIsStepValid(false))
    }
  }, [skillSet.length, selectedValue.length, vedicSub])

  useEffect(() => {
    const skillSetOption =
      Array.isArray(providerFunnelData?.skillSet) &&
      providerFunnelData?.skillSet.map((skillSetVal: any) => ({
        value: skillSetVal,
        label: skillSetVal,
      }))
    setSkillSet(skillSetOption)
    
  }, [])

  
  return (
    <>
      <section className="py-lg-5 funnel-section">
        <Container fluid="lg">
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="pb-4 mb-4">
                  <p className="get-started-text fw-normal">
                    What You are providing{" "}
                    <span className=" text-danger">*</span>
                  </p>
                  <div className="multi-select-custom">
                    {/* <select className="select-source-custom" onSelect={()=> dispatch(
                          updateFunnel({
                            services:"Source" 
                          }),
                        )}>
                      <option value="Source">Source</option>
                    </select> */}
                    <FormMultiSelect
                      title=""
                      placeholder="Select"
                      control={control}
                      options={serviceOption}
                      onChange={(option) => {
                        handleSelectChange(option, setSelectedValue, "services")
                      }}
                      className="multi-select-bx"
                      name="services"
                      value={selectedValue as any}
                    />
                  </div>
                </div>
                <div className="pb-4 mb-4">
                  <p className="get-started-text fw-normal">
                    Which Vedic subjects are you proficient in teaching
                    <span className=" text-danger">*</span>
                  </p>

                  <div className="multi-select-custom position-relative">
                    {vedicSub?.value === "" && (
                      <label className="selectlabel">
                        Select one or Multiple
                      </label>
                    )}
                    <Select
                      onChange={(option) => {
                        dispatch(
                          updateFunnel({
                            vedicSubject: (option as any)?.label,
                          }),
                        )
                        setVedicSub(option as VedicSubType | null)
                        searchSkillSetBySubject(option as any)
                      }}
                      options={sub}
                      value={vedicSub}
                      styles={{
                        menu: (provided) => ({ ...provided, bottom: "auto" }),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="get-started-text fw-normal">
                    Select Skill set<span className=" text-danger">*</span>
                  </p>
                  <div className="multi-select-custom">
                    <FormMultiSelect
                      title=""
                      control={control}
                      options={selectSkillOptions}
                      onChange={(option) => {
                        handleSelectChange(option, setSkillSet, "skillSet")
                      }}
                      className="multi-select-bx"
                      name="skillSet"
                      value={skillSet}
                      disabled={selectSkillOptions[0].value === ""}
                    />
                  </div>
                </div>
                {/* {isStepValid && (
                  <div className="mt-3 text-danger">
                    All these are required fields*
                  </div>
                )} */}
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-3 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3">
                      Make it <br />
                      better!
                    </h3>
                  </div>
                  <h3 className="field-heading">
                    <span className=" text-black">*</span> Indicate as required
                    field
                  </h3>
                  <p className="font-lg-20 mb-3">
                    Lorem ipsum dolor sit amet consectetur. Eget quis dignissim
                    ac vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                    sagittis nulla ac luctus mus imperdiet. Tellus dui tempus
                    blandit cras. Et vel adipiscing ut at faucibus praesent
                    neque id orci.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SubjectDetail
