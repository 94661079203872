import React from "react"
import { Card } from "react-bootstrap"
import {
  BiLogoFacebook,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidCopy,
} from "react-icons/bi"
import { toast } from "react-toastify"
import twitterIcon from "../../../../assets/img/twitterIcon.svg"
const Recommendations = () => {
  const handleCopyText = () => {
    const currentURL = window.location.href
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        toast.success("Link copied to Clipboard")
      })
      .catch((error) => {
        console.error("Error copying URL to clipboard:", error)
      })
  }

  return (
    <div>
      {" "}
      <Card className="ad-card mb-4">
        <h3 className="ad-heading">Recommendations</h3>
        <Card.Body className="p-0">
          <ul className="social-media-list">
            <li>
              <a className="social-link" onClick={handleCopyText}>
                <span>
                  <BiSolidCopy />
                </span>
                Copy Link
              </a>
            </li>
            <li>
              <a
                className="social-link"
                href="https://twitter.com/intent/tweet?text=I%20am%20having%20great%20experience%20with%20this%20application&url=https://vedichome.online&hashtags=vedichom"
                target="_blank"
              >
                <span>
                  <img
                    src={twitterIcon}
                    alt="twitterIcon"
                    style={{ color: "black", height: "20px" }}
                  />
                </span>
                Twitter
              </a>
            </li>
            <li>
              <a
                className="social-link"
                href="
                https://www.linkedin.com/shareArticle?mini=true&url=https://vedichome.online&title=Great%20Experience%20with%20VedicHom&summary=I%20am%20having%20great%20experience%20with%20this%20application"
                target="_blank"
              >
                <span>
                  <BiLogoLinkedin />
                </span>
                Linkedin
              </a>
            </li>
            <li>
              <a
                className="social-link"
                target="_blank"
                href="https://www.facebook.com/sharer.php?
            u=https://vedichome.online&
            display=popup&
            quote=I%20am%20very%20delighted%20to%20share%20that,%20I%20had%20a%20great%20experience%20with%20VedicHom.&
            hashtag=relevant_hashtags"
              >
                <span>
                  <BiLogoFacebook />
                </span>
                Facebook
              </a>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Recommendations
