import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Table,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import reviewimage from "../../../../assets/img/card-image.png"
import { AiOutlineHeart } from "react-icons/ai"
import "./ReceivedReview.css"

import ReplyModal from "../../../modals/ReplyModal/ReplyModal"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useSendReviewReplyMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import redheart from "../../../../assets/img/redheart.png"
import { providerDashboardReplyReviewSchema } from "../../../../utils/yupSchema/Schema"
const ReplyReview = ({ data, id }: any) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [redHeart, setRedHeart] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  const [replymsg, setReplyMsg] = useState("")
  const [relpyMessage, { data: sendReviewResponse, isSuccess }] =
    useSendReviewReplyMutation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<any>({
    resolver: providerDashboardReplyReviewSchema,
  })
  const onSubmit = async (values: any) => {
    const payload = {
      message: values.replymsg,
      reviewId: data?.data[id]?._id,
    }
    const response = await relpyMessage(payload)
    if (response) {
      handleShow()
      setTimeout(() => {
        handleClose()
        setReplyMsg("")
        reset()
      }, 3000)
    }
  }
  return (
    <>
      <section className="my-account-sec py-5">
        <Container fluid>
          <div className="inner-account">
            <Row className="justify-content-center">
              <Col xl={7} lg={9}>
                <div className="complete-profile">
                  <div className="review-card2">
                    <div className="review py-4">
                      <div className="review-user d-flex align-items-center mb-5">
                        <img src={reviewimage} className="review-img" />
                        <p className="review-name">
                          {data?.data?.providersReview[id]?.seekerId.firstName}
                        </p>
                      </div>
                      <div className="review-posted mb-4">
                        <span className="review-head">Review</span>
                        <textarea
                          className="form-control review-field review-textarea"
                          placeholder="Enter your review"
                          defaultValue={
                            data?.data?.providersReview[id]?.message
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="review-react">
                        <p>
                          React{" "}
                          {!redHeart ? (
                            <span onClick={() => setRedHeart(!redHeart)}>
                              <AiOutlineHeart className="ms-2" />
                            </span>
                          ) : (
                            <img
                              src={redheart}
                              onClick={() => setRedHeart(!redHeart)}
                              className="redheartimage"
                            />
                          )}
                        </p>
                      </div>
                      <div className="reply-review-post mb-4">
                        <textarea
                          className="form-control review-field review-textarea"
                          placeholder="Enter your reply"
                          {...register("replymsg")}
                          name="replymsg"
                          value={replymsg}
                          onChange={(e) => setReplyMsg(e.target.value)}
                        />
                        {errors.replymsg &&
                          typeof errors.replymsg.message === "string" && (
                            <p className="text-danger">
                              {errors.replymsg.message}
                            </p>
                          )}
                      </div>
                      <div className="review-btnlist text-end">
                        <a href="#" className="reply-btn send-review-btn">
                          <a onClick={handleSubmit(onSubmit)}>Send</a>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <ReplyModal
        show={show}
        handleClose={handleClose}
        data={data?.data.providersReview[id]}
      />
    </>
  )
}

export default ReplyReview
