import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
// const BASE_URL = "http://192.168.29.64:3003"

export const seekerDashboardApi = createApi({
  reducerPath: "seekerDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    "getseekerProfile",
    "seekerRequests",
    "searchLandingPage",
    "GetFavourites",
  ],
  endpoints: (builder) => ({
    seekerRequests: builder.query<any,any>({
      query: (arg: void) => ({ url: "/seeker/get-request" }),
      providesTags: ["seekerRequests"],
    }),
    seekerReviews: builder.query({
      query: (args: void) => "/seeker/get-reviews",
    }),
    getseekerAcceptedReviewsList: builder.query({
      query: (args: void) => "/seeker/get-list-of-accepted-requests",
    }),
    getseekerProfile: builder.query({
      query: (args: void) => "/seeker/get-profile",
      providesTags: ["getseekerProfile"],
    }),

    updateSeekerProfile: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/update-profile`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getseekerProfile"],
    }),
    changeSeekerPassword: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/change-password`,
        method: "POST",
        body: data,
      }),
    }),
    deleteSeekerAccount: builder.mutation({
      query: (args: void) => ({
        url: `seeker/delete-account`,
        method: "POST",
      }),
    }),
    createSeekerReview: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-review`,
        method: "POST",
        body: data,
      }),
    }),
    seekerSearch: builder.mutation<any, any>({
      query: (data) => ({
        url: `/seeker/search-sort-filter-request?searchOption=${data.search}&requestStatus=${data.status}&sortByDate=${data.sort}`,
      }),
      transformResponse: (rawData) =>
        transformSeekerRequestSearchResponse(rawData),
    }),
    acceptDeleteRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-review`,
        method: "POST",
        body: data,
      }),
    }),
    // search landing page
    searchLandingPage: builder.mutation<any, any>({
      query: (data) =>
        data != null
          ? `/seeker/search-sort-providers?services=${
              data?.teacherType
            }&vedicSubject=${data?.VedicSubject}&sortOrder=${
              data?.sortOrder
            }&sortBy=${data?.sortBy || ""}&pageLimit=8&pageNo=${data?.pageNo}`
          : `/seeker/search-sort-providers`,
    }),
    createSeekerRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-request`,
        method: "POST",
        body: data,
      }),
      invalidatesTags:["seekerRequests"]
    }),
    seekerDeleteRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/delete-request`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["seekerRequests"],
    }),

    seekerRequestSorting: builder.mutation<any, any>({
      query: (sort) => ({
        url: `/seeker/sort-request?sortOrder=${sort}`,
      }),
    }),
    seekerSentReviews: builder.query({
      query: (rated) => `/seeker/get-rated-unrated-reviews?rated=${rated}`,
    }),
    // PymentGateway

    paymentCheckout: builder.mutation<any, any>({
      query: (amount) => ({
        url: `/seeker/payment/checkout`,
        method: "POST",
        body: amount,
      }),
    }),
    paymentVerify: builder.mutation<any, any>({
      query: (data) => ({
        url: `/seeker/payment/payment-verify?orderId=${data?.id}`,
        method: "POST",
        body: data?.data,
      }),
      invalidatesTags: ["getseekerProfile"],
    }),
    paymentKey: builder.query({
      query: (args: void) => `/seeker/payment/get-key`,
    }),

    addToFav: builder.mutation<any, any>({
      query: (data) => ({
        url: `/seeker/make-fav-ad?adId=${data}`,
        method: "GET",
      }),
      invalidatesTags: ["GetFavourites"],
    }),
    uploadDocuments: builder.mutation<any, any>({
      query: (data) => ({
        url: `/seeker/upload-docs`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getseekerProfile"],
    }),
    GetFavourites: builder.query({
      query: (args: void) => `/seeker/get-fav-ads`,
      providesTags: ["GetFavourites"],
    }),
    GetFAQ: builder.query({
      query: (args: void) => `/static-data/get-faq?category=General`,
    }),
    GetSeekerFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `/seeker/get-faq?adId=${data?.adId}`,
        method: "GET",
      }),
    }),
    seekerPhoneVerification: builder.mutation({
      query: (args: void) => ({
        url: "seeker/send-mobile-otp",
        method: "GET",
      }),
    }),

    seekerVerifyPhoneOtp: builder.mutation({
      query: (data) => ({
        url: "seeker/verify-mobile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getseekerProfile"],
    }),
  }),
})

export const {
  useSeekerRequestsQuery,
  useSeekerReviewsQuery,
  useAcceptDeleteRequestMutation,
  useGetseekerAcceptedReviewsListQuery,
  useGetseekerProfileQuery,
  useUpdateSeekerProfileMutation,
  useChangeSeekerPasswordMutation,
  useCreateSeekerReviewMutation,
  useSearchLandingPageMutation,
  useCreateSeekerRequestMutation,
  useSeekerRequestSortingMutation,
  useSeekerSentReviewsQuery,
  useSeekerSearchMutation,
  useSeekerDeleteRequestMutation,
  usePaymentCheckoutMutation,
  usePaymentKeyQuery,
  usePaymentVerifyMutation,
  useDeleteSeekerAccountMutation,
  useAddToFavMutation,
  useGetFavouritesQuery,
  useGetFAQQuery,
  useUploadDocumentsMutation,
  useGetSeekerFAQMutation,
  useSeekerPhoneVerificationMutation,
  useSeekerVerifyPhoneOtpMutation,
} = seekerDashboardApi
export const transformSeekerRequestSearchResponse = (rawData: any) => {
  if (!rawData || !rawData.data) return null;

  return {
    totalRequests: rawData.totalRequests,
    data: rawData.data.map((item: any) => ({
      _id: item?._id || null,
      seekerId: item.seekerId || null,
      adId: {
        _id: item?._id || null,
        providerId: {
          _id: item.providerData?.[0]?._id || null,
          firstName: item.providerData?.[0]?.firstName || null,
          lastName: item.providerData?.[0]?.lastName || null,
          profilePic: item.providerData?.[0]?.profilePic || null,
        },
        vedicSubject: item.adData?.[0]?.vedicSubject || null,
      },
      sessionPreference: item.sessionPreference || null,
      date: item.updatedAt || null,
      sessionForWhom: item.sessionForWhom || null,
      requestMessage: item.requestMessage || null,
      isDeleted: item.isDeleted || null,
      requestStatus: item.requestStatus || null,
      createdAt: item.createdAt || null,
      updatedAt: item.updatedAt || null,
      __v: item.__v || null,
    })),
  };
};
