import React from "react"
import { Row, Col } from "react-bootstrap"
import "../LandingPage.css"
import {
    GiMusicSpell,
    GiNotebook,
    GiPiercingSword,
    GiSpotedFlower,
} from "react-icons/gi"
import { GrYoga } from "react-icons/gr"
import { GiMeditation } from "react-icons/gi"
import { MdSunnySnowing } from "react-icons/md"
import { PiFlowerLotusThin } from "react-icons/pi"
import LandingSearch from "../../../searchBars/LandingSearchBar"
import { setSelectedSearchText } from "../../../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppDispatch } from "../../../../app/store"

const BannerSearchSection = () => {
    const dispatch = useAppDispatch()
    const subjects = [
        { name: "Ayurveda", icon: <GiSpotedFlower /> },
        { name: "Dharma Shastra", icon: <PiFlowerLotusThin /> },
        { name: "Dance", icon:  <GrYoga /> },
        { name: "Sanskrit", icon: <GiNotebook /> },
        { name: "Surya Namaskar", icon: <MdSunnySnowing /> },
        { name: "Vedic Rituals", icon: <GiPiercingSword /> },
        { name: "Vastu Shastra", icon: <GiMusicSpell /> },
        { name: "Yoga", icon:< GiMeditation /> },   
    ]

    return (
        <div> <div className="inner-hero">
            <Row className="justify-content-center">
                <Col md={11}>
                    <div>
                        <h1 className="hero-header mb-3">
                            What You Seek Is Seeking You
                        </h1>
                        <p className="hero-text mb-md-5 mb-4">
                        “Discover Your Path: Connect with sources you are seeking on VedicHom”
                        </p>

                        <LandingSearch />
                        <div className="keywords-wrapper">
                            <div className="inner-keyword-wrapper">
                                {subjects.map((subject, index) => (
                                    <button
                                        id="yourButtonId"
                                        key={index}
                                        className="keyword-btn"
                                        onClick={() =>
                                            dispatch(setSelectedSearchText(subject.name))
                                        }
                                    >
                                        {subject.icon} <span>{subject.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div></div>
    )
}

export default BannerSearchSection