import React, { useState } from "react"
import {
  Container,
  Card,
  Button,
  Form,
  InputGroup,
  FormControl,
  Nav,
  Row,
  Col,
  Tab,
  Modal,
  Spinner,
} from "react-bootstrap"
import filtericon from "../../../../assets/img/filter-icon.svg"
import searchicon from "../../../../assets/img/icon-search.svg"
import sorticon from "../../../../assets/img/sort.svg"
import userImg from "../../../../assets/img/card-image.png"
import callIcon from "../../../../assets/img/call-icon.svg"
import SpinnerSecondary from "../../spinner/SpinnerSecondary"
import { useDispatch } from "react-redux"
import { useSeekerDeleteRequestMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { setAcceptRejectRequest } from "../../../features/featSeekerDashboard/seekerDashboardSlice"

interface deleteModalProps {
  data: any
}

const DeleteRequestModal = ({ data }: deleteModalProps) => {
  const [showModal, setshowModal] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [deleteRequest, { data: delData, isLoading }] =
    useSeekerDeleteRequestMutation()
  const handleShowModal = () => {
    setshowModal(!showModal)
  }
  const handleDelete = async () => {
    const result = await deleteRequest({
      requestId: data?.requestData?.data?._id,
    })
    if (result) {
      handleShowModal()
      dispatch(setAcceptRejectRequest(true as any))
    }
  }

  return (
    <>
      <div className="text-end">
        <Button
          variant="danger"
          className="cancel-request"
          onClick={handleShowModal}
        >
          Delete Request
        </Button>
      </div>

      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title className="delete-heading"> Delete Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="inner-fav mb-5">
              <Row className="justify-content-center">
                <Col>
                  <div className="d-flex justify-content-between flex-column h-70 text-center">
                    <div>
                      <p className="get-started-text mb-1">
                        Are you sure to delete your Request Permanently !
                      </p>
                      {isLoading ||
                        (isLoading && (
                          <div>
                            <SpinnerSecondary />
                          </div>
                        ))}
                    </div>
                    <div className=" d-flex justify-content-center mt-3">
                      {" "}
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={handleShowModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="Delete-button"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DeleteRequestModal
