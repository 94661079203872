import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./CreateAdvert.css"
import { useAppDispatch } from "../../../../app/store"
import {
  updateFunnel,
  setIsStepValid,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { useAppSelector } from "../../../../app/hooks"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { toast } from "react-toastify"

interface SelectDetails {
  aboutClass: string
  addTitle: string
  aboutYou: string
  youtubeLink: string
}
const CreateAdvert = () => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState<any>("")
  const [classValue, setClassValue] = useState<any>("")
  const [aboutYouValue, setAboutYouValue] = useState<any>("")
  const [letterCount, setLetterCount] = useState<any>(0)
  const [aboutYouCount, setAboutYouCount] = useState<any>(0)
  const [ytCount, setYtCount] = useState<any>(0)
  const [classCount, setClassCount] = useState<any>(0)

  const [focusField, setFocusField] = useState<string>("")
  const [youtubeLink, setYoutubeLink] = useState<string>("")
  const [selectDetails, setSelectDetails] = useState<SelectDetails>({
    addTitle: "",
    aboutClass: "",
    aboutYou: "",
    youtubeLink: "",
  })
  const { isStepValid } = useAppSelector((state) => state.providerFunnel)
  const [maxLetterCount, setMaxLetterCount] = useState<number>()
  const [infoactive, setInfoActive] = useState(false)
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }
  const maxLetters = 50
  const minLetterCount = 10
  const handleTitleChange = (
    e: any,
    setValue: any,
    setCount: any,
    name: any,
  ) => {
    const inputValue = e.target.value
    const words = inputValue
      .trim()
      .split(/\s+/)
      .filter((word: string) => word !== "")
    if (words.length > 50) {
      setMaxLetterCount(words.length)
      return
    }

    setCount(words.length)
    if (name === "youtubeLink") {
      setValue({
        value: inputValue,
        count: inputValue.length,
      })
      dispatch(updateFunnel({ [name]: inputValue }))
    }
    if (words.length <= maxLetters) {
      setValue({
        value: inputValue,
        count: words.length,
      })
      dispatch(updateFunnel({ [name]: inputValue }))
    }
    if (
      (title.count >= minLetterCount &&
        classValue.count >= minLetterCount &&
        aboutYouValue.count >= minLetterCount) ||
      (providerFunnelData?.title.trim().split(/\s+/).length < 0 &&
        providerFunnelData?.aboutClass.trim().split(/\s+/).length < 0 &&
        providerFunnelData?.aboutYou.trim().split(/\s+/).length < 0)
    ) {
      dispatch(setIsStepValid(false))
    } else {
      dispatch(setIsStepValid(true))
    }
  }

  const providerFunnelData = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )

  useEffect(() => {
    const aboutCountInit = providerFunnelData.aboutYou
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length
    const letterCountInit = providerFunnelData.title
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length
    const classCountInit = providerFunnelData.aboutClass
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length
    setAboutYouCount(aboutCountInit)
    setLetterCount(letterCountInit)
    setClassCount(classCountInit)

    if (aboutCountInit >= 10 && letterCountInit >= 10 && classCountInit >= 10) {
      dispatch(setIsStepValid(false))
    }
  }, [providerFunnelData])


  return (
    <>
      <section className="py-lg-5 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="pb-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      Add Title for your Ad{" "}
                      <span className=" text-danger">*</span>
                    </p>
                    <div className="letter-count-info font-14 d-flex">
                      {letterCount}/{maxLetters}
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    onChange={(e) =>
                      handleTitleChange(e, setTitle, setLetterCount, "title")
                    }
                    value={title.value || providerFunnelData?.title}
                    // maxLength={maxLetterCount}
                    onFocus={() => setFocusField("Title")}
                    className="shadow-none rounded-4 font-14 py-2"
                    onBlur={() => setFocusField("")}
                    style={{
                      height: "151px",
                    }}
                  />
                  {focusField !== "Title" &&
                    letterCount > 1 &&
                    letterCount < 10 && (
                      <div
                        className="mt-2 font-14"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 10 Words are required*
                      </div>
                    )}
                </div>
                <div className="pb-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      About the Class <span className=" text-danger">*</span>
                    </p>
                    <div className="letter-count-info d-flex font-14">
                      {classCount}/{maxLetters}
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    onChange={(e) =>
                      handleTitleChange(
                        e,
                        setClassValue,
                        setClassCount,
                        "aboutClass",
                      )
                    }
                    value={
                      (classValue as any).value ||
                      providerFunnelData?.aboutClass
                    }
                    // maxLength={maxLetterCount}
                    className="shadow-none rounded-4 font-14 py-2"
                    onFocus={() => setFocusField("aboutClass")}
                    onBlur={() => setFocusField("")}
                    style={{
                      height: "151px",
                    }}
                  />
                  {focusField !== "aboutClass" &&
                    classCount > 1 &&
                    classCount < 10 && (
                      <div
                        className="font-14 mt-2"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 10 words are required*
                      </div>
                    )}
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="get-started-text fw-normal mb-0">
                      About You <span className=" text-danger">*</span>
                    </p>
                    <div className="letter-count-info font-14 d-flex">
                      {aboutYouCount}/{maxLetters}
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    // rows={10}
                    onChange={(e) =>
                      handleTitleChange(
                        e,
                        setAboutYouValue,
                        setAboutYouCount,
                        "aboutYou",
                      )
                    }
                    value={
                      (aboutYouValue as any).value ||
                      providerFunnelData?.aboutYou
                    }
                    // maxLength={maxLetterCount}
                    onFocus={() => setFocusField("aboutYou")}
                    onBlur={() => setFocusField("")}
                    className="shadow-none rounded-4 font-14 py-2"
                    style={{
                      height: "151px",
                    }}
                  />
                  {focusField !== "aboutYou" &&
                    aboutYouCount > 1 &&
                    aboutYouCount < 10 && (
                      <div
                        className="font-14 mt-2"
                        style={{ marginLeft: "0", color: "red" }}
                      >
                        Minimum 10 words are required*
                      </div>
                    )}
                </div>
                <div className="mt-3 mb-5">
                  <p className="get-started-text fw-normal">
                    Upload youtube link{" "}
                    <span className="text-black-50">(if any)</span>
                  </p>
                  <p className=" text-black-50">
                    Upload your youtube channel link so that students can visit
                    and see your work{" "}
                  </p>
                  <Form.Control
                    type="text"
                    value={
                      (youtubeLink as any).value ||
                      providerFunnelData?.youtubeLink
                    }
                    onChange={(e) =>
                      handleTitleChange(
                        e,
                        setYoutubeLink,
                        setYtCount,
                        "youtubeLink",
                      )
                    }
                    placeholder="paste URL"
                    className="py-3 shadow-none font-14"
                    style={{
                      borderRadius: "10px",
                      paddingLeft: "1rem",
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                      Make it better!
                    </h3>
                  </div>
                  <p className="font-lg-20 mb-0">
                    Lorem ipsum dolor sit amet consectetur. Eget quis dignissim
                    ac vestibulum sapien nunc adipiscing odio. Etiam nunc tempor
                    sagittis nulla ac luctus mus imperdiet. Tellus dui tempus
                    blandit cras. Et vel adipiscing ut at faucibus praesent
                    neque id orci.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default CreateAdvert
