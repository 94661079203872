import React, { useState } from "react"
import {
  Container,
  Card,
  Modal,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import "./EditAbout.css"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useUpdateAdvertisementMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { editAboutAdSchema } from "../../../../utils/yupSchema/Schema"
import { toast } from "react-toastify"
interface CardProps {
  show: boolean
  handleClose: () => void
  about: any
}
const EditAbout = ({ show, handleClose, about }: CardProps) => {
  const [EditTitle, { data, error }] = useUpdateAdvertisementMutation()
  const [wordCount, setWordCount] = useState<number>(0)
  const [focus, setFocus] = useState<boolean>(false)
  const [maxLetterCount, setMaxLetterCount] = useState<number>()
  const { handleSubmit, register,resetField } = useForm<any>({
    resolver: editAboutAdSchema,
  })

  const handleAboutYouChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const content = event.target.value
    setFocus(true)
    const words = content
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "")
    setWordCount(words.length)
    if (words.length > 50) {
      toast.dismiss()
      toast.error("Maximum words limit reached")
      const truncatedContent = words.slice(0, 50).join(" ")
      event.target.value = truncatedContent
      setWordCount(50)
      setMaxLetterCount(50)
      return
    }
  }

  const onSubmit = async (values: any) => {
    const payload = {
      aboutYou: values.aboutYou,
    }
    const response = await EditTitle({ servicedata: payload, id: about._id })
    if (response) {
      resetField("aboutYou")
      handleClose()
      setFocus(!focus)
    }
  }
  const handleModalClose =()=>{
  resetField("aboutYou")
  handleClose()
  setFocus(false)
  
}
  return (
    <>
      <Modal show={show}>
        <Row>
          <Col lg={6}>
            <div className="edit-card">
              <h3 className="mb-4 text-center teaching-head">About You</h3>
              <div className="titles-1 px-3">
                <Form.Control
                  as="textarea"
                  rows={4}
                  className="rounded-3 custom-textarea-lg"
                  defaultValue={about?.aboutYou}
                  disabled={true}
                />
              </div>

              <div className="title-2 px-3 py-2 mt-3">
                <h6>Make it better!</h6>
                <Form.Control
                  as="textarea"
                  rows={4}
                  {...register("aboutYou")}
                  name="aboutYou"
                  className="rounded-3 custom-textarea-sm"
                  placeholder="Enter about Yourself"
                  //  onFocus={setIsAboutYouFocus(true)}
                  onChange={handleAboutYouChange}
                  defaultValue=""
                  // maxLength={maxLetterCount}
                />
              </div>
              {wordCount < 10 && focus && (
                <p className=" text-danger">
               Minimum 10 words are required
                </p>
              )}
              <div className="word-limit py-2">
                <p>{wordCount}/50 words</p>
              </div>
              {/* {isAboutYouFocus && (
                <p className="alert text-danger">Minimum 10 words required</p>
              )} */}

              <div className="active-button">
                <div className="d-sm-flex justify-content-between px-md-4 px-2 w-100 mt-5">
                  <Button
                    className="btn bottom-btn cancel-edit-btn mb-sm-0 mb-3"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn bottom-btn save-edit-btn"
                    onClick={handleSubmit(onSubmit)}
                    disabled={wordCount < 10}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default EditAbout
function watch(arg0: string, aboutYou: any) {
  throw new Error("Function not implemented.")
}
