import { useEffect, useState } from "react"
import LandingHeader from "../../../navbars/TopNavbar"
import thankyouvedic from "../../../../assets/img/thankyou-vedic.png"
import { Link, useLocation } from "react-router-dom"
import { useAppSelector } from "../../../../app/hooks"
import ProviderCards from "../../../cards/providerCards"
import "./SeekerStepSix.css"
const ThankyouVedic = () => {
  const { state } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const dataProviders = useAppSelector((state: any) => state.auth.data)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => setLoading(false), 3000)
  }, [])
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      <div className="thankyoucontainer">
        {state?.error ? (
          <div className="text-center d-flex justify-content-center">
            <h1 className="thankyou-error mt-4">
              {state?.error?.data.message}
              <p>
              <Link to={"/"}  className="common-btn d-inline-block w-auto mt-4 text-decoration-none">
                  Go to home
                </Link>
              </p>
            </h1>
          </div>
        ) : loading ? (
          <div className="d-flex justify-content-center flex-column">
            <div className="text-center">
              <img src={thankyouvedic} className="thankyou-img" />
              <h3 className="thank-head">GREAT!</h3>
              <p className="thankyou-text mb-4">
                Thankyou for sharing your preferences
              </p>
              <p className="subhead-thankyou">
                Please wait we are loading the related results...
              </p>
            </div>
          </div>
        ) : (
          <div className="cards-wrapper">
            {dataProviders?.length && (
              <ProviderCards
                    data={dataProviders}
                    key={""}
                    searchError={""}
                    sortBy={false}
                    page={1} sortOrder={false}              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ThankyouVedic
