import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Form } from "react-bootstrap"
import mailicon from "../../../../assets/img/mail-icon.svg"
import passwordicon from "../../../../assets/img/password-icon.svg"
import facebookimage from "../../../../assets/img/facebook-icon.png"
import googleimage from "../../../../assets/img/google-icon.png"
import profilecircle from "../../../../assets/images/profilecircle.svg"
import phone from "../../../../assets/images/phone.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import FormInput from "../../../form/FormInput"
import { useAppDispatch } from "../../../../app/store"
import { useNavigate } from "react-router-dom"
import {
  useGoogleSignupSeekerMutation,
  useGoogleSignupMutation,
  useUserRegisterMutation,
} from "../../../../features/Auth/authApi"
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME, USERTYPE } from "../../../../constants"
import { useProviderRegisterMutation } from "../../../../features/Auth/authApi"
import EmailVerificationModal from "../EmailVerification/EmailVerificationModal"
import { useAppSelector } from "../../../../app/hooks"
import { passwordRegex } from "../../../../utils/regexPattern/Pattern"
import OTP from "../../../Verification/Number/Otp"
import { setCurrentUser } from "../../../../features/Auth/authSlice"
import { MdEmail } from "react-icons/md"
import { seekerSignupSchema } from "../../../../utils/yupSchema/Schema"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "../authComponent.css"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
const ENDPOINT_URL_PROVIDER = import.meta.env
  .VITE_REACT_APP_GOOGLE_SIGN_UP_PROVIDER
const ENDPOINT_URL_SEEKER = import.meta.env.VITE_REACT_APP_GOOGLE_SIGN_UP_SEEKER
type Props = { userType: any }
interface SignUpUser {
  firstName: string
  email: string
  lastName: string
  password: string
  phone: any
}

export default function SignupSeeker({ userType }: Props) {
  const lastNameInputRef = React.createRef()
  const emailInputRef = React.createRef()
  const phoneInputRef = React.createRef()
  const passwordInputRef = React.createRef()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isFormVisible, setFormVisible] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<any>()

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible)
  }
  const [
    submitProfile,
    { isError: providerisError, error: providerError, data: providerData },
  ] = useProviderRegisterMutation()
  const [googleSignup, { data: googleData }] = useGoogleSignupMutation()
  const [googleLoginSeeker, { data: googleDataSeeker }] =
    useGoogleSignupSeekerMutation()
  const [userRegister, { data, isError, error, isSuccess }] =
    useUserRegisterMutation()

  const {
    control,
    handleSubmit,
    register,
    reset,

    formState: { errors },
  } = useForm<SignUpUser>({
    resolver: seekerSignupSchema,
  })
  const onSubmit = (values: any) => {
    const payload = {
      firstName: values.firstName,
      email: values.email,
      password: values.password,
      lastName: values.lastName,
      mobileNo: parseInt(values.phone, 10),
    }
    const handleSeekerRegistration = async (payload: any) => {
      try {
        const response = await userRegister(payload)
        dispatch(setCurrentUser(USERTYPE.SEEKER))
      } catch (error) {
        console.error("Error during registration:", error)
      }
    }
    handleSeekerRegistration(payload)
  }

  useEffect(() => {
    if (data?.message || providerData?.message) {
      toast.success(data?.message || providerData?.message)
      dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
    } else {
      toast.error(
        (error as any)?.data?.message || (providerError as any)?.data?.message,
      )
    }
  }, [data, error, providerError, providerData])

  const handleGoogleLogin = async () => {
    window.location.replace(`${BASE_URL}/api/seeker/auth/google`)
  }
  const handleKeyDown = (e: any, nextInputRef: any) => {
    if (e.key === "Enter") {
      e.preventDefault()
      nextInputRef.current.focus()
    }
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        <div
          className={
            isFormVisible
              ? "signup-form-wrapper mb-3 active"
              : "signup-form-wrapper mb-3"
          }
        >
          <div className="popup-field-wrapper mt-1 w-100">
            <img src={profilecircle} alt="Mail Icon" />
            <FormInput
              name="firstName"
              placeholder="First Name"
              register={register}
              // errors={errors}
              className="popup-field w-100"
              onKeyDown={(e: any) => handleKeyDown(e, lastNameInputRef)}
            />
          </div>
          <span className="error">{errors?.firstName?.message}</span>
          <div className="popup-field-wrapper mt-3 w-100">
            <img src={profilecircle} alt="Password Icon" />

            <FormInput
              name="lastName"
              // title="Last Name"
              placeholder="Last Name"
              register={register}
              ref={lastNameInputRef}
              // errors={errors}
              className="popup-field w-100"
              onKeyDown={(e: any) => handleKeyDown(e, phoneInputRef)}
            />
          </div>
          <span className="error">{errors?.lastName?.message}</span>
          <div className="popup-field-wrapper mt-3">
            <img src={phone} alt="Password Icon" />
            <FormInput
              name="phone"
              type="number"
              
              placeholder="Enter the phone number"
              register={register}
              // errors={errors}
              className="popup-field w-100"
              ref={emailInputRef}
              onKeyDown={(e: any) => handleKeyDown(e, emailInputRef)}
            />
            {/* <PhoneInput
              inputStyle={{ width: '100%', border: 'none', background: 'none', fontSize: '15px', fontWeight: '600' }}
              buttonStyle={{ border: 'none' }}
              country={'IN'}
              placeholder="Enter the phone number"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            /> */}
          </div>
          <span className="error">{(errors as any)?.phone?.message}</span>
          <div className="popup-field-wrapper mt-3">
            <img src={mailicon} alt="Mail Icon" />
            <FormInput
              name="email"
              placeholder="Enter your email"
              register={register}
              // errors={errors}
              className="popup-field w-100"
              ref={emailInputRef}
              onKeyDown={(e: any) => handleKeyDown(e, emailInputRef)}
            />
          </div>
          <span className="error">{errors?.email?.message}</span>

          <div className="popup-field-wrapper mt-3">
            <img src={passwordicon} alt="Password Icon" />

            <FormInput
              name="password"
              type="password"
              placeholder="Enter your password"
              register={register}
              // errors={errors}
              className="popup-field w-100"
              ref={passwordInputRef}
              onKeyDown={(e: any) => handleKeyDown(e, emailInputRef)}
            />
          </div>
          <span className="error mb-3">{errors?.password?.message}</span>

          <Button
            type="submit"
            className="common-btn auth-btn mb-3"
            disabled={Object.keys(errors).length !== 0}
          >
            Signup
          </Button>
        </div>

        <div className="gap-3 max-w-450 mx-auto mb-4 my-1">
          <a
            className="loginwith-button google-btn d-block mb-3"
            onClick={handleGoogleLogin}
          >
            <img src={googleimage} alt="Google Icon" />
            Signup with Google
          </a>
          {/* <a className="loginwith-button facebook-btn d-block mb-3">
            <img src={facebookimage} alt="Facebook Icon" />
            Signup with Facebook
          </a> */}
          <a
            className={
              isFormVisible
                ? "loginwith-button signup-btn d-block mb-3 d-none"
                : "loginwith-button signup-btn d-block mb-3 d-block"
            }
            onClick={toggleFormVisibility}
          >
            <MdEmail /> Signup with Email
          </a>
        </div>
        <div className="text-center">
          <a className="signup-link">
            Already have an account?{" "}
            <span
              onClick={() => dispatch(setModalName(MODALNAME.LOGIN))}
              // onClick={() => dispatch(setModalName(MODALNAME.OTP_VERIFICATION))}
            >
              Log in
            </span>
          </a>
          <div className="text-center mt-0">
            or <br />
            <div
              
              className=" "
              onClick={() => {
                navigate("/source-signup"),
                  dispatch(setModalName(MODALNAME.CLOSED))
              }}
            >
              SignUp as a Tutor
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}
