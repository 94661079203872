import { Image } from 'image-js';
async function getCroppedImg(imageSrc:any, crop:any) {
  const image = await Image.load(imageSrc);
  const { x, y, width, height } = crop;
  const croppedImage = image.crop({ x, y, width, height });
  const blob = await croppedImage.toBlob('image/jpeg');
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
}

export default getCroppedImg;
