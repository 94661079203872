import React from "react"
import {
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap"
import styles from "./ChatUi.module.css"
import searchIcon from "../../assets/img/icon-search.svg"
const ChatBar = () => {
  return (
    //    { <Container className={`${style.chatWrapper} ${style.pt_200}`}>}

    <Row className={`${styles["chat-bar"]} py-3`}>
      <Col lg={5} xl={4}>
        <div className="position-relative">
          <input
            placeholder="Search"
            type="text"
            className={` form-control ${styles["search-field-2"]}`}
            value=""
          />
          <button
            type="button"
            className={` btn btn-transparent ${styles["search-icon-type--2"]}`}
          >
            <img src={searchIcon} alt="Search" />
          </button>
        </div>
      </Col>
      <Col lg={7} xl={8} className="d-flex justify-content-end mt-2 mt-lg-0">
        <Button
          variant="light"
          className={`  me-2 ${styles["rounded-xl"]} ${styles["white-btn"]}`}
        >
          Clear chat
        </Button>
        <Button
          variant="light"
          className={`  me-2 ${styles["rounded-xl"]} ${styles["white-btn"]}`}
        >
          More
        </Button>
      </Col>
    </Row>
  )
}

export default ChatBar
