import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const providerDashboardApi = createApi({
  reducerPath: "providerDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
       
      }
      return headers
    },
  }),
  tagTypes: [
    "providerAdvertisement",
    "providerRequests",
    "providerAccountDetails",
    "getPaymentsInvoices"
   
  ],
  endpoints: (builder) => ({
    providerlogin: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "provider/login",
        method: "POST",
        body: credentials,
      }),
      onQueryStarted(arg, {dispatch}) {
        dispatch(providerDashboardApi.util.invalidateTags(["providerAdvertisement","getPaymentsInvoices",
        "providerRequests",
        "providerAccountDetails"]))
      },
    }),
    getPaymentsInvoices: builder.query({
      query: (args: void) => ({
        url: "seeker/payment/get-payment",
      }),
      providesTags:["getPaymentsInvoices"]
    }),
    providerAccountDetails: builder.query({
      query: (args: void) => ({
        url: "/provider/get-provider-account-details",
        method: "GET",
      }),
      providesTags: ["providerAccountDetails"],
    }),
    providerAdvertisement: builder.query({
      query: (args: void) => ({
        url: "/provider/get-provider-ad",
        method: "Get",
      }),
      providesTags: ["providerAdvertisement"],
      invalidatesTags:["providerlogin"] as any
    }),
    providerRequests: builder.query({
      query: (args: void) => ({
        url: "/provider/get-provider-requests",
        method: "GET",
      }),
      providesTags: ["providerRequests"],
    }),
    providerRecievedReviews: builder.query({
      query: (args: void) => "/provider/get-provider-reviews",
      providesTags: ["providerAdvertisement"],
    }),
    providerRequestSorting: builder.mutation<any, any>({
      query: (sort) => ({
        url: `/provider/sort-requests?sortOrder=${sort}`,
      }),
    }),
    updateAccount: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/update-provider-account-details`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["providerAccountDetails", "providerAdvertisement"],
    }),
    changePassword: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/change-pass-provider-account`,
        method: "POST",
        body: data,
      }),
    }),
    deleteAccount: builder.mutation({
      query: (args: void) => ({
        url: `provider/delete-account`,
        method: "POST",
      }),
    }),
    sendReviewReply: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/send-review-reply`,
        method: "POST",
        body: data,
      }),
    }),
    addDeatils: builder.mutation<any, any>({
      query: (adddeatils) => ({
        url: `provider/create-update-ad`,
        method: "POST",
        body: adddeatils,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    updateAdvertisement: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/create-update-ad?adId=${data.id}`,
        method: "POST",
        body: data.servicedata,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    acceptDeleteRequest: builder.mutation<any, any>({
      query: (action) => ({
        url: `provider/accept-delete-request`,
        method: "POST",
        body: action,
      }),
      invalidatesTags: ["providerRequests"],
    }),
    providerSearch: builder.mutation<any, any>({
      query: (data) => ({
        url: `/provider/search-sort-filter-requests?searchOption=${data.search}&requestStatus=${data.status}&sortByDate=${data.sort}`,
      }),
      transformResponse: (rawData) =>
        transformProviderSearchRequestResponse(rawData),
    }),
    // chat Application
    chatWrite: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/chat-write`,
        method: "POST",
        body: data,
      }),
    }),
    uploadProfilepic: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/upload-profile-pic`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["providerAccountDetails"],
    }),
    uploadAdProfileDetails: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/upload-ad-media?adId=${data.id}`,
        method: "POST",
        body: data.adData,
      }),
    }),
    uploadProfileDocuments: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/upload-docs`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["providerAccountDetails"],
    }),
    updateAdStatus: builder.mutation<any, any>({
      query: (id) => ({
        url: `provider/update-ad-status`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    DeleteAd: builder.mutation<any, any>({
      query: (id) => ({
        url: `provider/delete-advertisement`,
        method: "delete",
        body: id,
      }),
      invalidatesTags: ["providerAdvertisement"],
    }),
    AskFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/ask-faq`,
        method: "POST",
        body: data,
      }),
    }),
    GetFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/get-ad-faq's?adId=${data?.adId}&pageNo=1&pageLimit=10`,
        method: "GET",
      }),
    }),
    ReplyFAQ: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/reply-update-ad-faq's`,
        method: "POST",
        body: data,
      }),
    }),
    AcceptRejectRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `provider/accept-delete-request`,
        method: "POST",
        body: data,
      }),
    }),
    getRejectedRequests: builder.query({
      query: (args: void) => ({
        url: "provider/get-rejected-requests",
        method: "GET",
      }),
    }),
    getProviderStatus: builder.query({
      query: (args: void) => ({
        url: "provider/get-exp-status",
        method: "GET",
      }),
    }),
    updateProviderStatus: builder.query({
      query: (args: void) => ({
        url: "provider/update-exp-status",
        method: "GET",
      }),
    }),
    providerPhoneVerification: builder.mutation({
      query: (args: void) => ({
        url: "provider/send-mobile-otp",
        method: "GET",
      }),
    }),

    providerVerifyPhoneOtp: builder.mutation({
      query: (data) => ({
        url: "provider/verify-mobile",
        method: "POST",
        body: data,
      }),
    }),
    getLocationSuggestion: builder.mutation({
      query: (search:any) => ({
        url: `static-data/get-location-suggestion?search=punjab=${search.value}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useProviderloginMutation,
  useGetPaymentsInvoicesQuery,
  useProviderAdvertisementQuery,
  useProviderRequestsQuery,
  useProviderRecievedReviewsQuery,
  useProviderRequestSortingMutation,
  useUpdateAccountMutation,
  useProviderAccountDetailsQuery,
  useChangePasswordMutation,
  useSendReviewReplyMutation,
  useUpdateAdvertisementMutation,
  useAcceptDeleteRequestMutation,
  useProviderSearchMutation,
  useChatWriteMutation,
  useDeleteAccountMutation,
  useUploadProfilepicMutation,
  useUploadAdProfileDetailsMutation,
  useUploadProfileDocumentsMutation,
  useUpdateAdStatusMutation,
  useDeleteAdMutation,
  useAskFAQMutation,
  useGetFAQMutation,
  useReplyFAQMutation,
  useAcceptRejectRequestMutation,
  useGetRejectedRequestsQuery,
  useGetProviderStatusQuery,
  useProviderPhoneVerificationMutation,
  useProviderVerifyPhoneOtpMutation,
  useUpdateProviderStatusQuery,
  useAddDeatilsMutation,
  useGetLocationSuggestionMutation

} = providerDashboardApi

const transformProviderSearchRequestResponse = (rawData: any) => {
  if (!rawData || !rawData.data) {
    return { data: [] }
  }
  return {
    message: rawData.message,
    totalRequests: rawData.totalRequests,
    data: rawData.data.map((item: any) => ({
      _id: item._id,
      date: item.updatedAt,

      adId: {
        _id: item.adId,
        vedicSubject: item.adData[0]?.vedicSubject || null,
      },
      sessionPreference: item.sessionPreference,
      requestMessage: item.requestMessage,
      requestStatus: item.requestStatus,
      updatedAt: item.updatedAt,
      seekerId: {
        firstName: item.seekerData[0]?.firstName || null,
        lastName: item.seekerData[0]?.lastName || null,
        profilePic: item.seekerData[0]?.profilePic || null,
      },
    })),
  }
}