import React from "react"
import { InputGroup, FormControl, Image, Badge, Stack } from "react-bootstrap"
import ProfileAccountImg from "../../assets/images/profile-a.png"
import ProfileAvatars from "./ProfileAvatars"

import styles from "./ChatUi.module.css"

const ChatList = () => {
  return (
    <>
      <div
        className={`${styles["chat-list"]} p-2 p-lg-3 pt-4 mb-2 bg-white ${styles["rounded-xl"]} h-100`}
      >
        <Stack
          direction="horizontal"
          gap={0}
          className="mb-1 align-items-start"
        >
          <div className={`p-1 ${styles["chat-imgbx"]} position-relative`}>
            <Image
              src={ProfileAccountImg}
              roundedCircle
              width={52}
              height={52}
              className="me-1"
            />
            <div className={styles["active-indicator"]}></div>
          </div>
          <div className="w-100">
            <div className="d-flex">
              <div className={`p-2 ${styles["chat-msg"]}`}>
                <h6 className="pb-1 m-0">John Doe</h6>
                <p className="m-0 text-truncate">Hello there!</p>
              </div>
              <div className="p-1 ms-auto">
                <div className={styles["time-text"]}>9:00</div>
                <Badge className={`${styles["orange-badge"]} ms-auto`}>5</Badge>
              </div>
            </div>
            <hr className="my-2" />
          </div>
        </Stack>
        <ProfileAvatars />
      </div>
    </>
  )
}

export default ChatList
