import React, { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Spinner,
} from "react-bootstrap"
import "../../Providerdashboard/Dashboardcomponent/DashboardComponent.css"
import dashboardimage from "../../../../assets/img/card-image.png"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import DashboardComponent from "../../Providerdashboard/Dashboardcomponent/DashboardComponent"
import classimage from "../../../../assets/img/card-image.png"
import {
  useSeekerRequestsQuery,
  useSeekerReviewsQuery,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import { Link } from "react-router-dom"
import RequestBlank from "../../Blanks/RequestBlank/RequestBlank"
import DashboardBlank from "../../Blanks/DashboardBlank/DashboardBlank"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import RequestCard from "../../commonDashboard/requestCard/RequestCard"
import DashRequestCard from "./DashRequestCard"
import SpinnerMain from "../../../spinner/SpinnerMain"
import { ThreeDots } from "react-loader-spinner"
import blankImage from "../../../../assets/images/blankDashboardImage.png"

const DashboardDesign = () => {
  const { data, isLoading, isFetching } = useSeekerRequestsQuery(null, {
    pollingInterval: 3600 * 5 * 1000,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  })
  const [types, setTypes] = useState("seeker")
  const [showAll, setShowAll] = useState(false)
  const [showAllRev, setShowAllRev] = useState(false)
  const { data: reviewData } = useSeekerReviewsQuery()

  return (
    <>
      {/* {isLoading && (
          <SpinnerSecondary />
      )} */}
      {/* <DashboardHeader type={types} /> */}
      <section className="py-0 bg-f9f9f9 mt-4">
        <Container>
          <Row>
            <Col lg={12} className="px-0">

              <section className="dashboard-status">
                <div className="status-stepper ">
                  <div id="employer-post-new-job">
                    <Col md={12}>
                      <div className="block-card mb-5">
                        <h4 className="mb-3 font-20 fw-semibold">
                          Class Request
                        </h4>
                        <div className="class-listing">
                          {data && data?.data.length > 0 ? (
                            data?.data
                              ?.slice(0, showAll ? data?.data?.length : 2)
                              .map((details: any) => {
                                return (
                                  <React.Fragment key={details._id}>

                                    <DashRequestCard details={details} />
                                  </React.Fragment>
                                )
                              })
                          ) : (

                            <>
                            <div className="d-flex justify-content-center align-items-center mt-2"> No Request Yet</div>
                             <div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-direction-column">
                               {/* <DashboardBlank text="No Request yet" /> */}
                               <div>
                                
                              <img src={blankImage} alt="No Ads Published"/>
                             </div>
                             </div>
                             </>
                          )}

                        </div>
                        {!showAll ? (
                          isLoading || isFetching ?
                            <SpinnerMain className="d-flex justify-content-center" /> :
                            <div
                              className="text-center mt-2"
                              onClick={() => setShowAll(true)}
                            >
                              {data?.data?.length > 2
                                ? "See all requests "
                                : ""}
                              <i className="fas fa-chevron-right"></i>
                            </div>
                        ) : (
                          <div
                            className="text-center mt-2"
                            onClick={() => setShowAll(false)}
                          >
                            Show less
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        )}
                      </div>
                      <div className="block-card mb-5">
                        <h4 className="mb-3">Reviews</h4>
                        <div className="table-responsive">
                          <table className="table table-borderless review-table">
                            <tbody>
                              {reviewData?.data.length > 0 ? (
                                reviewData?.data
                                  ?.slice(0, showAllRev ? reviewData?.data?.length : 2)
                                  .map((details: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center mb-md-0 mb-4">
                                          <div className="user-img me-3">
                                            <img src={dashboardimage} alt="User" />
                                          </div>
                                          <div>
                                            <div>
                                              <h3 className="class-name d-block">
                                                {details?.providerId?.firstName}
                                              </h3>
                                              <p className="occ-name">
                                                {details?.providerId?.skills[0]}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <p className="review-text">{details.message}</p>
                                      </td>
                                      <td className="text-end">
                                        <Link
                                          className="review-link"
                                          to="/seeker-dashboard/write-review"
                                          state={{
                                            data: reviewData,
                                            index,
                                          }}
                                        >
                                          Write a review
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <>
                            <div className="d-flex justify-content-center align-items-center mt-2"> No Reviews Yet</div>
                             <div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-direction-column">
                               {/* <DashboardBlank text="No Request yet" /> */}
                               <div>
                              <img src={blankImage} alt="No Ads Published"/>
                             </div>
                             </div>
                             </>
                              )}
                              {reviewData?.data.length > 0 && (
                                <tr key="additional-review">
                                  <td>
                                    <div className="d-flex align-items-center mb-md-0 mb-4">
                                      <div className="user-img me-3">
                                        <img src={dashboardimage} alt="User" />
                                      </div>
                                      <div>
                                        <div>
                                          <h3 className="class-name d-block">Vivek Jain</h3>
                                          <p className="occ-name">Yoga Master</p>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-end">
                                    <a href="#" className="review-link evaluate">
                                      Evaluate
                                    </a>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {!showAllRev ? (
                          <div
                            className="text-center mt-4"
                            onClick={() => setShowAllRev(true)}
                          >
                            <a href="#" className="all-req-link review-all">
                              {reviewData?.data?.length > 2
                                ? "See all reviews"
                                : ""}
                              <i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        ) : (
                          <div
                            className="text-center mt-4"
                            onClick={() => setShowAllRev(false)}
                          >
                            <a href="#" className="all-req-link review-all">
                              See less
                              <i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default DashboardDesign
