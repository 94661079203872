import React, { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Collapse,
  Table,
  Spinner,
} from "react-bootstrap"
import DashboardHeader from "../../../navbars/DashNav/DashNav"
import reviewimage from "../../../../assets/img/card-image.png"
import "../../Providerdashboard/receivedReview/ReceivedReview.css"
import ReviewCard from "../../commonDashboard/reviewCard/ReviewCard"
import WriteReview from "./WriteReview"
import {
  useSeekerReviewsQuery,
  useSeekerSentReviewsQuery,
} from "../../../../features/featSeekerDashboard/seekerDashboardApi"
import { useNavigate } from "react-router-dom"
import ReviewBlank from "../../Blanks/ReviewsBlank/ReviewBlank"
const ReceivedReviewCustomer = () => {
  const [types, setTypes] = useState("seeker")
  const [show, setShow] = useState<boolean>(false)
  const { data, isLoading } = useSeekerReviewsQuery()
  const { data: sentReviews } = useSeekerSentReviewsQuery(1)
  const [id, setId] = useState()
  const navigate = useNavigate()
  const handleClose = (id: any) => {
    navigate("/seeker-dashboard/write-review", {
      state: { data: data, index: id },
    })
    // setShow(true)
    // setId(id)
  }
  return (
    <>
      {isLoading && (
        <div className="spin-loading">
          <Spinner />
        </div>
      )}
      <section className="my-account-sec bg-f9f9f9 py-3">
        <Container fluid>
          <div className="inner-account">
            <Row>
              <Col xl={7} lg={9}>
                <div className="evolution-heading">
                  <h3 className="evolution-heading my-3">My Reviews</h3>

                  {data?.data.length > 0 ? (
                    data?.data?.map((reviews: any, index: number) => {
                      return (
                        <Card className="border-0 review-card px-3 mb-2" key={index}>
                          <Card.Body className="p-0">
                            <ReviewCard
                              id={index}
                              name={reviews?.providerId?.firstName}
                              course={"yoga master"}
                              para={reviews.message}
                              btnStr={"Write a review"}
                              handleClose={handleClose}
                              userType={""}
                            />
                          </Card.Body>
                        </Card>
                      )
                    })
                  ) : (
                    <ReviewBlank />
                  )}

                  <div className="complete-profile mt-4">
                    <h3 className="complete-para text-start mb-4">
                      Reviews Sent
                    </h3>
                    {sentReviews?.data?.length > 0 ? (
                      sentReviews?.data?.map((review: any, index: number) => {
                        return (
                          <div className="review-card px-3 mt-2" key={index}>
                            <div className="review-list">
                              <div className="review-user d-flex align-items-center me-lg-3">
                                <img src={reviewimage} className="review-img" />
                                <div>
                                  <h3 className="class-name d-block">
                                    {review.providerId.firstName}
                                  </h3>
                                  <p className="occ-name">
                                    {" "}
                                    {review.providerId.skills}
                                  </p>
                                </div>
                              </div>
                              <div className="review-post">
                                <p className="">{review?.message}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <ReviewBlank />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default ReceivedReviewCustomer
