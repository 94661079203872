import React from "react"
import ReactDOM from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ErrorBoundary from "./errorBoundary/ErrorBoundary"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import { globalToastStyle, progressStyle } from "./utils/toast/ToastStyles"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback="Something went wrong...">
      <Provider store={store}>
        <App />
        <ToastContainer
          position="bottom-right"
          toastStyle={globalToastStyle}
          autoClose={1500}
          progressStyle={progressStyle}
          closeButton={false}
        />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
)
